import gjs from "grapesjs";

function script() {
    if (!this) return;
    if (this.getAttribute("data-gjs-type")) return;

    let elem = this.querySelector("a");
    if (!elem) return;

    const getParamsFromURLObject = (url) => {
        let firstPart = url.split("?");
        if (firstPart.length !== 2) return {};
        let secondPart = firstPart[1].split("&");
        let final = {};
        for (let item of secondPart) {
            let tmp = item.split("=").filter(tmp2 => tmp2);
            if (tmp.length === 2) {
                final[decodeURI(tmp[0])] = decodeURI(tmp[1]);
            };
        };
        return final;
    };

    elem.addEventListener("click", async e => {
        if (window.sctrk) {
            try {
                await window.sctrk.redirect();
            } catch {};
        };
        
        let params = getParamsFromURLObject(String(window.location));
        params["lp"] = "1";
    
        let finalLocation = String(window.location).split("?")[0];
    
        Object.keys(params).forEach((key, keyIndex) => {
            finalLocation += `${keyIndex === 0 ? "?" : "&"}${key}=${params[key]}`;
        });
        window.location = finalLocation;
    });
};

/**
 * @param {gjs.Editor} editor
 */
export default editor => {
    editor.Components.addType('scalecms-redirectLander', {
        isComponent: e => e?.classList?.contains("scalecms-redirectLander"),
        model: {
            defaults: {
                textable: 1,
                attributes: {
                    class: "scalecms-redirectLander"
                },
                type: "default",
                script,
                styles: `
                    .scalecms-redirectLander {
                        display: inline-block;
                        padding: 5px 0px;
                    }
                `,
                components: {
                    type: "link",
                    components: `Go to lander`,
                    traits: [],
                    attributes: {
                        href: "#"
                    },
                    draggable: false,
                    removable: false,
                    copyable: false
                }
            }
        }
    });


    editor.BlockManager.add('scalecms-redirectLander-blk', {
        label: 'Redirect to lander',
        content: {
            type: "scalecms-redirectLander",
        },
        category: {
            label: 'Interactions'
        },
        media: `<svg style="width: 100%; height: auto;" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.0001 18.3333C8.8473 18.3333 7.76397 18.1146 6.75008 17.6771C5.73619 17.2396 4.85425 16.6458 4.10425 15.8958C3.35425 15.1458 2.7605 14.2639 2.323 13.25C1.8855 12.2361 1.66675 11.1528 1.66675 9.99999C1.66675 8.84721 1.8855 7.76388 2.323 6.74999C2.7605 5.7361 3.35425 4.85416 4.10425 4.10416C4.85425 3.35416 5.73619 2.76041 6.75008 2.32291C7.76397 1.88541 8.8473 1.66666 10.0001 1.66666C11.1529 1.66666 12.2362 1.88541 13.2501 2.32291C14.264 2.76041 15.1459 3.35416 15.8959 4.10416C16.6459 4.85416 17.2397 5.7361 17.6772 6.74999C18.1147 7.76388 18.3334 8.84721 18.3334 9.99999C18.3334 11.1528 18.1147 12.2361 17.6772 13.25C17.2397 14.2639 16.6459 15.1458 15.8959 15.8958C15.1459 16.6458 14.264 17.2396 13.2501 17.6771C12.2362 18.1146 11.1529 18.3333 10.0001 18.3333ZM9.10425 17.0625V15.3542C8.61814 15.3542 8.20841 15.1736 7.87508 14.8125C7.54175 14.4514 7.37508 14.0278 7.37508 13.5417V12.625L3.10425 8.35416C3.0348 8.63193 2.98619 8.90624 2.95841 9.17707C2.93064 9.44791 2.91675 9.72221 2.91675 9.99999C2.91675 11.8055 3.50355 13.3819 4.67716 14.7292C5.85078 16.0764 7.32647 16.8542 9.10425 17.0625ZM15.2292 14.8125C15.5348 14.4792 15.8022 14.125 16.0313 13.75C16.2605 13.375 16.4549 12.9826 16.6147 12.5729C16.7744 12.1632 16.8924 11.743 16.9688 11.3125C17.0452 10.8819 17.0834 10.4444 17.0834 9.99999C17.0834 8.52777 16.6806 7.19096 15.8751 5.98957C15.0695 4.78818 13.9931 3.90971 12.6459 3.35416V3.72916C12.6459 4.21527 12.4792 4.63888 12.1459 4.99999C11.8126 5.3611 11.4029 5.54166 10.9167 5.54166H9.10425V7.35416C9.10425 7.59027 9.0105 7.78471 8.823 7.93749C8.6355 8.09027 8.42369 8.16666 8.18758 8.16666H6.45841V9.99999H11.8334C12.0695 9.99999 12.264 10.0903 12.4167 10.2708C12.5695 10.4514 12.6459 10.6597 12.6459 10.8958V13.5417H13.5417C13.9445 13.5417 14.2987 13.6597 14.6042 13.8958C14.9098 14.1319 15.1181 14.4375 15.2292 14.8125Z" fill="white"/>
            </svg>`,
        attributes: {
            title: 'Redirect a user from prelander to lander page.',
        },
        select: true
    });
};