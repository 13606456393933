import { js_beautify } from "js-beautify";

function htmlDecode(input) {
  var doc = new DOMParser().parseFromString(input, "text/html");
  return doc.documentElement.textContent;
}

function extractScriptData(html, type = "script") {
  let tmp = document.createElement("p");
  tmp.innerHTML = html;
  let scr = tmp.querySelector(type);

  if (!scr) return {};
  const attrs = scr.getAttributeNames().reduce((acc, name) => {
    return { ...acc, [name]: scr.getAttribute(name) };
  }, {});

  return attrs;
};

function createAttributeStringFromObj(attrs) {
  let out = [];

  for (let key of Object.keys(attrs)) {
    if (key.includes("=")) continue;
    out.push(`//# has:${key}${attrs[key] ? `=${attrs[key]}` : ""}`);
  };
  if (out.length > 0) {
    return `${out.join("\n")}\n\n`;
  } else {
    return "";
  };
};

function createObjFromAttributeString(str) {
  let out = {};

  if (!str.startsWith("//# has:")) return;
  str = str.split(":");
  str.shift();
  while (str.length > 0 && str[0] === ":") str.shift();
  str = str.join(":").split("=");
  if (str.length > 0) {
    out[str.shift()] = str.join("=")
  };
  return out;
};

function scriptToPre(txt) {
  if (!txt) return "";
  let final = [];

  function checkScript() {
    let matches = txt.match(/<script[\s\S]*?>[\s\S]*?<\/script>/gi);
    if (!matches) return txt;
    for (let item of matches) {
      let attrString = createAttributeStringFromObj(extractScriptData(item));
  
      let tmp = document.createElement("span");
      tmp.innerHTML = item;
      let finalScript = tmp?.querySelector("script")?.innerHTML ?? "";
      finalScript = js_beautify(finalScript).split("\n");
      let out = [];
      let wrapFound = false;
      for (let item2 of finalScript) {
        if (!wrapFound && item2 === "docReady(() => {" && finalScript.indexOf(item2) === 0) {
          wrapFound = true;
          continue;
        } else if (wrapFound && item2 === "});" && finalScript.indexOf(item2) === finalScript.length-1) {
          wrapFound = false;
          continue;
        };
        out.push(item2);
      };
  
  
      tmp = `<pre data-gjs-highlightable="0" type='script' style='max-width: 100%; border: 1px solid gray; padding: 5px; background-color: #272727; color: white; font-family: sans-serif Roboto; overflow-x:auto;'>\n`;
      tmp += `//# script\n\n`;
      tmp += attrString;
      tmp += `${js_beautify(out.join("\n"))}\n\n`;
      tmp += `//# /script`;
      tmp += `</pre>`;
      final.push({ old: item, new: tmp });
    };
  };

  function checkLink() {
    let matches = txt.match(/<link[\s\S]*?\/?>/gi);
    if (!matches) return txt;
    for (let item of matches) {
      let attrString = createAttributeStringFromObj(extractScriptData(item, "link"));
  
      let tmp = document.createElement("span");
      tmp.innerHTML = item;
      let finalLink = tmp?.querySelector("link")?.innerHTML ?? "";
  
      tmp = `<pre data-gjs-highlightable="0" type='link' style='max-width: 100%; border: 1px solid gray; padding: 5px; background-color: #272727; color: white; font-family: sans-serif Roboto; overflow-x:auto;'>\n`;
      tmp += `//# link\n\n`;
      tmp += attrString;
      tmp += `${finalLink}\n\n`;
      tmp += `//# /link`;
      tmp += `</pre>`;
      final.push({ old: item, new: tmp });
    };
  };

  function checkStyle() {
    let matches = txt.match(/<style(z?)[\s\S]*?>[\s\S]*?<\/style(z?)>/gi);
    if (!matches) return txt;
    for (let item of matches) {
      let attrString = createAttributeStringFromObj(extractScriptData(item, "style"));
  
      let tmp = document.createElement("span");
      tmp.innerHTML = item;
      let finalLink = tmp?.querySelector("stylez")?.innerHTML ?? "";
  
      tmp = `<pre data-gjs-highlightable="0" type='style' style='max-width: 100%; border: 1px solid gray; padding: 5px; background-color: #272727; color: white; font-family: sans-serif Roboto; overflow-x:auto;'>\n`;
      tmp += `//# style\n\n`;
      tmp += attrString;
      tmp += `${finalLink}\n\n`;
      tmp += `//# /style`;
      tmp += `</pre>`;
      final.push({ old: item, new: tmp });
    };
  };

  checkScript();
  checkLink();
  checkStyle();

  for (let item of final) {
    txt = txt.replace(item.old, item.new);
  };

  return txt;
};

function preToScript(pre, generateInline = true) {
  if (!pre) return "";
  let final = [];
  
  function checkScript() {
    let matches = pre.match(/<pre type="script"[\s\S]*?>[\s\S]*?<\/pre>/gi);
    if (!matches) return;
  
    for (let item of matches) {
      let tmp = document.createElement("span");
      tmp.innerHTML = item;
      tmp = tmp.querySelector("pre")?.innerHTML ?? "";
  
      tmp = tmp.replace("//# script\n", "").replace("\n//# /script", "");
  
      let attrs = {};
      let out = [];
  
      for (let item2 of tmp.split("\n")) {
        if (item2.startsWith("//# has:")) {
          attrs = { ...attrs, ...createObjFromAttributeString(item2) };
        } else {
          out.push(item2);
        };
      };
  
      tmp = document.createElement("script");
      tmp.innerHTML = `\n${generateInline ? `docReady(() => {\n` : ""}${htmlDecode(out.join("\n").trim())}\n${generateInline ? "});" : ""}`;
      for (let key of Object.keys(attrs)) {
        tmp.setAttribute(key, attrs[key]);
      };
  
      final.push({ old: item, new: tmp.outerHTML });
    };
  };

  function checkLink() {
    let matches = pre.match(/<pre type="link"[\s\S]*?>[\s\S]*?<\/pre>/gi);
    if (!matches) return;
  
    for (let item of matches) {
      let tmp = document.createElement("span");
      tmp.innerHTML = item;
      tmp = tmp.querySelector("pre")?.innerHTML ?? "";
  
      tmp = tmp.replace("//# link\n", "").replace("\n//# /link", "");
  
      let attrs = {};
      let out = [];
  
      for (let item2 of tmp.split("\n")) {
        if (item2.startsWith("//# has:")) {
          attrs = { ...attrs, ...createObjFromAttributeString(item2) };
        } else {
          out.push(item2);
        };
      };
  
      tmp = document.createElement("link");
      tmp.innerHTML = `\n${htmlDecode(out.join("\n").trim())}\n`;
      for (let key of Object.keys(attrs)) {
        tmp.setAttribute(key, attrs[key]);
      };
  
      final.push({ old: item, new: tmp.outerHTML });
    };
  };

  function checkStyle() {
    let matches = pre.match(/<pre type="style"[\s\S]*?>[\s\S]*?<\/pre>/gi);
    if (!matches) return;
  
    for (let item of matches) {
      let tmp = document.createElement("span");
      tmp.innerHTML = item;
      tmp = tmp.querySelector("pre")?.innerHTML ?? "";
  
      tmp = tmp.replace("//# style\n", "").replace("\n//# /style", "");
  
      let attrs = {};
      let out = [];
  
      for (let item2 of tmp.split("\n")) {
        if (item2.startsWith("//# has:")) {
          attrs = { ...attrs, ...createObjFromAttributeString(item2) };
        } else {
          out.push(item2);
        };
      };
  
      tmp = document.createElement("stylez");
      tmp.innerHTML = `\n${htmlDecode(out.join("\n").trim())}\n`;
      for (let key of Object.keys(attrs)) {
        tmp.setAttribute(key, attrs[key]);
      };
  
      final.push({ old: item, new: tmp.outerHTML });
    };
  };

  checkScript();
  checkLink();
  checkStyle();

  for (let item of final) {
    pre = pre.replace(item.old, item.new);
  };

  return pre;
};

export default (editor, options) => {
  options = options || {};

  addHTMLCodeEditor();
  addHTMLCodeComponent();
  addHTMLCodeBlock();

  function addHTMLCodeEditor() {
    editor.Commands.add("open-html-code-editor", {
      run: function (editor, sender, data) {
        var component = editor.getSelected();

        var codeViewer = editor.CodeManager.getViewer("CodeMirror").clone();
        codeViewer.set({
          codeName: "htmlmixed",
          theme: "hopscotch",
          readOnly: false
        });

        var modalContent = document.createElement("div");

        var editorTextArea = document.createElement("textarea");

        var saveButton = document.createElement("button");
        saveButton.innerHTML = "Save";
        saveButton.className = "gjs-btn-prim";
        saveButton.style = "margin-top: 8px;";
        saveButton.onclick = function () {
          component.set("content", "");
          component.components(scriptToPre(codeViewer.editor.getValue()));
          editor.Modal.close();
        };

        modalContent.appendChild(editorTextArea);
        modalContent.appendChild(saveButton);

        codeViewer.init(editorTextArea);

        var htmlContent = document.createElement("div");
        htmlContent.innerHTML = component.toHTML();
        htmlContent = htmlContent.firstChild.innerHTML;

        codeViewer.setContent(preToScript(htmlContent, false));

        editor.Modal
          .setTitle("Edit HTML")
          .setContent(modalContent)
          .open();

        codeViewer.editor.refresh();
      }
    });
  };

  function addHTMLCodeComponent() {
    var defaultType = editor.DomComponents.getType('default');

    var _initToolbar = defaultType.model.prototype.initToolbar;

    editor.DomComponents.addType('html-code', {
      model: defaultType.model.extend({
        initToolbar(args) {
          _initToolbar.apply(this, args);

          var toolbar = this.get("toolbar");
          toolbar.push({
            attributes: { "class": "fa fa-code" },
            command: "open-html-code-editor"
          });
          this.set("toolbar", toolbar);
        },
      }, {
        isComponent: function (el) {
          if (typeof el.hasAttribute == "function" && el.hasAttribute("data-html-code")) {
            el.innerHTML = scriptToPre(el.innerHTML);
            return { type: "html-code" };
          }
        }
      }),
      view: defaultType.view,
    });

  };

  function addHTMLCodeBlock() {
    editor.BlockManager.add("html-code", {
      media: `
        <svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16 11L17.6965 12.5268C19.239 13.9151 20.0103 14.6093 20.0103 15.5C20.0103 16.3907 19.239 17.0849 17.6965 18.4732L16 20" stroke="#fff" stroke-width="1.5" stroke-linecap="round"/>
        <path d="M8.00005 4.82959L6.30358 6.35641C4.76102 7.74471 3.98975 8.43886 3.98975 9.32959C3.98975 10.2203 4.76102 10.9145 6.30358 12.3028L8.00005 13.8296" stroke="#fff" stroke-width="1.5" stroke-linecap="round"/>
        <path d="M13.9868 5L12.9934 8.70743M11.8432 13L10.0132 19.8297" stroke="#fff" stroke-width="1.5" stroke-linecap="round"/>
        </svg>
      `,
      attributes: { class: "fa fa-code" },
      label: "HTML Code",
      content: '<div data-html-code>Edit my HTML content</div>',
      category: {
        label: 'Interactions'
      },
    });
  };

};

export { scriptToPre, preToScript }