import React from "react";
import "./index.scss";


const DocMobileTemplates = (props) => {

  return <div className="route__documentation">
    <div className="route__documentation__headline">
      <p>Mobile Templates</p>
    </div>
    <div className="route__documentation__desc">
      <p>Mobile Templates are finished templates and ready to use as a website</p>
      <p>Currently we have template as Messenger, Instagram, Viber, Whatsapp</p>
      <p>Altho these templates require Message Component to be inserted into them to have full functionality</p>
    </div>
    <div className="route__documentation__introBlock">
      <div className="route__documentation__introBlock__left">
        <img src="/images/tutorials/mobiletemplates/MobileTemplateStep1.png" />
      </div>
      <div className="route__documentation__introBlock__right">
        <p>Firstly we have to click on the '+' icon to open the side menu dropdown</p>
        <p>Then we have to select the 'Templates' tab in the menu</p>
        <p>After we select the Templates, we're offered with different options of Templates</p>
        <p>In example we will use messenger template.</p>
      </div>
    </div>
    <div className="route__documentation__introBlock flex-column">
      <div className="route__documentation__introBlock__right">
        <p>After we drag it, our website it should look something like this</p>
      </div>
      <div className="route__documentation__introBlock__left">
        <img src="/images/tutorials/mobiletemplates/MobileTemplateStep2.png" />
      </div>
    </div>

    <div className="route__documentation__desc">
      <p>There are many Templates to choose from the CMS, pick the one most suiting for your needs.</p>
      <p>Note that all the text, colors, layouts can be changed through the CMS, also images should be uploaded</p>
    </div>

    <div className="route__documentation__introBlock">
      <div className="route__documentation__introBlock__left">
        <img src="/images/tutorials/mobiletemplates/MobileTemplateStep3.png" />
      </div>
      <div className="route__documentation__introBlock__right">
        <p>In the component settings we can preview the form.</p>
        <p>We can also modify that form through CMS, to change colors, texts, dimensions etc</p>
      </div>
    </div>

    <div className="route__documentation__desc">
      <p>To fully have functional mobile templates, we need to add message component into it.</p>
    </div>

    <div className="route__documentation__introBlock">
      <div className="route__documentation__introBlock__left">
        <img src="/images/tutorials/mobiletemplates/MobileTemplateStep4.png" />
      </div>
      <div className="route__documentation__introBlock__right">
        <p>Message Component can be found in the Templates tab on the sidebar</p>
      </div>
    </div>

    <div className="route__documentation__introBlock flex-column">
      <div className="route__documentation__introBlock__right">
        <p>Message Component should be dragged into the body od a chosen template</p>
        <p>It should look like this</p>
      </div>
      <div className="route__documentation__introBlock__left">
        <img src="/images/tutorials/mobiletemplates/MobileTemplateStep5.png" />
      </div>
    </div>

    <div className="route__documentation__introBlock">
      <div className="route__documentation__introBlock__left">
        <img src="/images/tutorials/mobiletemplates/MobileTemplateStep6.png" />
      </div>
      <div className="route__documentation__introBlock__right">
        <p>Settings of a Message Component</p>
        <p>Secs to Show - is the amount of seconds that will take for a message to show up</p>
        <p>Select type - is the type of the message, select it based on template selected</p>
      </div>
    </div>

    <div className="route__documentation__introBlock flex-column">
      <div className="route__documentation__introBlock__right">
        <p>Fully modified template after user interacts with it will look like this</p>
        <p>Note that changes can be applied through the CMS</p>
      </div>
      <div className="route__documentation__introBlock__left">
        <img src="/images/tutorials/mobiletemplates/MobileTemplateStep7.png" />
      </div>
    </div>


  </div>
};

export default DocMobileTemplates;
export { DocMobileTemplates };