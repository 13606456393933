import gjs from "grapesjs";
import axios from "axios";

import * as backendModule from "../../../../modules/backendModule";

function script() {

  eval(`(()=>{

  if (!this) return;

  const recensionName = this.getAttribute('recensionname');
  const recensionText = this.getAttribute('recensiontext');
  const userImage = this.getAttribute('userimage');
  const textImage = this.getAttribute('textimage');
  const rating = this.getAttribute('rating');
  const date = this.getAttribute('date');
  const index = this.getAttribute('indexvalue');
  const replyRecensions = this.getAttribute('replyrecensions');

  const today = new Date();

  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  const dayBeforeYesterday = new Date();
  dayBeforeYesterday.setDate(dayBeforeYesterday.getDate() - 2);

  const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
  const formattedToday = today.toLocaleDateString('en-GB', options);
  const formattedYesterday = yesterday.toLocaleDateString('en-GB', options);
  const formattedDayBeforeYesterday = dayBeforeYesterday.toLocaleDateString('en-GB', options);


  let preImage = this.querySelector(".scalecms-recension-body-image");
  if (textImage) {
    preImage.src = \`\${textImage}\`
  } else {
    preImage.style.display = "none";
  }

  if (rating) {
    let stars = this.querySelectorAll(".scalecms-recension-head-stars-star");
    for (let index = 0; index < stars.length; index++) {
      const element = stars[index];
      if(index < Number(stars.length)-1){
        element.classList.add("scalecms-recension-head-stars-star-filled");
      }
      
    }
  }

  let dateValueText = '';

  if (index && index < 6) {
    dateValueText = formattedToday;
  } else if (index && index <= 14) {
    dateValueText = formattedYesterday;
  } else if (index && index > 14){
    dateValueText = formattedDayBeforeYesterday;
  }else{
    dateValueText = '';
  }

  if (date) {
    let datePara = this.querySelector(".scalecms-recension-head-info-date");
    datePara.textContent = dateValueText;
  } else {
    let datePara = this.querySelector(".scalecms-recension-head-info-date");
    datePara.textContent = '';
  }

  const regex = /\{\{\{([^}]+)\}\}\}/g;
  const modifiedRecensionText = recensionText.replace(regex, (match, group) => {
    return \`<span class="scalecms-recension-body-text-btn scroll-to">\${group}</span>\`;
  });

  let headline = this.querySelector(".scalecms-recension-head-info-heading")
  let text = this.querySelector(".scalecms-recension-body-text")

  headline.textContent = recensionName;
  text.innerHTML = modifiedRecensionText;

  let recensionScrollToBtns = this.querySelectorAll(".scroll-to");
  let recensionScrollTarget = document.querySelector(".scroll-target");
  if(!recensionScrollTarget) recensionScrollTarget = document.querySelector(".present__form");
  if (recensionScrollToBtns.length) {
    for (let elem of recensionScrollToBtns) {
      elem.addEventListener('click', () => {
        recensionScrollTarget.scrollIntoView({ behavior: 'smooth' });
      });
    }
  }

  })()`)

}

/**
 * @param {gjs.Editor} editor
 */
export default editor => {

  editor.Components.addType('scalecms-recension-layout2', {
    isComponent: e => e?.classList?.contains("scalecms-recension"),
    model: {
      async init() {
        if (!this) return;
      },

      defaults: {
        type: "default",
        traits: [
          {
            type: 'select',
            label: 'Dropdown',
            name: 'dropdown',
            options: [],
            changeProp: 1
          },
        ],
        attributes: {
          class: "scalecms-recension",
          dataOfferName: "apolo kosa",
        },
        script,
        styles: `
          .scalecms-recension {
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            display: flex;
            flex-direction: column;
            min-height: 70px;
            height: fit-content;
            max-width: 600px;
            min-width: 500px;
            background-color: #D7F0FF;
            padding: 14px;
            border: 1px solid var(--Card-card-brd, #222425);
            border-radius: 10px;
          }
          @media screen and (max-width: 800px) {
            .scalecms-recension {
              min-width: 300px
            }
          }
          .scalecms-recension-head{
            display: flex;
            align-items: center;
            width: 100%;
          }
          .scalecms-recension-head-info{
            display: flex;
            flex-direction: column;
            gap: 4px;
          }
          .scalecms-recension-head-info-heading{
            font-family: Helvetica Neue;
            font-size: 24px;
            font-weight: 500;
            line-height: 29.3px;
            text-align: left;
            color: #222425;
          }
          .scalecms-recension-head-info-date{
            font-family: Inter;
            font-size: 12px;
            font-weight: 400;
            line-height: 14.52px;
            text-align: left;
            color: #676767;
          }
          .scalecms-recension-head-stars{
            font-size: 16px;
            color: #1E1E1E99;
            display: flex;
          }
          .scalecms-recension-head-stars-star-filled {
            color: #FDBF00;
          }
          .scalecms-recension-body{
            margin-top: 12px;
          }
          .scalecms-recension-body-text{
            font-family: Inter;
            font-size: 14px;
            font-weight: 400;
            line-height: 16.94px;
            text-align: left;
            color: #222425;
          }
        `,
        components: [
          {
            tagName: 'div',
            attributes: { class: "scalecms-recension-head" },
            components: [
              {
                tagName: 'div',
                attributes: { class: "scalecms-recension-head-info" },
                components: [
                  {
                    type: 'text',
                    attributes: { class: "scalecms-recension-head-info-heading" },
                    components: "Ime osobe"
                  },
                  {
                    type: 'text',
                    attributes: { class: "scalecms-recension-head-info-date" },
                    components: "datum"
                  },
                  {
                    tagName: 'div',
                    attributes: { class: "scalecms-recension-head-stars" },
                    components: [
                      {
                        type: 'span',
                        attributes: { class: "scalecms-recension-head-stars-star" },
                        components: "&#9733"
                      },
                      {
                        type: 'span',
                        attributes: { class: "scalecms-recension-head-stars-star" },
                        components: "&#9733"
                      },
                      {
                        type: 'span',
                        attributes: { class: "scalecms-recension-head-stars-star" },
                        components: "&#9733"
                      },
                      {
                        type: 'span',
                        attributes: { class: "scalecms-recension-head-stars-star" },
                        components: "&#9733"
                      },
                      {
                        type: 'span',
                        attributes: { class: "scalecms-recension-head-stars-star" },
                        components: "&#9733"
                      },
                    ]
                  },
                ]
              },
            ]
          },
          {
            tagName: 'div',
            attributes: { class: "scalecms-recension-body" },
            components: [
              {
                type: 'text',
                attributes: { class: "scalecms-recension-body-text" },
                components: "Text komentara..."
              },
              {
                type: 'image',
                attributes: { class: "scalecms-recension-body-image" }
              },
            ]
          }
        ]
      }
    }
  });


  editor.BlockManager.add('scalecms-recension-blk', {
    label: 'Recension Layout 2',
    content: {
      type: "scalecms-recension-layout2",
    },
    category: {
      label: 'Not Used'
    },
    media: `
      <?xml version="1.0" encoding="utf-8"?>
      <svg version="1.1" id="Layer_1" x="0px" y="0px" width="100px" height="100px" viewBox="0 0 100 100" xml:space="preserve" enable-background="white" background="#fff">
        <g>
          <path fill="#FFFFFF" d="M88.466,16.936c-0.172-1.703-1.592-3.036-3.34-3.036H14.781v0.009c-1.705,0.047-3.079,1.355-3.247,3.027H11.5v56.342h0.068
            c0.305,1.512,1.622,2.65,3.214,2.694v0.009h11.564v6.744c0,1.864,1.51,3.374,3.374,3.374c1.165,0,2.192-0.591,2.798-1.49
            l8.629-8.629h43.979c1.634,0,2.995-1.161,3.306-2.703H88.5V16.936H88.466z"></path>
        </g>
      </svg>
    `,
    attributes: {
      title: 'Recension Layout2',
    },
    select: true
  });
};