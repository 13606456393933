import gjs from "grapesjs";

/**
 * @param {gjs.Editor} editor
 */
export default editor => {
    editor.Components.addType('scalecms-sidebarTwo', {
        isComponent: e => e?.classList?.contains("scalecms-sidebarTwo"),
        model: {
            init(){
                this.on('change', e => {
                    let visibleUntil = e?.attributes?.attributes?.['data-maxwidth'];
                    if(isNaN(Number(visibleUntil))) return;
                    if(Number(visibleUntil) < 0) return;

                    this.get("components").each(child => {
                        if(child?.attributes?.tagName === 'style'){
                            child.components(
                            `
                                @media screen and (max-width: ${visibleUntil}px){
                                    .scalecms-sidebarTwo{
                                        display: none;
                                    }
                                }
                            `
                            );
                        }
                    })
                });
            },
            defaults: {
                type: "default",
                traits: [
                    { name: "data-maxwidth", label: "Visible until", placeholder: "600", value: "0" },
                ],
                attributes: {
                    class: "scalecms-sidebarTwo"
                },
                styles: `
                    .scalecms-sidebarTwo {
                        width: 100%;
                        background: transparent;
                        padding: 20px;
                        font-family: 'Arial';
                    }
                    .scalecms-sidebarTwo-search{
                        display: flex;
                        flex-direction: row;
                        justify-content: baseline;
                        align-items: center;
                        padding: 10px;
                        border-bottom: 3px solid rgba(63, 103, 197, 1);
                        box-shadow: 0 0 2px gray;
                        background: white;
                    }
                    .scalecms-sidebarTwo-search-input{
                        border: 1px solid gray;
                        outline: none;
                        height: 2.5rem;
                        width: 100%;
                        padding: 5px;
                    }
                    .scalecms-sidebarTwo-search-button{
                        background: linear-gradient(to bottom, rgba(49, 85, 171, 1) 0%, rgba(63, 103, 197, 1) 100%);
                        padding: 5px;
                        height: 2.5rem;
                        cursor: pointer;
                    }
                    .scalecms-sidebarTwo-heading{
                        margin-top: 20px;
                        font-size: 22px;
                        font-weight: 700;
                        color: #1f326b;
                    }
                    .scalecms-sidebarTwo-article{
                        display: flex;
                        flex-direction: row;
                        justify-content: baseline;
                        align-items: center;
                        margin-top: 20px;
                        box-shadow: 0 0 2px gray;
                        padding: 10px;
                        border-bottom: 3px solid rgba(63, 103, 197, 1);
                        background: white;
                        cursor: pointer;
                    }
                    .scalecms-sidebarTwo-article-image{
                        width: 70px;
                        height: 70px;
                        margin-right: 10px;
                    }
                    .scalecms-sidebarTwo-article-text{
                        display: flex;
                        flex-direction: column;
                        justify-content: baseline;
                        align-items: baseline;
                    }
                    .scalecms-sidebarTwo-article-text-content{
                        font-size: 16px;
                        margin-bottom: 3px;
                    }
                    .scalecms-sidebarTwo-categories{
                        margin-top: 20px;
                        box-shadow: 0 0 2px gray;
                        padding: 10px;
                        border-bottom: 3px solid rgba(63, 103, 197, 1);
                        background: white;
                    }
                    .scalecms-sidebarTwo-categories-item{
                        color: #1f326b;
                        margin-top: 10px;
                        cursor: pointer;
                    }
                `,
                components: [
                    {
                        tagName: 'style'
                    },
                    {
                        tagName: 'div',
                        attributes: {class: 'scalecms-sidebarTwo-search'},
                        components: [
                            {
                                tagName: 'input',
                                attributes: {class: 'scalecms-sidebarTwo-search-input', placeholder: 'Pretraga...'},
                            },
                            {
                                tagName: 'img',
                                attributes: {class: 'scalecms-sidebarTwo-search-button', src: 'https://i.imgur.com/iqDzrP8.png'}
                            },
                        ]
                    },
                    {
                        type: "text",
                        attributes: { class: "scalecms-sidebarTwo-heading" },
                        components: "Ranije objave"
                    },
                    {
                        tagName: 'div',
                        attributes: {class: 'scalecms-sidebarTwo-article'},
                        components: [
                            {
                                type: 'image',
                                attributes: {class: 'scalecms-sidebarTwo-article-image'}
                            },
                            {
                                type: 'text',
                                attributes: {class: 'scalecms-sidebarTwo-article-text'},
                                components: [
                                    {
                                        type: 'text',
                                        attributes: {class: 'scalecms-sidebarTwo-article-text-content'},
                                        components: 'Text članka...'
                                    },
                                    {
                                        type: 'scalecms-customDate',
                                    }
                                ]
                            },
                        ]
                    },
                    {
                        tagName: 'div',
                        attributes: {class: 'scalecms-sidebarTwo-article'},
                        components: [
                            {
                                type: 'image',
                                attributes: {class: 'scalecms-sidebarTwo-article-image'}
                            },
                            {
                                type: 'text',
                                attributes: {class: 'scalecms-sidebarTwo-article-text'},
                                components: [
                                    {
                                        type: 'text',
                                        attributes: {class: 'scalecms-sidebarTwo-article-text-content'},
                                        components: 'Text članka...'
                                    },
                                    {
                                        type: 'scalecms-customDate',
                                    }
                                ]
                            },
                        ]
                    },
                    {
                        tagName: 'div',
                        attributes: {class: 'scalecms-sidebarTwo-article'},
                        components: [
                            {
                                type: 'image',
                                attributes: {class: 'scalecms-sidebarTwo-article-image'}
                            },
                            {
                                type: 'text',
                                attributes: {class: 'scalecms-sidebarTwo-article-text'},
                                components: [
                                    {
                                        type: 'text',
                                        attributes: {class: 'scalecms-sidebarTwo-article-text-content'},
                                        components: 'Text članka...'
                                    },
                                    {
                                        type: 'scalecms-customDate',
                                    }
                                ]
                            },
                        ]
                    },
                    {
                        tagName: 'div',
                        attributes: {class: 'scalecms-sidebarTwo-article'},
                        components: [
                            {
                                type: 'image',
                                attributes: {class: 'scalecms-sidebarTwo-article-image'}
                            },
                            {
                                type: 'text',
                                attributes: {class: 'scalecms-sidebarTwo-article-text'},
                                components: [
                                    {
                                        type: 'text',
                                        attributes: {class: 'scalecms-sidebarTwo-article-text-content'},
                                        components: 'Text članka...'
                                    },
                                    {
                                        type: 'scalecms-customDate',
                                    }
                                ]
                            },
                        ]
                    },
                    {
                        type: "text",
                        attributes: { class: "scalecms-sidebarTwo-heading" },
                        components: "Kategorije"
                    },
                    {
                        tagName: 'div',
                        attributes: {class: 'scalecms-sidebarTwo-categories'},
                        components: [
                            {
                                type: 'text',
                                attributes: {class: 'scalecms-sidebarTwo-categories-item'},
                                components: '&#x25CF; Konferencije (1)'
                            },
                            {
                                type: 'text',
                                attributes: {class: 'scalecms-sidebarTwo-categories-item'},
                                components: '&#x25CF; Članci s naslovnice (2)'
                            },
                            {
                                type: 'text',
                                attributes: {class: 'scalecms-sidebarTwo-categories-item'},
                                components: '&#x25CF; Zdravlje (2)'
                            },
                            {
                                type: 'text',
                                attributes: {class: 'scalecms-sidebarTwo-categories-item'},
                                components: '&#x25CF; Faktor uticaja (1)'
                            }
                        ]
                    }
                ]
            }
        }
    });


    editor.BlockManager.add('scalecms-sidebarTwo-blk', {
        label: 'Sidebar 2',
        content: {
            type: "scalecms-sidebarTwo",
        },
        category: {
            label: 'Sidebars'
        },
        media: `<svg width="100" height="100" viewBox="0 0 24 24"><path d="M19.25,6a.5.5,0,0,1-.5.5h-2a.5.5,0,0,1,0-1h2A.5.5,0,0,1,19.25,6Zm-.5,2.5h-2a.5.5,0,0,0,0,1h2a.5.5,0,0,0,0-1Zm0,3h-2a.5.5,0,0,0,0,1h2a.5.5,0,0,0,0-1Zm2.5-6v13a2.5026,2.5026,0,0,1-2.5,2.5h-14a2,2,0,0,1-2-2V5a2,2,0,0,1,2-2h14A2.5026,2.5026,0,0,1,21.25,5.5Zm-1,0A1.5017,1.5017,0,0,0,18.75,4h-3.5V20h3.5a1.5017,1.5017,0,0,0,1.5-1.5Z"/></svg>`,
        attributes: {
            title: 'Basic sidebar 2',
        },
        select: true
    });
};