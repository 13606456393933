import gjs from "grapesjs";

function script() {
    if (!this) return;
    if (this.getAttribute("data-gjs-type")) return;

    let btnText = this.getAttribute("data-buttontext");
    let btn = this.querySelector(".scalecms-redirectonparams-button");
    if (!btn) return;
    btn.textContent = btnText;

    btn.addEventListener("click", () => {
        let param = this.getAttribute("data-param");
        if (!param) return;

        try {
            let curURL = new URL(window.location.toString());
            let foundParam = curURL.searchParams.get(param);
            if (foundParam) {
                window.location = foundParam;
            };
        } catch {};
    });
}
/**
 * @param {gjs.Editor} editor
 */
export default editor => {
    editor.Components.addType('scalecms-redirectonparams', {
        isComponent: e => e?.classList?.contains("scalecms-redirectonparams"),
        model: {
            defaults: {
                script,
                textable: 1,
                type: "default",
                traits: [
                    { name: "data-param", label: "URL param to redirect to", placeholder: "r", default: "r" },
                    { name: "data-buttontext", label: "Button text", placeholder: "Redirect button", default: "Redirect button" }
                ],
                attributes: {
                    class: "scalecms-redirectonparams"
                },
                styles: ``,
                components: [
                    {
                        tagName: 'button',
                        attributes: { class: 'scalecms-redirectonparams-button' },
                        draggable: false,
                        removable: false,
                        components: "Redirect button"
                    }
                ]
            }
        }
    });


    editor.BlockManager.add('scalecms-redirectonparams-blk', {
        label: 'Redirect to URL param',
        content: {
            type: "scalecms-redirectonparams",
        },
        category: {
            label: 'Interactions'
        },
        media: `
        <svg version="1.1" id="Icons" x="0px" y="0px" viewBox="0 0 32 32" style="enable-background:new 0 0 32 32;" xml:space="preserve">
        <style type="text/css">
            .st0{fill:none;stroke:#000000;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
        </style>
        <path class="st0" d="M29,23H3c-1.1,0-2-0.9-2-2V11c0-1.1,0.9-2,2-2h26c1.1,0,2,0.9,2,2v10C31,22.1,30.1,23,29,23z" id="id_101" style="stroke: rgb(255, 255, 255);"></path>
        <path class="st0" d="M13,19L13,19c-1.1,0-2-0.9-2-2v-2c0-1.1,0.9-2,2-2h0c1.1,0,2,0.9,2,2v2C15,18.1,14.1,19,13,19z" id="id_102" style="stroke: rgb(255, 255, 255);"></path>
        <line class="st0" x1="18" y1="13" x2="18" y2="19" id="id_103" style="stroke: rgb(255, 255, 255);"></line>
        <line class="st0" x1="21" y1="13" x2="18" y2="17" id="id_104" style="stroke: rgb(255, 255, 255);"></line>
        <line class="st0" x1="21" y1="19" x2="19" y2="16" id="id_105" style="stroke: rgb(255, 255, 255);"></line>
        </svg>
        `,
        attributes: {
            title: 'Custom button',
        },
        select: true
    });
};