import React from "react";
import "./index.scss";

import axios from "axios";
import moment from "moment";
import { useSelector } from "react-redux";
import * as backendModule from "../../modules/backendModule";
import { animateBox } from "../../modules/componentAnimation";

import CustomInput from "../../components/customComponents/CustomInput";
import CustomCheckbox from "../../components/customComponents/CustomCheckbox";
import { FilteredCustomTable } from "../../components/customComponents/Table";
import Spinner from "../../components/customComponents/Spinner";

let curTimeout = null;
const Translations = () => {

  const [data, setData] = React.useState();
  const [filters, setFilters] = React.useState([]);
  const [canPaginate, setCanPaginate] = React.useState(false);
  const [spinner, setSpinner] = React.useState(false);
  const [secondarySpinner, setSecondarySpinner] = React.useState(false);
  const [creationP, setCreationP] = React.useState("");

  const bosnianPhraseRef = React.useRef();
  const serbianPhraseRef = React.useRef();
  const croatianPhraseRef = React.useRef();
  const montenegrinPhraseRef = React.useRef();

  const paginationOffset = React.useRef();
  const curPaginationTimestamp = React.useRef();
  const searchRef = React.useRef();

  const siteSettingsSelector = useSelector(state => state?.siteSettings ?? {});
  const timestampSelector = useSelector(state => state?.timestamp ?? null);
  const userDataSelector = useSelector(state => state?.userData?.userData ?? {});


  const getData = () => {
    paginationOffset.current = 0;
    curPaginationTimestamp.current = Date.now();

    setCanPaginate(false);
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/translations/getAllTranslations`,
      data: {
        offset: paginationOffset.current,
        filters: [
          ...filters,
        ],
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        if (res.data.data.length === 20) {
          paginationOffset.current += 20;
          setTimeout(() => setCanPaginate(true), 500);
        } else {
          setCanPaginate(false);
          paginationOffset.current = -1;
        };
        return setData({ ...res.data, timestamp: Date.now() });
      }
      return setData({ status: "error", data: "SERVER_ERROR", timestamp: Date.now() });
    }).catch(() => {
      return setData({ status: "error", data: "SERVER_ERROR", timestamp: Date.now() });
    });
  };

  const continueData = (timestamp) => {
    if (paginationOffset.current === -1) {
      if (timestamp !== curPaginationTimestamp.current) return;
      if (canPaginate) setCanPaginate(false);
      return;
    };

    setSecondarySpinner(true);
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/translations/getAllTranslations`,
      data: {
        offset: data?.data?.length ?? 0,
        filters: [
          ...filters,
        ],
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (timestamp !== curPaginationTimestamp.current) return;
      if (res.data.status === "ok") {
        if (res.data.data.length === 20) {
          paginationOffset.current += 20;
          setTimeout(() => setCanPaginate(true));
        } else {
          setCanPaginate(false);
          paginationOffset.current = -1;
        };
        return setData(old => {
          return {
            ...old,
            data: [
              ...old.data,
              ...res.data.data
            ], timestamp: Date.now()
          };
        });
      }
      return setData({ status: "error", data: "SERVER_ERROR", timestamp: Date.now() });
    }).catch(() => {
      return setData({ status: "error", data: "SERVER_ERROR", timestamp: Date.now() });
    }).finally(() => {
      if (timestamp !== curPaginationTimestamp.current) return;
      setSecondarySpinner(false);
    });
  };

  const PaginationData = () => {
    let tmpRef = React.useRef();
    React.useEffect(() => {
      if (!tmpRef?.current) return;
      let observer = null;
      try {
        let observer = new IntersectionObserver((entries) => {
          entries.forEach(entry => {
            if (entry.intersectionRatio > 0) {
              try { observer.unobserve(tmpRef.current); } catch { };
              if (canPaginate) {
                continueData(curPaginationTimestamp.current);
              };
            };
          });
        }, { threshold: [1] });
        observer.observe(tmpRef.current);
      } catch { };

      return () => {
        if (tmpRef?.current) {
          try { observer.unobserve(tmpRef.current); } catch { };
        };
      };
    }, [tmpRef]);

    return <div ref={tmpRef}></div>;
  };

  const addTranslation = () => {
    setCreationP("");
    let data = {
      Phrase_BA: bosnianPhraseRef.current.value,
      Phrase_RS: serbianPhraseRef.current.value,
      Phrase_HR: croatianPhraseRef.current.value,
      Phrase_ME: montenegrinPhraseRef.current.value,
    };

    if (!data.Phrase_BA) return setCreationP("Bosnian Phrase can't be empty!");
    if (!data.Phrase_RS) return setCreationP("Serbian Phrase can't be empty!");
    if (!data.Phrase_HR) return setCreationP("Croatian Phrase can't be empty!");
    if (!data.Phrase_ME) return setCreationP("Montenegrin Phrase can't be empty!");

    setSpinner(true);
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/translations/createTranslation`,
      data,
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        bosnianPhraseRef.current.value = '';
        serbianPhraseRef.current.value = '';
        croatianPhraseRef.current.value = '';
        montenegrinPhraseRef.current.value = '';
        getData();
      } else {
        setCreationP(`${res.data.data}`);
      };
    }).catch(() => {
      setCreationP("Server timed out!");
    }).finally(() => {
      setSpinner(false);
    });
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter'){
      addTranslation();
      bosnianPhraseRef.current.focus();
    } 
  };

  React.useEffect(() => {
    if (!searchRef.current) return;

    let handler = () => {
      let searchVal = searchRef?.current?.value;
      clearTimeout(curTimeout);
      curTimeout = setTimeout(() => {
        setFilters([{
          or: [
            { name: "Phrase_BA", op: "like", value: searchVal },
            { name: "Phrase_RS", op: "like", value: searchVal },
            { name: "Phrase_HR", op: "like", value: searchVal },
            { name: "Phrase_ME", op: "like", value: searchVal },
          ]
        }]);
      }, 500);
    };

    searchRef.current.addEventListener("input", handler);

    return () => {
      try {
        searchRef.current.removeEventListener("input", handler);
      } catch { };
    };
  }, [searchRef.current]);

  React.useEffect(() => {
    if (filters?.length > 0) setFilters([]);
  }, [siteSettingsSelector.advancedSearch]);

  React.useEffect(() => {
    getData();
  }, [filters, timestampSelector]);

  const removeTranslation = (ID) => {
    setSpinner(true);
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/translations/removeTranslation`,
      data: {
        ID: ID
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === 'ok') getData();
    }).catch((err) => {
    }).finally(() => {
      setSpinner(false);
    });
  }


  return <div className="route__translations" onKeyDown={handleKeyDown}>

    <div className="route__translations__create" >
      <CustomInput autocomplete="off" ref={bosnianPhraseRef} accent="#fff" theme="dark" placeholder="Bosnian Language Phrase" style={{ width: "100%", marginBottom: "20px" }} />
      <CustomInput autocomplete="off" ref={serbianPhraseRef} accent="#fff" theme="dark" placeholder="Serbian Language Phrase" style={{ width: "100%", marginBottom: "20px" }} />
      <CustomInput autocomplete="off" ref={croatianPhraseRef} accent="#fff" theme="dark" placeholder="Croatian Language Phrase" style={{ width: "100%", marginBottom: "20px" }} />
      <CustomInput autocomplete="off" ref={montenegrinPhraseRef} accent="#fff" theme="dark" placeholder="Montenegrin Language Phrase" style={{ width: "100%", marginBottom: "20px" }} />
      <p onClick={() => addTranslation()} className="route__translations__create__btn">Add Translation</p>
      <p style={{color: "#d60418"}}>{creationP}</p>
    </div>

    {!siteSettingsSelector.advancedSearch && <CustomInput autocomplete="off" ref={searchRef} theme="dark" accent="#3F7CEA" placeholder="Search..." style={{ width: "100%", marginBottom: "20px" }} />}
    <FilteredCustomTable
      key={`domainTable-${siteSettingsSelector.advancedSearch ? "advanced" : "simple"}`}
      theme="dark"
      accent="#48515C"
      style={{ width: "100%" }}
      customColumns={["100px", "1fr", "1fr", "1fr", "1fr"]}
      headers={["ID", "Bosnian", "Serbian", "Croatian", "Montenegrin"]}
      filters={siteSettingsSelector.advancedSearch ? [
        { name: "ID", friendlyName: "ID", type: "number" },
        { name: "Phrase_BA", friendlyName: "Bosnian", type: "string" },
        { name: "Phrase_RS", friendlyName: "Serbian", type: "string" },
        { name: "Phrase_HR", friendlyName: "Croatian", type: "string" },
        { name: "Phrase_ME", friendlyName: "Montenegrin", type: "string" },
      ] : undefined}
      filterCB={f => setFilters(f)}
      data={(() => {
        if (!data) return [[{ keyID: "noDataSpinner", type: "spinner" }]];
        if (data?.status !== "ok") return [[{ keyID: "noDataError", type: "custom", data: <p>There was an error while fetching data</p> }]]
        if (data.data.length === 0) return [[{ keyID: "noDataError2", type: "custom", data: <p>There is nothing to show.</p> }]]

        let tmp = data.data.map(elem => {
          return [
            { keyID: String(elem.ID), type: "text", text: elem.ID },
            { keyID: String(elem.ID), type: "text", text: elem.Phrase_BA },
            { keyID: String(elem.ID), type: "text", text: elem.Phrase_RS },
            { keyID: String(elem.ID), type: "text", text: elem.Phrase_HR },
            { keyID: String(elem.ID), type: "text", text: elem.Phrase_ME },
            {
              keyID: String(elem.ID), type: "groupNewline", group: [
                { keyID: String(elem.ID), type: "button", text: "Edit", onClick: e => animateBox(e, <EditTranslation id={elem.ID} elem={elem} onChange={getData} />) },
                { keyID: String(elem.ID), type: "button", text: "Remove", style: { marginLeft: "10px"}, onClick: e => removeTranslation(elem.ID) },
              ]
            },
            (userDataSelector?.UserInfo?.Flags?.isAdmin ? {keyID: String(elem.ID), type: "groupNewline", group: [
              { keyID: String(elem.ID), type: "text", text: `${elem.createdBy} at ${moment(elem.createdAt).format('DD/MM/YYYY hh:mm A')}`, style: { marginLeft: "0px", color: '#00a824'}},
              { keyID: String(elem.ID), type: "text", text: `${elem.lastEditBy} at ${moment(elem.updatedAt).format('DD/MM/YYYY hh:mm A')}`, style: { marginLeft: "30px", color: "#00a89e"}}
            ]} : null)
          ].filter(f => f);
        });
        if (secondarySpinner) tmp.push([{ keyID: "paginationSpinner", type: "spinner" }]);
        if (canPaginate) tmp.push([{
          keyID: "paginationData",
          type: "custom",
          data: <PaginationData />
        }]);

        return tmp;
      })()}
    />

  </div>
};

const EditTranslation = (props) => {

  const bosnianPhraseRef = React.useRef();
  const serbianPhraseRef = React.useRef();
  const croatianPhraseRef = React.useRef();
  const montenegrinPhraseRef = React.useRef();

  const editTranslation = () => {
    let data = {
      ID: props.id,
      Phrase_BA: bosnianPhraseRef.current.value ? bosnianPhraseRef.current.value : props.elem.Phrase_BA,
      Phrase_RS: serbianPhraseRef.current.value ? serbianPhraseRef.current.value : props.elem.Phrase_RS,
      Phrase_HR: croatianPhraseRef.current.value ? croatianPhraseRef.current.value : props.elem.Phrase_HR,
      Phrase_ME: montenegrinPhraseRef.current.value ? montenegrinPhraseRef.current.value : props.elem.Phrase_ME,
    };

    axios({
      method: "POST",
      url: `${backendModule.backendURL}/translations/editTranslation`,
      data,
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        bosnianPhraseRef.current.value = '';
        serbianPhraseRef.current.value = '';
        croatianPhraseRef.current.value = '';
        montenegrinPhraseRef.current.value = '';
        props.onChange();
        props.onClose();
      } else {
      };
    }).catch(() => {
    }).finally(() => {
    });
  };

  return <div className="route__translations__editTranslation">
    <div className="route__translations__editTranslation__wrap">

      <h3 style={{ marginBottom: "20px" }}>Edit Translation</h3>
      <CustomInput autocomplete="off" ref={bosnianPhraseRef} accent="#fff" theme="dark" placeholder={`Bosnian - ${props.elem.Phrase_BA}`} style={{ width: "100%", marginBottom: "20px" }} />
      <CustomInput autocomplete="off" ref={serbianPhraseRef} accent="#fff" theme="dark" placeholder={`Serbian - ${props.elem.Phrase_RS}`} style={{ width: "100%", marginBottom: "20px" }} />
      <CustomInput autocomplete="off" ref={croatianPhraseRef} accent="#fff" theme="dark" placeholder={`Croatian - ${props.elem.Phrase_HR}`} style={{ width: "100%", marginBottom: "20px" }} />
      <CustomInput autocomplete="off" ref={montenegrinPhraseRef} accent="#fff" theme="dark" placeholder={`Montenegrin - ${props.elem.Phrase_ME}`} style={{ width: "100%", marginBottom: "20px" }} />

      <div className="route__translations__editTranslation__wrap__btns">
        <p onClick={editTranslation}>
          <img style={{ marginRight: "15px" }} src="/images/saveBtn.png" />
          <span>Save</span>
        </p>
        <p onClick={props.onClose}>
          <span>Cancel</span>
          <img style={{ marginLeft: "15px" }} src="/images/closeBtn.png" />
        </p>
      </div>
    </div>
  </div>

}


export default Translations;