import gjs from "grapesjs";

function script() {
  if (!this) return;

  let commentBtn = document.querySelector(".scalecms-leaveComment-body-bot-button");
  let comment = document.querySelector(".scalecms-leaveComment-body-input");
  let name = document.querySelector(".scalecms-leaveComment-body-bot-name-input");
  let file = document.querySelector(".scalecms-leaveComment-body-bot-image-input");
  let commentContent = document.querySelector(".scalecms-leaveComment-comment");
  let userComm = document.querySelector(".new-comm")

  commentBtn.addEventListener("click", () => {

    if(!name.value.length || !comment.value.length) return;

    // set local storage here
    if (localStorage.getItem('userComment')) {
      const storedObject = JSON.parse(localStorage.getItem('userComment'));
      commentContent.style.maxHeight = '1000px';
      let commName = commentContent.querySelector(".scalecms-comment-body-heading");
      let commText = commentContent.querySelector(".scalecms-comment-body-text");

      commName.textContent = `${storedObject.name}`;
      commText.textContent = `${storedObject.comment}`;

      if(name.value !== storedObject.name && comment.value !== storedObject.comment){
        const userComment = {
          name: `${name.value}`,
          file: 'example.txt',
          comment: `${comment.value}`,
        };
        commName.textContent = `${name.value}`;
        commText.textContent = `${comment.value}`;
        localStorage.setItem('userComment', JSON.stringify(userComment));
      }else if(name.value !== storedObject.name){
        const userComment = {
          name: `${name.value}`,
          file: 'example.txt',
          comment: `${storedObject.comment}`,
        };
        commName.textContent = `${name.value}`;
        commText.textContent = `${storedObject.comment}`;
        localStorage.setItem('userComment', JSON.stringify(userComment));
      }else if(comment.value !== storedObject.comment){
        const userComment = {
          id: `${this.id}`,
          name: `${storedObject.name}`,
          file: 'example.txt',
          comment: `${comment.value}`,
        };
        commName.textContent = `${storedObject.name}`;
        commText.textContent = `${comment.value}`;
        localStorage.setItem('userComment', JSON.stringify(userComment));
      }
      
    } else {
      const userComment = {
        id: `${this.id}`,
        name: `${name.value}`,
        file: 'example.txt',
        comment: `${comment.value}`,
      };
      localStorage.setItem('userComment', JSON.stringify(userComment));
      const storedObject = JSON.parse(localStorage.getItem('userComment'));
      commentContent.style.maxHeight = '1000px';
      let commName = commentContent.querySelector(".scalecms-comment-body-heading");
      let commText = commentContent.querySelector(".scalecms-comment-body-text");

      commName.textContent = `${storedObject.name}`;
      commText.textContent = `${storedObject.comment}`;
    }
  })

  if (localStorage.getItem('userComment')) {
    const storedObject = JSON.parse(localStorage.getItem('userComment'));
    if(String(storedObject.id) !== String(this.id)) return;
    commentContent.style.maxHeight = '1000px';
    let commName = commentContent.querySelector(".scalecms-comment-body-heading");
    let commText = commentContent.querySelector(".scalecms-comment-body-text");

    commName.textContent = `${storedObject.name}`;
    commText.textContent = `${storedObject.comment}`;
  }
  
}

/**
 * @param {gjs.Editor} editor
 */
export default editor => {
  editor.Components.addType('scalecms-leaveComment', {
    isComponent: e => e?.classList?.contains("scalecms-leaveComment"),
    model: {
      defaults: {
        type: "default",
        traits: [

        ],
        attributes: {
          class: "scalecms-leaveComment"
        },
        script,
        styles: `
          .scalecms-leaveComment {
            width: 95%;
            margin-left: auto;
            margin-right: auto;
            background: white;
            padding: 10px;
            display: flex;
            flex-direction: column;
            gap: 10px;
            min-height: 70px;
            height: fit-content;
            border-bottom: 2px solid #021859;
            margin-top: 20px;
            box-shadow: 0 0 4px gray;
            font-family: 'Arial';
          }
          .scalecms-leaveComment-desc{
            font-weight: 700;
            font-size: 19px;
            font-family: 'Open Sans', sans-serif;
          }
          .scalecms-leaveComment-body{
            width: 100%;
            height: fit-content;
            display: flex;
            flex-direction: column;
          }
          .scalecms-leaveComment-body-desc{
            font-weight: 600;
            font-size: 16px;
            font-family: 'Open Sans', sans-serif;
          }
          .scalecms-leaveComment-body-input{
            margin-bottom: 20px;
            width: 100%;
            border: 1px solid #D4D8DE;
            border-radius: 5px;
            padding: 10px;
          }

          .scalecms-leaveComment-body-bot-name-desc{
            font-weight: 600;
            font-size: 16px;
            font-family: 'Open Sans', sans-serif;
          }
          .scalecms-leaveComment-body-bot-name-input{
            width: 100%;
            max-width: 320px;
            border: 1px solid #D4D8DE;
            border-radius: 5px;
            padding: 10px;
          }
          .scalecms-leaveComment-body-bot-image-desc{
            font-weight: 600;
            font-size: 16px;
            font-family: 'Open Sans', sans-serif;
          }
          .scalecms-leaveComment-body-bot-image-input{
            width: 100%;
            max-width: 320px;
            border: 1px solid #D4D8DE;
            border-radius: 5px;
            padding: 10px;
          }
          .scalecms-leaveComment-body-bot-button{
            display:flex;
            justify-content: center;
            align-items: center;
            padding: 15px 20px;
            border-radius: 5px;
            border: 1px solid #D4D8DE;
            cursor: pointer;
          }
          .scalecms-leaveComment-body-bot-button-text{
            font-weight: 600;
            font-size: 16px;
            font-family: 'Open Sans', sans-serif;
          }

          .scalecms-leaveComment-body-bot{
            display: flex;
            align-items: center;
            gap: 20px;
          }

          .scalecms-leaveComment-comment{
            max-height: 0;
            overflow: hidden;
            transition: 0.3s
          }
    
          .scalecms-leaveComment-comment-headline{
            font-weight: 600;
            font-size: 16px;
            font-family: 'Open Sans', sans-serif;
            width: 95%;
            margin: 0 auto;
            margin-top: 20px;
          }
          .new-comm{
            margin-top: 4px;
          }
          @media screen and (max-width: 600px){
            .scalecms-leaveComment{
              flex-direction: column;
              justify-content: center;
              align-items: center;
            }
            .scalecms-leaveComment-body{
              flex-direction: column;
              align-items: start;
            }
            .scalecms-leaveComment-body-bot{
              flex-direction: column;
              align-items: start;
            }
          }
        `,
        components: [
          {
            type: 'text',
            attributes: { class: "scalecms-leaveComment-desc" },
            components: "Ostavite Komentar"
          },
          {
            tagName: 'div',
            attributes: { class: "scalecms-leaveComment-body" },
            components: [
              {
                type: 'text',
                attributes: { class: "scalecms-leaveComment-body-desc" },
                components: "Komentar"
              },
              {
                tagName: 'input',
                // Add other custom properties for styling or behavior
                attributes: { type: 'text', placeholder: 'Upisite komentar', class: "scalecms-leaveComment-body-input" },
                droppable: false,
                editable: true,
              },
              {
                tagName: 'div',
                attributes: { class: "scalecms-leaveComment-body-bot" },
                components: [
                  {
                    tagName: 'div',
                    attributes: { class: "scalecms-leaveComment-body-bot-name" },
                    components: [
                      {
                        type: 'text',
                        attributes: { class: "scalecms-leaveComment-body-bot-name-desc" },
                        components: "Vaše ime"
                      },
                      {
                        tagName: 'input',
                        // Add other custom properties for styling or behavior
                        attributes: { type: 'text', placeholder: 'Vase ime i prezime', class: "scalecms-leaveComment-body-bot-name-input" },
                        droppable: false,
                        editable: true,
                      },
                    ]
                  },
                  {
                    tagName: 'div',
                    attributes: { class: "scalecms-leaveComment-body-bot-image" },
                    components: [
                      {
                        type: 'text',
                        attributes: { class: "scalecms-leaveComment-body-bot-image-desc" },
                        components: "Slika"
                      },
                      {
                        tagName: 'input',
                        attributes: { type: 'file', placeholder: 'Vasa slika', class: "scalecms-leaveComment-body-bot-image-input" },
                        droppable: false,
                        editable: true,
                      },
                    ]
                  },
                  {
                    tagName: 'div',
                    attributes: { class: "scalecms-leaveComment-body-bot-button" },
                    components: [
                      {
                        type: 'text',
                        attributes: { class: "scalecms-leaveComment-body-bot-button-text" },
                        components: "Ostavi Komentar"
                      },
                    ]
                  }
                ]
              },
              {
                tagName: 'div',
                attributes: { class: "scalecms-leaveComment-comment" },
                components: [
                  {
                    type: 'text',
                    attributes: { class: "scalecms-leaveComment-comment-headline" },
                    components: "Vaš komentar je poslan moderaciji i trenutno u obradi"
                  },
                  {
                    tagName: 'div',
                    attributes: {class: "scalecms-leaveComments-comment-block"},
                    components: [
                      {
                        type: 'scalecms-comment',
                        attributes: { class: 'scalecms-comment new-comm' }                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    
                      },
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    }
  });


  editor.BlockManager.add('scalecms-leaveComment-blk', {
    label: 'Leave Comment',
    content: {
      type: "scalecms-leaveComment",
    },
    category: {
      label: 'Comments'
    },
    media: `
      <?xml version="1.0" encoding="utf-8"?>
      <svg version="1.1" id="Layer_1" x="0px" y="0px"
        width="100px" height="100px" viewBox="0 0 100 100" enable-background="new 0 0 100 100" xml:space="preserve">
      <g>
        <path d="M88.466,16.936c-0.172-1.703-1.592-3.036-3.34-3.036H14.781v0.009c-1.705,0.047-3.079,1.355-3.247,3.027H11.5v56.342h0.068
          c0.305,1.512,1.622,2.65,3.214,2.694v0.009h11.564v6.744c0,1.864,1.51,3.374,3.374,3.374c1.165,0,2.192-0.591,2.798-1.49
          l8.629-8.629h43.979c1.634,0,2.995-1.161,3.306-2.703H88.5V16.936H88.466z"/>
      </g>
      </svg>
        `,
    attributes: {
      title: 'Basic comment',
    },
    select: true
  });
};