import gjs from "grapesjs";
import axios from "axios";
import * as backendModule from "../../../../modules/backendModule";

function script() {
  let headlineText = this.querySelector(".product-bought-popup-right-head");
  let linkText = this.querySelector(".product-bought-popup-right-text");
  let scrollToBtn = this.querySelector(".product-bought-popup-right-text");
  let scrollTarget = document.querySelector(".scroll-target");
  if(!scrollTarget) scrollTarget = document.querySelector(".scalelead-form");


  scrollToBtn.addEventListener('click', () => {
    scrollTarget.scrollIntoView({ behavior: 'smooth' });
  });

  let timeToPop = this.getAttribute('data-seconds');
  let classToPop = this.getAttribute("data-mustseeclass");
  let timeToClose = this.getAttribute('data-secondsclose');
  let headline = this.getAttribute('data-headline-text');
  let link = this.getAttribute('data-link.test');

  let elemToPop = null;
  if (classToPop) {
    try {
      elemToPop = document.querySelector(`.${classToPop}`);
    } catch {};
  };

  if(headline) headlineText.textContent = `${headline}`;
  if(link) linkText.textContent = `${link}`
  
  if (isNaN(Number(timeToPop))) return;
  if (Number(timeToPop) < 0) return;

  timeToClose = Number(timeToClose);
  if (isNaN(timeToClose)) timeToClose = 0;
  if (timeToClose < 0) timeToClose = 0;

  if (!this) return;
  if (this.getAttribute("data-gjs-type")) return;

  if (elemToPop) {
    let isOpened = false;

    let handler = () => {
      if (isOpened) {
        document.removeEventListener("scroll", handler);
        return;
      };

      let rect = elemToPop.getBoundingClientRect();
      if (rect.y < 0) {
        isOpened = true;
        document.removeEventListener("scroll", handler);
        if (window.innerWidth <= 550) {
          this.style.bottom = '0px';
        } else {
          this.style.top = '40px';
        };
        if (timeToClose > 0) {
          setTimeout(() => {
            if (this.style.top) this.style.top = null;
            if (this.style.bottom) this.style.bottom = null;
          }, timeToClose * 1000);
        };
      };
    };

    document.addEventListener("scroll", handler);
    return;
  };

  var timer = Number(timeToPop), seconds;
  let intervalID = setInterval(() => {
    seconds = parseInt(timer % 60, 10);

    seconds = seconds < 10 ? "0" + seconds : seconds;

    if (--timer < 0) {
      timer = Number(timeToPop);
      if (window.innerWidth <= 550) {
        this.style.bottom = '0px';
      } else {
        this.style.top = '40px';
      };

      if (timeToClose > 0) {
        setTimeout(() => {
          if (this.style.top) this.style.top = null;
          if (this.style.bottom) this.style.bottom = null;
        }, timeToClose * 1000);
      };
      clearInterval(intervalID);
    }
  }, 1000);
};

/**
 * @param {gjs.Editor} editor
 */

export default (editor, offers) => {

  const getAllOffers = async () => {
    try {
      const response = await axios({
        method: "POST",
        url: `${backendModule.backendURL}/comments/getAllOffersForComments`,
        ...backendModule.axiosConfig
      });

      if (response.data.status === "ok") {
        return response.data.data;
      } else {
        return [];
      }
    } catch (error) {
      return [];
    }
  };

  const updateOfferOptions = (component, data) => {
    if (!component) return;
    const trait = component.getTrait('offers');
    if (trait) {
      trait.set('options', data);
    }
  };

  editor.on('component:add', async function (component) {
    if (component.get('type') === 'product-bought-popup_cmp') {
      try {
        const allOffers = await getAllOffers();
        this.trigger('component:product-bought-popup:componentInitialized', { component, data: allOffers });
      } catch {}
    }
  });

  editor.on('load', async function () {
    try {
      setTimeout(async () => {
        let myComponent = findComponentByType(editor.DomComponents.getComponents(), 'product-bought-popup_cmp');

        if (!myComponent) {
          return;
        }
        const allOffers = await getAllOffers();

        this.trigger('component:product-bought-popup:componentInitialized', { component: myComponent, data: allOffers });
      }, 500);

    } catch {}
  });

  const findComponentByType = (components, type) => {
    for (let component of components) {
      if (component.get('type') === type) {
        return component;
      }
      if (component.models && component.models.length > 0) {
        let nestedComponent = findComponentByType(component.components, type);
        if (nestedComponent) {
          return nestedComponent;
        }
      }
    }
    return null;
  }

  editor.on('component:scalecms-commentWrap:componentInitialized', async (data) => {
    const optionsArray = data.data.map(item => ({
      value: item.ID,
      name: `${item.OfferName} (${item.Country})`,
    }));

    const component = data.component;
    updateOfferOptions(component, optionsArray);

    data.component.view.render();

    const offerTrait = component.getTrait('offers');
  });

  editor.Components.addType('product-bought-popup_cmp', {
    isComponent: e => e?.classList?.contains("product-bought-popup"),
    model: {
      init() {
        this.on("change", e => {
          this.get("components").each(child => {
            let headline = e?.attributes?.attributes?.["data-headline-text"];
            let link = e?.attributes?.attributes?.["data-link-text"];

            let el = child.getEl();
            let elHeadline = el.querySelector(".product-bought-popup-right-head");
            let elLink = el.querySelector(".product-bought-popup-right-text");

            if (elHeadline) elHeadline.textContent = headline;
            if (elLink) elLink.textContent = link;
          });
          // let selectedForm = e?.attributes?.attributes?.['select-form'];
          // let scaleLeadForm = document.querySelector(".scalelead-form");
          // let scaleCRMForm = document.querySelector(".scalecrm-form");

          // console.log("ooooo", document)


          // switch (selectedForm){
          //   case 'scaleLeadForm':
          //     scaleCRMForm.style.display = 'none';
          //   break;
          //   case 'scaleCrmForm':
          //     scaleLeadForm.style.display = 'none';
          //   break;
          // }

        });
      },
      defaults: {
        type: "default",
        traits: [
          {
            type: 'select',
            label: 'Select an offer',
            name: 'offers',
            options: [],
          },
          { name: "data-seconds", label: "seconds to open", placeholder: "3", default: "3" },
          { name: "data-mustseeclass", label: "Must scroll to class before opening", placeholder: "some-class", default: "" },
          { name: "data-secondsclose", label: "seconds to close", placeholder: "10", default: "10" },
          { name: "data-headline-text", label: "headline text", placeholder: "N.N. iz Tuzle je upravo dobio proizvod", default: "N.N. iz Tuzle je upravo dobio proizvod" },
          { name: "data-link-text", label: "link text", placeholder: "Naručite i vi", default: "Naručite i vi" },
          { type: "button", text: "Open / Close popup", full: true, command: editor => {
            let el = editor.getSelected().getEl();
            el.style.top = el.style.top ? null : "40px";
          } }
        ],
        attributes: {
          class: "product-bought-popup"
        },
        script,
        styles: `
          .product-bought-popup{
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
            width: 100%;
            max-width: 360px;
            border-radius: 10px;
            padding: 10px;
            background: linear-gradient(180deg,#adfbb9 0%,#e4fbad 100%),#e4fbad;
            position: fixed;
            top: -150px;
            left: 50%;
            transform: translateX(-50%);
            cursor: pointer;
            z-index: 9999;
            transition: 0.3s ease;
          }
          .product-bought-popup-left{
            width: 100%;
            max-width: 60px;
            height: auto;
            border-radius: 10px;
            overflow: hidden;
          }

          .product-bought-popup-left img{
            width: 100%;
            height: auto;
          }
          .product-bought-popup-right-head{
            font-family: nunito,roboto,proxima-nova,"proxima nova",sans-serif;
            font-size: 16px;
            font-weight: 800;
            line-height: 16px;
            min-height: 40px;
          }
          .product-bought-popup-right-text{
            text-decoration: underline;
          }
          @media screen and (max-width: 550px) {
            .product-bought-popup {
              top: unset !important;
              bottom: -150px;
              left: 0;
              transform: none;
              width: 80%;
              max-width: 100%;
              height: max-content;
            }
          }

        `,
        components: [
          {
            tagName: 'div',
            attributes: { class: 'product-bought-popup-left'},
            components: [
              {
                type: 'image',
                attributes: { class: 'product-bought-popup-left-img' },
              },
            ]
          },
          {
            tagName: 'div',
            attributes: { class: 'product-bought-popup-right'},
            components: [
              {
                type: 'text',
                attributes: { class: 'product-bought-popup-right-head' },
                components: 'N.N. iz Tuzle je upravo dobio proizvod'
              },
              {
                type: 'text',
                attributes: { class: 'product-bought-popup-right-text' },
                components: 'Naručite i vi'
              },
            ]
          },
        ]
      }
    }
  });


  editor.BlockManager.add('product-bought-popup_blk', {
    label: 'Product Bought PopUp',
    content: {
      type: "product-bought-popup_cmp",
    },
    category: {
      label: 'Custom assets'
    },
    media: "<img src='/images/component_form.png' style='width: 100%; height: auto; filter: invert(1) hue-rotate(180deg);' />",
    attributes: {
      title: 'Insert an Open Form block',
    },
    select: true
  });
};