import React from "react";
import "./index.scss";

import axios from "axios";
import { useSelector } from "react-redux";
import * as backendModule from "../../modules/backendModule";
import { animateBox } from "../../modules/componentAnimation";

import CustomInput from "../../components/customComponents/CustomInput";
import Dropdown from "../../components/customComponents/Dropdown";
import { FilteredCustomTable } from "../../components/customComponents/Table";
import Spinner from "../../components/customComponents/Spinner";
import Button from "../../components/customComponents/Button";
import { LandingItem } from "../LandingList";

let curTimeout = null;
const RouteList = () => {
    const [data, setData] = React.useState();
    const [filters, setFilters] = React.useState([]);
    const [canPaginate, setCanPaginate] = React.useState(false);
    const [secondarySpinner, setSecondarySpinner] = React.useState(false);

    const paginationOffset = React.useRef();
    const curPaginationTimestamp = React.useRef();
    const searchRef = React.useRef();

    const siteSettingsSelector = useSelector(state => state?.siteSettings ?? {});
    const timestampSelector = useSelector(state => state?.timestamp ?? null);
    const userDataSelector = useSelector(state => state?.userData?.userData ?? {});

    const getData = () => {
        paginationOffset.current = 0;
        curPaginationTimestamp.current = Date.now();

        setCanPaginate(false);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/websiteRoutes/getAllRoutes`,
            data: {
                offset: paginationOffset.current,
                filters: [
                    ...filters,
                ],
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (res.data.data.length === 20) {
                    paginationOffset.current += 20;
                    setTimeout(() => setCanPaginate(true), 500);
                } else {
                    setCanPaginate(false);
                    paginationOffset.current = -1;
                };

                return setData({ ...res.data, timestamp: Date.now() });
            }
            return setData({ status: "error", data: "SERVER_ERROR", timestamp: Date.now() });
        }).catch(() => {
            return setData({ status: "error", data: "SERVER_ERROR", timestamp: Date.now() });
        });
    };

    const continueData = (timestamp) => {
        if (paginationOffset.current === -1) {
            if (timestamp !== curPaginationTimestamp.current) return;
            if (canPaginate) setCanPaginate(false);
            return;
        };

        setSecondarySpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/websiteRoutes/getAllRoutes`,
            data: {
                offset: data?.data?.length ?? 0,
                filters: [
                    ...filters,
                ],
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestamp !== curPaginationTimestamp.current) return;
            if (res.data.status === "ok") {
                if (res.data.data.length === 20) {
                    paginationOffset.current += 20;
                    setTimeout(() => setCanPaginate(true));
                } else {
                    setCanPaginate(false);
                    paginationOffset.current = -1;
                };
                return setData(old => {
                    return {
                        ...old,
                        data: [
                            ...old.data,
                            ...res.data.data
                        ], timestamp: Date.now()
                    };
                });
            }
            return setData({ status: "error", data: "SERVER_ERROR", timestamp: Date.now() });
        }).catch(() => {
            return setData({ status: "error", data: "SERVER_ERROR", timestamp: Date.now() });
        }).finally(() => {
            if (timestamp !== curPaginationTimestamp.current) return;
            setSecondarySpinner(false);
        });
    };

    const PaginationData = () => {
        let tmpRef = React.useRef();
        React.useEffect(() => {
            if (!tmpRef?.current) return;
            let observer = null;
            try {
                let observer = new IntersectionObserver((entries) => {
                    entries.forEach(entry => {
                        if (entry.intersectionRatio > 0) {
                            try { observer.unobserve(tmpRef.current); } catch { };
                            if (canPaginate) {
                                continueData(curPaginationTimestamp.current);
                            };
                        };
                    });
                }, { threshold: [1] });
                observer.observe(tmpRef.current);
            } catch { };

            return () => {
                if (tmpRef?.current) {
                    try { observer.unobserve(tmpRef.current); } catch { };
                };
            };
        }, [tmpRef]);

        return <div ref={tmpRef}></div>;
    };

    React.useEffect(() => {
        if (!searchRef.current) return;

        let handler = () => {
            let searchVal = searchRef?.current?.value;
            clearTimeout(curTimeout);
            curTimeout = setTimeout(() => {
                setFilters([{or: [
                    {name: "ID", op: "like", value: searchVal},
                    {name: "Name", op: "like", value: searchVal},
                    {name: "Path", op: "like", value: searchVal},
                    {name: "QueryParameters", op: "like", value: searchVal}
                ]}]);
            }, 500);
        };

        searchRef.current.addEventListener("input", handler);

        return () => {
            try {
                searchRef.current.removeEventListener("input", handler);
            } catch {};
        };
    }, [searchRef.current]);

    React.useEffect(() => {
        if (filters?.length > 0) setFilters([]);
    }, [siteSettingsSelector.advancedSearch]);

    React.useEffect(() => {
        getData();
    }, [filters, timestampSelector]);

    return <div className="route__websiteRoutes" style={{overflow: "visible"}}>
        {!siteSettingsSelector.advancedSearch && <CustomInput autocomplete="off" ref={searchRef} theme="dark" accent="#3F7CEA" placeholder="Search..." style={{width: "100%", marginBottom: "20px"}} />}
        <FilteredCustomTable
            key={`domainTable-${siteSettingsSelector.advancedSearch ? "advanced" : "simple"}`}
            theme="dark"
            accent="#48515C"
            style={{width: "100%"}}
            headers={["ID", "Name", "Path"]}
            filters={siteSettingsSelector.advancedSearch ? [
                {name: "ID", friendlyName: "ID", type: "number"},
                {name: "Name", friendlyName: "Name", type: "string"},
                {name: "Path", friendlyName: "Path", type: "string"},
                {name: "QueryParameters", friendlyName: "Query parameters", type: "string"}
            ] : undefined}
            filterCB={f => setFilters(f)}
            data={(() => {
                if (!data) return [[{keyID: "noDataSpinner", type: "spinner"}]];
                if (data?.status !== "ok") return [[{keyID: "noDataError", type: "custom", data: <p>There was an error while fetching data</p>}]]
                if (data.data.length === 0) return [[{keyID: "noDataError2", type: "custom", data: <p>There is nothing to show.</p>}]]

                let tmp = data.data.map(elem => {
                    return [
                        {keyID: String(elem.ID), type: "text", text: elem.ID},
                        {keyID: String(elem.ID), type: "text", text: elem.Name},
                        {keyID: String(elem.ID), type: "text", text: `${elem.Domain.isSSL ? "https" : "http"}://${elem.Domain.Domain}${elem.Path.startsWith("/") ? elem.Path : `/${elem.Path}`}`},
                        {keyID: String(elem.ID), type: "groupNewline", group: [
                            {keyID: String(elem.ID), type: "custom", data: <div className="route__websiteRoutes__tablePill">
                                <p>Path:</p>
                                <span>{elem.Path}</span>
                            </div>},
                            {keyID: String(elem.ID), type: "custom", data: <div className="route__websiteRoutes__tablePill">
                                <p>Domain:</p>
                                <span>{elem.Domain.Domain} ({elem.Domain.Name}) (SSL:{elem.Domain.isSSL ? "Yes" : "No"})</span>
                            </div>},
                            {keyID: String(elem.ID), type: "custom", data: <div className="route__websiteRoutes__tablePill">
                                <p>Website:</p>
                                <span>{elem.Website.Name} ({elem.Website.Country})</span>
                            </div>},
                            {keyID: String(elem.ID), type: "custom", data: <div className="route__websiteRoutes__tablePill">
                                <p>Query params:{Object.keys(elem.QueryParameters).length === 0 ? " None" : ""}</p>
                                {Object.keys(elem.QueryParameters).map(key => {
                                    return <span className="route__websiteRoutes__tablePill--secondary">{key}: {elem.QueryParameters[key] ? `'${elem.QueryParameters[key]}'` : "any"}</span>
                                })}
                            </div>},
                        ]},
                        {keyID: String(elem.ID), type: "groupNewline", group: [
                            {keyID: String(elem.ID), type: "button", text: "Edit", style: {marginRight: "5px"}, onClick: e => animateBox(e, <AddRoute edit={elem} onChange={() => getData()} />)},
                            {keyID: String(elem.ID), type: "button", text: "Remove", onClick: e => animateBox(e, <RemoveRoute data={elem} onChange={() => getData()} />)}
                        ]}
                    ].filter(f => f);
                });
                if (secondarySpinner) tmp.push([{keyID: "paginationSpinner", type: "spinner"}]);
                if (canPaginate) tmp.push([{
                    keyID: "paginationData",
                    type: "custom",
                    data: <PaginationData />
                }]);

                return tmp;
            })()}
        />
    </div>
};

const AddRoute = props => {
    const [domains, setDomains] = React.useState();
    const [spinner, setSpinner] = React.useState(false);
    const [infoP, setInfoP] = React.useState("");

    const [selectedDomain, setSelectedDomain] = React.useState();
    const [selectedWebsite, setSelectedWebsite] = React.useState();
    const [queryParams, setQueryParams] = React.useState([]);

    const cancelBtnRef = React.useRef();
    const nameRef = React.useRef();
    const pathRef = React.useRef();

    const getDomains = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/domains/getAllDomains`,
            data: {
                offset: 0,
                limit: undefined
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setDomains(res.data);
            } else {
                animateBox({currentTarget: cancelBtnRef.current}, <ModalError error={`Error while fetching domains, a server error occured!`} onChange={props.onClose} />);
                setDomains({status: "error", data: "SERVER_ERROR"});
            };
        }).catch(() => {
            animateBox({currentTarget: cancelBtnRef.current}, <ModalError error={`Error while fetching domains, server timed out!`} onChange={props.onClose} />);
            setDomains({status: "error", data: "SERVER_ERROR"});
        });
    };

    const addRoute = () => {
        setInfoP();

        let finalQP = {};

        for (let item of queryParams) {
            if (!item?.Name) return;
            finalQP[item.Name] = item.Value;
        };

        let data = {
            DomainID: selectedDomain,
            WebsiteID: selectedWebsite?.ID,
            Name: nameRef.current.value,
            Path: pathRef.current.value,
            QueryParameters: finalQP
        };

        if (props.edit) data["ID"] = props.edit.ID;

        if (!data.DomainID) return setInfoP("Domain can't be empty");
        if (!data.WebsiteID) return setInfoP("Please select a website");
        if (!data.Name) return setInfoP("Name can't be empty");
        if (!data.Path) return setInfoP("Path can't be empty");

        let finalURL = "http://example.com";
        finalURL += `${data.Path.startsWith("/") ? "" : "/"}${data.Path}`;

        try {
            new URL(finalURL);
        } catch {
            setInfoP("Path is invalid!");
        };

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/websiteRoutes/${props.edit ? "editRoute" : "addRoute"}`,
            data,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                props.onChange && props.onChange();
                props?.onClose();
            } else {
                if (res.data?.data === "PATH_ALREADY_EXISTS") {
                    return setInfoP(`Path ${data.Path} with the same query parameters already exists for this domain!`);
                } else {
                    setInfoP(`There was an error while ${props.edit ? "editing" : "adding"} this route!\nCheck the information you entered and try again later.`);
                };
            };
        }).catch(() => {
            setInfoP("Server timed out!");
        }).finally(() => {
            setSpinner(false);
        });
    };

    React.useEffect(() => {
        getDomains();

        if (props.edit) {
            setSelectedDomain(props.edit.DomainID);
            setSelectedWebsite({ID: props.edit.WebsiteID, Name: props.edit.Website.Name});

            let id = 0;
            let finalQuery = [];
            for (let key of Object.keys(props.edit.QueryParameters)) {
                id += 1;
                if (!props.edit.QueryParameters[key]) {
                    finalQuery.push({Name: key, Value: null, rndID: id});
                } else {
                    finalQuery.push({Name: key, Value: props.edit.QueryParameters[key], rndID: id});
                };
            };
            setQueryParams(finalQuery);
        };
    }, []);

    return <div className="route__websiteRoutes__addRoute">
        <div className="route__websiteRoutes__addRoute__wrap">
            <div className="route__websiteRoutes__addRoute__wrap__spinner" style={{
                opacity: (domains && !spinner) ? 0 : 1,
                pointerEvents: (domains && !spinner) ? "none" : "all"
            }} onClick={(e) => (!domains || spinner) && e.stopPropagation()}>
                <Spinner color="#2c7de3" />
            </div>

            <h3 style={{marginBottom: "20px"}}>{props.edit ? "Edit" : "Add"} website route</h3>

            <CustomInput ref={nameRef} defaultValue={props?.edit?.Name ?? ""} accent="#fff" theme="dark" placeholder="Name" style={{marginBottom: "20px"}} />
            <CustomInput ref={pathRef} defaultValue={props?.edit?.Path ?? ""} accent="#fff" theme="dark" placeholder="Path (/home, /about...)" style={{marginBottom: "20px"}} />
            <Dropdown inlinePlaceholder="Select a domain" accent="#fff" theme="dark" data={(()=>{
                if (!domains) return [];
                if (domains.status === "error") return [];
                return domains.data.map(item => {
                    return {name: `${item.Name} (SSL: ${item.isSSL ? "Yes" : "No"})`, value: item.ID}
                });
            })()} onChange={e => setSelectedDomain(e?.value)} selected={(()=>{
                if (!selectedDomain) return null;
                if (!domains || domains?.status === "error") return null;
                return domains.data.indexOf(domains.data.find(f => f.ID === selectedDomain));
            })()} />

            {!selectedWebsite ? <>
                <Button theme="dark" accent="#3F7CEA" value="Select website" style={{marginTop: "20px", width: "100%"}} onClick={e => {
                    animateBox(e, <SelectWebsite type="static" onChange={ws => {
                        setSelectedWebsite({
                            ID: ws.ID,
                            Name: ws.Name
                        });
                    }} />)
                }} />
            </> : <div className="route__websiteRoutes__addRoute__wrap__selectedWebsite">
                <p><span style={{color: "#7F8993"}}>Website: </span>{selectedWebsite?.Name}</p>
                <Button theme="dark" accent="rgb(234, 145, 63)" value="Change" onClick={e => {
                    animateBox(e, <SelectWebsite type="static" onChange={ws => {
                        setSelectedWebsite({
                            ID: ws.ID,
                            Name: ws.Name
                        });
                    }} />)
                }} />
            </div>}

            <div className="route__websiteRoutes__addRoute__wrap__queryFilter">
                <p>Query parameter filtering</p>
                <div className="route__websiteRoutes__addRoute__wrap__btns" style={{width: "auto", margin: 0}}>
                    <p onClick={() => {
                        setQueryParams((qp) => [...qp, {Name: `param${qp.length+1}`, Value: null, rndID: `${Math.floor(Math.random() * 987654)}-${Date.now()}`}]);
                    }}>
                        <img style={{ marginRight: "15px" }} src="/images/addBtn.png" />
                        <span>Add</span>
                    </p>
                    <p></p>
                </div>
            </div>
            {queryParams.length > 0 && <FilteredCustomTable
                key={`domainTable-queryParams`}
                theme="dark"
                accent="#48515C"
                headers={["Name", "Value"]}
                className="route__websiteRoutes__addRoute__wrap__queryFilter__table"
                data={(()=>{
                    let ct = null;
                    return queryParams?.map((elem, elemID) => {
                        return [
                            {keyID:elem.rndID, type: "custom", data: <CustomInput placeholder="Parameter name" accent="#fff" theme="dark" defaultValue={elem?.Name} onChange={e => {
                                setQueryParams(qp => [
                                    ...qp.filter((_, idx) => idx < elemID),
                                    {Name: e.currentTarget?.value, Value: elem?.Value, rndID: elem?.rndID},
                                    ...qp.filter((_, idx) => idx > elemID)
                                ]);
                            }} style={{width: "calc(100% - 20px)"}} />},
                            {keyID:elem.rndID, type: "custom", data: <CustomInput placeholder="Parameter value (string)" accent="#fff" theme="dark" defaultValue={elem?.Value} onChange={e => {
                                setQueryParams(qp => [
                                    ...qp.filter((_, idx) => idx < elemID),
                                    {Name: elem?.Name, Value: e.currentTarget?.value, rndID: elem?.rndID},
                                    ...qp.filter((_, idx) => idx > elemID)
                                ]);
                            }} style={{width: "calc(100% - 20px)"}} />},
                            {keyID:elem.rndID, type: "groupNewline", group: [
                                {keyID:elem.rndID, type: "custom", data: (() => {
                                    if (queryParams.filter(t => t.Name === elem.Name).length > 1) {
                                        return <span style={{color: "#f76363"}}>It is not recommended to have 2 or more params with the same name!<br/>Only last one will be taken into account</span>;
                                    };
                                    if (!elem?.Name) return <span style={{color: "#f76363"}}>Param name missing!</span>;
                                    if (!elem.Value) {
                                        return <span>Expected <span style={{color: "#3F7CEA"}}>{elem.Name}</span> to have <span style={{color: "rgb(234, 145, 63)"}}>any (non-empty)</span> value</span>
                                    } else {
                                        return <span>Expected <span style={{color: "#3F7CEA"}}>{elem.Name}</span> to exactly have <span style={{color: "rgb(129, 232, 135)"}}>'{elem.Value}'</span></span>
                                    };
                                })()}
                            ]},
                            {keyID:elem.rndID, type: "groupNewline", group: [
                                {keyID:elem.rndID, type: "button", text: "Delete", style:{height: "25px"}, onClick: () => setQueryParams(qp => [...qp.filter((_, idx) => idx !== elemID)])}
                            ]}
                        ];
                    }) ?? [];
                })()}
            />}

            <div className="route__websiteRoutes__addRoute__wrap__btns">
                <p onClick={addRoute}>
                    <img style={{ marginRight: "15px" }} src="/images/saveBtn.png" />
                    <span>Save</span>
                </p>
                <p onClick={props.onClose} ref={cancelBtnRef}>
                    <span>Cancel</span>
                    <img style={{ marginLeft: "15px" }} src="/images/closeBtn.png" />
                </p>
            </div>
            

            {infoP && <p className="route__domainsList__addDomain__wrap__infoP">{infoP}</p>}
        </div>
    </div>
};

const RemoveRoute = props => {
    const [spinner, setSpinner] = React.useState(false);
    const [infoP, setInfoP] = React.useState();

    const removeRoute = () => {
        setInfoP();

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/websiteRoutes/removeRoute`,
            data: {
                ID: props.data.ID
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (props.onChange) props.onChange();
                props.onClose();
            } else {
                setInfoP("There was an error while removing the route!");
            };
        }).catch(() => {
            setInfoP("Server timed out!");
        }).finally(() => {
            setSpinner(false);
        });
    };

    return <div className="route__websiteRoutes__addRoute">
        <div className="route__websiteRoutes__addRoute__wrap">
            <div className="route__websiteRoutes__addRoute__wrap__spinner" style={{
                opacity: (!spinner) ? 0 : 1,
                pointerEvents: (!spinner) ? "none" : "all"
            }} onClick={(e) => (spinner) && e.stopPropagation()}>
                <Spinner color="#2c7de3" />
            </div>

            <h3>Delete route</h3>
            <p>Are you sure? Removal of <span style={{color: "#3F7CEA"}}>{props.data.Name}</span> is irreversible!</p>

            <div className="route__websiteRoutes__addRoute__wrap__btns">
                <p onClick={removeRoute}>
                    <img style={{ marginRight: "15px" }} src="/images/saveBtn.png" />
                    <span>Save</span>
                </p>
                <p onClick={props.onClose}>
                    <span>Cancel</span>
                    <img style={{ marginLeft: "15px" }} src="/images/closeBtn.png" />
                </p>
            </div>

            {infoP && <p className="route__domainsList__addDomain__wrap__infoP">{infoP}</p>}
        </div>
    </div>
};

const ModalError = (props) => {
    return <div className="route__createWebisteDialog">
        <div className="route__createWebisteDialog__wrap">
            <h3 style={{ marginBottom: "10px" }}>{props.heading ? props.heading : "Error"}</h3>
            <p style={{ marginBottom: "20px", whiteSpace: "pre-wrap" }}>{props.error}</p>
            <div className="route__createWebisteDialog__wrap__btns">
                <p onClick={() => {
                    props.onClose().then(() => {
                        if (props.onChange) props.onChange();
                    });
                }}>
                    <img style={{ marginRight: "15px" }} src="/images/closeBtn.png" />
                    <span>Close</span>
                </p>
            </div>
        </div>
    </div>
};

let curTimeout2 = null;
const SelectWebsite = (props) => {
    const [data, setData] = React.useState();
    const [filters, setFilters] = React.useState([]);
    const [spinner, setSpinner] = React.useState(false);

    const mainRef = React.useRef();
    const searchRef = React.useRef();

    const getTemplates = () => {
        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/websites/getAllWebsites`,
            data: {
                filters: [
                    ...filters,
                    {name: "Type", op: "eq", value: props.type}
                ],
                limit: undefined,
                orders: [{name: "createdAt", order: "desc"}]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setData(res.data);
            } else {
                setData({status: "error", data: "SERVER_ERROR"});
            };
        }).catch(() => {
            setData({status: "error", data: "SERVER_ERROR"});
        }).finally(() => {
            setSpinner(false);
        });
    };

    const selectWebsite = id => {
        props.onChange(id);
        props.onClose();
    };

    React.useEffect(() => {
        if (!searchRef.current) return;

        let handler = () => {
            let searchVal = searchRef?.current?.value;
            clearTimeout(curTimeout2);
            curTimeout2 = setTimeout(() => {
                setFilters([{name: "--searcher", value: searchVal}])
            }, 500);
        };

        searchRef.current.addEventListener("input", handler);

        return () => {
            try {
                searchRef.current.removeEventListener("input", handler);
            } catch {};
        };
    }, [searchRef.current]);

    React.useEffect(() => {
        setData();
        getTemplates();
    }, [filters]);

    return <div className="route__openTemplateModal" ref={mainRef} style={{all: "initial", color: "white"}}>
        <div className="route__openTemplateModal__wrap" style={{gridTemplateColumns: "1fr"}}>
            <div className="route__openTemplateModal__wrap__spinner" style={{
                opacity: spinner ? 1 : 0,
                pointerEvents: spinner ? "all" : "none"
            }} onClick={e => {
                if (spinner) e.stopPropagation();
            }}>
                <Spinner accent="#3F7CEA" />
            </div>

            <div className="route__openTemplateModal__wrap__heading" style={{marginBottom: "20px"}}>
                <h3>Select website</h3>
                <div className="route__openTemplateModal__wrap__btns">
                    <p onClick={() => {
                        props.onClose();
                    }}>
                        <span>Close</span>
                        <img style={{ marginLeft: "15px" }} src="/images/closeBtn.png" />
                    </p>
                </div>
            </div>

            <CustomInput autocomplete="off" ref={searchRef} accent="#3F7CEA" theme="dark" placeholder="Search..." style={{gridColumn: "1 / span all"}} />
            <div className="route__openTemplateModal__wrap__templates">
                {!data ? <Spinner /> : <>
                    {data.status === "error" ? <p>There was an error while getting websites!</p> : <>
                        {data.data.length === 0 && <p>No websites found</p>}
                        {data.data.map((elem) => {
                            return <LandingItem
                                key={`${elem.ID}-${elem.updatedAt}`}
                                data={elem}
                                buttons={<>
                                    <Button value="Select template" accent="rgb(63, 124, 234)" style={{width: "150px"}} onClick={e => {
                                        e.stopPropagation();
                                        selectWebsite(elem);
                                    }} />
                                </>}
                            />
                        })}
                    </>}
                </>}
            </div>

        </div>
    </div>
};

export default RouteList;
export {AddRoute};