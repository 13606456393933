import gjs from "grapesjs";

function script() {
    if(!this) return;

    const hamburger = this.querySelector('.scalecms-headerThree-hamburger');
    const navbar = this.querySelector('.scalecms-headerThree-firstTrack-navbar');
    if (!hamburger || !navbar) return;

    hamburger.addEventListener('click', () => {
        if(!navbar.classList.contains('active')){
            navbar.style.display = 'flex';
            navbar.classList.add('active');
        }
        else {
            navbar.style.display = 'none';
            navbar.classList.remove('active');
        }
    });
}

/**
 * @param {gjs.Editor} editor
 */
export default editor => {
    editor.Components.addType('scalecms-headerThree', {
        isComponent: e => e?.classList?.contains("scalecms-headerThree"),
        model: {
            defaults: {
                type: "default",
                traits: [

                ],
                attributes: {
                    class: "scalecms-headerThree"
                },
                script,
                styles: `
                    .scalecms-headerThree {
                        font-family: 'Arial';
                        width: 100%;
                        margin-left: auto;
                        margin-right: auto;
                        position: relative;
                    }
                    .scalecms-headerThree-firstTrack-socials{
                        position: absolute;
                        top: 5px;
                        right: 5px;
                        width: 24px;
                        height: 24px;
                        cursor: pointer;
                    }
                    .scalecms-headerThree-firstTrack{
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        justify-content: baseline;
                        align-items: center;
                        padding: 5px;
                        padding-right: 10px;
                        padding-left: 10px;
                        background: #eb2969;
                        height: 55px;
                    }

                    .scalecms-headerThree-firstTrack-logo{
                        width: 80px;
                        height: 50px;
                    }

                    .scalecms-headerThree-firstTrack-navbar{
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        justify-content: baseline;
                        align-items: center;
                    }
                    .scalecms-headerThree-firstTrack-navbar-item{
                        color: white;
                        margin-left: 20px;
                        font-weight: 700;
                        cursor: pointer;
                    }
                    .scalecms-headerThree-secondTrack{
                        background: #2d2d2d;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        height: 55px;
                        padding-left: 10px;
                        padding-right: 10px;
                    }
                    .scalecms-headerThree-secondTrack-item{
                        color: #c9c9c9;
                        font-size: 12px;
                        cursor: pointer;
                    }
                    .scalecms-headerThree-thirdTrack{
                        background: #eb2969;
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        height: 48px;
                    }
                    .scalecms-headerThree-thirdTrack-item{
                        font-weight: 700;
                        border-right: 2px solid white;
                        padding-right: 10px;
                        padding-left: 10px;
                        color: white;
                        cursor: pointer;
                    }
                    .scalecms-headerThree-hamburger{
                        display: none;
                        cursor: pointer;
                    }
                    @media screen and (max-width: 1400px){
                        .scalecms-headerThree{
                            width: 100%;
                        }
                    }
                    @media screen and (max-width: 600px){
                        .scalecms-headerThree-hamburger{
                            display: block;
                        }
                        .scalecms-headerThree-firstTrack-navbar{
                            display: none;
                            flex-direction: column;
                            position: absolute;
                            top: 55px;
                            left: 0;
                            background: #eb2969;
                            justify-content: baseline;
                            align-items: baseline;
                        }
                        .scalecms-headerThree-firstTrack-navbar-item{
                            margin-bottom: 10px;
                            margin-left: 10px;
                        }
                        .scalecms-headerThree-secondTrack{
                            display: none;
                        }
                        .scalecms-headerThree-thirdTrack{
                            display: none;
                        }
                        .scalecms-headerThree-firstTrack-logo{
                            display: block;
                            margin-left: auto;
                            margin-right: auto;
                        }
                        .scalecms-headerThree-firstTrack-socials{
                            top: 15px;
                        }
                    }
                `,
                components: [
                    {
                        tagName: 'div',
                        attributes: {class: 'scalecms-headerThree-firstTrack'},
                        components: [
                            {
                                tagName: 'img',
                                attributes: {class: 'scalecms-headerThree-firstTrack-socials', src: 'https://i.imgur.com/IywaVv1.png'},
                            },
                            {
                                tagName: 'img',
                                attributes: {class: 'scalecms-headerThree-hamburger', src: 'https://i.imgur.com/boqY7Lz.png'},
                            },
                            {
                                type: 'image',
                                attributes: {class: 'scalecms-headerThree-firstTrack-logo'}
                            },
                            {
                                tagName: 'div',
                                attributes: {class: 'scalecms-headerThree-firstTrack-navbar'},
                                components: [
                                    {
                                        type: 'text',
                                        attributes: {class: 'scalecms-headerThree-firstTrack-navbar-item'},
                                        components: 'NAV-ITEM'
                                    },
                                    {
                                        type: 'text',
                                        attributes: {class: 'scalecms-headerThree-firstTrack-navbar-item'},
                                        components: 'NAV-ITEM'
                                    },
                                    {
                                        type: 'text',
                                        attributes: {class: 'scalecms-headerThree-firstTrack-navbar-item'},
                                        components: 'NAV-ITEM'
                                    },
                                    {
                                        type: 'text',
                                        attributes: {class: 'scalecms-headerThree-firstTrack-navbar-item'},
                                        components: 'NAV-ITEM'
                                    },
                                    {
                                        type: 'text',
                                        attributes: {class: 'scalecms-headerThree-firstTrack-navbar-item'},
                                        components: 'NAV-ITEM'
                                    }
                                ]
                            },
                        ]
                    },
                    {
                        tagName: 'div',
                        attributes: {class: 'scalecms-headerThree-secondTrack'},
                        components: [
                            {
                                type: 'text',
                                attributes: {class: 'scalecms-headerThree-secondTrack-item'},
                                components: 'nav-item'
                            },
                            {
                                type: 'text',
                                attributes: {class: 'scalecms-headerThree-secondTrack-item'},
                                components: 'nav-item'
                            },
                            {
                                type: 'text',
                                attributes: {class: 'scalecms-headerThree-secondTrack-item'},
                                components: 'nav-item'
                            },
                            {
                                type: 'text',
                                attributes: {class: 'scalecms-headerThree-secondTrack-item'},
                                components: 'nav-item'
                            },
                            {
                                type: 'text',
                                attributes: {class: 'scalecms-headerThree-secondTrack-item'},
                                components: 'nav-item'
                            },
                            {
                                type: 'text',
                                attributes: {class: 'scalecms-headerThree-secondTrack-item'},
                                components: 'nav-item'
                            },
                            {
                                type: 'text',
                                attributes: {class: 'scalecms-headerThree-secondTrack-item'},
                                components: 'nav-item'
                            },
                            {
                                type: 'text',
                                attributes: {class: 'scalecms-headerThree-secondTrack-item'},
                                components: 'nav-item'
                            },
                            {
                                type: 'text',
                                attributes: {class: 'scalecms-headerThree-secondTrack-item'},
                                components: 'nav-item'
                            },
                            {
                                type: 'text',
                                attributes: {class: 'scalecms-headerThree-secondTrack-item'},
                                components: 'nav-item'
                            },
                        ]
                    },
                    {
                        tagName: 'div',
                        attributes: {class: 'scalecms-headerThree-thirdTrack'},
                        components: [
                            {
                                type: 'text',
                                attributes: {class: 'scalecms-headerThree-thirdTrack-item'},
                                components: 'Nav-item'
                            },
                            {
                                type: 'text',
                                attributes: {class: 'scalecms-headerThree-thirdTrack-item'},
                                components: 'Nav-item'
                            },
                            {
                                type: 'text',
                                attributes: {class: 'scalecms-headerThree-thirdTrack-item'},
                                components: 'Nav-item'
                            },
                            {
                                type: 'text',
                                attributes: {class: 'scalecms-headerThree-thirdTrack-item'},
                                components: 'Nav-item'
                            },
                            {
                                type: 'text',
                                attributes: {class: 'scalecms-headerThree-thirdTrack-item'},
                                components: 'Nav-item'
                            },
                            {
                                type: 'text',
                                attributes: {class: 'scalecms-headerThree-thirdTrack-item'},
                                components: 'Nav-item'
                            },
                            {
                                type: 'text',
                                attributes: {class: 'scalecms-headerThree-thirdTrack-item'},
                                components: 'Nav-item'
                            },
                            {
                                type: 'text',
                                attributes: {class: 'scalecms-headerThree-thirdTrack-item'},
                                components: 'Nav-item'
                            },
                        ]
                    }
                ]
            }
        }
    });


    editor.BlockManager.add('scalecms-headerThree-blk', {
        label: 'Header 3',
        content: {
            type: "scalecms-headerThree",
        },
        category: {
            label: 'Headers'
        },
        media: `<svg viewBox="0 0 24 24"><g data-name="layout 34"><path d="M19,2H5A3,3,0,0,0,2,5V19a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V5A3,3,0,0,0,19,2Zm1,17a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V5A1,1,0,0,1,5,4H19a1,1,0,0,1,1,1Z"/><path d="M17,5H7A2,2,0,0,0,5,7V8a2,2,0,0,0,2,2H17a2,2,0,0,0,2-2V7A2,2,0,0,0,17,5Zm0,3H7V7H17Z"/></g></svg>`,
        attributes: {
            title: 'Basic header 3',
        },
        select: true
    });
};