import gjs from "grapesjs";

/**
 * @param {gjs.Editor} editor
 */
export default editor => {
    editor.Components.addType('scalecms-sidebar', {
        isComponent: e => e?.classList?.contains("scalecms-sidebar"),
        model: {
            init(){
                this.on('change', e => {
                    let visibleUntil = e?.attributes?.attributes?.['data-maxwidth'];
                    if(isNaN(Number(visibleUntil))) return;
                    if(Number(visibleUntil) < 0) return;

                    this.get("components").each(child => {
                        if(child?.attributes?.tagName === 'style'){
                            child.components(
                            `
                                @media screen and (max-width: ${visibleUntil}px){
                                    .scalecms-sidebar{
                                        display: none;
                                    }
                                }
                            `
                            );
                        }
                    })
                });
            },
            defaults: {
                type: "default",
                traits: [
                    { name: "data-maxwidth", label: "Visible until", placeholder: "600", value: "0" },
                ],
                attributes: {
                    class: "scalecms-sidebar"
                },
                styles: `
                    .scalecms-sidebar {
                        width: 100%;
                        background: #021859;
                        padding: 20px;
                        font-family: 'Arial';
                    }
                    .scalecms-sidebar-testimonial{
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        border-bottom: 1px solid white;
                        margin-bottom: 30px;
                        padding-bottom: 10px;
                    }
                    .scalecms-sidebar-testimonial-image{
                        max-width: 100%;
                        max-height: 100%;
                    }
                    
                    .scalecms-sidebar-testimonial-text{
                        margin-top: 10px;
                        color: white;
                        font-size: 20px;
                        font-weight: 700;
                    }
                    .scalecms-sidebar-heading{
                        font-size: 25px;
                        color: white;
                        margin-bottom: 10px;
                        display: block;
                        margin-left: 0;
                        margin-right: auto;
                        font-weight: 700;
                    }
                    .scalecms-sidebar-testimonial-textlong{
                        font-size: 15px;
                    }
                    .scalecms-sidebar-testimonial-textlong{
                        color: white;
                        margin-top: 10px;
                    }
                    .scalecms-sidebar-article{
                        display: grid;
                        grid-template-columns: 30% 70%;
                        margin-bottom: 30px;
                    }
                    .scalecms-sidebar-article-image{
                        width: 100%;
                        height: 100%;
                    }
                    .scalecms-sidebar-article-text{
                        font-size: 25px;
                        font-weight: 700;
                        color: white;
                        margin-left: 10px;
                    }
                    @media screen and (max-width: 1200px){
                        .scalecms-sidebar-article-text{
                            font-size: 20px;
                        }
                    }
                `,
                components: [
                    {
                        type: "text",
                        attributes: { class: "scalecms-sidebar-heading" },
                        components: "Recenzije:"
                    },
                    {
                        tagName: "div",
                        attributes: { class: "scalecms-sidebar-testimonial" },
                        components: [
                            {
                                type: "image",
                                attributes: { class: "scalecms-sidebar-testimonial-image" }
                            },
                            {
                                type: "text",
                                attributes: { class: "scalecms-sidebar-testimonial-text" },
                                components: "Ime osobe"
                            },
                            {
                                type: "text",
                                attributes: { class: "scalecms-sidebar-testimonial-textlong" },
                                components: "Vaš text..."
                            }
                        ]
                    },
                    {
                        tagName: "div",
                        attributes: { class: "scalecms-sidebar-testimonial" },
                        components: [
                            {
                                type: "image",
                                attributes: { class: "scalecms-sidebar-testimonial-image" }
                            },
                            {
                                type: "text",
                                attributes: { class: "scalecms-sidebar-testimonial-text" },
                                components: "Ime osobe"
                            },
                            {
                                type: "text",
                                attributes: { class: "scalecms-sidebar-testimonial-textlong" },
                                components: "Vaš text..."
                            }
                        ]
                    },
                    {
                        tagName: "div",
                        attributes: { class: "scalecms-sidebar-testimonial" },
                        components: [
                            {
                                type: "image",
                                attributes: { class: "scalecms-sidebar-testimonial-image" }
                            },
                            {
                                type: "text",
                                attributes: { class: "scalecms-sidebar-testimonial-text" },
                                components: "Ime osobe"
                            },
                            {
                                type: "text",
                                attributes: { class: "scalecms-sidebar-testimonial-textlong" },
                                components: "Vaš text..."
                            }
                        ]
                    },
                    {
                        tagName: "div",
                        attributes: { class: "scalecms-sidebar-testimonial" },
                        components: [
                            {
                                type: "image",
                                attributes: { class: "scalecms-sidebar-testimonial-image" }
                            },
                            {
                                type: "text",
                                attributes: { class: "scalecms-sidebar-testimonial-text" },
                                components: "Ime osobe"
                            },
                            {
                                type: "text",
                                attributes: { class: "scalecms-sidebar-testimonial-textlong" },
                                components: "Vaš text..."
                            }
                        ]
                    },
                    {
                        tagName: "div",
                        attributes: { class: "scalecms-sidebar-testimonial" },
                        components: [
                            {
                                type: "image",
                                attributes: { class: "scalecms-sidebar-testimonial-image" }
                            },
                            {
                                type: "text",
                                attributes: { class: "scalecms-sidebar-testimonial-text" },
                                components: "Ime osobe"
                            },
                            {
                                type: "text",
                                attributes: { class: "scalecms-sidebar-testimonial-textlong" },
                                components: "Vaš text..."
                            }
                        ]
                    },
                    {
                        tagName: "div",
                        attributes: { class: "scalecms-sidebar-article" },
                        components: [
                            {
                                type: "image",
                                attributes: { class: "scalecms-sidebar-article-image" }
                            },
                            {
                                type: "text",
                                attributes: { class: "scalecms-sidebar-article-text" },
                                components: "naslov"
                            },
                        ]
                    },
                    {
                        tagName: "div",
                        attributes: { class: "scalecms-sidebar-article" },
                        components: [
                            {
                                type: "image",
                                attributes: { class: "scalecms-sidebar-article-image" }
                            },
                            {
                                type: "text",
                                attributes: { class: "scalecms-sidebar-article-text" },
                                components: "naslov"
                            },
                        ]
                    },
                    {
                        tagName: "div",
                        attributes: { class: "scalecms-sidebar-article" },
                        components: [
                            {
                                type: "image",
                                attributes: { class: "scalecms-sidebar-article-image" }
                            },
                            {
                                type: "text",
                                attributes: { class: "scalecms-sidebar-article-text" },
                                components: "naslov"
                            },
                        ]
                    },
                    {
                        tagName: "div",
                        attributes: { class: "scalecms-sidebar-article" },
                        components: [
                            {
                                type: "image",
                                attributes: { class: "scalecms-sidebar-article-image" }
                            },
                            {
                                type: "text",
                                attributes: { class: "scalecms-sidebar-article-text" },
                                components: "naslov"
                            },
                        ]
                    },
                    {
                        tagName: "div",
                        attributes: { class: "scalecms-sidebar-article" },
                        components: [
                            {
                                type: "image",
                                attributes: { class: "scalecms-sidebar-article-image" }
                            },
                            {
                                type: "text",
                                attributes: { class: "scalecms-sidebar-article-text" },
                                components: "naslov"
                            },
                        ]
                    },
                    {
                        tagName: "style",
                        attributes: {class: "scalecms-sidebar-style"}
                    },
                ]
            }
        }
    });


    editor.BlockManager.add('scalecms-sidebar-blk', {
        label: 'Sidebar 1',
        content: {
            type: "scalecms-sidebar",
        },
        category: {
            label: 'Sidebars'
        },
        media: `<svg width="100" height="100" viewBox="0 0 24 24"><path d="M19.25,6a.5.5,0,0,1-.5.5h-2a.5.5,0,0,1,0-1h2A.5.5,0,0,1,19.25,6Zm-.5,2.5h-2a.5.5,0,0,0,0,1h2a.5.5,0,0,0,0-1Zm0,3h-2a.5.5,0,0,0,0,1h2a.5.5,0,0,0,0-1Zm2.5-6v13a2.5026,2.5026,0,0,1-2.5,2.5h-14a2,2,0,0,1-2-2V5a2,2,0,0,1,2-2h14A2.5026,2.5026,0,0,1,21.25,5.5Zm-1,0A1.5017,1.5017,0,0,0,18.75,4h-3.5V20h3.5a1.5017,1.5017,0,0,0,1.5-1.5Z"/></svg>`,
        attributes: {
            title: 'Basic sidebar',
        },
        select: true
    });
};