import gjs from "grapesjs";

function script() {
  if (!this) return;
  if (this.getAttribute("data-gjs-type")) return;

  let minutesInput = this.getAttribute('data-minutes');

  let minutesText = document.querySelector(".generic-timer8-minutes-text");
  let minutesContainer = document.querySelector(".generic-timer8-minutes");
  let secondsText = document.querySelector(".generic-timer8-seconds-text");
  let secondsContainer = document.querySelector(".generic-timer8-seconds");

  if (isNaN(Number(minutesInput))) return;
  if (Number(minutesInput) < 0) return;

  var timer = Number(minutesInput) * 60, minutes, seconds;
  setInterval(() => {
    minutes = parseInt(timer / 60, 10);
    seconds = parseInt(timer % 60, 10);

    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    minutesText.textContent = minutes;
    secondsText.textContent = seconds;

    // this.textContent = minutes + ":" + seconds;

    if (--timer < 0) {
      timer = Number(minutesInput) * 60;
    }
  }, 1000);
}

/**
 * @param {gjs.Editor} editor
 */
export default editor => {
  editor.Components.addType('generic-timer8', {
    isComponent: e => e?.classList?.contains("generic-timer8"),
    model: {
      defaults: {
        script,
        textable: 1,
        type: "default",
        traits: [
          { name: "data-minutes", label: "N minutes left", placeholder: "15", value: "15" },
        ],
        attributes: {
          class: "generic-timer8"
        },
        styles: `
          .generic-timer8{
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 5px;
          }
          .generic-timer8-minutes{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 5px;
            padding: 10px;
            border-radius: 3px;
            background: #FF6666;
            font-family: sans-serif;
	          color: #fff;
          }
          .generic-timer8-minutes-text{
            padding: 15px;
            border-radius: 3px;
            background: #CC0000;
            display: inline-block;
            font-weight: 500;
            text-align: center;
            font-size: 30px;
          }
          .generic-timer8-minutes-desc{
            padding-top: 5px;
	          font-size: 16px;
          }
          .generic-timer8-seconds{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 5px;
            padding: 10px;
            border-radius: 3px;
            background:  #FF6666;
            font-family: sans-serif;
	          color: #fff;
          }
          .generic-timer8-seconds-text{
            padding: 15px;
            border-radius: 3px;
            background: #CC0000;
            display: inline-block;
            font-weight: 500;
            text-align: center;
            font-size: 30px;
          }
          .generic-timer8-seconds-desc{
            padding-top: 5px;
	          font-size: 16px;
          }
        `,
        // components: `00:00`
        components: [
          {
            tagName: 'div',
            attributes: { class: 'generic-timer8-minutes' },
            components: [
              {
                type: 'text',
                attributes: { class: 'generic-timer8-minutes-text' },
                components: '00'
              },
              {
                type: 'text',
                attributes: { class: 'generic-timer8-minutes-desc' },
                components: 'Min'
              },
            ]
          },
          {
            tagName: 'div',
            attributes: { class: 'generic-timer8-seconds' },
            components: [
              {
                type: 'text',
                attributes: { class: 'generic-timer8-seconds-text' },
                components: '00'
              },
              {
                type: 'text',
                attributes: { class: 'generic-timer8-seconds-desc' },
                components: 'Sek'
              },
            ]
          },
        ]
      }
    }
  });


  editor.BlockManager.add('generic-timer8-blk', {
    label: 'Generic Timer 8',
    content: {
      type: "generic-timer8",
    },
    category: {
      label: 'Timers'
    },
    media: `
        <?xml version="1.0" encoding="iso-8859-1"?>
        <svg version="1.1" id="Layer_1" x="0px" y="0px"
             viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
        <g>
            <g>
                <g>
                    <path d="M276.193,58.507V40.389h14.578c11.153,0,20.194-9.042,20.194-20.194S301.923,0,290.771,0h-69.544
                        c-11.153,0-20.194,9.042-20.194,20.194s9.042,20.194,20.194,20.194h14.578v18.118C119.952,68.76,28.799,166.327,28.799,284.799
                        C28.799,410.078,130.721,512,256,512s227.201-101.922,227.201-227.201C483.2,166.327,392.046,68.76,276.193,58.507z
                         M276.193,470.516v-20.124c0-11.153-9.042-20.194-20.194-20.194c-11.153,0-20.194,9.042-20.194,20.194v20.124
                        c-86.91-9.385-156.137-78.614-165.522-165.522h20.124c11.153,0,20.194-9.042,20.194-20.194s-9.042-20.194-20.194-20.194H70.282
                        c9.385-86.91,78.614-156.137,165.522-165.523v20.124c0,11.153,9.042,20.194,20.194,20.194c11.153,0,20.194-9.042,20.194-20.194
                        V99.081c86.91,9.385,156.137,78.614,165.522,165.523h-20.124c-11.153,0-20.194,9.042-20.194,20.194s9.042,20.194,20.194,20.194
                        h20.126C432.331,391.903,363.103,461.132,276.193,470.516z"/>
                    <path d="M317.248,194.99l-58.179,58.18c-1.011-0.097-2.034-0.151-3.071-0.151c-17.552,0-31.779,14.229-31.779,31.779
                        c0,17.552,14.228,31.779,31.779,31.779s31.779-14.229,31.779-31.779c0-1.037-0.054-2.06-0.151-3.07l58.178-58.18
                        c7.887-7.885,7.887-20.672,0-28.559C337.922,187.103,325.135,187.103,317.248,194.99z"/>
                </g>
            </g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        </svg>
        
        `,
    attributes: {
      title: 'Generic timer',
    },
    select: true
  });
};