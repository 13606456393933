import gjs from "grapesjs";

function script() {

  let scaleLeadForm = document.querySelector(".scalelead-form");

  eval(``)
};

/**
 * @param {gjs.Editor} editor
 */
export default (editor, data, trackData) => {

  editor.on('component:add', (component) => {
    // Check if there are existing components with the class 'formInserted'
    const existingForms = editor.Canvas.getDocument().querySelectorAll('.formInserted');
  
    if (existingForms.length > 1) {
      // Remove the newly added component from the editor
      component.remove();
  
      console.log("Existing forms found: ", existingForms.length);
    }
  });

  const findComponentByType = (components, type) => {
    for (let component of components) {
      if (component.get('type') === type) {
        return component;
      }
      if (component.models && component.models.length > 0) {
        let nestedComponent = findComponentByType(component.components, type);
        if (nestedComponent) {
          return nestedComponent;
        }
      }else{
        if(typeof(component.components) === "function"){
          let nestedComponent = findComponentByType(component.components(), type);
          if (nestedComponent) {
            return nestedComponent;
          }
        }
      }
    }
    return null;
  }

  function findForKeyInAttributes(object, changed) {
    // console.log(inner, "OBJECTS ARRAY --- ", object)
    if(!changed) return

    if (object?.attributes && object?.attributes?.for || object?.attributes && object?.attributes?.attributes?.for) {
      switch (object?.attributes?.attributes?.for) {
        case 'ProizvodName':
          let currElem = data.find(el => el.UniqueToken === changed["data-offers"]);
          if(!currElem) break;
          let trackElem = trackData.find(el => el.ScaleleadOfferID === currElem.BoundOffer);
          if(!trackElem) break;
          object.components(`${trackElem.OfferName}`);
          break;
        case 'OldPrice':
          switch (changed?.country) {
            case "rs":
              object.components('7200 RSD');
              return;
            case "hr":
              object.components('79.80 €');
              return;
            case "ba":
              object.components('119.80 KM');
              return;
            case "me":
              object.components('79.80 €');
              return;
            default:
              break;
          };
          break;
      
        default:
          break;
      }
    }

    if (object?.attributes?.components?.models) {
      object?.attributes?.components?.models.forEach(elem => {
        findForKeyInAttributes(elem, changed)
      })
    }
  }

  editor.Components.addType('open-form7_cmp', {
    isComponent: e => e?.classList?.contains("open-form7"),
    model: {
      init() {
        this.on("change", e => {
          let formComponent = findComponentByType(editor.DomComponents.getComponents(), 'scalelead-form_cmp');
          if (e?.attributes?.attributes?.["data-offers"]) {
            formComponent.attributes.attributes["data-tokenselect"] = e?.attributes?.attributes?.["data-offers"];
            let curOffer = data.find(o => o.UniqueToken === e?.attributes?.attributes?.["data-offers"]);
            if (curOffer) {
              formComponent.attributes.attributes["data-domain"] = curOffer.Domain;
            }
          }

          formComponent.attributes.attributes["data-tokenselect"] = e?.attributes?.attributes?.["data-offers"];
          formComponent.attributes.attributes["data-article"] = e?.attributes?.attributes?.["data-suffix"];

          this.get("components").each(child => {
            findForKeyInAttributes(child, e.changed.attributes)
          });
        });
      },
      defaults: {
        type: "default",
        traits(component) {

          let offerData = data.map(o => {
            return {
              id: o.UniqueToken,
              name: o.FriendlyName ? `${o.FriendlyName} (${o.TakenOfferName})` : o.TakenOfferName
            };
          }).sort((a, b) => {
            return String(a.name).localeCompare(b.name);
          });
          offerData = [{ id: null, name: "Not selected" }, ...offerData];
          return [
            {
              type: 'select',
              label: 'Select an offer',
              name: 'data-offers',
              options: offerData,
            },
            {
              name: "country", type: "select", options: [
                { id: "custom", name: "Custom" },
                { id: "ba", name: "Bosna", selected: true },
                { id: "rs", name: "Srbija" },
                { id: "hr", name: "Hrvatska" },
                { id: "me", name: "Crna Gora" }
              ], value: "ba"
            },
            { name: "data-suffix", label: "Lead UID (Name suffix in CRM)", placeholder: "CRM, LV...", value: "" },
          ]
        },
        attributes: {
          class: "open-form7 scroll-target"
        },
        script,
        styles: `
          .open-form7{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            max-width: 340px;
            margin: 0 auto;
            border-radius: 10px;
            background: linear-gradient(180deg,#adfbb9 0%,#e4fbad 100%),#e4fbad;
            padding-bottom: 30px;
          }
          .open-form7-head{
            background: linear-gradient(180deg,#8840c0 0%,#5a1784 71.67%);
            position: relative;
            font-family: inter,sans-serif;
            padding-top: 20px;
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 135%;
            text-align: center;
            color: #fff;
            width: 100%;
            margin-bottom: 20px;
          }
          .open-form7-head-circle{
            display: block;
            position: absolute;
            bottom: -27px;
            background: 0 0/100% 100% url(https://backend.scale-cms.com:5000/staticContent/images/2752ee36-2b9f-41c5-9b69-9595be4ed2a1-1708611354029.png) no-repeat;
            height: 27px;
            width: 100%;
          }
          .open-form7-head-second{
            color: #e4fbad;
            display: block;
            margin-top: 5px;
            text-shadow: 0 1px 1px rgba(0,0,0,.7);
            font-weight: 600;
            font-size: 22px;
            line-height: 135%;
          }

          .open-form7-imgBlock-discount{
            display: flex;
            align-items: center;
            gap: 8px;
          }
          .open-form7-imgBlock-discount-oldPrice{
            font-size: 25px;
            text-decoration: line-through;
            color: #000;
            font-family: inter,sans-serif;
          }
          .open-form7-imgBlock-discount-newPrice{
            font-size: 30px;
            color: red;
            font-family: inter,sans-serif;
          }



          .open-form7 .scalecrm-form{
            width: 100%;
            max-width: 280px;
          }
          .open-form7 .scalecrm-form > label{
            font-family: inter,sans-serif;
          }
          .open-form7 .scalecrm-form > input{
            line-height: 48px;
            padding-left: 24px;
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            display: block;
            background: #fff;
            border: 1px solid #5a1784;
            border-radius: 24px;
            width: 100%;
            margin-bottom: 16px;
            position: relative;
          }
          .open-form7 .scalecrm-form > button{
            text-transform: uppercase;
            margin-top: 0;
            border-radius: 24px;
            height: 48px;
            display: block;
            text-align: center;
            width: 100%;
            background: #f64777;
            font-style: normal;
            font-weight: 700;
            letter-spacing: .03em;
            border: none;
            color: #fff;
            cursor: pointer;
            box-shadow: 0 0 0 0 rgb(232 76 61/70%);
            max-width: 300px;
            font-size: 24px;
            line-height: 34px;
            text-decoration: none;
            transition: 0.3s ease;
          }
          .open-form7 .scalecrm-form > button:hover{
            background-color: #d80303;
          }

          .open-form7 .scalelead-form{
            width: 100%;
            max-width: 280px;
          }
          .open-form7 .scalelead-form > label{
            font-family: inter,sans-serif;
          }
          .open-form7 .scalelead-form > input{
            line-height: 48px;
            padding-left: 24px;
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            display: block;
            background: #fff;
            border: 1px solid #5a1784;
            border-radius: 24px;
            width: 100%;
            margin-bottom: 16px;
            position: relative;
          }
          .open-form7 .scalelead-form > button{
            text-transform: uppercase;
            margin-top: 0;
            border-radius: 24px;
            height: 48px;
            display: block;
            text-align: center;
            width: 100%;
            background: #f64777;
            font-style: normal;
            font-weight: 700;
            letter-spacing: .03em;
            border: none;
            color: #fff;
            cursor: pointer;
            box-shadow: 0 0 0 0 rgb(232 76 61/70%);
            max-width: 300px;
            font-size: 24px;
            line-height: 34px;
            text-decoration: none;
            transition: 0.3s ease;
          }
          .open-form7 .scalelead-form > button:hover{
            background-color: #d80303;
          }
        `,
        components: [
          {
            tagName: 'div',
            attributes: { class: 'open-form7-head formInserted'},
            components: [
              {
                type: 'text',
                attributes: { class: 'open-form7-head-first' },
                components: [
                  {
                    type: 'text',
                    attributes: { class: '' },
                    components: 'Ostvarite '
                  },
                  {
                    type: 'text',
                    attributes: { for: "ProizvodName", class: 'proizvod-name' },
                    components: 'PRODUKT '
                  },
                ]
              },
              {
                type: 'text',
                attributes: { class: 'open-form7-head-second' },
                components: 'SA 50% POUSTA'
              },
              {
                type: 'div',
                attributes: { class: 'open-form7-head-circle' },
              },
            ]
          },
          {
            type: 'scalelead-form_cmp',
            attributes: { class: 'scalelead-form', 
              'data-token': '', 
              'data-tokenselect': '', 
              'data-domain': '' ,
              'data-article': '',
              'language': '',
              'data-custombuttontext': ''
            }
          },
          {
            tagName: 'div',
            attributes: { class: 'open-form7-imgBlock-discount'},
            components: [
              {
                type: 'text',
                attributes: { class: 'open-form7-imgBlock-discount-oldPrice' },
                components: '79.80 €'
              },
              {
                type: 'text',
                attributes: { class: 'open-form7-imgBlock-discount-newPrice' },
                components: 'BESPLATNO'
              },
            ]
          }
        ]
      }
    }
  });


  editor.BlockManager.add('open-form7_blk', {
    label: 'Open form 7',
    content: {
      type: "open-form7_cmp",
    },
    category: {
      label: 'Open Forms'
    },
    media: "<img src='/images/openForm7.svg' style='width: 100%; height: auto; filter: invert(1) hue-rotate(180deg);' />",
    attributes: {
      title: 'Insert an Open Form block',
    },
    select: true
  });
};