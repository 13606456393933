const initialState = {
    advancedSearch: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case "SETTINGS_TOGGLE_ADVANCED_SEARCH":
            return {
                ...state,
                advancedSearch: !state.advancedSearch
            };
        default:
            return state;
    };
};