import gjs from "grapesjs";


/**
 * @param {gjs.Editor} editor
 */
export default editor => {
    editor.Components.addType('scalecms-table', {
        isComponent: e => e?.classList?.contains("scalecms-table"),
        model: {
            defaults: {
                type: "default",
                traits: [

                ],
                attributes: {
                    class: "scalecms-table"
                },
                styles: `
                    .scalecms-table {
                        width: 100%;
                        height: auto;
                        min-height: 40px;
                    }
                    .scalecms-table table {
                        border-collapse: collapse;
                        margin: 25px 0;
                        font-size: 0.9em;
                        font-family: sans-serif;
                        min-width: 400px;
                        box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
                    }
                    .scalecms-table table thead tr {
                        background-color: #009879;
                        color: #ffffff;
                        text-align: left;
                    }
                    .scalecms-table table th, .scalecms-table table td {
                        padding: 12px 15px;
                    }
                    .scalecms-table table tbody tr {
                        border-bottom: 1px solid #dddddd;
                    }
                    
                    .scalecms-table table tbody tr:nth-of-type(even) {
                        background-color: #f3f3f3;
                    }
                    
                    .scalecms-table table tbody tr:last-of-type {
                        border-bottom: 2px solid #6D8095;
                    }
                    .scalecms-table table tbody tr th {
                        background-color: #6D8095;
                        color: white;
                    }
                `,
                activeOnRender: 1
            }
        },
        view: {
            onActive() {
                let rows = null;
                let cols = null;
                let includeHeaders = true;
                const cb = (r,c, ih) => {
                    rows = r;
                    cols = c;
                    includeHeaders = ih;
                };
                let modal = editor.Modal
                    .setTitle("Insert table")
                    .onceClose(() => {
                        if (!rows || !cols) {
                            return editor.getSelected().remove();
                        };
                        this.model.components(`
                            <table>
                                ${includeHeaders ? `<tr>${new Array(cols).fill(0).map(() => {
                                    return "<th><span>Header</span></th>"
                                }).join("")}</tr>` : ""}
                                ${new Array(rows).fill(0).map((_,idxRow) => {
                                    return `<tr>${new Array(cols).fill(0).map((_, idxCol) => {
                                        return `<td><span>col ${idxRow}-${idxCol}</span></td>`;
                                    }).join("")}</tr>`
                                }).join("")}
                            </table>
                        `);
                    })
                    .open();
                let content = modalContent(modal, cb);
                modal.setContent(content.wrap);
            }
        }
    });

    editor.BlockManager.add('scalecms-table-blk', {
        label: 'Table',
        content: {
            type: "scalecms-table",
        },
        category: {
            label: 'Basic'
        },
        media: `<svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <path d="M859.1 161H165.9c-19.8 0-35.9 16.9-35.9 37.7v627.7c0 20.8 16.1 37.7 35.9 37.7h693.3c19.8 0 35.9-16.9 35.9-37.7V198.7c-0.1-20.8-16.2-37.7-36-37.7z m0 150.6v147.2H652V311.6h207.1zM408.9 642.9V496.5h207.2V643H408.9z m207.2 37.7v145.7H408.9V680.6h207.2zM373 642.9H165.9V496.5H373v146.4z m243.1-331.3v147.2H408.9V311.6h207.2zM652 496.5h207.2V643H652V496.5zM416.9 198.7c19.8 0 35.9 16.9 35.9 37.7 0 20.8-16.1 37.7-35.9 37.7-19.8 0-35.9-16.9-35.9-37.7 0-20.9 16.1-37.7 35.9-37.7z m-107.6 0c19.8 0 35.9 16.9 35.9 37.7 0 20.8-16.1 37.7-35.9 37.7-19.8 0-35.9-16.9-35.9-37.7 0-20.9 16.1-37.7 35.9-37.7z m-107.6 0c19.8 0 35.9 16.9 35.9 37.7 0 20.8-16.1 37.7-35.9 37.7-19.8 0-35.9-16.9-35.9-37.7 0.1-20.9 16.1-37.7 35.9-37.7z m0 112.9H373v147.2H165.9V311.6h35.8z m-35.8 369H373v145.7H165.9V680.6zM652 826.3V680.6h207.2v145.7H652z m0 0" fill="white" />
                </svg>`,
        attributes: {
            title: 'Creates a table',
        },
        select: true
    });
};

/**
 * 
 * @param {gjs.Modal} modal 
 * @param {(rows: number, cols: number) => void} cb
 * @returns {{wrap: HTMLDivElement, getData: () => {rows: number, cols: number}}}
 */
const modalContent = (modal, cb) => {
    let wrap = document.createElement("div");
    wrap.classList.add("custom_modalTable");

    let rowsWrap = document.createElement("div");
    rowsWrap.innerHTML = "<span>No. of rows:</span>";
    let rowsInput = document.createElement("input");
    rowsInput.value = 2;
    rowsInput.type = "number";
    rowsWrap.appendChild(rowsInput);

    let colsWrap = document.createElement("div");
    colsWrap.innerHTML = "<span>No. of columns:</span>";
    let colsInput = document.createElement("input");
    colsInput.value = 2;
    colsInput.type = "number";
    colsWrap.appendChild(colsInput);

    let headersWrap = document.createElement("div");
    let chk = document.createElement("input");
    chk.type = "checkbox";
    chk.checked = true;
    headersWrap.appendChild(chk);

    let txt = document.createElement("span");
    txt.textContent = "Include headers";
    headersWrap.appendChild(txt);

    let saveBtn = document.createElement("button");
    saveBtn.textContent = "Save";
    saveBtn.style.marginTop = "20px";
    saveBtn.classList.add("gjs-btn-prim");

    const style = document.createElement("style");
    style.innerHTML = `
        .custom_modalTable input {
            background: transparent;
            border: 1px solid gray;
            margin-left: 10px;
            padding: 0px 5px;
            outline: none;
            color: white;
            width: 80px;
        }
        .custom_modalTable input[type=checkbox] {
            width: auto;
            margin-left: 0;
            margin-right: 10px;
        }
        .custom_modalTable > div {
            margin-bottom: 5px;
        }
    `;

    saveBtn.addEventListener("click", () => {
        let rows = Number(rowsInput.value);
        let cols = Number(colsInput.value);
        if (isNaN(rows) || isNaN(cols)) {
            cb(0, 0, chk.checked);
            return setTimeout(() => modal.close(), 0);
        };
        cb(rows, cols, chk.checked);
        return setTimeout(() => modal.close(), 0);
    });

    wrap.appendChild(rowsWrap);
    wrap.appendChild(colsWrap);
    wrap.appendChild(headersWrap);
    wrap.appendChild(saveBtn);
    wrap.appendChild(style);

    return {wrap};
};