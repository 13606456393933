import oneColumn from "./oneColumn";
import twoColumns from "./twoColumns";
import twoColumnsCustomizable from "./twoColumnsCustomizable";
import threeColumns from "./threeColumns";
import centeredColumn from "./centeredColumn";

/**
 * @param {gjs.Editor} editor
 */
 export default editor => {
    oneColumn(editor);
    twoColumns(editor);
    twoColumnsCustomizable(editor);
    threeColumns(editor);
    centeredColumn(editor);
};