import gjs from "grapesjs";
import axios from "axios";

import * as backendModule from "../../../../modules/backendModule";

function script() {

  eval(`(()=>{
  if (!this) return;

  const commentName = this.getAttribute('commentname');
  const commentText = this.getAttribute('commenttext');
  const userImage = this.getAttribute('userimage');
  const textImage = this.getAttribute('textimage');
  const likes = this.getAttribute('likes');
  const dislikes = this.getAttribute('dislikes');
  const date = this.getAttribute('date');
  const replyComments = this.getAttribute('replycomments');
  const index = this.getAttribute('indexvalue');

  const today = new Date();

  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  const dayBeforeYesterday = new Date();
  dayBeforeYesterday.setDate(dayBeforeYesterday.getDate() - 2);

  const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
  const formattedToday = today.toLocaleDateString('en-GB', options);
  const formattedYesterday = yesterday.toLocaleDateString('en-GB', options);
  const formattedDayBeforeYesterday = dayBeforeYesterday.toLocaleDateString('en-GB', options);

  let preImage = this.querySelector(".scalecms-comment-layout4-body-image");
  if (textImage) {
    preImage.src = \`\${textImage}\`
  } else {
    preImage.style.display = "none";
  }

  if (likes) {
    let likeImage = this.querySelector(".scalecms-comment-layout4-body-infoDiv-image");
    let likesAmount = this.querySelector(".scalecms-comment-layout4-body-infoDiv-likePara-amount");
    let likeBtn = this.querySelector(".scalecms-comment-layout4-body-infoDiv-likePara-likebtn");
    let replyBtn = this.querySelector(".scalecms-comment-layout4-body-infoDiv-likePara-replyBtn");

    likeImage.src = "https://backend.scale-cms.com:5000/staticContent/images/e692e0b5-de09-4a97-a2c3-fe9eefcf5b8f-1713180836604.webp";
    likesAmount.textContent = likes;


    let leaveCommentTarget = document.querySelector(".scalecms-leaveComment");
    replyBtn.addEventListener("click", () => {
      if (!leaveCommentTarget) return;
      leaveCommentTarget.scrollIntoView({ behavior: 'smooth' });
    })

    likeBtn.addEventListener("click", () => {
      let newLikeAmount = Number(likes) + 1;
      likesAmount.textContent = newLikeAmount;
    })
    likeImage.addEventListener("click", () => {
      let newLikeAmount = Number(likes) + 1;
      likesAmount.textContent = newLikeAmount;
    })
  } else {
    let infoDiv = this.querySelector(".scalecms-comment-layout4-body-infoDiv");
    infoDiv.style.display = "none";
  }

  let dateValueText = '';

  if (index && index < 6) {
    dateValueText = formattedToday;
  } else if (index && index <= 14) {
    dateValueText = formattedYesterday;
  } else if (index && index > 14){
    dateValueText = formattedDayBeforeYesterday;
  }else{
    dateValueText = '';
  }

  if (date) {
    let datePara = this.querySelector(".scalecms-comment-layout4-body-date");
    datePara.textContent = dateValueText;
  } else {
    let datePara = this.querySelector(".scalecms-comment-layout4-body-date");
    datePara.textContent = '';
  }

  if (replyComments) {
    const parsedReplyComments = JSON.parse(replyComments);
    const replyContainer = this.querySelector(".reply-container");

    parsedReplyComments.forEach(reply => {
      // Create a new comment element
      let newReplyComment = document.createElement("div");
      newReplyComment.classList.add("scalecms-comment-layout4");
      newReplyComment.style.marginTop = "10px";

      // Create the div for the left section of the comment
      let leftSection = document.createElement("div");
      leftSection.classList.add("scalecms-comment-layout4-left");

      // Create and set attributes for image element in the left section
      let imageElement = document.createElement("img");
      imageElement.classList.add("scalecms-comment-layout4-image");
      imageElement.src = reply.attributes.UserImage;
      leftSection.appendChild(imageElement);

      newReplyComment.appendChild(leftSection);

      // Create the div for the body section of the comment
      let bodySection = document.createElement("div");
      bodySection.classList.add("scalecms-comment-layout4-body");

      // Create the div for the top section of the body
      let topSection = document.createElement("div");
      topSection.classList.add("scalecms-comment-layout4-body-top");

      // Create and set attributes for heading element in the top section
      let headingElement = document.createElement("div");
      headingElement.classList.add("scalecms-comment-layout4-body-heading");
      headingElement.textContent = reply.attributes.CommentName;
      topSection.appendChild(headingElement);

      // Create and set attributes for text element in the top section
      let textElement = document.createElement("div");
      textElement.classList.add("scalecms-comment-layout4-body-text");
      textElement.innerHTML = reply.attributes.CommentText.replace(/\{\{\{([^}]+)\}\}\}/g, (match, group) => {
        return \`<span class="scalecms-comment-body-text-btn scroll-to">\${group}</span>\`;
      });
      topSection.appendChild(textElement);

      // Create and set attributes for image element in the top section
      let imageElementTop = document.createElement("img");
      imageElementTop.classList.add("scalecms-comment-layout4-body-image");
      imageElementTop.src = reply.attributes.TextImage;
      topSection.appendChild(imageElementTop);

      bodySection.appendChild(topSection);

      // Create the div for the infoDiv in the body section
      let infoDiv = document.createElement("div");
      infoDiv.classList.add("scalecms-comment-layout4-body-infoDiv");

      // Create elements for likePara, replyBtn, image, likeParaAmount, and date in the infoDiv
      if (likes) {
        let likePara = document.createElement("div");
        likePara.classList.add("scalecms-comment-layout4-body-infoDiv-likePara", "scalecms-comment-layout4-body-infoDiv-likePara-likebtn");
        likePara.textContent = "Sviđa mi se";

        // Add event listener to increment Likes only once
        let likeIncremented = false;
        likePara.addEventListener("click", () => {
          if (!likeIncremented) {
            likeIncremented = true;
            let newLikeAmount = parseInt(reply.attributes.Likes) + 1;
            likeParaAmount.textContent = \`\${newLikeAmount}\`;
          }
        });
        infoDiv.appendChild(likePara);

        let replyBtn = document.createElement("div");
        replyBtn.classList.add("scalecms-comment-layout4-body-infoDiv-likePara", "scalecms-comment-layout4-body-infoDiv-likePara-replyBtn");
        replyBtn.textContent = "Odgovori";

        // Add event listener to scroll to scalecms-leaveComment element
        replyBtn.addEventListener("click", () => {
          let leaveCommentTarget = document.querySelector(".scalecms-leaveComment");
          if (leaveCommentTarget) {
            leaveCommentTarget.scrollIntoView({ behavior: 'smooth' });
          }
        });

        infoDiv.appendChild(replyBtn);

        let infoImage = document.createElement("img");
        infoImage.classList.add("scalecms-comment-layout4-body-infoDiv-image");
        infoImage.src = "https://backend.scale-cms.com:5000/staticContent/images/e692e0b5-de09-4a97-a2c3-fe9eefcf5b8f-1713180836604.webp";

        // Add event listener to increment Likes only once for infoImage
        infoImage.addEventListener("click", () => {
          if (!likeIncremented) {
            likeIncremented = true;
            let newLikeAmount = parseInt(reply.attributes.Likes) + 1;
            likeParaAmount.textContent = \`\${newLikeAmount}\`;
          }
        });
        infoDiv.appendChild(infoImage);

        let likeParaAmount = document.createElement("div");
        likeParaAmount.classList.add("scalecms-comment-layout4-body-infoDiv-likePara-amount");
        likeParaAmount.textContent = \`\${reply.attributes.Likes}\`;
        infoDiv.appendChild(likeParaAmount);
      }

      if (date) {
        let dateElement = document.createElement("div");
        dateElement.classList.add("scalecms-comment-layout4-body-date");
        dateElement.textContent = dateValueText;
        infoDiv.appendChild(dateElement);
      }

      bodySection.appendChild(infoDiv);
      newReplyComment.appendChild(bodySection);

      replyContainer.appendChild(newReplyComment);
    });
  }

  /// END OF REPLY COMMENTS SECTION


  const regex = /\{\{\{([^}]+)\}\}\}/g;
  const modifiedCommentText = commentText.replace(regex, (match, group) => {
    return \`<span class="scalecms-comment-layout4-body-text-btn scroll-to">\${group}</span>\`;
  });

  let headline = this.querySelector(".scalecms-comment-layout4-body-heading")
  let text = this.querySelector(".scalecms-comment-layout4-body-text")
  let image = this.querySelector(".scalecms-comment-layout4-image")

  headline.textContent = commentName;
  text.innerHTML = modifiedCommentText;
  image.src = userImage;

  let commentScrollToBtns = this.querySelectorAll(".scroll-to");
  let commentScrollTarget = document.querySelector(".scroll-target");
  if(!commentScrollTarget) commentScrollTarget = document.querySelector(".present__form");
  if (commentScrollToBtns.length) {
    for (let elem of commentScrollToBtns) {
      elem.addEventListener('click', () => {
        commentScrollTarget.scrollIntoView({ behavior: 'smooth' });
      });
    }
  }

  })()`)

}

/**
 * @param {gjs.Editor} editor
 */
export default editor => {

  editor.Components.addType('scalecms-comment-layout4', {
    isComponent: e => e?.classList?.contains("scalecms-comment-layout4"),
    model: {
      async init() {
        if (!this) return;
      },

      defaults: {
        type: "default",
        traits: [
          {
            type: 'select',
            label: 'Dropdown',
            name: 'dropdown',
            options: [],
            changeProp: 1
          },
        ],
        attributes: {
          class: "scalecms-comment-layout4",
          dataOfferName: "apolo kosa",
        },
        script,
        styles: `
          .popup-content {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100vh;
            background-color: rgba(0, 0, 0, 0.3);
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .scalecms-comment-layout4 {
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            background: transparent;
            display: flex;
            flex-direction: row;
            gap: 10px;
            min-height: 70px;
            height: fit-content;
            font-family: 'Arial';
            position: relative;
            font-family: sans-serif;
          }
          @media screen and (max-width: 600px) {
            .scalecms-comment-layout4 {
              padding: 0px 16px;
            }
            .scalecms-comment-layout4-body{
              width: 100%;
            }
            .scalecms-comment-layout4-body-top {
              width: 100%;
            }
            .scalecms-comment-layout4-body-infoDiv{
              width: 100%;
              max-width: 100%;
            }
          }
          @media screen and (max-width: 400px) {
            .scalecms-comment-layout4 {
              padding: 0px 4px;
            }
          }
          .scalecms-comment-layout4-left{
            display: flex;
          }
          .scalecms-comment-layout4-image {
            width: 36px !important;
            height: 36px !important;
            outline: none;
            border-radius: 50%;
          }
          .scalecms-comment-layout4-body-date{
            font-weight: 400;
            font-size: 13px;
            color: #b3b3b3;
            margin-left: auto;
          }
          .scalecms-comment-layout4-body {
            width: 85%;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            gap: 10px;
          }
          .scalecms-comment-layout4-body-top{
            width: 85%;
            background-color: #ebedf0;
            border-radius: 18px;
            line-height: 16px;
            overflow: hidden;
            padding: 6px 12px 7px 12px;
            display: flex;
            flex-direction: column;
            gap: 10px;
          }
          .scalecms-comment-layout4-body-heading {
            font-size: 12px;
            color: #000;
            margin-bottom: 3px;
            line-height: 16px;
            font-family: 'Arial', sans-serif;
            font-weight: 600;
          }
          
          .scalecms-comment-layout4-body-text {
            font-size: 16px;
            line-height: 16px;
            margin: 0;
            color: #2b2929;
            font-family: 'Arial', sans-serif;
          }
          .scalecms-comment-layout4-body-text span{
            font-size: 16px;
            color: #000;
            line-height: 16px;
            font-family: 'Arial', sans-serif;
            font-weight: 600;
            cursor: pointer;
          }
          .scalecms-comment-layout4-body img{
            width: 100%;
            max-width: 140px;
            height: auto;
            outline: none;
          }
          .scalecms-comment-layout4-body-infoDiv{
            display: flex;
            gap: 15px;
            align-items: flex-end;
            max-width: 85%;
          }
          .scalecms-comment-layout4-body-infoDiv img{
            cursor: pointer;
            outline: none;
          }
          .scalecms-comment-layout4-body-infoDiv-likePara{
            color: #929292;
            font-weight: 600;
            font-size: 12px;
            cursor: pointer;
          }
          .scalecms-comment-layout4-body-infoDiv img{
            width: 20px;
            height: 20px;
          }
          .scalecms-comment-layout4-body-infoDiv-likePara-amount{
            color: #929292;
            font-weight: 600;
            font-size: 12px;
            margin-left: -12px;
          }
          .reply-container{
            display: felx;
            flex-direction: column;
          }
          .scalecms-comment-layout4-body-infoDiv-image {
            width: 24px !important;
            height: 24px !important;
          }
        `,
        components: [
          {
            tagName: 'div',
            attributes: { class: "scalecms-comment-layout4-left" },
            components: [
              {
                type: 'image',
                attributes: { class: "scalecms-comment-layout4-image" }
              },
            ]
          },
          {
            tagName: 'div',
            attributes: { class: "scalecms-comment-layout4-body" },
            components: [

              {
                tagName: 'div',
                attributes: { class: "scalecms-comment-layout4-body-top" },
                components: [
                  {
                    type: 'text',
                    attributes: { class: "scalecms-comment-layout4-body-heading" },
                    components: "Ime osobe"
                  },
                  {
                    type: 'text',
                    attributes: { class: "scalecms-comment-layout4-body-text" },
                    components: "Text komentara..."
                  },
                  {
                    type: 'image',
                    attributes: { class: "scalecms-comment-layout4-body-image" }
                  },
                ]
              },
              {
                tagName: 'div',
                attributes: { class: "scalecms-comment-layout4-body-infoDiv" },
                components: [
                  {
                    type: 'text',
                    attributes: { class: "scalecms-comment-layout4-body-infoDiv-likePara scalecms-comment-layout4-body-infoDiv-likePara-likebtn" },
                    components: "Sviđa mi se"
                  },
                  {
                    type: 'text',
                    attributes: { class: "scalecms-comment-layout4-body-infoDiv-likePara scalecms-comment-layout4-body-infoDiv-likePara-replyBtn" },
                    components: "Odgovori"
                  },
                  {
                    type: 'image',
                    attributes: { class: "scalecms-comment-layout4-body-infoDiv-image" }
                  },
                  {
                    type: 'text',
                    attributes: { class: "scalecms-comment-layout4-body-infoDiv-likePara-amount" },
                    components: "00"
                  },
                  {
                    type: 'text',
                    attributes: { class: "scalecms-comment-layout4-body-date" },
                    components: "datum"
                  },
                ]
              },
              {
                tagName: 'div',
                attributes: { class: "reply-container" },
              }
            ]
          },
        ]
      }
    }
  });


  editor.BlockManager.add('scalecms-comment-layout4-blk', {
    label: 'Comment Layout 4',
    content: {
      type: "scalecms-comment-layout4",
    },
    category: {
      label: 'Not Used'
    },
    media: `
      <?xml version="1.0" encoding="utf-8"?>
      <svg version="1.1" id="Layer_1" x="0px" y="0px" width="100px" height="100px" viewBox="0 0 100 100" xml:space="preserve" enable-background="white" background="#fff">
        <g>
          <path fill="#FFFFFF" d="M88.466,16.936c-0.172-1.703-1.592-3.036-3.34-3.036H14.781v0.009c-1.705,0.047-3.079,1.355-3.247,3.027H11.5v56.342h0.068
            c0.305,1.512,1.622,2.65,3.214,2.694v0.009h11.564v6.744c0,1.864,1.51,3.374,3.374,3.374c1.165,0,2.192-0.591,2.798-1.49
            l8.629-8.629h43.979c1.634,0,2.995-1.161,3.306-2.703H88.5V16.936H88.466z"></path>
        </g>
      </svg>
    `,
    attributes: {
      title: 'Comment Layout 2',
    },
    select: true
  });
};