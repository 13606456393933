import React from "react";
import "./index.scss";

import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import * as backendModule from "../../modules/backendModule";
import { updateTimestamp } from "../../actions/timestampActions";
import { animateBox } from "../../modules/componentAnimation";

import Spinner from "../../components/customComponents/Spinner";
import { FilteredCustomTable } from "../../components/customComponents/Table";
import CustomInput from "../../components/customComponents/CustomInput";
import CustomCheckbox from "../../components/customComponents/CustomCheckbox";

let curTimeout = null;
const UsersList = () => {
    const [data, setData] = React.useState();
    const [filters, setFilters] = React.useState([]);

    const curUserData = useSelector(state => state?.userData?.userData ?? {});
    const curTimestampSelector = useSelector(state => state?.timestamp ?? null);
    const siteSettingsSelector = useSelector(state => state?.siteSettings ?? {});

    const searchRef = React.useRef();

    let searchHandler = () => {
        let searchVal = searchRef?.current?.value;
        clearTimeout(curTimeout);
        curTimeout = setTimeout(() => {
            setFilters([{
                or: [
                    { name: "Username", op: "like", value: searchVal },
                    { name: "Email", op: "like", value: searchVal },
                ]
            }]);
        }, 500);
    };

    const getData = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/getAllUsers`,
            data: {
                filters
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setData(res.data);
            } else {
                setData({ status: "error", data: "SERVER_ERROR" });
            };
        }).catch(() => {
            setData({ status: "error", data: "SERVER_ERROR" });
        });
    };

    React.useEffect(() => {
        if (filters?.length > 0) setFilters([]);
    }, [siteSettingsSelector?.advancedSearch]);

    React.useEffect(() => {
        getData();
    }, [curTimestampSelector, filters]);

    return <div className="route__usersList" style={{ paddingTop: "20px" }}>
        {!siteSettingsSelector.advancedSearch && <CustomInput autocomplete="off" onChange={searchHandler} ref={searchRef} theme="dark" accent="#3F7CEA" placeholder="Search..." style={{ width: "100%", marginBottom: "20px" }} />}
        <FilteredCustomTable
            theme="dark"
            accent="#48515C"
            style={{width: "100%"}}
            headers={["ID", "Username", "E-Mail", "Admin"]}
            filters={siteSettingsSelector.advancedSearch ? [
                { name: "ID", friendlyName: "ID", type: "string" },
                { name: "Username", friendlyName: "Username", type: "string" },
                { name: "Email", friendlyName: "Email", type: "string" },
                { name: "Flags:isAdmin", friendlyName: "Is admin", type: "boolean" }
            ] : undefined}
            filterCB={f => setFilters(f)}
            data={(() => {
                if (!data) return [[{ keyID: "noData-spinner", type: "spinner" }]];
                if (data.status === "error") return [[{ keyID: "noData-text", type: "custom", data: <p>There was an error while fetching users</p> }]];

                let tmp = data.data.map(elem => {
                    return [
                        { keyID: String(elem.ID), type: "text", text: elem.ID },
                        { keyID: String(elem.ID), type: "text", text: elem.Username },
                        { keyID: String(elem.ID), type: "text", text: elem.Email },
                        { keyID: String(elem.ID), type: "text", text: elem.Flags?.isAdmin ? <span style={{ color: "#53ff53" }}>Yes</span> : <span style={{ color: "#ff7474" }}>No</span> },
                        {
                            keyID: String(elem.ID), type: "groupNewline", group: (curUserData?.UserInfo?.Flags?.isAdmin ? [
                                { keyID: String(elem.ID), type: "button", text: "Edit", onClick: e => animateBox(e, <AddUser data={elem} />), style: { marginRight: "10px" } },
                                { keyID: String(elem.ID), type: "button", text: "Remove", onClick: e => animateBox(e, <RemoveUser data={elem} />) },
                            ] : [])
                        }
                    ];
                });

                if (tmp.length === 0) tmp.push({ keyID: "noData-empty", type: "custom", data: <p>Nothing to show.</p> })
                return tmp;
            })()}
        />
    </div>
};

const AddUser = props => {
    const [spinner, setSpinner] = React.useState(false);
    const [infoP, setInfoP] = React.useState("");

    const [fAdmin, setFAdmin] = React.useState(false);
    const [fReqLander, setFReqLander] = React.useState(false);
    const [fEditWebsite, setFEditWebsite] = React.useState(false);
    const [fSuperiorCloner, setFSuperiorCloner] = React.useState(false);
    const [fCopywriter, setFCopywriter] = React.useState(false);

    const usernameRef = React.useRef();
    const emailRef = React.useRef();
    const passwordRef = React.useRef();
    const tokenRef = React.useRef();

    const curDispatch = useDispatch();

    const addUser = () => {
        setInfoP("");

        const data = {
            username: usernameRef.current.value,
            email: emailRef.current.value,
            password: passwordRef.current.value,
            apiToken: tokenRef.current.value,

            flags: {
                isAdmin: !!fAdmin,
                canRequestLander: !!fReqLander,
                canEditWebsite: !!fEditWebsite,
                isSuperiorCloner: !!fSuperiorCloner,
                isCopywriter: !!fCopywriter,
            }
        };

        if (props.data) data["ID"] = props.data.ID;

        if (!props.data) {
            if (!data.username) return setInfoP("Username can't be empty!");
            if (!data.email) return setInfoP("E-Mail can't be empty!");
            if (!data.password) return setInfoP("Password can't be empty!");

            if (data.username.length < 8 || data.username.length > 64) return setInfoP("Username must be between 8 and 64 characters.");
            if (data.password.length < 8 || data.password.length > 64) return setInfoP("Password must be between 8 and 64 characters.");
        } else {
            if (data.password) {
                if (data.password.length < 8 || data.password.length > 64) return setInfoP("Password must be between 8 and 64 characters.");
            };
        };

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/${props.data ? "editUser" : "createNewUser"}`,
            data,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                props.onClose();
                curDispatch(updateTimestamp());
            } else {
                setInfoP("Error while creating the user, check the information you provided! (Email or Uername already exists?!)");
            };
        }).catch(() => {
            setInfoP("Server timed out!");
        }).finally(() => {
            setSpinner(false);
        });
    };

    React.useEffect(() => {
        if (!props.data) return;

        usernameRef.current.value = props.data.Username;
        emailRef.current.value = props.data.Email;
        tokenRef.current.value=props.data.TrackToken ?? "";
        setFAdmin(props.data?.Flags?.isAdmin);
        setFReqLander(props.data?.Flags?.canRequestLander);
        setFEditWebsite(props.data?.Flags?.canEditWebsite);
        setFSuperiorCloner(props.data?.Flags?.isSuperiorCloner);
        setFCopywriter(props.data?.Flags?.isCopywriter);
    }, [props.data]);

    return <div className="route__usersList__addUser">
        <div className="route__usersList__addUser__wrap">
            <div className="route__usersList__addUser__wrap__spinner" style={{
                opacity: spinner ? 1 : 0,
                pointerEvents: spinner ? "all" : "none"
            }} onClick={e => spinner && e.stopPropagation()}>
                <Spinner color="#3F7CEA" />
            </div>

            <h3 style={{ marginBottom: "20px" }}>{props.data ? "Edit" : "Add"} user</h3>
            <CustomInput autocomplete="off" disabled={!!props.data} ref={usernameRef} theme="dark" accent="#fff" placeholder="Username" style={{ marginBottom: "20px", width: "100%" }} />
            <CustomInput autocomplete="off" ref={emailRef} theme="dark" accent="#fff" placeholder="E-Mail" style={{ marginBottom: "20px", width: "100%" }} />
            <CustomInput autocomplete="off" ref={passwordRef} theme="dark" accent="#fff" placeholder="Password" type="password" style={{ marginBottom: "20px", width: "100%" }} />
            <CustomInput autocomplete="off" ref={tokenRef} theme="dark" accent="#fff"  placeholder="Api token" type="text" style={{ marginBottom: "20px", width: "100%" }} />
            <CustomCheckbox accent="#3F7CEA" theme="dark" placeholder="Admin" defaultValue={fAdmin} onChange={d => setFAdmin(d)} />
            <br />
            <br />
            <CustomCheckbox accent="#3F7CEA" theme="dark" placeholder="Can request landers" defaultValue={fReqLander} onChange={d => setFReqLander(d)} />
            <br />
            <br />
            <CustomCheckbox accent="#3F7CEA" theme="dark" placeholder="Can edit websites without permission" defaultValue={fEditWebsite} onChange={d => setFEditWebsite(d)} />
            <br />
            <br />
            <CustomCheckbox accent="#3F7CEA" theme="dark" placeholder="User has more options while cloning the website" defaultValue={fSuperiorCloner} onChange={d => setFSuperiorCloner(d)} />
            <br />
            <br />
            <CustomCheckbox accent="#3F7CEA" theme="dark" placeholder="User is copywriter, edits and approves websites" defaultValue={fCopywriter} onChange={d => setFCopywriter(d)} />


            <div className="route__usersList__addUser__wrap__btns">
                <p onClick={addUser}>
                    <img style={{ marginRight: "15px" }} src="/images/saveBtn.png" />
                    <span>Save</span>
                </p>
                <p onClick={props.onClose}>
                    <span>Cancel</span>
                    <img style={{ marginLeft: "15px" }} src="/images/closeBtn.png" />
                </p>
            </div>

            {infoP && <p className="route__usersList__addUser__wrap__infoP">{infoP}</p>}
        </div>
    </div>
};

const RemoveUser = props => {
    const [spinner, setSpinner] = React.useState(false);
    const [infoP, setInfoP] = React.useState("");

    const curDispatch = useDispatch();

    const removeUser = () => {
        setInfoP("");

        const data = {
            ID: props.data.ID
        };

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/removeUser`,
            data,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                props.onClose();
                curDispatch(updateTimestamp());
            } else {
                setInfoP("Error while removing the user.");
            };
        }).catch(() => {
            setInfoP("Server timed out!");
        }).finally(() => {
            setSpinner(false);
        });
    };

    return <div className="route__usersList__addUser">
        <div className="route__usersList__addUser__wrap">
            <div className="route__usersList__addUser__wrap__spinner" style={{
                opacity: spinner ? 1 : 0,
                pointerEvents: spinner ? "all" : "none"
            }} onClick={e => spinner && e.stopPropagation()}>
                <Spinner color="#3F7CEA" />
            </div>

            <h3 style={{ marginBottom: "20px" }}>Remove user</h3>
            <p>Are you sure?</p>
            <p>Removal of <span style={{ color: "#3F7CEA" }}>{props.data.Username}</span> is irreversible!</p>

            <div className="route__usersList__addUser__wrap__btns">
                <p onClick={removeUser}>
                    <img style={{ marginRight: "15px" }} src="/images/saveBtn.png" />
                    <span>Save</span>
                </p>
                <p onClick={props.onClose}>
                    <span>Cancel</span>
                    <img style={{ marginLeft: "15px" }} src="/images/closeBtn.png" />
                </p>
            </div>

            {infoP && <p className="route__usersList__addUser__wrap__infoP">{infoP}</p>}
        </div>
    </div>
};

export default UsersList;
export { AddUser };