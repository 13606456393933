import React from "react";

import {Routes, Route, useNavigate, Outlet} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import * as userDataActions from "./actions/userDataActions";

import axios from "axios";
import * as backendModule from "./modules/backendModule";
import * as timestmapActions from "./actions/timestampActions";
import animateModule from "./modules/animateModule";

import Header from "./components/Header";
import Sidebar from "./components/Sidebar";
import ContentWrapper from "./components/ContentWrapper";
import Spinner from "./components/customComponents/Spinner";

import Login from "./routes/Login";

import CreateWebsite from "./routes/CreateWebsite";
import CampaignsList from "./routes/CampaignsList";
import LandingList from "./routes/LandingList";
import DeletedWebsites from "./routes/DeletedLandings";
import CopyWriter from "./routes/CopyWriter";
import CopyWriterAdmin from "./routes/CopyWriterAdmin";
import DocGateways from "./routes/Documentation/DocGateways";
import DocOpenForms from "./routes/Documentation/DocOpenForms";
import DocMobileTemplates from "./routes/Documentation/MobileTemplates";
import DocTimers from "./routes/Documentation/Timers";
import DocCustomAssets from "./routes/Documentation/CustomAssets";
import DocCreateComments from "./routes/Documentation/CreateComments";
import DocApplyComments from "./routes/Documentation/ApplyComments";
import DocTranslations from "./routes/Documentation/Translations";
import RouteList from "./routes/RouteList";
import DomainsList from "./routes/DomainsList";
import CronTasks from "./routes/CronTasks";
import OpenAiUsage from "./routes/OpenAiUsage";
import CommentsList from "./routes/CommentsList";
import RecensionsList from "./routes/RecensionsList";
import Translations from "./routes/Translations";
import ImagesList from "./routes/ImagesList";
import CreativesList from "./routes/Creatives/CreativesList";
import CreativeItems from "./routes/Creatives/CreativeItems";
import CreativeItem from "./routes/Creatives/CreativeItem";
import ApiTokens from "./routes/ApiTokens";
import UsersList from "./routes/UsersList";
import LanderRequests from "./routes/LanderRequests";

import Campaign from "./routes/Campaign";

const App = () => {
  const timestampSelector = useSelector(state => state?.timestamp ?? null);
  const userDataSelector = useSelector(state => state.userData ?? {});

  const mainDispatch = useDispatch();
  const mainNavigate = useNavigate();

  const animateNavigate = to => {
    animateModule(mainNavigate, to, document.querySelector(".component__contentWrapper"));
  };

  const checkLogin = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/auth/checkLogin`,
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        mainDispatch(userDataActions.setUserData(true, res.data.data));
        if ((window.location.href.includes("login") || window.location.toString().endsWith("/")) && !window.location.toString().includes("/main")) {
          animateNavigate("/main/campaign-list");
        };
      } else {
        if (!window.location.href.endsWith("/login")) {
          animateNavigate("/login");
        };
        mainDispatch(userDataActions.setUserData(false, null));
      };
    }).catch(() => {
      mainDispatch(userDataActions.setUserData(null, null));
      setTimeout(() => mainDispatch(timestmapActions.updateTimestamp()), 2000);
    });
  };
  
  React.useEffect(() => {
    checkLogin();
  }, [timestampSelector]);


  return <>
  {userDataSelector?.isLoggedIn !== null ? <>
    <Header />
    {userDataSelector?.isLoggedIn ? <>
        <ContentWrapper className="component__contentWrapper--active">
          <Routes>
            <Route path="/" element={<>
                <Sidebar />
                <ContentWrapper className="component__mainContent" style={{backgroundImage: 'url("/images/bcg.png")', backgroundSize: "100% 100%"}}>
                  <Outlet />
                </ContentWrapper>
              </>}>
            </Route>

            <Route path="main/*" element={<>
                <Sidebar />
                <ContentWrapper className="component__mainContent" style={{backgroundImage: 'url("/images/bcg2.png")', backgroundSize: "100% 100%"}}>
                  <Outlet />
                </ContentWrapper>
              </>}>

              <Route path="campaign-list" element={<CampaignsList />} />
              <Route path="campaign/*" element={<Campaign />} />
              <Route path="route-list" element={<RouteList />} />
              <Route path="domain-list" element={<DomainsList />} />
              <Route path="landing-list" element={<LandingList type="landing" />} />
              <Route path="prelanding-list" element={<LandingList type="prelanding" />} />
              <Route path="thankyou-list" element={<LandingList type="thankyou" />} />
              <Route path="static-list" element={<LandingList type="static" />} />
              <Route path="templates" element={<LandingList type="template" />} />
              <Route path="gateways" element={<DocGateways />} />
              <Route path="openforms" element={<DocOpenForms />} />
              <Route path="mobile-templates" element={<DocMobileTemplates />} />
              <Route path="doc-timers" element={<DocTimers />} />
              <Route path="doc-custom-assets" element={<DocCustomAssets />} />
              <Route path="doc-create-comments" element={<DocCreateComments />} />
              <Route path="doc-apply-comments" element={<DocApplyComments />} />
              <Route path="doc-translations" element={<DocTranslations />} />
              <Route path="asset-list" element={<ImagesList />} />
              <Route path="cron-tasks" element={<CronTasks />} />
              
              <Route path="comment-list" element={<CommentsList />} />
              <Route path="recension-list" element={<RecensionsList />} />
              <Route path="translations" element={<Translations />} />
              <Route path="creative-list" element={<CreativesList />} />
              <Route path="creative-list/:groupID/:id" element={<CreativeItem />} />
              <Route path="creative-list/:id" element={<CreativeItems />} />
              <Route path="lander-requests" element={<LanderRequests />} />
              {userDataSelector?.userData?.UserInfo?.Flags?.isCopywriter && <Route path="copywriter" element={<CopyWriter />} />}
              {userDataSelector?.userData?.UserInfo?.Flags?.isAdmin && <Route path="copywriter-admin" element={<CopyWriterAdmin />} />}
              {userDataSelector?.userData?.UserInfo?.Flags?.isAdmin && <Route path="deleted-websites" element={<DeletedWebsites />} />}
              {userDataSelector?.userData?.UserInfo?.Flags?.isAdmin && <Route path="users-list" element={<UsersList />} />}
              {userDataSelector?.userData?.UserInfo?.Flags?.isAdmin && <Route path="api-tokens-list" element={<ApiTokens />} />}
              {userDataSelector?.userData?.UserInfo?.Flags?.isAdmin && <Route path="open-ai-usage" element={<OpenAiUsage />} />}

            </Route>

              <Route path="/create-website/:type" element={<CreateWebsite />} />
              <Route path="/create-website/:type/:id" element={<CreateWebsite />} />
          </Routes>
      </ContentWrapper>
    </> : <ContentWrapper>
        <Routes>
          <Route path="/login"  element={<Login />} />
        </Routes>
      </ContentWrapper>}
  </> : <Spinner align="center" color="#3F7CEA" />}
</>
};

export default App;
