import React from "react";
import "./index.scss";


const DocOpenForms = (props) => {

  return <div className="route__documentation">
    <div className="route__documentation__headline">
      <p>Open Forms</p>
    </div>
    <div className="route__documentation__desc">
      <p>Open Forms are ways for users to approach form directly on the site.</p>
      <p>Currently we have 7 different variations of Open Forms, keep in mind that this number can change in the future.</p>
    </div>
    <div className="route__documentation__introBlock">
      <div className="route__documentation__introBlock__left">
        <img src="/images/tutorials/openform/OpenFormsStep1.png" />
      </div>
      <div className="route__documentation__introBlock__right">
        <p>Firstly we have to click on the '+' icon to open the side menu dropdown</p>
        <p>Then we have to select the 'OpenForms' tab in the menu</p>
        <p>After we select the OpenForms, we're offered with different options of OpenForms</p>
        <p>OpenForm of our choice we drag to the part of the site where we want it.</p>
      </div>
    </div>
    <div className="route__documentation__introBlock flex-column">
    <div className="route__documentation__introBlock__right">
        <p>After we drag it into our website it should look something like this</p>
      </div>
      <div className="route__documentation__introBlock__left">
        <img src="/images/tutorials/openform/OpenFormsStep2.png" />
      </div>
    </div>

    <div className="route__documentation__desc">
      <p>There are many OpenForms to choose from the CMS, pick the one most suiting for your website.</p>
      <p>Note that all the text, colors, layouts can be changed through the CMS</p>
    </div>

  </div>
};

export default DocOpenForms;
export { DocOpenForms };