import gjs from "grapesjs";

import swiper from "swiper/bundle";

/**
 * @param {gjs.Editor} editor
*/

function script() {
  eval(`(()=>{
    if (!this) return;
    if (this.getAttribute("data-gjs-type")) return;

    let modal = this.querySelector(".scalecms-popUpSlider__modal");
    let mainImage = this.querySelector(".scalecms-popUpSlider__mainImg");
    let closeBtn = this.querySelector(".scalecms-popUpSlider__modal__close");
    let ctaBtn = this.querySelector(".scalecms-ctaButton-button");

    mainImage.addEventListener("click", () => {
      modal.style.display = 'flex'
    })

    closeBtn.addEventListener("click", () => {
      modal.style.display = 'none'
    })

    ctaBtn.addEventListener("click", () => {
      modal.style.display = 'none'
    })
    
  })()`)

};


export default editor => {
  editor.Components.addType('scalecms-popUpSlider', {
    isComponent: e => e?.classList?.contains("scalecms-popUpSlider"),
    model: {
      init() {
        let curSwiper = null;

        const initializeSwiper = () => {
          if (curSwiper) return;

          const slider = this.find(".swiper")[0]?.getEl();
          const pagination = this.find(".swiper-pagination")[0]?.getEl();
          const prevBtn = this.find(".swiper-button-prev")[0]?.getEl();
          const nextBtn = this.find(".swiper-button-next")[0]?.getEl();

          if (!slider || !pagination || !prevBtn || !nextBtn) return;

          curSwiper = new swiper(slider, {
            direction: 'horizontal',
            loop: false,
            pagination: {
              el: pagination,
            },
            navigation: {
              nextEl: nextBtn,
              prevEl: prevBtn,
            },
          });
        };

        this.on("component:mount", initializeSwiper);
        this.on("component:update", () => setTimeout(initializeSwiper, 100));
      },
      defaults: {
        type: "default",
        traits: [
          {
            type: "button", text: "Open modal", full: true, command: editor => {
              let el = editor.getSelected().getEl();
              let modal = el.querySelector('.scalecms-popUpSlider__modal');
              modal.style.display = "flex";
            }
          },
          {
            type: "button", text: "Close modal", full: true, command: editor => {
              let el = editor.getSelected().getEl();
              let modal = el.querySelector('.scalecms-popUpSlider__modal');
              modal.style.display = "none";
            }
          },
        ],
        attributes: {
          class: "scalecms-popUpSlider"
        },
        script,
        styles: `
          .scalecms-popUpSlider {
            width: 100%;
            height: auto;
            min-height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .scalecms-popUpSlider__modal{
            display: none;
            position: fixed;
            flex-direction: column;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 9999;
            background-color: rgba(0,0,0,0.8);
            backdrop-filter: blur(10px);
            -webkit-backdrop-filter: blur(10px);
            justify-content: center;
            align-items: center;
          }
          .scalecms-popUpSlider__modal__close{
            position: absolute;
            top: 20px;
            right: 20px;
          }
          .scalecms-popUpSlider__modal__close img{
            width: 30px;
            height: 30px;
          }
          .swiper{
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            position: relative;
            overflow: hidden;
            list-style: none;
            padding: 0;
            z-index: 1;
          }
          .swiper-wrapper{
            position: relative;
            width: 100%;
            height: 100%;
            z-index: 1;
            display: flex;
            transition-property: transform;
            box-sizing: content-box;
          }
          .swiper-slide{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
            width: 100%;
            height: 100%;
            position: relative;
            transition-property: transform;
            margin-bottom: 20px;
          }
          .swiper-pagination{
            background-color: rgba(0,0,0,0.3)
          }
          .scalecms-ctaButton{
            margin-top: 20px;
          }

        `,
        activeOnRender: 1,
      },
    },
    view: {
      onActive() {
        let cols = null;
        const cb = (c) => {
          cols = c;
        };
        let modal = editor.Modal
          .setTitle("Insert slider")
          .onceClose(() => {
            if (!cols) {
              return editor.getSelected().remove();
            };
            this.model.components(`
              <img src="" class="scalecms-popUpSlider__mainImg" />
              <div class="scalecms-popUpSlider__modal">
                <p class="scalecms-popUpSlider__modal__close">
                  <img src="https://backend.scale-cms.com:5000/staticContent/images/d04c74f1-1eb4-4867-a3e3-f161cc84d3a4-1724316217193.svg" alt="close icon" />
                </p>
                <div class="swiper">
                  <div class="swiper-wrapper">

                  ${new Array(cols).fill(0).map((_, idx) => {
              return `<div class="swiper-slide">
                      <img src="" />
                    </div>`
            }).join("")}

                  </div>

                  <div class="swiper-pagination"></div>
              
                  <div class="swiper-button-prev"></div>
                  <div class="swiper-button-next"></div>
                </div>
                <div data-gjs-type="scalecms-ctaButton"></div>
              </div>
            `);
          })
          .open();
        let content = modalContent(modal, cb);
        modal.setContent(content.wrap);
      }
    }
  });

  editor.BlockManager.add('scalecms-popUpSlider-blk', {
    label: 'PopUp Slider',
    content: {
      type: "scalecms-popUpSlider",
    },
    category: {
      label: 'Extra'
    },
    media: `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 32 32" style="filter:invert(1); enable-background:new 0 0 32 32;" xml:space="preserve"><style type="text/css">.st0{fill:none;stroke:#000000;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}</style><polyline class="st0" points="25,11 27,13 25,15 "/><polyline class="st0" points="7,11 5,13 7,15 "/><path class="st0" d="M29,23H3c-1.1,0-2-0.9-2-2V5c0-1.1,0.9-2,2-2h26c1.1,0,2,0.9,2,2v16C31,22.1,30.1,23,29,23z"/><circle class="st0" cx="16" cy="28" r="1"/><circle class="st0" cx="10" cy="28" r="1"/><circle class="st0" cx="22" cy="28" r="1"/></svg>`,
    attributes: {
      title: 'Creates a slider',
    },
    select: true
  });
};

/**
 * 
 * @param {gjs.Modal} modal 
 * @param {(rows: number, cols: number) => void} cb
 * @returns {{wrap: HTMLDivElement, getData: () => {rows: number, cols: number}}}
 */

const modalContent = (modal, cb) => {
  let wrap = document.createElement("div");
  wrap.classList.add("custom_modalSwiper");

  let colsWrap = document.createElement("div");
  colsWrap.innerHTML = "<span>No. of columns:</span>";
  let colsInput = document.createElement("input");
  colsInput.value = 2;
  colsInput.type = "number";
  colsWrap.appendChild(colsInput);

  let saveBtn = document.createElement("button");
  saveBtn.textContent = "Save";
  saveBtn.style.marginTop = "20px";
  saveBtn.classList.add("gjs-btn-prim");

  const style = document.createElement("style");
  style.innerHTML = `
    .custom_modalSwiper input {
      background: transparent;
      border: 1px solid gray;
      margin-left: 10px;
      padding: 0px 5px;
      outline: none;
      color: white;
      width: 80px;
    }
    .custom_modalSwiper > div {
      margin-bottom: 5px;
    }
  `;

  saveBtn.addEventListener("click", () => {
    let cols = Number(colsInput.value);
    if (isNaN(cols)) {
      cb(0);
      return setTimeout(() => modal.close(), 0);
    };
    cb(cols);
    return setTimeout(() => modal.close(), 0);
  });

  wrap.appendChild(colsWrap);
  wrap.appendChild(saveBtn);
  wrap.appendChild(style);

  return { wrap };
};