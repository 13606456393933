import gjs from "grapesjs";

function script() {
    if(!this) return;

    const hamburger = this.querySelector('.scalecms-header-controles-hamburger');
    const navbar = this.querySelector('.scalecms-header-navbar');
    if (!hamburger || !navbar) return;
    
    hamburger.addEventListener('click', () => {
        if(!navbar.classList.contains('active')){
            navbar.style.display = 'flex';
            navbar.classList.add('active');
        }
        else {
            navbar.style.display = 'none';
            navbar.classList.remove('active');
        }
    });
}

/**
 * @param {gjs.Editor} editor
 */
export default editor => {
    editor.Components.addType('scalecms-header', {
        isComponent: e => e?.classList?.contains("scalecms-header"),
        model: {
            defaults: {
                type: "default",
                traits: [

                ],
                attributes: {
                    class: "scalecms-header"
                },
                script,
                styles: `
                    .scalecms-header {
                        width: 100%;
                        max-height: 60px;
                        min-height: 40px;
                        display: grid;
                        grid-template-columns: 10% 80% 10%;
                        background: #021859;
                        padding-left: 20px;
                        padding-right: 20px;
                        padding-top: 5px;
                        font-family: 'Arial';
                    }
                    .scalecms-header-logo{
                        max-width: 100px;
                        width: 50px;
                    }
                    .scalecms-header-logo img{
                        max-width: 100px;
                        width: 50px;
                        height: 50px;
                    }
                    @media screen and (max-width: 600px){
                        .scalecms-header{
                            grid-template-columns: 80% 20%;
                        }
                        .scalecms-header-logo{
                            max-width: 100px;
                            width: 50px;
                        }
                        .scalecms-header-logo img{
                            max-width: 100px;
                            width: 50px;
                            height: 50px;
                        }
                    }
                    .scalecms-header-logo img{
                        width: 100%;
                        height: 50px;
                    },
                    .scalecms-header-navbar{
                        display: flex; 
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-evenly;
                        height: 50px;
                    }
                    @media screen and (max-width: 600px){
                        .scalecms-header-navbar{
                            display: none;
                            position: absolute;
                            top: 50px;
                            left: 0px;
                            flex-direction: column;
                            justify-content: baseline;
                            align-items: baseline;
                            background: #021859;
                            width: 100%;
                            height: fit-content;
                            padding: 20px;
                            overflow: hidden;
                            animation: animateNavbar 1s linear;
                        }

                        @keyframes animateNavbar{
                            0%{
                                max-height: 0px;
                            }
                            100%{
                                max-height: 1000px;
                            }
                        }
                    }
                    .scalecms-header-controles{
                        width: 100%;
                        display: grid;
                        grid-template-columns: 50% 50%;
                        place-items: center;
                        cursor: pointer;
                    }
                    .scalecms-header-navbar-navitem{
                        color: white;
                        cursor: pointer;
                    }
                    .scalecms-header-controles-hamburger{
                        display: none;
                    }
                    @media screen and (max-width: 600px){
                        .scalecms-header-controles-hamburger{
                            display: block;
                        }
                        .scalecms-header-controles{
                            margin-left: -30px;
                            grid-template-columns: 1fr 1fr 1fr;
                            gap: 10px;
                        }
                    }
                    .scalecms-header-controles-search, .scalecms-header-controles-user{
                        width: 24px;
                        height: 24px;
                    }
                `,
                components: [
                    {
                        tagName: "div",
                        attributes: {class: "scalecms-header-logo"},
                        components:{
                            type: "image",
                            attributes: {src: '#'}
                        }
                    },
                    {
                        tagName: "div",
                        attributes: {class: "scalecms-header-navbar"},
                        components: [
                            {
                                type: "text",
                                attributes: {class: "scalecms-header-navbar-navitem"},
                                components: "nav-item"
                            },
                            {
                                type: "text",
                                attributes: {class: "scalecms-header-navbar-navitem"},
                                components: "nav-item"
                            },
                            {
                                type: "text",
                                attributes: {class: "scalecms-header-navbar-navitem"},
                                components: "nav-item"
                            },
                            {
                                type: "text",
                                attributes: {class: "scalecms-header-navbar-navitem"},
                                components: "nav-item"
                            },
                            {
                                type: "text",
                                attributes: {class: "scalecms-header-navbar-navitem"},
                                components: "nav-item"
                            },
                            {
                                type: "text",
                                attributes: {class: "scalecms-header-navbar-navitem"},
                                components: "nav-item"
                            },
                        ]
                    },
                    {
                        tagName: "div",
                        attributes: {class: "scalecms-header-controles"},
                        components: [
                            {
                                tagName: "img",
                                attributes: {class: "scalecms-header-controles-search", src: "https://i.imgur.com/iqDzrP8.png"},
                            },
                            {
                                tagName: "img",
                                attributes: {class: "scalecms-header-controles-user", src: "https://i.imgur.com/zDxXZKk.png"},
                            },
                            {
                                tagName: "img",
                                attributes: {class: "scalecms-header-controles-hamburger", src: "https://i.imgur.com/boqY7Lz.png"}
                            }
                        ]
                    }
                ]
            }
        }
    });


    editor.BlockManager.add('scalecms-header-blk', {
        label: 'Header 1',
        content: {
            type: "scalecms-header",
        },
        category: {
            label: 'Headers'
        },
        media: `<svg viewBox="0 0 24 24"><g data-name="layout 34"><path d="M19,2H5A3,3,0,0,0,2,5V19a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V5A3,3,0,0,0,19,2Zm1,17a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V5A1,1,0,0,1,5,4H19a1,1,0,0,1,1,1Z"/><path d="M17,5H7A2,2,0,0,0,5,7V8a2,2,0,0,0,2,2H17a2,2,0,0,0,2-2V7A2,2,0,0,0,17,5Zm0,3H7V7H17Z"/></g></svg>`,
        attributes: {
            title: 'Basic header',
        },
        select: true
    });
};