import gjs from "grapesjs";


/**
 * @param {gjs.Editor} editor
 */
export default editor => {
    editor.Components.addType('scalecms-centeredColumn', {
        isComponent: e => e?.classList?.contains("scalecms-centeredColumn"),
        model: {
            defaults: {
                type: "default",
                traits: [

                ],
                attributes: {
                    class: "scalecms-centeredColumn"
                },
                styles: `
                    .scalecms-centeredColumn {
                        width: 70%;
                        height: auto;
                        min-height: 40px;
                        margin-left: auto;
                        margin-right: auto;
                    }
                `,
                components: ``
            }
        }
    });


    editor.BlockManager.add('scalecms-centeredColumn-blk', {
        label: 'Centered column',
        content: {
            type: "scalecms-centeredColumn",
        },
        category: {
            label: 'Layout'
        },
        media: `<svg width="100px" height="100px" viewBox="0 0 32 32" id="icon">
                <defs>
                    <style>.cls-1{fill:none;}</style>
                </defs>
                <title>column</title>
                <rect x="24" y="4" width="2" height="24" fill="white"/>
                <path d="M18,6V26H14V6h4m0-2H14a2,2,0,0,0-2,2V26a2,2,0,0,0,2,2h4a2,2,0,0,0,2-2V6a2,2,0,0,0-2-2Z" fill="white"/>
                <rect x="6" y="4" width="2" height="24" fill="white"/>
                <rect id="_Transparent_Rectangle_" data-name="&lt;Transparent Rectangle&gt;" class="cls-1" width="32" height="32"/>
                </svg>`,
        attributes: {
            title: 'Basic 1 column block',
        },
        select: true
    });
};