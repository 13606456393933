import React from "react";
import "./index.scss";

import axios from "axios";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import * as backendModule from "../../modules/backendModule";
import * as timestampActions from "../../actions/timestampActions";
import { animateBox } from "../../modules/componentAnimation";
import useOnScreen from "../../modules/hooks/useOnScreen";
import useDefer from "../../modules/hooks/useDefer";

import { FilteredCustomTable } from "../../components/customComponents/Table";
import CustomInput from "../../components/customComponents/CustomInput";
import Spinner from "../../components/customComponents/Spinner";
import Dropdown from "../../components/customComponents/Dropdown";

let curTimeout = null;
const LanderRequests = () =>{
    const [data, setData] = React.useState();
    const [dataCount, setDataCount] = React.useState();
    const [canPaginate, setCanPaginate] = React.useState();
    const [filters, setFilters] = React.useState([]);

    const siteSettingsSelector = useSelector(state => state?.siteSettings ?? {});
    const curUserData = useSelector(state => state?.userData?.userData ?? {});
    const curTimestampSelector = useSelector(state => state?.timestamp ?? null);

    const searchRef = React.useRef();
    const timestampRef = React.useRef();
    const curDefer = useDefer();
    const curOnScreen = useOnScreen();

    let searchHandler = () => {
        let searchVal = searchRef?.current?.value;
        clearTimeout(curTimeout);
        curTimeout = setTimeout(() => {
            setFilters([{
                or: [
                    { name: "OfferName", op: "like", value: searchVal },
                    { name: "OfferURL", op: "like", value: searchVal }
                ]
            }]);
        }, 500);
    };

    let orderHandler = () => {
        let out = [];

        if (curUserData?.UserInfo?.Flags?.canRequestLander) {
            out.push(
                {name: "isCompleted", order: "desc"},
                {name: "expectedDeliveryTime", order: "desc"}
            );
        } else {
            out.push(
                {name: "isCompleted", order: "asc"},
                {name: "expectedDeliveryTime", order: "asc"}
            );
        };

        return out;
    };

    const getData = (ts) => {
        if (timestampRef.current !== ts) return;
        setCanPaginate(false);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/landerRequests/getAllRequests`,
            data: {
                limit: 20,
                offset: 0,
                filters,
                orders: orderHandler()
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestampRef.current !== ts) return;
            setData(res.data);

            if (res.data.status === "ok") {
                if (res.data.data.length === 20) setCanPaginate(true);
            };
        }).catch(() => {
            if (timestampRef.current !== ts) return;

            setData(backendModule.genericError);
        });
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/landerRequests/countRequests`,
            data: {
                filters
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setDataCount(res.data);
        }).catch(() => {
            setDataCount(backendModule.genericError);
        });
    };

    const continueData = (ts) => {
        if (!data) return;
        if (data.status !== "ok") return;
        if (timestampRef.current !== ts) return;
        if (!canPaginate) return;

        setCanPaginate(false);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/landerRequests/getAllRequests`,
            data: {
                limit: 20,
                offset: data.data.length,
                filters,
                orders: orderHandler()
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestampRef.current !== ts) return;
            if (res.data.status === "ok") {
                if (res.data.data.length === 20) setCanPaginate(true);
                setData(d => {
                    return {
                        ...d,
                        data: [
                            ...d.data,
                            ...res.data.data
                        ]
                    };
                });
            };
        }).catch(() => null);
    };

    const getPriority = p => {
        switch (p) {
            case 0: return <span style={{color: "rgb(83, 255, 83)"}}>Low</span>;
            case 1: return <span style={{color: "yellow"}}>Medium</span>;
            case 2: return <span style={{color: "#ff7373"}}>High</span>;
            default: return "?";
        };
    };

    React.useEffect(() => {
        if (filters?.length > 0) setFilters([]);
    }, [siteSettingsSelector?.advancedSearch]);

    React.useEffect(() => {
        if (!canPaginate) return;
        if (!curOnScreen.isIntersecting) return;

        try {
            curOnScreen.observer.unobserve(curOnScreen.measureRef.current);
        } catch {};

        let ts = Date.now();
        timestampRef.current = ts;
        curDefer(() => {
            continueData(ts);
        }, 500);
    }, [curOnScreen.isIntersecting, canPaginate]);

    React.useEffect(() => {
        let ts = Date.now();
        timestampRef.current = ts;
        curDefer(() => {
            getData(ts);
        }, 500);
    }, [curTimestampSelector, filters]);

    return <div className="route__landerRequests">
        {!siteSettingsSelector.advancedSearch && <CustomInput autocomplete="off" onChange={searchHandler} ref={searchRef} theme="dark" accent="#3F7CEA" placeholder="Search..." style={{ width: "100%", marginBottom: "20px" }} />}

        <div className="route__landerRequests__kpi">
            <div className="route__landerRequests__kpi__item">
                <p className="route__landerRequests__kpi__item__top">Total requests</p>
                <p className="route__landerRequests__kpi__item__bottom">
                    {dataCount ? (dataCount.status === "ok" ? dataCount.data.Total : "?") : <Spinner style={{width: "28px", height: "28px"}} color="white" />}
                </p>
            </div>
            <div className="route__landerRequests__kpi__item">
                <p className="route__landerRequests__kpi__item__top">Completed requests</p>
                <p className="route__landerRequests__kpi__item__bottom">
                    {dataCount ? (dataCount.status === "ok" ? dataCount.data.Completed : "?") : <Spinner style={{width: "28px", height: "28px"}} color="white" />}
                </p>
            </div>
            <div className="route__landerRequests__kpi__item">
                <p className="route__landerRequests__kpi__item__top">Pending requests</p>
                <p className="route__landerRequests__kpi__item__bottom">
                    {dataCount ? (dataCount.status === "ok" ? (Number(dataCount.data.Total) - Number(dataCount.data.Completed)) : "?") : <Spinner style={{width: "28px", height: "28px"}} color="white" />}
                </p>
            </div>
        </div>
        <FilteredCustomTable
            theme="dark"
            accent="#48515C"
            style={{width: "100%"}}
            headers={["Offer", "Priority", "Created by", "Created at", "Expected delivery", "Is completed"]}
            filters={siteSettingsSelector.advancedSearch ? [
                { name: "ID", friendlyName: "ID", type: "string" },
                { name: "OfferName", friendlyName: "Offer name", type: "string" },
                { name: "OfferURL", friendlyName: "Offer URL", type: "string" },
                { name: "Priority", friendlyName: "Priority", type: "custom", varType: "string", data: [
                    {text: "Low", value: 0},
                    {text: "Medium", value: 1},
                    {text: "High", value: 2}
                ] },
                { name: "expectedDeliveryTime", friendlyName: "Expected delivery", type: "date" },
                { name: "isCompleted", friendlyName: "Is completed", type: "custom", varType: "boolean", data: [
                    { text: "Yes", value: true },
                    { text: "No", value: false }
                ] },
                { name: "createdAt", friendlyName: "Created at", type: "date" },
            ] : undefined}
            filterCB={f => setFilters(f)}
            data={(() => {
                if (!data) return [[{ keyID: "noData-spinner", type: "spinner" }]];
                if (data.status === "error") return [[{ keyID: "noData-text", type: "custom", data: <p>There was an error while fetching requests</p> }]];

                let tmp = data.data.map(elem => {
                    return [
                        { keyID: String(elem.ID), type: "text", text: elem.OfferName },
                        { keyID: String(elem.ID), type: "text", text: getPriority(elem.Priority) },
                        { keyID: String(elem.ID), type: "text", text: elem.Username },
                        { keyID: String(elem.ID), type: "text", text: moment(elem.createdAt).toDate().toLocaleString() },
                        { keyID: String(elem.ID), type: "text", text: elem.expectedDeliveryTime ? moment(elem.expectedDeliveryTime).toDate().toLocaleString() : "Unknown" },
                        { keyID: String(elem.ID), type: "text", text: elem.isCompleted ? <span style={{ color: "#53ff53" }}>Yes</span> : <span style={{ color: "#ff7474" }}>No</span> },
                        (elem.OfferURL ? { keyID: String(elem.ID), type: "groupNewline", group: [
                            { keyID: String(elem.ID), type: "custom", data: <div className="route__landerRequests__url">
                                <p>URL:</p>
                                <span>{elem.OfferURL}</span>
                            </div> }
                        ]} : null),
                        {
                            keyID: String(elem.ID), type: "groupNewline", group: (curUserData?.UserInfo?.Flags?.isAdmin ? [
                                { keyID: String(elem.ID), type: "button", text: "Remove", onClick: e => animateBox(e, <RemoveLanderRequest edit={elem} onChange={() => {
                                    setData(d => {
                                        return {
                                            ...d,
                                            data: d.data.filter(dd => dd.ID !== elem.ID)
                                        };
                                    });
                                }} />) },
                                (!elem.expectedDeliveryTime ? { keyID: String(elem.ID), type: "button", text: "Set expected delivery", style: {marginLeft: "10px"}, onClick: e => animateBox(e, <SetLanderDeliveryDate edit={elem} onChange={(e) => {
                                    setData(d => {
                                        return {
                                            ...d,
                                            data: d.data.map(dd => {
                                                if (dd.ID === elem.ID) dd.expectedDeliveryTime = e;
                                                return dd;
                                            })
                                        };
                                    });
                                }} />) } : null),
                                (!elem.isCompleted ? { keyID: String(elem.ID), type: "button", text: "Mark as completed", style: {marginLeft: "10px"}, onClick: e => animateBox(e, <MarkLanderRequestAsCompleted edit={elem} onChange={() => {
                                    setData(d => {
                                        return {
                                            ...d,
                                            data: d.data.map(dd => {
                                                if (dd.ID === elem.ID) dd.isCompleted = true;
                                                return dd;
                                            })
                                        };
                                    });
                                }} />) } : null),
                            ].filter(t => t) : [])
                        }
                    ].filter(t => t);
                });

                if (tmp.length === 0) tmp.push([{ keyID: "noData-empty", type: "custom", data: <p>Nothing to show.</p> }])
                return tmp;
            })()}
        />
        {canPaginate && <div ref={curOnScreen.measureRef} style={{width: "1px", height: "1px", opacity: 0}}></div>}
    </div>
};

const AddLanderRequest = props => {
    const [spinner, setSpinner] = React.useState(false);
    const [infoP, setInfoP] = React.useState("");
    const [priority, setPriority] = React.useState(null);

    const offerNameRef = React.useRef();
    const offerURLRef = React.useRef();
    const curDispatch = useDispatch();

    const addRequest = () => {
        setInfoP("");
        let data = {
            OfferName: offerNameRef.current.value,
            OfferURL: offerURLRef.current.value,
            Priority: priority
        };

        if (!data.OfferName) return setInfoP("Offer name can't be empty");
        if (data.Priority === null) return setInfoP("Please select a priority");

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/landerRequests/addRequest`,
            data,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (props.onChange) props.onChange();
                curDispatch(timestampActions.updateTimestamp());
                props.onClose();
            } else {
                setInfoP("An error occured while adding a lander request!");
            };
        }).catch(() => {
            setInfoP("Server timed out!");
        }).finally(() => {
            setSpinner(false);
        });
    };

    return <div className="route__landerRequests__addLanderRequest">
        <div className="route__landerRequests__addLanderRequest__wrap">

            <div className="route__landerRequests__addLanderRequest__wrap__spinner" style={{
                opacity: spinner ? 1 : 0,
                pointerEvents: spinner ? "all" : "none"
            }} onClick={e => spinner && e.stopPropagation()}>
                <Spinner color="#3F7CEA" />
            </div>

            <h3 style={{ marginBottom: "20px" }}>{props.data ? "Edit" : "Add"} request</h3>
            <CustomInput autocomplete="off" ref={offerNameRef} theme="dark" accent="#fff" placeholder="Offer name" style={{ marginBottom: "20px", width: "100%" }} />
            <CustomInput autocomplete="off" ref={offerURLRef} theme="dark" accent="#fff" placeholder="Offer URL (optional)" style={{ marginBottom: "20px", width: "100%" }} />
            <Dropdown inlinePlaceholder="Select a priority" accent="#fff" theme="dark" data={[
                {name: "Low", value: 0},
                {name: "Medium", value: 1},
                {name: "High", value: 2}
            ]} onChange={e => setPriority(e?.value)} selected={(()=>{
                if (priority === null) return null;
                return Number(priority);
            })()} />

            <div className="route__usersList__addUser__wrap__btns">
                <p onClick={addRequest}>
                    <img style={{ marginRight: "15px" }} src="/images/saveBtn.png" />
                    <span>Save</span>
                </p>
                <p onClick={props.onClose}>
                    <span>Cancel</span>
                    <img style={{ marginLeft: "15px" }} src="/images/closeBtn.png" />
                </p>
            </div>

            {infoP && <p className="route__usersList__addUser__wrap__infoP">{infoP}</p>}

        </div>
    </div>
};

const MarkLanderRequestAsCompleted = props => {
    const [spinner, setSpinner] = React.useState(false);
    const [infoP, setInfoP] = React.useState("");

    const completeRequest = () => {
        setInfoP("");

        let data = {
            ID: props.edit?.ID
        };
        if (!data.ID) return setInfoP("Info is missing?!");

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/landerRequests/completeRequest`,
            data,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (props.onChange) props.onChange();
                props.onClose();
            } else {
                setInfoP("An error occured while completing the request!");
            };
        }).catch(() => {
            setInfoP("Server timed out!");
        }).finally(() => {
            setSpinner(false);
        });
    };

    return <div className="route__landerRequests__addLanderRequest">
        <div className="route__landerRequests__addLanderRequest__wrap">

            <div className="route__landerRequests__addLanderRequest__wrap__spinner" style={{
                opacity: spinner ? 1 : 0,
                pointerEvents: spinner ? "all" : "none"
            }} onClick={e => spinner && e.stopPropagation()}>
                <Spinner color="#3F7CEA" />
            </div>

            <h3 style={{ marginBottom: "20px" }}>Mark request as completed</h3>
            <p>Are you sure?</p>
            <p><span style={{color: "rgb(63, 124, 234)"}}>{props.edit?.OfferName}</span> will be marked as completed.</p>

            <div className="route__usersList__addUser__wrap__btns">
                <p onClick={completeRequest}>
                    <img style={{ marginRight: "15px" }} src="/images/saveBtn.png" />
                    <span>Complete</span>
                </p>
                <p onClick={props.onClose}>
                    <span>Cancel</span>
                    <img style={{ marginLeft: "15px" }} src="/images/closeBtn.png" />
                </p>
            </div>

            {infoP && <p className="route__usersList__addUser__wrap__infoP">{infoP}</p>}

        </div>
    </div>
};

const SetLanderDeliveryDate = props => {
    const [spinner, setSpinner] = React.useState(false);
    const [infoP, setInfoP] = React.useState("");

    const deliveryDateRef = React.useRef();

    const completeRequest = () => {
        setInfoP("");

        let data = {
            ID: props.edit?.ID,
            DeliveryTime: moment(deliveryDateRef.current.value)
        };
        if (!data.ID) return setInfoP("Info is missing?!");
        if (!data.DeliveryTime.isValid()) return setInfoP("Delivery is not a valid date!");
        if (moment().add(-1, "days").isAfter(data.DeliveryTime)) return setInfoP("Delivery time can't be in the past!");

        data.DeliveryTime = data.DeliveryTime.toDate().getTime();
        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/landerRequests/setExpectedDeliveryTime`,
            data,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (props.onChange) props.onChange(data.DeliveryTime);
                props.onClose();
            } else {
                setInfoP("An error occured while completing the request!");
            };
        }).catch(() => {
            setInfoP("Server timed out!");
        }).finally(() => {
            setSpinner(false);
        });
    };

    return <div className="route__landerRequests__addLanderRequest">
        <div className="route__landerRequests__addLanderRequest__wrap">

            <div className="route__landerRequests__addLanderRequest__wrap__spinner" style={{
                opacity: spinner ? 1 : 0,
                pointerEvents: spinner ? "all" : "none"
            }} onClick={e => spinner && e.stopPropagation()}>
                <Spinner color="#3F7CEA" />
            </div>

            <h3 style={{ marginBottom: "20px" }}>Set expected delivery</h3>
            <CustomInput autocomplete="off" ref={deliveryDateRef} theme="dark" accent="#fff" placeholder="Expected delivery" type="date" style={{ marginBottom: "20px", width: "100%" }} />

            <div className="route__usersList__addUser__wrap__btns">
                <p onClick={completeRequest}>
                    <img style={{ marginRight: "15px" }} src="/images/saveBtn.png" />
                    <span>Complete</span>
                </p>
                <p onClick={props.onClose}>
                    <span>Cancel</span>
                    <img style={{ marginLeft: "15px" }} src="/images/closeBtn.png" />
                </p>
            </div>

            {infoP && <p className="route__usersList__addUser__wrap__infoP">{infoP}</p>}

        </div>
    </div>
};

const RemoveLanderRequest = props => {
    const [spinner, setSpinner] = React.useState(false);
    const [infoP, setInfoP] = React.useState("");

    const removeRequest = () => {
        setInfoP("");

        let data = {
            ID: props.edit?.ID
        };
        if (!data.ID) return setInfoP("Info is missing?!");

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/landerRequests/removeRequest`,
            data,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (props.onChange) props.onChange();
                props.onClose();
            } else {
                setInfoP("An error occured while removing the request!");
            };
        }).catch(() => {
            setInfoP("Server timed out!");
        }).finally(() => {
            setSpinner(false);
        });
    };

    return <div className="route__landerRequests__addLanderRequest">
        <div className="route__landerRequests__addLanderRequest__wrap">

            <div className="route__landerRequests__addLanderRequest__wrap__spinner" style={{
                opacity: spinner ? 1 : 0,
                pointerEvents: spinner ? "all" : "none"
            }} onClick={e => spinner && e.stopPropagation()}>
                <Spinner color="#3F7CEA" />
            </div>

            <h3 style={{ marginBottom: "20px" }}>Remove request</h3>
            <p>Are you sure?</p>
            <p>Removal of <span style={{color: "rgb(63, 124, 234)"}}>{props.edit?.OfferName}</span> is irreversible!</p>

            <div className="route__usersList__addUser__wrap__btns">
                <p onClick={removeRequest}>
                    <img style={{ marginRight: "15px" }} src="/images/saveBtn.png" />
                    <span>Remove</span>
                </p>
                <p onClick={props.onClose}>
                    <span>Cancel</span>
                    <img style={{ marginLeft: "15px" }} src="/images/closeBtn.png" />
                </p>
            </div>

            {infoP && <p className="route__usersList__addUser__wrap__infoP">{infoP}</p>}

        </div>
    </div>
};

export default LanderRequests
export {AddLanderRequest};