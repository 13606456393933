import gjs from "grapesjs";

function script(){
    if(!this) return;

    let style = this.querySelector("style[data-spinner]");
    if (!style) {
        style = document.createElement("style");
        style.setAttribute("data-spinner", "true");
        this.appendChild(style);
    };

    style.innerHTML = `
        @keyframes spinner__rotate {
            100% {
                transform: rotate(360deg);
            }
        }
        @keyframes spinner__dash {
            0% {
                stroke-dasharray: 1, 150;
                stroke-dashoffset: 0;
            }
        
            50% {
                stroke-dasharray: 90, 150;
                stroke-dashoffset: -35;
            }
        
            100% {
                stroke-dasharray: 90, 150;
                stroke-dashoffset: -124;
            }
        }
    `;
};

/**
 * @param {gjs.Editor} editor
 */
export default editor => {
    editor.Components.addType('scalecms-spinner', {
        isComponent: e => e?.classList?.contains("scalecms-spinner"),
        model: {
            init() {
                this.on("change", e => {
                    let color = e?.attributes?.attributes?.["data-color"];
                    let thiccness = e?.attributes?.attributes["data-thickness"];

                    let childSVG = this.getChildAt(0);
                    if (!childSVG) return;

                    if (!childSVG.getEl().getAttributeNS(null, "viewBox")) {
                        childSVG.getEl().setAttributeNS(null, "viewBox", "0 0 50 50");
                        childSVG.replaceWith(childSVG.toHTML());
                    };

                    let childPath = childSVG.getChildAt(0);
                    if (!childPath) return;
                    childPath.setAttributes({...(childPath.getAttributes() ?? {}), style: `stroke: ${color}; stroke-width: ${thiccness ?? 3}`});
                });
            },
            defaults: {
                type: "default",
                script,
                attributes: {
                    class: "scalecms-spinner"
                },
                traits: [
                    {name: "data-color", label: "Spinner color", placeholder: "#00A3FF", type: "color"},
                    {name: "data-thickness", label: "Spinner thickness", placeholder: "spinner thickness", type: "number"},
                ],
                styles: `
                    .scalecms-spinner {
                        width: 64px;
                        height: 64px;
                    }
                    .scalecms-spinner-svg {
                        width: 100%;
                        height: 100%;
                    }
                    .scalecms-spinner-path {
                        stroke: #00A3FF;
                        stroke-width: 3;
                        stroke-linecap: round;
                        transform-origin: center;
                        animation: spinner__dash 1.5s ease-in-out infinite, spinner__rotate 2s linear infinite;
                        transition: stroke 0.3s ease;
                    }
                `,
                components: [
                    {
                        type: "default",
                        tagName: "svg",
                        attributes: {
                            class: "scalecms-spinner-svg",
                            xmlns: "http://www.w3.org/2000/svg",
                            viewBox: "0 0 50 50",
                            "data-gjs-removable": "false",
                            "data-gjs-stylable": "false",
                            "data-gjs-draggable": "false",
                            "data-gjs-resizable": "false",
                            "data-gjs-copyable": "false",
                            "data-gjs-hoverable": "false",
                            "data-gjs-locked": "false",
                        },
                        components: [
                            {
                                type: "default",
                                tagName: "circle",
                                attributes: {
                                    class: "scalecms-spinner-path",
                                    cx: "25",
                                    cy: "25",
                                    r: "20",
                                    fill: "none",
                                }
                            }
                        ]
                    }
                ]
            }
        }
    });


    editor.BlockManager.add('scalecms-spinner-blk', {
        label: 'Spinner',
        content: {
            type: "scalecms-spinner",
        },
        category: {
            label: 'Extra'
        },
        media: `
        <svg version="1.1" id="Capa_1" x="0px" y="0px" width="100px" height="100px" viewBox="0 0 456.817 456.817" style="enable-background:new 0 0 456.817 456.817;" xml:space="preserve">
        <g>
        <g>
        <path d="M109.641,324.332c-11.423,0-21.13,3.997-29.125,11.991c-7.992,8.001-11.991,17.706-11.991,29.129
                    c0,11.424,3.996,21.129,11.991,29.13c7.998,7.994,17.705,11.991,29.125,11.991c11.231,0,20.889-3.997,28.98-11.991
                    c8.088-7.991,12.132-17.706,12.132-29.13c0-11.423-4.043-21.121-12.132-29.129C130.529,328.336,120.872,324.332,109.641,324.332z" id="id_101" style="fill: rgb(255, 255, 255);"></path>
        <path d="M100.505,237.542c0-12.562-4.471-23.313-13.418-32.267c-8.946-8.946-19.702-13.418-32.264-13.418
                    c-12.563,0-23.317,4.473-32.264,13.418c-8.945,8.947-13.417,19.701-13.417,32.267c0,12.56,4.471,23.309,13.417,32.258
                    c8.947,8.949,19.701,13.422,32.264,13.422c12.562,0,23.318-4.473,32.264-13.422C96.034,260.857,100.505,250.102,100.505,237.542z" id="id_102" style="fill: rgb(255, 255, 255);"></path>
        <path d="M365.454,132.48c6.276,0,11.662-2.24,16.129-6.711c4.473-4.475,6.714-9.854,6.714-16.134
                    c0-6.283-2.241-11.658-6.714-16.13c-4.47-4.475-9.853-6.711-16.129-6.711c-6.283,0-11.663,2.24-16.136,6.711
                    c-4.47,4.473-6.707,9.847-6.707,16.13s2.237,11.659,6.707,16.134C353.791,130.244,359.171,132.48,365.454,132.48z" id="id_103" style="fill: rgb(255, 255, 255);"></path>
        <path d="M109.644,59.388c-13.897,0-25.745,4.902-35.548,14.703c-9.804,9.801-14.703,21.65-14.703,35.544
                    c0,13.899,4.899,25.743,14.703,35.548c9.806,9.804,21.654,14.705,35.548,14.705s25.743-4.904,35.544-14.705
                    c9.801-9.805,14.703-21.652,14.703-35.548c0-13.894-4.902-25.743-14.703-35.544C135.387,64.29,123.538,59.388,109.644,59.388z" id="id_104" style="fill: rgb(255, 255, 255);"></path>
        <path d="M439.684,218.125c-5.328-5.33-11.799-7.992-19.41-7.992c-7.618,0-14.089,2.662-19.417,7.992
                    c-5.325,5.33-7.987,11.803-7.987,19.421c0,7.61,2.662,14.092,7.987,19.41c5.331,5.332,11.799,7.994,19.417,7.994
                    c7.611,0,14.086-2.662,19.41-7.994c5.332-5.324,7.991-11.8,7.991-19.41C447.675,229.932,445.02,223.458,439.684,218.125z" id="id_105" style="fill: rgb(255, 255, 255);"></path>
        <path d="M365.454,333.473c-8.761,0-16.279,3.138-22.562,9.421c-6.276,6.276-9.418,13.798-9.418,22.559
                    c0,8.754,3.142,16.276,9.418,22.56c6.283,6.282,13.802,9.417,22.562,9.417c8.754,0,16.272-3.141,22.555-9.417
                    c6.283-6.283,9.422-13.802,9.422-22.56c0-8.761-3.139-16.275-9.422-22.559C381.727,336.61,374.208,333.473,365.454,333.473z" id="id_106" style="fill: rgb(255, 255, 255);"></path>
        <path d="M237.547,383.717c-10.088,0-18.702,3.576-25.844,10.715c-7.135,7.139-10.705,15.748-10.705,25.837
                    s3.566,18.699,10.705,25.837c7.142,7.139,15.752,10.712,25.844,10.712c10.089,0,18.699-3.573,25.838-10.712
                    c7.139-7.138,10.708-15.748,10.708-25.837s-3.569-18.698-10.708-25.837S247.636,383.717,237.547,383.717z" id="id_107" style="fill: rgb(255, 255, 255);"></path>
        <path d="M237.547,0c-15.225,0-28.174,5.327-38.834,15.986c-10.657,10.66-15.986,23.606-15.986,38.832
                    c0,15.227,5.327,28.167,15.986,38.828c10.66,10.657,23.606,15.987,38.834,15.987c15.232,0,28.172-5.327,38.828-15.987
                    c10.656-10.656,15.985-23.601,15.985-38.828c0-15.225-5.329-28.168-15.985-38.832C265.719,5.33,252.779,0,237.547,0z" id="id_108" style="fill: rgb(255, 255, 255);"></path>
        </g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        </svg>
        `,
        attributes: {
            title: 'Spinner',
        },
        select: true
    });
};