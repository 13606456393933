import React from "react";
import "./index.scss";

import axios from "axios";
import * as backendModule from "../../modules/backendModule";
import { useSelector } from "react-redux";
import { animateBox } from "../../modules/componentAnimation";

import Spinner from "../../components/customComponents/Spinner";
import Button from "../../components/customComponents/Button";
import CustomInput from "../../components/customComponents/CustomInput";
import FilterPanel from "../../components/customComponents/FilterPanel";
import Dropdown from "../../components/customComponents/Dropdown";

let curTimeout = null;
const ImagesList = (props) => {
    const currentURL = window.location.href;
    const [data, setData] = React.useState();
    const [filters, setFilters] = React.useState([]);

    const searchRef = React.useRef();

    const userTimestampSelector = useSelector(state => state?.timestamp ?? null);
    const siteSettingsSelector = useSelector(state => state?.siteSettings ?? {});

    let searchHandler = () => {
        let searchVal = searchRef?.current?.value;
        clearTimeout(curTimeout);
        curTimeout = setTimeout(() => {
            setFilters([{or: [
                {name: "ID", op: "like", value: searchVal},
                {name: "ImageName", op: "like", value: searchVal},
                {name: "Tag", op: "like", value: searchVal}
            ]}]);
        }, 500);
    };

    if (!currentURL.includes('asset-list')) {
        filters.push({
            name: "Tag",
            op: "eq",
            value: props.tag ? props.tag : "grapejs"
        });
    }

    const getData = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/images/getAllImages`,
            data: {
                filters: [
                    ...filters,
                ],
                limit: 200
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setData(res.data);
            } else {
                setData({ status: "error", data: "SERVER_ERROR" });
            };
        }).catch(() => {
            setData({ status: "error", data: "SERVER_ERROR" });
        })
    };

    React.useEffect(() => {
        if (filters.length > 0) setFilters([]);
    }, [siteSettingsSelector?.advancedSearch]);

    React.useEffect(() => {
        getData();
    }, [userTimestampSelector, filters]);

    return <div className="route__imagesList" style={{...props.style}}>
        {props.fromComments && <p className="fromComments__closeBtn" onClick={props.onClose}>
            <span>Close</span>
            <img style={{ marginLeft: "15px" }} src="/images/closeBtn.png" />
        </p>}
        <div className="route__imagesList__filter">
            {siteSettingsSelector?.advancedSearch ?
            <FilterPanel accent="rgb(63, 124, 234)" theme="dark" filters={[
                {name: "ID", friendlyName: "ID", type: "string"},
                {name: "ImageName", friendlyName: "Name", type: "string"}
            ]} filterCB={f => setFilters(f)} /> :
            <>
                <CustomInput autocomplete="off" ref={searchRef} onInput={searchHandler} theme="dark" accent="#3F7CEA" placeholder="Search..." style={{width: "100%"}} />
            </>}
        </div>


        <div className="route__imagesList__wrap">
            {data ? <>
                {data.status === "error" ? <p style={{ gridColumn: "1 / span all" }}>There was an error while getting images!</p> : <>
                    {data.data.map(img => {
                        return <div className="route__imagesList__wrap__image">
                            <div className="route__imagesList__wrap__image__img" onClick={(e) => {
                                let curImg = e.currentTarget.querySelector("img");
                                if (!curImg) return;
                                let w = window.open("", "_blank");
                                w.document.write("<style>*{margin: 0; padding:0; box-sizing: border-box;}</style>")
                                w.document.write(`<div style="display: flex; justify-content: center; align-items: center; width: 100%; height: auto; min-height: 100vh; background-color: #20262d;">${curImg.outerHTML}</div>`);
                                w.document.close();
                            }}>
                                {(img.URL.endsWith('.mp4') || img.URL.endsWith('.mov') || img.URL.endsWith('.avi') || img.URL.endsWith('.mkv') || img.URL.endsWith('.wmv') || img.URL.endsWith('.webm')) ? <video style={{ width: '100%', height: 'auto' }} controls>
                                <source src={img.URL} type="video/mp4" />
                                </video> : <img src={img.URL} />}
                                
                            </div>
                            <p>{img.ImageName}</p>

                            <div className="route__imagesList__wrap__image__btns">
                                <Button theme="dark" accent="rgb(63, 124, 234)" value="Rename" onClick={e => {
                                    animateBox(e, <RenameImage data={img} onChange={getData} />);
                                }} />
                                {props.select ? <Button theme="dark" accent="rgb(0, 170, 13)" value="Select" onClick={e => {
                                    props.setSelectedImage(img);
                                    props.onClose();
                                }} /> : null}
                                <Button theme="dark" accent="rgb(234, 63, 63)" value="Remove" onClick={e => {
                                    animateBox(e, <RemoveImage data={img} onChange={getData} />);
                                }} />
                            </div>
                        </div>
                    })}
                </>}
            </> : <Spinner style={{ gridColumn: "1 / span all" }} color="rgb(63, 124, 234)" align="center" />}
        </div>
    </div>
};

const AddImage = (props) => {
    const [spinner, setSpinner] = React.useState(false);
    const [infoP, setInfoP] = React.useState("");
    const [image, setImage] = React.useState();
    const [offers, setOffers] = React.useState();
    const [baseOffers, setBaseOffers] = React.useState();
    const [selectedOffer, setSelectedOffer] = React.useState();
    const [offersTab, setOffersTab] = React.useState(true);

    const searchRef = React.useRef();
    const nameRef = React.useRef();

    const getAllOffers = () => {
        axios({
          method: "POST",
          url: `${backendModule.backendURL}/comments/getAllOffersForComments`,
          ...backendModule.axiosConfig
        }).then(res => {
            console.log("OVO JE ", res.data)
          if (res.data.status === "ok") {
            setBaseOffers(res.data.data)
            setOffers(res.data.data)
          } else {
          };
        }).catch(() => {
        }).finally(() => {
          setSpinner(false);
        });
    }

    React.useEffect(() => {
        getAllOffers();
    }, []);

    const performSearch = () => {
        if (!baseOffers) return;
        let searchString = searchRef.current.value.toLowerCase();
    
        clearTimeout(curTimeout);
        curTimeout = setTimeout(() => {
          
          let out = baseOffers.filter(offer => offer.OfferName.toLowerCase().includes(searchString));
          setOffers(out)
    
        }, 500);
    };

    const addImage = () => {
        setInfoP("");

        let data = {
            ImageName: nameRef.current.value,
            image,
            tag: props.tag ? props.tag : "grapejs"
        };

        if (selectedOffer) data.tag = `offername-${selectedOffer}`;

        if (!data.image) return setInfoP("Image is not selected!");
        if (!data.ImageName) return setInfoP("Name can't be empty!");
        if (!data.ImageName.includes(".")) return setInfoP("Name must have an extension!");

        let fd = new FormData();
        for (let key of Object.keys(data)) {
            fd.append(key, data[key]);
        };

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/images/uploadImage`,
            data: fd,
            ...backendModule.axiosConfig,
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then(res => {
            if (res.data.status === "ok") {
                props.onClose();
                props.onChange();
            } else {
                setInfoP("Error while uploading the image!");
            };
        }).catch(() => {
            setInfoP("Server timed out!");
        }).finally(() => {
            setSpinner(false);
        });
    };

    return <div className="route__imagesList__renameImage">
        <div className="route__imagesList__renameImage__wrap">
            <div className="route__imagesList__renameImage__wrap__spinner" style={{
                opacity: spinner ? 1 : 0,
                pointerEvents: spinner ? "all" : "none"
            }} onClick={e => spinner && e.stopPropagation()}>
                <Spinner color="#fff" />
            </div>


            <h3 style={{ marginBottom: "20px" }}>Add image</h3>
            <CustomInput autocomplete="off" ref={nameRef} accent="#fff" theme="dark" placeholder="Name" style={{ width: "100%" }} />

            <div className="route__imagesList__renameImage__wrap__selectImage">
                <div className="route__imagesList__renameImage__wrap__selectImage__wrap" onClick={e => {
                    e.currentTarget.parentNode.querySelector("input[type=file]").click();
                }}>
                    <span>Click to select an image</span>
                    <input type="file" accept="image/*,video/*" style={{display: "none"}} onChange={e => {
                        setImage(e.currentTarget.files[0]);
                        if (nameRef.current) {
                            if (!nameRef.current.value) {
                                nameRef.current.value = e.currentTarget.files[0].name;
                            };
                        };
                    }} />
                </div>
                {image && <img src={URL.createObjectURL(image)} />}
            </div>

            <div className="route__commentsList__offers" style={{ maxHeight: offersTab ? '500px' : '42px' }}>
                <div className="route__commentsList__offers__itemList" style={{ maxHeight: offersTab ? '500px' : '0px', padding: offers ? '10px 16px' : '0px 16px' }}>
                {offers?.length > 0 && <Dropdown
                    theme="dark"
                    accent="#fff"
                    inlinePlaceholder="Select offer"
                    data={[
                        {name: "Select OfferName Image Tag", value: null},
                        ...offers.map(o => {
                            return {name: `${o.OfferName} ${o.Country}`, value: `${o.OfferName}`}
                        })
                    ]}
                    onChange={e => selectedOffer !== e?.value && setSelectedOffer(e?.value)}
                    selected={(()=>{
                        if (!selectedOffer) return 0;
                        return offers.indexOf(offers.find(o => `${o.OfferName} ${o.Country}` === selectedOffer)) + 1;
                    })()}
                />}
                </div>
            </div>

            <div className="route__imagesList__renameImage__wrap__btns">
                <p onClick={addImage}>
                    <img style={{ marginRight: "15px" }} src="/images/saveBtn.png" />
                    <span>Save</span>
                </p>
                <p onClick={props.onClose}>
                    <span>Close</span>
                    <img style={{ marginLeft: "15px" }} src="/images/closeBtn.png" />
                </p>
            </div>

            {infoP && <p className="route__imagesList__renameImage__wrap__infoP">{infoP}</p>}
        </div>
    </div>
};

const RenameImage = (props) => {
    const [spinner, setSpinner] = React.useState(false);
    const [infoP, setInfoP] = React.useState("");

    const nameRef = React.useRef();

    const renameImage = () => {
        setInfoP("");

        let data = {
            ID: props.data.ID,
            ImageName: nameRef.current.value
        };

        if (!data.ImageName) return setInfoP("Name can't be empty!");
        if (!data.ImageName.includes(".")) return setInfoP("Name must have an extension!");

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/images/renameImage`,
            data,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                props.onClose();
                props.onChange();
            } else {
                setInfoP("Error while renaming the image!");
            };
        }).catch(() => {
            setInfoP("Server timed out!");
        }).finally(() => {
            setSpinner(false);
        });
    };

    return <div className="route__imagesList__renameImage">
        <div className="route__imagesList__renameImage__wrap">
            <div className="route__imagesList__renameImage__wrap__spinner" style={{
                opacity: spinner ? 1 : 0,
                pointerEvents: spinner ? "all" : "none"
            }} onClick={e => spinner && e.stopPropagation()}>
                <Spinner color="#fff" />
            </div>


            <h3 style={{ marginBottom: "20px" }}>Rename image</h3>
            <CustomInput autocomplete="off" ref={nameRef} accent="#fff" theme="dark" placeholder="Name" defaultValue={props.data.ImageName} style={{ width: "100%" }} />

            <div className="route__imagesList__renameImage__wrap__btns">
                <p onClick={renameImage}>
                    <img style={{ marginRight: "15px" }} src="/images/saveBtn.png" />
                    <span>Save</span>
                </p>
                <p onClick={props.onClose}>
                    <span>Close</span>
                    <img style={{ marginLeft: "15px" }} src="/images/closeBtn.png" />
                </p>
            </div>

            {infoP && <p className="route__imagesList__renameImage__wrap__infoP">{infoP}</p>}
        </div>
    </div>
};

const RemoveImage = (props) => {
    const [spinner, setSpinner] = React.useState(false);
    const [infoP, setInfoP] = React.useState("");

    const removeImage = () => {
        setInfoP("");

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/images/deleteImage`,
            data: {
                id: props.data.ID
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                props.onClose();
                props.onChange();
            } else {
                setInfoP("Error while removing the image!");
            };
        }).catch(() => {
            setInfoP("Server timed out!");
        }).finally(() => {
            setSpinner(false);
        });
    };

    return <div className="route__imagesList__renameImage">
        <div className="route__imagesList__renameImage__wrap">
            <div className="route__imagesList__renameImage__wrap__spinner" style={{
                opacity: spinner ? 1 : 0,
                pointerEvents: spinner ? "all" : "none"
            }} onClick={e => spinner && e.stopPropagation()}>
                <Spinner color="#fff" />
            </div>


            <h3 style={{ marginBottom: "20px" }}>Remove image</h3>
            <p>Are you sure?</p>
            <p>Removal of <span style={{ color: "rgb(63, 124, 234)" }}>{props.data.ImageName}</span> is irreversible!</p>

            <div className="route__imagesList__renameImage__wrap__btns">
                <p onClick={removeImage}>
                    <img style={{ marginRight: "15px" }} src="/images/saveBtn.png" />
                    <span>Remove</span>
                </p>
                <p onClick={props.onClose}>
                    <span>Close</span>
                    <img style={{ marginLeft: "15px" }} src="/images/closeBtn.png" />
                </p>
            </div>

            {infoP && <p className="route__imagesList__renameImage__wrap__infoP">{infoP}</p>}
        </div>
    </div>
};

export default ImagesList;
export { AddImage }