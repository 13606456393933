import React from "react";
import "./index.scss";

import moment from "moment";
import axios from "axios";
import * as backendModule from "../../../modules/backendModule";
import { useSelector, useDispatch } from "react-redux";
import { updateTimestamp } from "../../../actions/timestampActions";
import { animateBox } from "../../../modules/componentAnimation";
import { SaveWebsite } from "../../CreateWebsite";

import FilterPanel from "../../../components/customComponents/FilterPanel";
import Spinner from "../../../components/customComponents/Spinner";
import Button from "../../../components/customComponents/Button";
import CustomInput from "../../../components/customComponents/CustomInput";
import Heatmaps from "../../../components/Heatmaps";

let curTimeout = null;
const DocGateways = (props) => {

  return <div className="route__documentation">
    <div className="route__documentation__headline">
      <p>Closed Forms</p>
    </div>
    <div className="route__documentation__desc">
      <p>Closed Forms are ways for users to approach form, different variations of possiblities for users/clients on landing sites to get to the form to order the product.</p>
      <p>Currently we have 5 different Closed Forms, keep in mind that this number can change in the future, Present Box, Lucky Wheel, Medical Boxes, Potent Specific and Magic Doors.</p>
      <p>In this example we'll be using Medical Box to demonstrate how to use Closed Forms on your landing site.</p>
    </div>
    <div className="route__documentation__introBlock">
      <div className="route__documentation__introBlock__left">
        <img src="/images/tutorials/gateway/GatewayStep1.png" />
      </div>
      <div className="route__documentation__introBlock__right">
        <p>Firstly we have to click on the '+' icon to open the side menu dropdown</p>
        <p>Then we have to select the 'Gateway' tab in the menu</p>
        <p>After we select the Closed Forms, we're offered with different options of Closed Forms, as mentioned before, we wil be using Medical boxes</p>
        <p>Gateway of our choice we drag to the part of the site where we want it.</p>
      </div>
    </div>
    <div className="route__documentation__introBlock flex-column">
    <div className="route__documentation__introBlock__right">
        <p>After we drag it into our website it should look something like this</p>
      </div>
      <div className="route__documentation__introBlock__left">
        <img src="/images/tutorials/gateway/GatewayStep2.png" />
      </div>
    </div>
    <div className="route__documentation__introBlock">
      <div className="route__documentation__introBlock__left">
        <img src="/images/tutorials/gateway/GatewayStep3.png" />
      </div>
      <div className="route__documentation__introBlock__right">
        <p>Our right side, component info section will be looking like this</p>
      </div>
    </div>
    <div className="route__documentation__desc">
      <p>Now there are many ways to approach to the form of the component</p>
      <p>For example we can go throught settings of the component.</p>
    </div>
    <div className="route__documentation__introBlock">
      <div className="route__documentation__introBlock__left">
        <img src="/images/tutorials/gateway/GatewayStep4.png" />
      </div>
      <div className="route__documentation__introBlock__right">
        <p>If we click on the settings button, we should have an option to open or close the form</p>
      </div>
    </div>
    <div className="route__documentation__introBlock flex-column">
      <div className="route__documentation__introBlock__right">
        <p>Component with opened form through the settings will look like this</p>
      </div>
      <div className="route__documentation__introBlock__left">
        <img src="/images/tutorials/gateway/GatewayStep5.png" />
      </div>
    </div>
    <div className="route__documentation__desc">
      <p>Another way of approaching the form would be by manually interacting with the component</p>
      <p>In this case, we'll click on the medical box to interact with it</p>
    </div>
    <div className="route__documentation__introBlock flex-column">
      <div className="route__documentation__introBlock__right">
        <p>After we click on the box, we should get the small pop up window giving us a message</p>
        <p>And if we click on the 'OK' we'll have the form opened in the component</p>
      </div>
      <div className="route__documentation__introBlock__left">
        <img src="/images/tutorials/gateway/GatewayStep6.png" />
      </div>
    </div>
    <div className="route__documentation__desc">
      <p>Keep in mind that all the texts, all the dimensions and colors are customable through the CMS.</p>
    </div>
  </div>
};

export default DocGateways;
export { DocGateways };