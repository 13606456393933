import React from "react";
import "./index.scss";

import moment from "moment";
import axios from "axios";
import { useParams } from "react-router-dom";

import 'grapesjs/dist/css/grapes.min.css';
import grapejsTuImageEditor from "grapesjs-tui-image-editor";
import grapejsBlocksBasic from "grapesjs-blocks-basic";
import grapejsStyleGradient from "grapesjs-style-gradient";
import grapejsStyleFilter from "grapesjs-style-filter";
import grapesjsStyleBg from "grapesjs-style-bg";
import grapesjsForms from "grapesjs-plugin-forms";
import grapesjsPresetWebpage from "grapesjs-preset-webpage";
import grapesjsExporter from "grapesjs-plugin-export";
import grapesjsCodeEditor from "grapesjs-component-code-editor";
import grapesjsScssParser from "grapesjs-parser-postcss";

import { preToScript } from "./customPlugins/customCode";
import customPlugins from "./customPlugins";
import grapesjs from 'grapesjs';

import { animateBox } from "../../modules/componentAnimation";
import { useDispatch, useSelector } from "react-redux";
import * as backendModule from "../../modules/backendModule";
import countryList from "../../modules/countryList.json";

import { toggleSiteSaved } from "../../actions/siteSavedActions";

import assetManager from "./assetManager";
import blockManager from "./blockManager";
import CustomInput from "../../components/customComponents/CustomInput";
import Button from "../../components/customComponents/Button";
import Dropdown from "../../components/customComponents/Dropdown";
import Spinner from "../../components/customComponents/Spinner";
import { LandingItem } from "../LandingList";
import Checkbox from "../../components/customComponents/Checkbox";

const scrollonclick_trait = {
    label: 'Scroll to class on click',
    name: 'data-scrollonclick',
    type: 'text'
};
const scrollonclickDouble_trait = {
    label: 'Scroll on doubleclick',
    name: 'data-scrollonclick-double',
    type: 'checkbox'
};
function addNewTraitToComponent(component, newTrait) {
    const traits = component.get('traits');
    const traitExists = traits.find(trait => trait.attributes.name === newTrait.name);
    if (!traitExists) {
        traits.add(newTrait);
    };
};

function addNewToolbarItemToComponent(component) {
    const toolbar = component.get("toolbar");
    const toolBarItemExists = toolbar.find(item => item?.attributes?.class === "fab fa-android");
    const toolBarItem2Exists = toolbar.find(item => item?.attributes?.class === "fas fa-robot");

    if (!toolBarItemExists) {
        toolbar.push({
            attributes: { "class": "fab fa-android", title: "Modify text with AI" },
            command: "modify-text-with-chatgpt"
        });
        component.set("toolbar", toolbar);
    }
    if (!toolBarItem2Exists) {
        toolbar.push({
            attributes: { "class": "fas fa-robot", title: "Reformat text with AI" },
            command: "reformat-text-with-chatgpt"
        });
        component.set("toolbar", toolbar);
    }
};

function updateAllComponents(editor, newTrait) {
    const rootComponents = editor.getComponents();

    const updateComponentsRecursively = (components) => {
        components.each(component => {
            addNewToolbarItemToComponent(component)
            addNewTraitToComponent(component, newTrait);

            const childComponents = component.components();
            if (childComponents.length) {
                updateComponentsRecursively(childComponents);
            };
        });
    };

    updateComponentsRecursively(rootComponents);
};

async function modifyTextWithChatGPT(component) {

    let oldText = component.getEl().innerHTML;
    let fallbackText = '<p>AI modifying text, please wait...</p>';

    const modifyText = async (text) => {
        if(text === fallbackText) return;
        component.components(fallbackText);
        axios({
          method: "POST",
          url: `${backendModule.backendURL}/openai/openaiModifyText`,
          data: {
            text: text
          },
          ...backendModule.axiosConfig
        }).then(res => {
            if(res.data.status === 'ok'){
                component.components(res.data.data);
            }else{
                component.components(oldText)
            }
        }).catch(() => {
        }).finally(() => {
        });
    }

    await modifyText(component.getEl().innerHTML);
}

async function reformatTextWithChatGPT(component) {

    let oldText = component.getEl().innerHTML;
    let fallbackText = '<p>AI reformatting text, please wait...</p>';

    const reformatText = async (text) => {
        if(text === fallbackText) return;
        component.components(fallbackText);
        axios({
          method: "POST",
          url: `${backendModule.backendURL}/openai/openaiReformatText`,
          data: {
            text: text
          },
          ...backendModule.axiosConfig
        }).then(res => {
            if(res.data.status === 'ok'){
                component.components(res.data.data);
            }else{
                component.components(oldText)
            }
        }).catch(() => {
        }).finally(() => {
        });
    }

    await reformatText(component.getEl().innerHTML);
}


let getWebsiteData = () => null;
let setWebsiteData = () => null;
let setWebsiteProjectData = () => null;
let openSaveDialog = () => null;
let openTemplateDialog = () => null;
let openFileDialog = () => null;
let openRecoverDialog = () => null;

const setupAutoBackup = (editor) => {
    console.log("Auto-save runner started!");
    const handler = async () => {
        setTimeout(handler, 1000 * 60 * 1);
        if (!editor) return;

        const selectedComponent = editor.getSelected();
        if (selectedComponent?.attributes?.type === "text") return;

        let curData = null;
        try {
            curData = editor.getProjectData();
            if (!curData) throw "might as well....";
        } catch (e) {
            console.warn("Error while auto-saving!!!");
            console.warn(e);
            return;
        };

        let curDate = Date.now();
        let final = await axios({
            method: "POST",
            url: `${backendModule.backendURL}/backups/addBackup`,
            data: {
                Data: curData
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") return null;
            return res.data;
        }).catch(() => {
            return { status: "error", data: "GENERIC_ERROR" };
        });

        if (!final) {
            console.log(`Auto-saved at ${moment(curDate).toDate().toLocaleString()}`);
        } else {
            console.warn(`Error while auto-saving, failed to save a LocalDB entry!`);
            console.warn(final);
        };
    };

    setTimeout(handler, 1000 * 60 * 1);
};

const CreateWebsite = () => {
    const mainRef = React.useRef();
    const editorRef = React.useRef();
    const editDataRef = React.useRef();

    const [spinner, setSpinner] = React.useState(false);
    const [loadingSpinner, setLoadingSpinner] = React.useState(true);

    const curParams = useParams();

    const getAssetManagerImages = () => {
        let am = editorRef?.current?.AssetManager;
        if (!am) return;

        am.clear();

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/images/getAllImages`,
            data: {
                // filters: [{ name: "Tag", op: "eq", value: "grapejs" }],
                limit: 1000
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                for (let img of res.data.data) {
                    am.add({
                        src: img.URL,
                        name: img.ImageName,
                        type: "image",
                        tag: img.Tag,
                        width: 250,
                        headers: 350
                    })
                };
            };
        }).catch(() => null);
    };

    const initEditor = async () => {
        if (!curParams?.type) return;
        if (!mainRef?.current) return;

        const postInit = () => {
            editorRef.current.StyleManager.addProperty('extra', { extend: 'filter' });
            editorRef.current.StyleManager.addProperty('extra', { extend: 'filter', property: 'backdrop-filter' });
            editorRef.current.Panels.addPanel({
                id: "views"
            }).get("buttons").add([
                {
                    attributes: {
                        title: "Open Code"
                    },
                    className: "fa fa-file-code-o",
                    command: "open-code",
                    togglable: false,
                    id: "open-code"
                }
            ]);
            editorRef.current.DomComponents.clear();
            editorRef.current.CssComposer.clear();
            editorRef.current.UndoManager.clear();
            editorRef.current.on("asset:custom", assetManager);
            editorRef.current.on("block:custom", blockManager);
            editorRef.current.BlockManager.remove("column1");
            editorRef.current.BlockManager.remove("column2");
            editorRef.current.BlockManager.remove("column3");
            editorRef.current.BlockManager.remove("column3-7");

            getAssetManagerImages();

            getWebsiteData = () => new Promise(r2 => {
                return r2({
                    HTML: preToScript(editorRef?.current?.getHtml()),
                    CSS: editorRef?.current?.getCss(),
                    JSON: editorRef.current.getProjectData()
                });
            });
            setWebsiteData = (HTML, CSS) => {
                editorRef.current.setComponents(HTML);
                editorRef.current.setStyle(CSS);
            };
            setWebsiteProjectData = j => editorRef.current.loadProjectData(j);
            openSaveDialog = (e) => {
                animateBox(e, <SaveWebsite type={curParams?.type} id={curParams.id ?? null} data={editDataRef.current} />);
            };
            openTemplateDialog = e => {
                animateBox(e, <OpenTemplateModal />);
            };
            openFileDialog = () => {
                editorRef.current.runCommand("gjs-open-import-webpage");
            };
            openRecoverDialog = e => {
                animateBox(e, <RecoverWebsite />);
            };

            editorRef.current.on("load", (e) => {
                const categories = editorRef.current.BlockManager.getCategories();
                categories.each(category => {
                    category.set('open', false);
                });
                setupAutoBackup(editorRef.current);

                for (let type of e.Components.componentTypes) {
                    let cmp = e.DomComponents.getType(type.id);
                    let curTraits = cmp?.model?.prototype?.defaults?.traits;
                    if (!Array.isArray(curTraits)) continue;

                    e.DomComponents.addType(type.id, {
                        model: {
                            defaults: {
                                traits: [
                                    ...curTraits,
                                    {
                                        label: 'Scroll to class on click',
                                        name: 'data-scrollonclick',
                                        type: 'text'
                                    },
                                    {
                                        label: 'Scroll on doubleclick',
                                        name: 'data-scrollonclick-double',
                                        type: 'checkbox'
                                    }
                                ]
                            }
                        }
                    });
                };

                editorRef.current.TraitManager.addType('image', {
                    createInput({ trait }) {
                      const el = document.createElement('div');
                      const traitName = trait.id;
                  
                      const btn = document.createElement('button');
                      btn.innerText = 'Select Image';
                      btn.style.display = 'block';
                      btn.style.width = "100%";
                      btn.onclick = () => {
                        editorRef.current.AssetManager.open({
                          select(asset) {
                            const url = asset.getSrc();
                            const selectedComponent = editorRef.current.getSelected();
                            if (selectedComponent) {
                              selectedComponent.addAttributes({ [traitName]: url });
                              const traitInstance = selectedComponent.getTrait(traitName);
                              if (traitInstance) {
                                traitInstance.set('value', url);
                              }
                            }
                          }
                        });
                      };
                  
                      el.appendChild(btn);
                  
                      return el;
                    }
                  });
            });
            setLoadingSpinner(false)
        };

        let grapeOptions = {
            container: mainRef.current.querySelector(".route__createWebiste__inner"),
            fromElement: true,
            domComponents: { storeWrapper: 1 },
            width: "100%",
            height: "100%",
            jsInHtml: true,
            allowScripts: true,
            projectData: {},
            selectorManager: {
                componentFirst: 1,
            },
            modal: {
                backdrop: false
            },
            canvas: {
                styles: [
                    "https://cdn.jsdelivr.net/npm/swiper@8/swiper-bundle.min.css"
                ],
            },
            assetManager: {
                storageType: '',
                storeOnChange: true,
                storeAfterUpload: true,
                assets: [],
                custom: true,
                uploadFile: async function (e, tagValue) {
                    setSpinner(true);

                    let imageTag = '';
                    if(tagValue && tagValue.length){
                        if(tagValue[0].value === 'offername-null'){
                            imageTag = 'grapejs'
                        }else{
                            imageTag = tagValue[0].value
                        }
                    }

                    for (let file of e?.target?.files ?? []) {
                        let fd = new FormData();
                        fd.append("image", file);
                        fd.append("ImageName", file.name);
                        if(!imageTag){
                            imageTag = 'grapejs'
                        }
                        fd.append("tag", `${imageTag}`);
                        await axios({
                            method: "POST",
                            url: `${backendModule.backendURL}/images/uploadImage`,
                            data: fd,

                            ...backendModule.axiosConfig,
                            headers: { "Content-Type": "multipart/form-data" }
                        }).then((res) => {
                            if (res.data.status === "ok") {
                                getAssetManagerImages();
                            };
                        }).catch(() => null);
                    };
                    setSpinner(false);
                },
            },
            plugins: [
                grapejsTuImageEditor,
                grapejsBlocksBasic,
                grapejsStyleGradient,
                grapejsStyleFilter,
                grapesjsStyleBg,
                grapesjsForms,
                grapesjsPresetWebpage,
                grapesjsExporter,
                grapesjsCodeEditor,
                grapesjsScssParser,
                await customPlugins()
            ],
            pluginsOpts: {
                'grapesjs-tui-image-editor': {
                    config: {
                        includeUI: {
                            initMenu: 'Filters',
                        },
                    },
                }
            },
            storageManager: {
                type: "",
                autoload: 0
            },
            blockManager: {
                custom: true
            },
            commands: {
                defaults: [
                    {
                        id: 'modify-text-with-chatgpt',
                        run: function (editor, sender, data) {
                            var component = editor.getSelected();
                            modifyTextWithChatGPT(component)
                        }
                    },
                    {
                        id: 'reformat-text-with-chatgpt',
                        run: function (editor, sender, data) {
                            var component = editor.getSelected();
                            reformatTextWithChatGPT(component)
                        }
                    }
                ]
            }
        };

        if (curParams.id) {
            axios({
                method: "POST",
                url: `${backendModule.backendURL}/websites/getWebsiteByID`,
                data: {
                    ID: curParams.id
                },
                ...backendModule.axiosConfig
            }).then(res => {
                if (res.data.status === "ok") {
                    editorRef.current = grapesjs.init(grapeOptions);
                    editorRef.current.on("load", () => {
                        if (!res.data.data.JSON) {
                            grapeOptions["components"] = res.data.data.HTML;
                            grapeOptions["styles"] = res.data.data.CSS;
                            editorRef.current.setComponents(res.data.data.HTML);
                            editorRef.current.setStyle(res.data.data.CSS);
                        } else {
                            editorRef.current.loadProjectData(res.data.data.JSON);
                        };

                        updateAllComponents(editorRef.current, scrollonclick_trait);
                        updateAllComponents(editorRef.current, scrollonclickDouble_trait);
                        editorRef.current.getComponents().forEach(component => component?.view?.render?.());
                    });
                    editDataRef.current = res.data.data;
                    postInit();
                } else {
                    animateBox({ currentTarget: document.querySelector(".component__header__right__btn") }, <ModalError error="There was an error while loading website data!" />);
                };
            }).catch(() => {
                animateBox({ currentTarget: document.querySelector(".component__header__right__btn") }, <ModalError error="Server timed out!" />);
            });
        } else {
            editorRef.current = grapesjs.init(grapeOptions);
            postInit();
        };

        return () => {
            getWebsiteData = () => null;
            setWebsiteData = () => null;
            openSaveDialog = () => null;
            openTemplateDialog = () => null;
            openFileDialog = () => null;
        };
    };

    React.useEffect(() => {
        initEditor();
    }, [mainRef]);

    return <div ref={mainRef} className="route__createWebiste">
        <div className="route__createWebisteDialog__wrap__spinner" style={{
            opacity: loadingSpinner ? 1 : 0,
            pointerEvents: loadingSpinner ? "all" : "none",
            zIndex: 99999
        }} onClick={e => {
            if (loadingSpinner) e.stopPropagation();
        }}>
            <p style={{ display: 'block' }}>Loading, please be patient...</p>
            <Spinner color="white" />
        </div>
        <div className="route__createWebisteDialog__wrap__spinner" style={{
            opacity: spinner ? 1 : 0,
            pointerEvents: spinner ? "all" : "none",
            zIndex: 99999
        }} onClick={e => {
            if (spinner) e.stopPropagation();
        }}>
            <Spinner color="white" />
        </div>
        <div className="route__createWebiste__inner"></div>
    </div>
};

const SaveWebsite = props => {
    const [spinner, setSpinner] = React.useState(false);
    const [users, setUsers] = React.useState();
    const [offers, setOffers] = React.useState();
    const [country, setCountry] = React.useState(props?.data?.Country ?? undefined);
    const [article, setArticle] = React.useState(props?.data?.Article ?? undefined);
    const [selectedUser, setSelectedUser] = React.useState(props?.data?.UserID ?? undefined);
    const [infoP, setInfoP] = React.useState("");
    const [currTemplates, setCurrTemplates] = React.useState([])
    const [selectedTemplate, setSelectedTemplate] = React.useState([]);
    const [formType, setFormType] = React.useState(null);
    const [approveWebsite, setApproveWebsite] = React.useState(false);

    const [targetUrl, setTargetUrl] = React.useState('')

    const [currSaved, setCurrSaved] = React.useState(false);

    const curDispatch = useDispatch();

    const currentURL = window.location.href;

    const [currScaleLeadUIDs, setCurrScaleLeadUIDs] = React.useState([
        { name: 'Muhamed - ScaleLead MS', value: 'ScaleLead MS' },
        { name: 'Esef - ScaleLead EC', value: 'ScaleLead EC' },
        { name: 'Mediha - ScaleLead MJ', value: 'ScaleLead MJ' },
        { name: 'Almerisa - ScaleLead AS', value: 'ScaleLead AS' },
        { name: 'Enes - ScaleLead EH', value: 'ScaleLead EH' },
        { name: 'Aldina - 3.gratis CIT 2', value: '3.gratis CIT 2' },
        { name: 'Aldina - 3. GRATIS CIT2', value: '3. GRATIS CIT2' }
    ])
    const [selectedLeadUID, setSelectedLeadUID] = React.useState([]);

    const [scaleLeadOffers, setScaleLeadOffers] = React.useState([]);
    const [selectedScaleLeadOffer, setSelectedScaleLeadOffer] = React.useState([]);

    const curUserState = useSelector(state => state?.userData?.userData?.UserInfo ?? {});

    const nameRef = React.useRef();

    const getUsers = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/getAllUsers`,
            ...backendModule.axiosConfig
        }).then(res => {
            setUsers(res.data);
        }).catch(() => {
            setUsers({ status: "error", data: "SERVER_ERROR" });
        });
    };

    const getAllTemplatesForSelectedOffer = async () => {
        if (!country || !article) return;

        if (!offers) return;

        let targetOfferElem = offers?.data.find(el => String(el?.Country).toLowerCase() === String(country).toLowerCase() && String(el?.OfferName).toLowerCase() === String(article).toLowerCase());

        if (!targetOfferElem) return;

        await axios({
            method: "POST",
            url: `${backendModule.backendURL}/commentsTemplate/getAllCommentsTemplate`,
            data: {
                offerID: targetOfferElem.ID
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.statusText === "OK") {

                setCurrTemplates(res.data.data)
            }
        }).catch(err => {
            console.log("ERROR :: ", err)
        })

    }

    const getAllOffersForComments = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/comments/getAllOffersForComments`,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setOffers(res.data)
            } else {
            };
        }).catch(() => {
        }).finally(() => {
            setSpinner(false);
        });
    }

    const getScaleLeadOffers = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/integrations/getOffers`,
            data: {
                limit: null
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setScaleLeadOffers(res.data.data.data)
            } else {
            };
        }).catch(() => []);
    }

    const saveData = async () => {
        setInfoP();

        let data = {
            Name: nameRef.current.value,
            Country: country,
            Article: article,
            Type: props.type,
            CommentsTemplateID: selectedTemplate ? selectedTemplate : null,
            Status: (props?.existing?.Status === 110 || props?.data?.Status === 110) ? 110 : approveWebsite ? 100 : 0,
            FormType: formType,
            UserID: selectedUser
        };

        if (props.existing && !curUserState?.Flags?.isAdmin) {
            data["Country"] = props.existing.Country;
            data["Article"] = props.existing.Article;
        };

        if (props.id) data["ID"] = props.id;
        if (props.clonedID) data["ClonedID"] = props.clonedID;

        if (!data.Name) return setInfoP("Name can't be empty");
        if (!data.Country) return setInfoP("Country can't be empty");
        if (!data.Article) return setInfoP("Article can't be empty");
        if (!data.Type) return setInfoP("Type can't be empty");

        if (!props.clonedID) {
            let finalData = await getWebsiteData();
            if (!finalData) return setInfoP("Error while getting website data");
            if (!finalData.HTML) return setInfoP("Website is empty!");

            data.HTML = finalData.HTML;
            data.CSS = finalData.CSS;
            data.JSONData = finalData.JSON;
        };

        let finalUrl = "addWebsite";
        if (props.id) finalUrl = "editWebsite";
        if (props.id && props.data.Status === 110 && approveWebsite){
            data.Status = 100;
        }else if(props.id && props.data.Status === 100){
            data.Status = 110;
        }
        if (props.clonedID) finalUrl = "cloneWebsite";
        if (props.clonedID && props.existing.Status === 100) data.Status = 110;
        if (props.clonedID && props.existing.Status === 110) data.Status = 110;
        
        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/websites/${finalUrl}`,
            data,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (props.onChange) {
                    props.onChange();
                    props.onClose();
                } else {
                    if(finalUrl === 'editWebsite' || finalUrl === 'addWebsite'){
                        setCurrSaved(true);
                        if (currentURL.includes('localhost')) {
                            setTargetUrl(`http://localhost:5000/websites/preview/${res.data.data}`)
                        } else if (currentURL.includes('scale-cms.com')) {
                            setTargetUrl(`https://backend.scale-cms.com:5000/websites/preview/${res.data.data}`)
                        }
                    }else{
                        props.onClose();
                    }
                };
                curDispatch(toggleSiteSaved());
            } else {
                setInfoP(`Error while ${props.id ? "editing" : (props.clonedID ? "cloning" : "adding")} website!`);
            };
        }).catch(() => {
            setInfoP("Server timed out");
        }).finally(() => {
            setSpinner(false);
        });
    };

    React.useEffect(() => {
        getUsers();
    }, []);

    React.useEffect(() => {
        getScaleLeadOffers();
    }, []);

    React.useEffect(() => {
        getAllOffersForComments();
    }, []);

    React.useEffect(() => {
        getAllTemplatesForSelectedOffer();
    }, [country, article]);

    return <div className="route__createWebisteDialog">
        <div className="route__createWebisteDialog__wrap">
            <div className="route__createWebisteDialog__wrap__spinner" style={{
                opacity: spinner ? 1 : 0,
                pointerEvents: spinner ? "all" : "none"
            }} onClick={e => {
                if (spinner) e.stopPropagation();
            }}>
                <Spinner color="white" />
            </div>

            <h3 style={{ marginBottom: "20px" }}>{props.clonedID ? "Clone" : "Save"} website</h3>
            <CustomInput autocomplete="off" defaultValue={props?.data?.Name ? props?.data?.Name : props?.existing?.Name ? props?.existing?.Name : ''} ref={nameRef} theme="dark" placeholder="Name (Natura BA LP 12...)" accent="#FFF" style={{ width: "100%" }} />

            {(curUserState?.Flags?.isAdmin && users?.status === "ok") && <Dropdown
                theme="dark"
                accent="#FFF"
                style={{ marginTop: "20px" }}
                inlinePlaceholder="Responsible person"
                data={users.data.map(usr => {
                    return { name: usr.Username, value: usr.ID };
                })}
                onChange={e => e?.value && setSelectedUser(e?.value)}
                selected={(() => {
                    if (users?.status !== "ok") return null;
                    return users.data.indexOf(users.data.find(u => u.ID === selectedUser));
                })()}
            />}

            {((curUserState?.Flags?.isAdmin || curUserState?.Flags?.isSuperiorCloner || curUserState?.Flags?.isCopywriter) || !props.existing) && <>
                {offers?.status === "ok" && <Dropdown theme="dark" inlinePlaceholder="Article" accent="#FFF" style={{ marginTop: "20px" }} data={offers.data.map(art => {
                    return { name: `${art.OfferName} (${art.Country})`, value: art.OfferName, country: art.Country };
                })} onChange={e => { setArticle(e?.value); setCountry(e.country) }} selected={offers.data.indexOf(offers.data.find(o => (o.OfferName === article && o.Country === country)))} />}
                <Dropdown theme="dark" inlinePlaceholder="Country" accent="#FFF" style={{ marginTop: "20px" }} data={countryList.map(c => {
                    return { name: c.name, value: c.code };
                })} onChange={e => setCountry(e?.value)} selected={countryList.indexOf(countryList.find(c => c.code === country))} />
            </>}
            {((curUserState?.Flags?.isAdmin || curUserState?.Flags?.isSuperiorCloner || curUserState?.Flags?.isCopywriter) && props.existing && currTemplates.length) ? <>
                <Dropdown theme="dark" inlinePlaceholder="Select Template For Comments" accent="#FFF" style={{ marginTop: "20px" }} data={currTemplates.map(template => {
                    return { name: template.Name, value: template.ID };
                })} onChange={e => setSelectedTemplate(e?.value)} selected={currTemplates.indexOf(selectedTemplate)} />
            </> : null}
            {/* {(curUserState?.Flags?.isAdmin || curUserState?.Flags?.isSuperiorCloner) ? <>
                <Dropdown theme="dark" inlinePlaceholder="Scalelead Offer" accent="#FFF" style={{ marginTop: "20px" }} data={scaleLeadOffers.map(item => {
                    return { name: item.TakenOfferName, value: item.ID };
                })} onChange={e => setSelectedScaleLeadOffer(e?.value)} selected={scaleLeadOffers.indexOf(selectedScaleLeadOffer)} />
            </> : null}
            {(curUserState?.Flags?.isAdmin || curUserState?.Flags?.isSuperiorCloner) ? <>
                <Dropdown theme="dark" inlinePlaceholder="Select Lead UID (Name suffix in CRM)" accent="#FFF" style={{ marginTop: "20px" }} data={currScaleLeadUIDs.map(item => {
                    return { name: item.name, value: item.value };
                })} onChange={e => setSelectedLeadUID(e?.value)} selected={currScaleLeadUIDs.indexOf(selectedLeadUID)} />
            </> : null} */}
            <div className="route__createWebisteDialog__wrap__choices">
                <p className={`${formType === 'OpenForm' ? 'selected' : ''}`} onClick={() => {setFormType('OpenForm')}}>Open Form</p>
                <p className={`${formType === 'ClosedForm' ? 'selected' : ''}`} onClick={() => {setFormType('ClosedForm')}}>Closed Form</p>
            </div>
            {(curUserState?.Flags?.isAdmin || curUserState?.Flags?.isCopywriter) && <p style={{display: "flex", alignItems: "center", gap: "10px", margin: "10px 0"}}>
                <Checkbox checked={approveWebsite} onChange={e => approveWebsite !== e && setApproveWebsite(!!e)} />
                Approve this website
            </p>}

            <div className="route__createWebisteDialog__wrap__btns">
                {currSaved ? <a href={targetUrl} target="_blank" style={{display: 'flex', alignItems: 'center', color: 'white', textDecoration:'none'}}>
                    <img style={{ marginRight: "15px" }} src="/images/staticWebsiteIcon.png" />
                    <span>View Website</span>
                </a> : <p onClick={saveData}>
                    <img style={{ marginRight: "15px" }} src="/images/saveBtn.png" />
                    <span>Save</span>
                </p>}
                <p onClick={props.onClose}>
                    <span>Close</span>
                    <img style={{ marginLeft: "15px" }} src="/images/closeBtn.png" />
                </p>
            </div>

            {infoP && <p className="route__createWebisteDialog__wrap__infoP">{infoP}</p>}
        </div>
    </div>
};

const ModalError = (props) => {
    return <div className="route__createWebisteDialog">
        <div className="route__createWebisteDialog__wrap">
            <h3 style={{ marginBottom: "10px" }}>Error</h3>
            <p style={{ marginBottom: "20px" }}>{props.error}</p>
            <div className="route__createWebisteDialog__wrap__btns">
                <p onClick={() => {
                    props.onClose().then(() => {
                        if (props.avoidClose) return;
                        window.close();
                    });
                }}>
                    <img style={{ marginRight: "15px" }} src="/images/closeBtn.png" />
                    <span>Close</span>
                </p>
            </div>
        </div>
    </div>
};

let curTimeout = null;
const OpenTemplateModal = (props) => {
    const [data, setData] = React.useState();
    const [filters, setFilters] = React.useState([]);
    const [spinner, setSpinner] = React.useState(false);

    const mainRef = React.useRef();
    const searchRef = React.useRef();

    const getTemplates = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/websites/getAllWebsites`,
            data: {
                filters: [
                    ...filters,
                    { name: "Type", op: "eq", value: "template" }
                ],
                limit: undefined,
                orders: [{ name: "createdAt", order: "desc" }]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setData(res.data);
            } else {
                setData({ status: "error", data: "SERVER_ERROR" });
            };
        }).catch(() => {
            setData({ status: "error", data: "SERVER_ERROR" });
        });
    };

    const selectWebsite = id => {
        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/websites/getWebsiteByID`,
            data: {
                ID: id
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setWebsiteData(res.data.data.HTML, res.data.data.CSS);
                props.onClose();
            } else {
                animateBox({
                    currentTarget: mainRef?.current?.querySelector(".route__openTemplateModal__wrap__btns p")
                }, <ModalError error="Error while fetching website" avoidClose={true} />);
            };
        }).catch(() => {
            animateBox({
                currentTarget: mainRef?.current?.querySelector(".route__openTemplateModal__wrap__btns p")
            }, <ModalError error="Server timed out" avoidClose={true} />);
        }).finally(() => {
            setSpinner(false);
        });
    };

    React.useEffect(() => {
        if (!searchRef.current) return;

        let handler = () => {
            let searchVal = searchRef?.current?.value;
            clearTimeout(curTimeout);
            curTimeout = setTimeout(() => {
                setFilters([{ name: "--searcher", value: searchVal }])
            }, 500);
        };

        searchRef.current.addEventListener("input", handler);

        return () => {
            try {
                searchRef.current.removeEventListener("input", handler);
            } catch { };
        };
    }, [searchRef.current]);

    React.useEffect(() => {
        setData();
        getTemplates();
    }, [filters]);

    return <div className="route__openTemplateModal" ref={mainRef}>
        <div className="route__openTemplateModal__wrap">
            <div className="route__openTemplateModal__wrap__spinner" style={{
                opacity: spinner ? 1 : 0,
                pointerEvents: spinner ? "all" : "none"
            }} onClick={e => {
                if (spinner) e.stopPropagation();
            }}>
                <Spinner accent="#3F7CEA" />
            </div>

            <div className="route__openTemplateModal__wrap__heading" style={{ marginBottom: "20px" }}>
                <h3>Load template</h3>
                <div className="route__openTemplateModal__wrap__btns">
                    <p onClick={() => {
                        props.onClose();
                    }}>
                        <span>Close</span>
                        <img style={{ marginLeft: "15px" }} src="/images/closeBtn.png" />
                    </p>
                </div>
            </div>

            <CustomInput autocomplete="off" ref={searchRef} accent="#3F7CEA" theme="dark" placeholder="Search..." style={{ width: "100%" }} />
            <div className="route__openTemplateModal__wrap__templates">
                {!data ? <Spinner /> : <>
                    {data.status === "error" ? <p>There was an error while getting templates!</p> : <>
                        {data.data.length === 0 && <p>No templates found</p>}
                        {data.data.map((elem) => {
                            return <LandingItem
                                key={`${elem.ID}-${elem.updatedAt}`}
                                data={elem}
                                buttons={<>
                                    <Button value="Select template" accent="rgb(63, 124, 234)" style={{ width: "150px" }} onClick={e => {
                                        e.stopPropagation();
                                        selectWebsite(elem.ID);
                                    }} />
                                </>}
                            />
                        })}
                    </>}
                </>}
            </div>

        </div>
    </div>
};

const RecoverWebsite = (props) => {
    const [curKeys, setCurKeys] = React.useState();
    const [selectedKey, setSelectedKey] = React.useState();
    const [spinner, setSpinner] = React.useState(false);

    React.useEffect(() => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/backups/getAllBackups`,
            ...backendModule.axiosConfig
        }).then(res => {
            setCurKeys(res.data);
        }).catch(() => {
            setCurKeys({ status: "error", data: "GENERIC_ERROR" });
        });
    }, []);

    const performRecovery = () => {
        if (!selectedKey) return;

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/backups/getBackupData`,
            data: {
                ID: selectedKey
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setWebsiteProjectData(res.data.data);
                return props.onClose();
            };

            alert("Error while recovering, got wrong data");
        }).catch(() => {
            alert("Server timed out!");
        }).finally(() => {
            setSpinner(false);
        });
    };

    if (spinner) return <div className="route__createWebisteDialog">
        <div className="route__createWebisteDialog__wrap">
            <h3>Recovering...</h3>

            <Spinner color="white" />
        </div>
    </div>

    return <div className="route__createWebisteDialog">
        <div className="route__createWebisteDialog__wrap">

            <h3>Recover</h3>

            {curKeys ? <>
                {curKeys.status === "ok" ? <>
                    <Dropdown
                        theme="dark"
                        accent="#FFF"
                        style={{ marginTop: "20px" }}
                        data={curKeys.data.map(key => {
                            return { name: moment(key.createdAt).toDate().toLocaleString(), value: key.ID }
                        })}
                        onChange={e => setSelectedKey(e?.value)}
                    />
                </> : <p>Error while fetching backups!</p>}
            </> : <Spinner color="white" />}

            {/* <Dropdown
                theme="dark"
                accent="#FFF"
                style={{ marginTop: "20px" }}
                data={curKeys.map(ck => { return { name: moment(ck).toDate().toLocaleString(), value: ck } })}
                onChange={e => setSelectedKey(e?.value)}
            /> */}

            <div className="route__createWebisteDialog__wrap__btns">
                <p onClick={performRecovery}>
                    <img style={{ marginRight: "15px" }} src="/images/saveBtn.png" />
                    <span>Recover</span>
                </p>
                <p onClick={props.onClose}>
                    <span>Close</span>
                    <img style={{ marginLeft: "15px" }} src="/images/closeBtn.png" />
                </p>
            </div>

        </div>
    </div>
};

export default CreateWebsite;
export { getWebsiteData, openSaveDialog, openTemplateDialog, openFileDialog, SaveWebsite, openRecoverDialog }