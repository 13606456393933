import React from "react";
import "./index.scss";

import axios from "axios";
import { useSelector } from "react-redux";
import * as backendModule from "../../modules/backendModule";
import { animateBox } from "../../modules/componentAnimation";

import CustomInput from "../../components/customComponents/CustomInput";
import CustomCheckbox from "../../components/customComponents/CustomCheckbox";
import { FilteredCustomTable } from "../../components/customComponents/Table";
import Spinner from "../../components/customComponents/Spinner";

let curTimeout = null;
const CronTasks = () => {
    

    return <div className="route__cronTasks">
        
    </div>
};


export default CronTasks;