import gjs from "grapesjs";

function script() {
    if (!this) return;
    if (this.getAttribute("data-gjs-type")) return;

    let button = this.querySelector('.scalecms-articleBox-button');
    let scrollTo = this.getAttribute('scroll-to');

    button.onclick = () => {
        let elem = document.querySelector(`.${scrollTo}`);
        if (elem) {
            elem.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
        }
    }

}

/**
 * @param {gjs.Editor} editor
 */
export default editor => {
    editor.Components.addType('scalecms-articleBox', {
        isComponent: e => e?.classList?.contains("scalecms-articleBox"),
        model: {
            defaults: {
                script,
                type: "default",
                traits: [
                    { name: "scroll-to", type: "Scroll to", placeholder: "scalecms-forma" },
                ],
                attributes: {
                    class: "scalecms-articleBox"
                },
                styles: `
                    .scalecms-articleBox{
                        display: grid;
                        grid-template-columns: 1fr;
                        grid-row-gap: 10px;
                        border-radius: 25px;
                        width: 350px;
                        box-shadow: 0 0 8px gray;
                        background: white;
                        padding-bottom: 10px;
                        font-family: 'Arial';
                        margin-left: auto;
                        margin-right: auto;
                        margin-top: 20px;
                    }
                    .scalecms-articleBox-button{
                        width: 80%;
                        height: 2rem;
                        font-weight: 700;
                        background: #ec622f;
                        color: white;
                        border: none;
                        cursor: pointer;
                        margin-left: auto;
                        margin-right: auto;
                        display: block;
                        font-size: 18px;
                        border-radius: 5px;
                    }
                    .scalecms-articleBox-image{
                        width: 100%;
                        height: 100%;
                        margin-left: auto;
                        margin-right: auto;
                        border-radius: 25px 25px 0 0;
                    }

                    .scalecms-articleBox-description{
                        padding-left: 10px;
                        padding-right: 10px;
                    }

                    .scalecms-articleBox-heading{
                        font-weight: 700;
                        padding-left: 10px;
                        padding-right: 10px;
                        font-size: 22px;
                    }

                    .scalecms-articleBox-oldPrice{
                        background: #c50c17;
                        padding: 10px;
                        color: white;
                        font-weight: 700;
                        border-radius: 5px;
                        box-shadow: 0 0 4px gray;
                        min-width: 100px;
                        margin-left: auto;
                        margin-right: -25px;
                        display: block;
                        text-align: center;
                        text-decoration: line-through;
                        font-size: 15px;
                        font-style: italic;
                    }

                    .scalecms-articleBox-price{
                        background: #11e95b;
                        padding: 10px;
                        color: white;
                        font-weight: 700;
                        border-radius: 5px;
                        box-shadow: 0 0 4px gray;
                        min-width: 100px;
                        margin-left: auto;
                        margin-right: -25px;
                        display: block;
                        text-align: center;
                        font-size: 20px;
                    }

                    @media screen and (max-width: 600px){
                        .scalecms-articleBox{
                            width: 90%;
                        }
                        .scalecms-articleBox-oldPrice{
                            margin-right: 5px;
                        }
                        .scalecms-articleBox-price{
                            margin-right: 5px;
                        }
                    }
                `,
                components: [
                    {
                        type: 'image',
                        attributes: { class: 'scalecms-articleBox-image' }
                    },
                    {
                        type: 'text',
                        attributes: { class: 'scalecms-articleBox-heading' },
                        components: 'NAZIV PROIZVODA'
                    },
                    {
                        type: 'text',
                        attributes: { class: 'scalecms-articleBox-description' },
                        components: 'Opis proizvoda...'
                    },
                    {
                        type: 'text',
                        attributes: { class: 'scalecms-articleBox-oldPrice' },
                        components: '0,00KM',
                    },
                    {
                        type: 'text',
                        attributes: { class: 'scalecms-articleBox-price' },
                        components: '0,00KM',
                    },
                    {
                        type: 'button',
                        attributes: { class: 'scalecms-articleBox-button' },
                        components: 'NARUČI'
                    }
                ]
            }
        }
    });


    editor.BlockManager.add('scalecms-articleBox-blk', {
        label: 'Article box',
        content: {
            type: "scalecms-articleBox",
        },
        category: {
            label: 'Custom assets'
        },
        media: `
        <svg version="1.1" id="Layer_1" x="0px" y="0px" width="92px" height="92px" viewBox="0 0 92 92" enable-background="new 0 0 92 92" xml:space="preserve">
        <path id="XMLID_1210_" d="M76,2H16c-2.2,0-4,1.8-4,4v80c0,2.2,1.8,4,4,4h60c2.2,0,4-1.8,4-4V6C80,3.8,78.2,2,76,2z M72,82H20V10h52
            V82z M30,67.5c0-1.9,1.6-3.5,3.5-3.5h23.8c1.9,0,3.5,1.6,3.5,3.5S59.3,71,57.3,71H33.5C31.6,71,30,69.4,30,67.5z M30,53.5
            c0-1.9,1.6-3.5,3.5-3.5h23.8c1.9,0,3.5,1.6,3.5,3.5S59.3,57,57.3,57H33.5C31.6,57,30,55.4,30,53.5z M61,24.5c0-1.9-1.6-3.5-3.5-3.5
            h-24c-1.9,0-3.5,1.6-3.5,3.5v14c0,1.9,1.6,3.5,3.5,3.5h24c1.9,0,3.5-1.6,3.5-3.5V24.5z M37,28h17v7H37V28z" style="fill: rgb(255, 255, 255);"></path>
        </svg>
        `,
        attributes: {
            title: 'Article box',
        },
        select: true
    });
};