import gjs from "grapesjs";

function script() {

  let scrollToBtn = document.querySelector(".scroll-to");
  let scrollTarget = document.querySelector(".scroll-target");
  if(!scrollTarget) scrollTarget = document.querySelector(".scalelead-form");

  scrollToBtn.addEventListener('click', () => {
    scrollTarget.scrollIntoView({ behavior: 'smooth' });
  });

  eval(``)
};

/**
 * @param {gjs.Editor} editor
 */
export default (editor, offers) => {
  editor.Components.addType('scroll-to_cmp', {
    isComponent: e => e?.classList?.contains("scroll-to"),
    model: {
      init() {
        this.on("change", e => {
          // let selectedForm = e?.attributes?.attributes?.['select-form'];
          // let scaleLeadForm = document.querySelector(".scalelead-form");
          // let scaleCRMForm = document.querySelector(".scalecrm-form");

          // console.log("ooooo", document)


          // switch (selectedForm){
          //   case 'scaleLeadForm':
          //     scaleCRMForm.style.display = 'none';
          //   break;
          //   case 'scaleCrmForm':
          //     scaleLeadForm.style.display = 'none';
          //   break;
          // }

        });
      },
      defaults: {
        type: "default",
        traits: [
          {
            name: "select-form", type: "select", options: [
              { id: "scaleLeadForm", name: "Scale Lead Form", selected: true },
              { id: "scaleCrmForm", name: "Scale CRM Form" },
            ], value: "generic1"
          },
        ],
        attributes: {
          class: "scroll-to"
        },
        script,
        styles: `
          .scroll-to{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            max-width: 200px;
            max-width: 140px;
            border-radius: 10px 0 0 10px;
            background: linear-gradient(180deg,#adfbb9 0%,#e4fbad 100%),#e4fbad;
            position: fixed;
            bottom: 40px;
            right: 0;
            cursor: pointer;
          }
          .up-arrow-svg {
            display: flex;
            justify-content: center;
            align-items: center;
          }

        `,
        components: [
          {
            type: 'text',
            attributes: { class: 'up-arrow-svg' },
            content: '<svg height="50" width="100" xmlns="http://www.w3.org/2000/svg"><polyline points="0,25 100,25 55,35 100,25 55,15 " style="fill:none;stroke:green;stroke-width:3" /></svg>',
          },
        ]
      }
    }
  });


  editor.BlockManager.add('scroll-to_blk', {
    label: 'Scroll To',
    content: {
      type: "scroll-to_cmp",
    },
    category: {
      label: 'Custom assets'
    },
    media: "<img src='/images/component_form.png' style='width: 100%; height: auto; filter: invert(1) hue-rotate(180deg);' />",
    attributes: {
      title: 'Insert an Open Form block',
    },
    select: true
  });
};