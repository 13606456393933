import gjs from "grapesjs";

function script() {
    if (!this) return;
}

/**
 * @param {gjs.Editor} editor
 */
export default editor => {
    editor.Components.addType('scalecms-footerThree', {
        isComponent: e => e?.classList?.contains("scalecms-footerThree"),
        model: {
            defaults: {
                type: "default",
                traits: [

                ],
                attributes: {
                    class: "scalecms-footerThree"
                },
                script,
                styles: `
                    .scalecms-footerThree {
                        width: 100%;
                        background: #808080ad;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        padding: 10px;
                        font-family: 'Arial';
                        color: white;
                    }
                    .scalecms-footerThree-links{
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-evenly;
                        width: 60%;
                        cursor: pointer;
                        margin-top: 10px;
                    }

                    @media screen and (max-width: 1300px){
                        .scalecms-footerThree-links{
                            width: 100%;
                        }
                    }
                    @media screen and (max-width: 600px){
                        .scalecms-footerThree-links{
                            flex-direction: column;
                        }
                        .scalecms-footerThree-links-item{
                            margin-top: 5px;
                        }
                    }
                `,
                components: [
                    {
                        type: 'text',
                        attributes: {class: 'scalecms-footerThree-copyright'},
                        components: 'Sva prava zdržana &copy;'
                    },
                    {
                        tagName: 'div',
                        attributes: {class: 'scalecms-footerThree-links'},
                        components: [
                            {
                                type: 'text',
                                attributes: {class: 'scalecms-footerThree-links-item'},
                                components: 'kontakt'
                            },
                            {
                                type: 'text',
                                attributes: {class: 'scalecms-footerThree-links-item'},
                                components: 'politika privatnosti'
                            },
                            {
                                type: 'link',
                                attributes: {class: 'scalecms-footerThree-links-item'},
                                components: 'odricanje odgovornosti'
                            },
                            {
                                type: 'text',
                                attributes: {class: 'scalecms-footerThree-links-item'},
                                components: 'o nama'
                            },
                        ]
                    }
                ]
            }
        }
    });


    editor.BlockManager.add('scalecms-footerThree-blk', {
        label: 'Footer 3',
        content: {
            type: "scalecms-footerThree",
        },
        category: {
            label: 'Footers'
        },
        media: `<svg viewBox="0 0 24 24"><g data-name="layout 35"><path d="M19,2H5A3,3,0,0,0,2,5V19a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V5A3,3,0,0,0,19,2Zm1,17a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V5A1,1,0,0,1,5,4H19a1,1,0,0,1,1,1Z"/><path d="M17,14H7a2,2,0,0,0-2,2v1a2,2,0,0,0,2,2H17a2,2,0,0,0,2-2V16A2,2,0,0,0,17,14Zm0,3H7V16H17Z"/></g></svg>`,
        attributes: {
            title: 'Basic Footer 3',
        },
        select: true
    });
};