import gjs from "grapesjs";

function script() {
    if(!this) return;

    const hamburger = this.querySelector('.scalecms-headerFive-hamburger');
    const navbar = this.querySelector('.scalecms-headerFive-navbar');
    if (!hamburger || !navbar) return;

    hamburger.addEventListener('click', () => {
        if(!navbar.classList.contains('active')){
            navbar.style.display = 'flex';
            navbar.classList.add('active');
        }
        else {
            navbar.style.display = 'none';
            navbar.classList.remove('active');
        }
    });
}

/**
 * @param {gjs.Editor} editor
 */
export default editor => {
    editor.Components.addType('scalecms-headerFive', {
        isComponent: e => e?.classList?.contains("scalecms-headerFive"),
        model: {
            defaults: {
                type: "default",
                traits: [

                ],
                attributes: {
                    class: "scalecms-headerFive"
                },
                script,
                styles: `
                    .scalecms-headerFive {
                        width: 100%;
                        display: flex; 
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        padding-left: 310px;
                        padding-right: 310px;
                        font-family: 'Arial';
                        padding-top: 10px;
                        padding-bottom: 10px;
                        background: #f7f8f9;
                    }
                    .scalecms-headerFive-logo{
                        width: 200px;
                        height: 100px;
                    }

                    .scalecms-headerFive-navbar{
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-end;
                    }

                    .scalecms-headerFive-navbar-item{
                        margin-left: 10px;
                        text-transform: uppercase;
                        color: #5e5b5b;
                        cursor: pointer;
                    }
                    .scalecms-headerFive-navbar-item:hover{
                        color: black;
                        transition: 400ms;
                    }
                    .scalecms-headerFive-hamburger{
                        display: none;
                    }
                    @media screen and (max-width: 1400px){
                        .scalecms-headerFive{
                            padding-left: 20px;
                            padding-right: 20px;
                        }
                    }
                    @media screen and (max-width: 700px){
                        .scalecms-headerFive-navbar{
                            display: none;
                            position: absolute;
                            flex-direction: column;
                            background: #f7f8f9;
                            width: 100%;
                            top: 70px;
                            left: 0;
                            padding-left: 10px;
                        }
                        .scalecms-headerFive-hamburger{
                            display: block;
                            width: 20px;
                            height: 20px;
                        }
                        .scalecms-headerFive-logo{
                            width: 50px;
                            height: 50px;
                        }
                        .scalecms-headerFive-navbar-item{
                            margin-bottom: 10px;
                        }
                    }
                `,
                components: [
                    {
                        type: 'image',
                        attributes: {class: 'scalecms-headerFive-logo'}
                    },
                    {
                        tagName: 'img',
                        attributes: {class: 'scalecms-headerFive-hamburger', src: 'https://freesvg.org/img/menu-icon.png'}
                    },
                    {
                        tagName: 'div',
                        attributes: {class: 'scalecms-headerFive-navbar'},
                        components: [
                            {
                                type: 'link',
                                attributes: {class: 'scalecms-headerFive-navbar-item'},
                                components: 'nav-item'
                            },
                            {
                                type: 'link',
                                attributes: {class: 'scalecms-headerFive-navbar-item'},
                                components: 'nav-item'
                            },
                            {
                                type: 'link',
                                attributes: {class: 'scalecms-headerFive-navbar-item'},
                                components: 'nav-item'
                            },
                            {
                                type: 'link',
                                attributes: {class: 'scalecms-headerFive-navbar-item'},
                                components: 'nav-item'
                            },
                            {
                                type: 'link',
                                attributes: {class: 'scalecms-headerFive-navbar-item'},
                                components: 'nav-item'
                            },
                        ]
                    }
                ]
            }
        }
    });


    editor.BlockManager.add('scalecms-headerFive-blk', {
        label: 'Header 5',
        content: {
            type: "scalecms-headerFive",
        },
        category: {
            label: 'Headers'
        },
        media: `<svg viewBox="0 0 24 24"><g data-name="layout 34"><path d="M19,2H5A3,3,0,0,0,2,5V19a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V5A3,3,0,0,0,19,2Zm1,17a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V5A1,1,0,0,1,5,4H19a1,1,0,0,1,1,1Z"/><path d="M17,5H7A2,2,0,0,0,5,7V8a2,2,0,0,0,2,2H17a2,2,0,0,0,2-2V7A2,2,0,0,0,17,5Zm0,3H7V7H17Z"/></g></svg>`,
        attributes: {
            title: 'Basic header 5',
        },
        select: true
    });
};