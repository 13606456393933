import React from "react";
import "./index.scss";


const DocTimers = (props) => {

  return <div className="route__documentation">
    <div className="route__documentation__headline">
      <p>Timers</p>
    </div>
    <div className="route__documentation__desc">
      <p>Timers are essential to the landing pages</p>
      <p>Currently we have various designs of timers</p>
    </div>
    <div className="route__documentation__introBlock">
      <div className="route__documentation__introBlock__left">
        <img src="/images/tutorials/timers/DocTimersStep1.png" />
      </div>
      <div className="route__documentation__introBlock__right">
        <p>Firstly we have to click on the '+' icon to open the side menu dropdown</p>
        <p>Then we have to select the 'Timers' tab in the menu</p>
        <p>After we select the Timers, we're offered with different options of Timers</p>
        <p>In example we will use all templates to demonstrate.</p>
      </div>
    </div>
    <div className="route__documentation__introBlock flex-column">
      <div className="route__documentation__introBlock__right">
        <p>These are all our timers from 1 to 8 in numerical order</p>
      </div>
      <div className="route__documentation__introBlock__left">
        <img src="/images/tutorials/timers/DocTimersStep2.png" />
      </div>
    </div>

    <div className="route__documentation__introBlock">
      <div className="route__documentation__introBlock__left">
        <img src="/images/tutorials/timers/DocTimersStep3.png" />
      </div>
      <div className="route__documentation__introBlock__right">
        <p>This is component settings of each timer, representing an input of how many minutes are left for the client do order something</p>
      </div>
    </div>




  </div>
};

export default DocTimers;
export { DocTimers };