import gjs from "grapesjs";

function script() {
    if (!this) return;
}

/**
 * @param {gjs.Editor} editor
 */
export default editor => {
    editor.Components.addType('scalecms-footer', {
        isComponent: e => e?.classList?.contains("scalecms-footer"),
        model: {
            defaults: {
                type: "default",
                traits: [

                ],
                attributes: {
                    class: "scalecms-footer"
                },
                script,
                styles: `
                    .scalecms-footer {
                        width: 100%;
                        background: #021859;
                        padding: 20px;
                    }
                    .scalecms-footer-box{
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        margin-left: auto;
                        margin-right: auto;
                        width: fit-content;
                    }
                    .scalecms-footer-logo{
                        max-width: 360px;
                        max-height: 130px;
                        width: 100px;
                        height: 50px;
                        margin-bottom: 10px;
                    }
                    .scalecms-footer-sitename{
                        color: white;
                        margin-bottom: 10px;
                        font-size: 25px;
                    }
                    .scalecms-footer-link{
                        margin-bottom: 10px;
                        color: white;
                    }
                    .scalecms-footer-copyright{
                        color: white;
                    }
                `,
                components: [
                    {
                        tagName: "div",
                        attributes: { class: "scalecms-footer-box" },
                        components: [
                            {
                                type: "image",
                                attributes: {class: "scalecms-footer-logo"}
                            },
                            {
                                type: "text",
                                attributes: {class: "scalecms-footer-sitename"},
                                components: "NAZIV STRANICE"
                            },
                            {
                                type: "link",
                                components: `Odricanje odgovornosti`,
                                attributes: {
                                    href: "#",
                                    class: "scalecms-footer-link"
                                },
                                draggable: false,
                                removable: false,
                                copyable: false
                            },
                            {
                                type: "text",
                                attributes: {class: "scalecms-footer-copyright"},
                                components: "Sva prava zadržana 2022 &#169;"
                            }
                        ]
                    }
                ]
            }
        }
    });


    editor.BlockManager.add('scalecms-footer-blk', {
        label: 'Footer 1',
        content: {
            type: "scalecms-footer",
        },
        category: {
            label: 'Footers'
        },
        media: `<svg viewBox="0 0 24 24"><g data-name="layout 35"><path d="M19,2H5A3,3,0,0,0,2,5V19a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V5A3,3,0,0,0,19,2Zm1,17a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V5A1,1,0,0,1,5,4H19a1,1,0,0,1,1,1Z"/><path d="M17,14H7a2,2,0,0,0-2,2v1a2,2,0,0,0,2,2H17a2,2,0,0,0,2-2V16A2,2,0,0,0,17,14Zm0,3H7V16H17Z"/></g></svg>`,
        attributes: {
            title: 'Basic footer',
        },
        select: true
    });
};