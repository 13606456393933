import gjs from "grapesjs";

function script() {
    if(!this) return;

    const hamburger = this.querySelector('.scalecms-headerTwo-hamburger');
    const navbar = this.querySelector('.scalecms-headerTwo-navbar');
    if (!hamburger || !navbar) return;

    hamburger.addEventListener('click', () => {
        if(!navbar.classList.contains('active')){
            navbar.style.display = 'flex';
            navbar.classList.add('active');
        }
        else {
            navbar.style.display = 'none';
            navbar.classList.remove('active');
        }
    });
}

/**
 * @param {gjs.Editor} editor
 */
export default editor => {
    editor.Components.addType('scalecms-headerTwo', {
        isComponent: e => e?.classList?.contains("scalecms-headerTwo"),
        model: {
            defaults: {
                type: "default",
                traits: [

                ],
                attributes: {
                    class: "scalecms-headerTwo"
                },
                script,
                styles: `
                    .scalecms-headerTwo {
                        width: 100%;
                        height: fit-content;
                        display: flex;
                        flex-direction: column;
                        background: #333333;
                        padding: 10px;
                        position: relative;
                        font-family: 'Arial';
                    }
                    .scalecms-headerTwo-hamburger{
                        display: none;
                    }
                    .scalecms-headerTwo-logo{
                        max-width: 370px;
                        width: 370px;
                        margin-left: auto;
                        margin-right: auto;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;
                    }

                    .scalecms-headerTwo-logo-siteName{
                        font-size: 25px;
                        color: white;
                        margin-left: 5px;
                    }

                    .scalecms-headerTwo-logo img{
                        max-width: 50px;
                        width: 50px;
                        height: 50px
                    }
                    @media screen and (max-width: 600px){
                        .scalecms-headerTwo-logo{
                            width: 90%;
                        }
                    }
                    .scalecms-headerTwo-navbar{
                        display: flex; 
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-evenly;
                        height: 50px;
                    }

                    @keyframes animateNavbar{
                        0%{
                            max-height: 0px;
                        }
                        100%{
                            max-height: 1000px;
                        }
                    }

                    @media screen and (max-width: 600px){
                        .scalecms-headerTwo-navbar{
                            display: none;
                            position: absolute;
                            top: 40px;
                            left: 0px;
                            flex-direction: column;
                            justify-content: baseline;
                            align-items: baseline;
                            background: #333333;
                            width: 100%;
                            height: fit-content;
                            padding: 10px;
                            overflow: hidden;
                            animation: animateNavbar 1s linear;
                        }
                        .scalecms-headerTwo-logo-siteName{
                            font-size: 20px;
                        }
                    }
                    .scalecms-headerTwo-navbar-navitem{
                        color: white;
                        cursor: pointer;
                    }
                    @media screen and (max-width: 600px){
                        .scalecms-headerTwo-hamburger{
                            display: block;
                            width: 20px;
                            height: 20px;
                        }
                    }
                    .scalecms-headerTwo-socials{
                        position: absolute;
                        top: 20px;
                        right: 20px;
                    }
                    .scalecms-headerTwo-socials-img{
                        width: 20px;
                        height: 20px;
                        margin-left: 10px;
                    }
                    @media screen and (max-width: 600px){
                        .scalecms-headerTwo-socials{
                            top: 10px;
                            right: 10px;
                        }
                        .scalecms-headerTwo-navbar-navitem{
                            margin-bottom: 10px;
                        }
                    }
                `,
                components: [
                    {
                        tagName: 'img',
                        attributes: {class: 'scalecms-headerTwo-hamburger', src: 'https://i.imgur.com/boqY7Lz.png'},
                    },
                    {
                        tagName: 'div',
                        attributes: {class: 'scalecms-headerTwo-socials'},
                        components: [
                            {
                                tagName: 'img',
                                attributes: {class: 'scalecms-headerTwo-socials-img', src: 'https://i.imgur.com/OWdUupI.png'}
                            },
                            {
                                tagName: 'img',
                                attributes: {class: 'scalecms-headerTwo-socials-img', src: 'https://i.imgur.com/yh45vCH.png'}
                            }
                        ]
                    },
                    {
                        tagName: "div",
                        attributes: {class: "scalecms-headerTwo-logo"},
                        components:[
                            {
                                type: "image",
                                attributes: {src: '#'}
                            },
                            {
                                type: 'text',
                                attributes: {class: 'scalecms-headerTwo-logo-siteName'},
                                components: 'NAZIV STRANICE'
                            }
                        ]
                    },
                    {
                        tagName: "div",
                        attributes: {class: "scalecms-headerTwo-navbar"},
                        components: [
                            {
                                type: "text",
                                attributes: {class: "scalecms-headerTwo-navbar-navitem"},
                                components: "nav-item"
                            },
                            {
                                type: "text",
                                attributes: {class: "scalecms-headerTwo-navbar-navitem"},
                                components: "nav-item"
                            },
                            {
                                type: "text",
                                attributes: {class: "scalecms-headerTwo-navbar-navitem"},
                                components: "nav-item"
                            },
                            {
                                type: "text",
                                attributes: {class: "scalecms-headerTwo-navbar-navitem"},
                                components: "nav-item"
                            },
                            {
                                type: "text",
                                attributes: {class: "scalecms-headerTwo-navbar-navitem"},
                                components: "nav-item"
                            },
                            {
                                type: "text",
                                attributes: {class: "scalecms-headerTwo-navbar-navitem"},
                                components: "nav-item"
                            },
                        ]
                    },
                ]
            }
        }
    });


    editor.BlockManager.add('scalecms-headerTwo-blk', {
        label: 'Header 2',
        content: {
            type: "scalecms-headerTwo",
        },
        category: {
            label: 'Headers'
        },
        media: `<svg viewBox="0 0 24 24"><g data-name="layout 34"><path d="M19,2H5A3,3,0,0,0,2,5V19a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V5A3,3,0,0,0,19,2Zm1,17a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V5A1,1,0,0,1,5,4H19a1,1,0,0,1,1,1Z"/><path d="M17,5H7A2,2,0,0,0,5,7V8a2,2,0,0,0,2,2H17a2,2,0,0,0,2-2V7A2,2,0,0,0,17,5Zm0,3H7V7H17Z"/></g></svg>`,
        attributes: {
            title: 'Basic header 2',
        },
        select: true
    });
};