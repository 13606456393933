import gjs from "grapesjs";

/**
 * @param {gjs.Editor} editor
 */
export default editor => {
    editor.Components.addType('scalecms-customBox', {
        isComponent: e => e?.classList?.contains("scalecms-customBox"),
        model: {
            defaults: {
                type: "default",
                attributes: {
                    class: "scalecms-customBox"
                },
                styles: `
                    .scalecms-customBox{
                        width: 350px;
                        min-height: fit-content;
                        padding-top: 20px;
                        padding-left: 20px;
                        padding-right: 20px;
                        padding-bottom: 0px;
                        border-radius: 10px;
                        box-shadow: 0 0 4px gray;
                        font-family: 'Arial';
                        margin-left: auto;
                        margin-right: auto;
                        margin-top: 20px;
                    }
                    .scalecms-customBox-heading{
                        font-size: 25px;
                        font-weight: 700;
                    }
                    .scalecms-customBox-text{
                        font-size: 16px;
                        margin-top: 20px;
                    }
                    .scalecms-customBox-image{
                        display: block;
                        margin-left: auto;
                        margin-right: 0;
                        width: 100px;
                        height: 100px;
                        border-radius: 0 0 10px 0px;
                        margin-right: -20px;
                        margin-top: 20px;
                    }

                    @media screen and (max-width: 600px){
                        .scalecms-customBox{
                            width: 95%;
                        }
                        .scalecms-customBox-heading{
                            font-size: 22px;
                            font-weight: 700;
                        }
                        .scalecms-customBox-text{
                            font-size: 14px;
                            margin-top: 20px;
                        }
                    }
                `,
                components: [
                    {
                        type: 'text',
                        attributes: {class: 'scalecms-customBox-heading'},
                        components: 'Lorem Ispum'
                    },
                    {
                        type: 'text',
                        attributes: {class: 'scalecms-customBox-text'},
                        components: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin ornare felis ac velit semper, non ullamcorper tortor dapibus. Donec nisi dui, malesuada vitae sapien sed, luctus laoreet est. '
                    },
                    {
                        type: 'image',
                        attributes: {class: 'scalecms-customBox-image'},
                    }
                ]
            }
        }
    });


    editor.BlockManager.add('scalecms-customBox-blk', {
        label: 'Custom box',
        content: {
            type: "scalecms-customBox",
        },
        category: {
            label: 'Custom assets'
        },
        media: `
        <svg id="Layer_1" data-name="Layer 1" viewBox="0 0 512 512"><title>Package</title><polygon points="378.776 95.246 176.107 180.58 256 214.219 458.669 128.885 378.776 95.246" id="id_101" style="fill: rgb(255, 255, 255);"></polygon><path d="M351.286,83.671,260.141,45.294a10.673,10.673,0,0,0-8.281,0L53.331,128.885l95.286,40.12Z" id="id_102" style="fill: rgb(255, 255, 255);"></path><path d="M266.667,232.877V467.542l196.141-82.586a10.67,10.67,0,0,0,6.526-9.831V147.543Z" id="id_103" style="fill: rgb(255, 255, 255);"></path><path d="M245.333,232.877,42.667,147.543V375.125a10.67,10.67,0,0,0,6.526,9.831l196.141,82.586Z" id="id_104" style="fill: rgb(255, 255, 255);"></path></svg>
        `,
        attributes: {
            title: 'Custom box',
        },
        select: true
    });
};