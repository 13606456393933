import gjs from "grapesjs";

/**
 * @param {gjs.Editor} editor
 */
export default editor => {
    editor.Components.addType('scalecms-smallBoxes', {
        isComponent: e => e?.classList?.contains("scalecms-smallBoxes"),
        model: {
            defaults: {
                type: "default",
                attributes: {
                    class: "scalecms-smallBoxes"
                },
                traits: [
                    { name: "num-of-cols", type: "num-of-cols", placeholder: "4", value: "4" },
                ],
                styles: `
                    .scalecms-smallBoxes{
                        width: 100%;
                        height: fit-content;
                        padding: 10px;
                        background: black;
                        font-family: 'Arial';
                    }
                    .scalecms-smallBoxes-gridbox{
                        width: 1200px;
                        display: grid;
                        grid-template-columns: 1fr 1fr 1fr 1fr;
                        height: fit-content;
                        margin-left: auto;
                        margin-right: auto;
                        gap: 10px;
                    }
                    .scalecms-smallBoxes-gridbox-item{
                        background: #282727;
                        color: white;
                        padding: 10px;
                        border-radius: 5px;
                    }

                    @media screen and (max-width: 1200px){
                        .scalecms-smallBoxes-gridbox{
                            height: fit-content;
                            grid-template-columns: 1fr 1fr;
                            width: 100%;
                        }
                        .scalecms-smallBoxes-gridbox-item{
                            width: 100%;
                        }
                    }

                    @media screen and (max-width: 600px){
                        .scalecms-smallBoxes-gridbox{
                            grid-template-columns: 1fr;
                        }
                    }
                `,
                components: [
                    {
                        tagName: 'div',
                        attributes: {class: 'scalecms-smallBoxes-gridbox'},
                        components: [
                            {
                                tagName: 'div',
                                attributes: {class: 'scalecms-smallBoxes-gridbox-item'},
                                components: {
                                    type: 'text',
                                    components: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce accumsan porta tincidunt. Maecenas vel laoreet ipsum. Phasellus sagittis sed mi sit amet interdum.'
                                }
                            },
                            {
                                tagName: 'div',
                                attributes: {class: 'scalecms-smallBoxes-gridbox-item'},
                                components: {
                                    type: 'text',
                                    components: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce accumsan porta tincidunt. Maecenas vel laoreet ipsum. Phasellus sagittis sed mi sit amet interdum.'
                                }
                            },
                            {
                                tagName: 'div',
                                attributes: {class: 'scalecms-smallBoxes-gridbox-item'},
                                components: {
                                    type: 'text',
                                    components: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce accumsan porta tincidunt. Maecenas vel laoreet ipsum. Phasellus sagittis sed mi sit amet interdum.'
                                }
                            },
                            {
                                tagName: 'div',
                                attributes: {class: 'scalecms-smallBoxes-gridbox-item'},
                                components: {
                                    type: 'text',
                                    components: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce accumsan porta tincidunt. Maecenas vel laoreet ipsum. Phasellus sagittis sed mi sit amet interdum.'
                                }
                            },
                        ]
                    }
                ]
            }
        }
    });


    editor.BlockManager.add('scalecms-smallBoxes-blk', {
        label: 'Small boxes',
        content: {
            type: "scalecms-smallBoxes",
        },
        category: {
            label: 'Custom assets'
        },
        media: `
        <svg version="1.1" id="Layer_3" x="0px" y="0px" viewBox="0 0 64 64" style="enable-background:new 0 0 64 64;" xml:space="preserve">
        <g>
        <rect x="39" y="27" width="5" height="2" id="id_101" style="fill: rgb(255, 255, 255);"></rect>
        <rect x="39" y="23" width="5" height="2" id="id_102" style="fill: rgb(255, 255, 255);"></rect>
        <rect x="24" y="53" width="5" height="2" id="id_103" style="fill: rgb(255, 255, 255);"></rect>
        <rect x="24" y="49" width="5" height="2" id="id_104" style="fill: rgb(255, 255, 255);"></rect>
        <path d="M62,31H48V6c0-0.552-0.447-1-1-1H17c-0.553,0-1,0.448-1,1v25H2c-0.553,0-1,0.448-1,1v26c0,0.552,0.447,1,1,1h30h30
                c0.553,0,1-0.448,1-1V32C63,31.448,62.553,31,62,31z M50,33v6.586l-0.293-0.293c-0.391-0.391-1.023-0.391-1.414,0L47,40.586
                l-1.293-1.293c-0.391-0.391-1.023-0.391-1.414,0L44,39.586V33H50z M29,7h6v6.586l-0.293-0.293c-0.391-0.391-1.023-0.391-1.414,0
                L32,14.586l-1.293-1.293c-0.391-0.391-1.023-0.391-1.414,0L29,13.586V7z M18,7h9v9c0,0.404,0.243,0.769,0.617,0.924
                s0.804,0.069,1.09-0.217L30,15.414l1.293,1.293c0.391,0.391,1.023,0.391,1.414,0L34,15.414l1.293,1.293
                C35.484,16.898,35.74,17,36,17c0.129,0,0.259-0.025,0.383-0.076C36.757,16.769,37,16.404,37,16V7h9v24h-9v-9
                c0-0.404-0.243-0.769-0.617-0.924s-0.804-0.069-1.09,0.217L34,22.586l-1.293-1.293c-0.391-0.391-1.023-0.391-1.414,0L30,22.586
                l-1.293-1.293c-0.286-0.286-0.716-0.371-1.09-0.217C27.243,21.231,27,21.596,27,22v9h-9V7z M14,33h6v6.586l-0.293-0.293
                c-0.391-0.391-1.023-0.391-1.414,0L17,40.586l-1.293-1.293c-0.391-0.391-1.023-0.391-1.414,0L14,39.586V33z M20,57h-6v-6.586
                l0.293,0.293c0.391,0.391,1.023,0.391,1.414,0L17,49.414l1.293,1.293c0.391,0.391,1.023,0.391,1.414,0L20,50.414V57z M31,57h-9v-9
                c0-0.404-0.243-0.769-0.617-0.924c-0.373-0.155-0.804-0.069-1.09,0.217L19,48.586l-1.293-1.293c-0.391-0.391-1.023-0.391-1.414,0
                L15,48.586l-1.293-1.293c-0.286-0.286-0.715-0.371-1.09-0.217C12.243,47.231,12,47.596,12,48v9H3V33h9v9
                c0,0.404,0.243,0.769,0.617,0.924c0.375,0.155,0.804,0.069,1.09-0.217L15,41.414l1.293,1.293c0.391,0.391,1.023,0.391,1.414,0
                L19,41.414l1.293,1.293C20.484,42.898,20.74,43,21,43c0.129,0,0.259-0.025,0.383-0.076C21.757,42.769,22,42.404,22,42v-9h9V57z
                 M29,31v-6.586l0.293,0.293c0.391,0.391,1.023,0.391,1.414,0L32,23.414l1.293,1.293c0.391,0.391,1.023,0.391,1.414,0L35,24.414V31
                H29z M50,57h-6v-6.586l0.293,0.293c0.391,0.391,1.023,0.391,1.414,0L47,49.414l1.293,1.293c0.391,0.391,1.023,0.391,1.414,0
                L50,50.414V57z M61,57h-9v-9c0-0.404-0.243-0.769-0.617-0.924c-0.373-0.155-0.803-0.069-1.09,0.217L49,48.586l-1.293-1.293
                c-0.391-0.391-1.023-0.391-1.414,0L45,48.586l-1.293-1.293c-0.286-0.286-0.715-0.371-1.09-0.217C42.243,47.231,42,47.596,42,48v9
                h-9V33h9v9c0,0.404,0.243,0.769,0.617,0.924s0.804,0.069,1.09-0.217L45,41.414l1.293,1.293c0.391,0.391,1.023,0.391,1.414,0
                L49,41.414l1.293,1.293C50.484,42.898,50.74,43,51,43c0.129,0,0.259-0.025,0.383-0.076C51.757,42.769,52,42.404,52,42v-9h9V57z" id="id_105" style="fill: rgb(255, 255, 255);"></path>
        <rect x="54" y="53" width="5" height="2" id="id_106" style="fill: rgb(255, 255, 255);"></rect>
        <rect x="54" y="49" width="5" height="2" id="id_107" style="fill: rgb(255, 255, 255);"></rect>
        </g>
        </svg>
        `,
        attributes: {
            title: 'Small boxes',
        },
        select: true
    });
};