import gjs from "grapesjs";


/**
 * @param {gjs.Editor} editor
 */
export default editor => {
    editor.Components.addType('scalecms-twoColumnsCustomizable', {
        isComponent: e => e?.classList?.contains("scalecms-twoColumnsCustomizable"),
        model: {
            init(){
                this.on('change', e => {
                    let responsive = e?.attributes?.attributes?.responsive;
                    this.get("components").each(child => {
                        if(child?.attributes?.tagName === 'style'){
                            child.components( responsive === true ? 
                            `
                                @media screen and (max-width: 600px){
                                    .scalecms-twoColumnsCustomizable{
                                        flex-direction: column;
                                    }
                                    .scalecms-twoColumnsCustomizable-right{
                                        width: 100% !important;
                                    }
                                    .scalecms-twoColumnsCustomizable-left{
                                        width: 100% !important;
                                    }
                                }
                            `
                            : '');
                        }
                    })
                });
            },
            defaults: {
                droppable: false,
                type: "default",
                traits: [
                    { name: "responsive", type:"checkbox"},
                ],
                attributes: {
                    class: "scalecms-twoColumnsCustomizable"
                },
                styles: `
                    .scalecms-twoColumnsCustomizable {
                        width: 100%;
                        height: auto;
                        min-height: 40px;
                        display: flex;
                        flex-wrap: nowrap;
                    }
                    .scalecms-twoColumnsCustomizable-left, .scalecms-twoColumnsCustomizable-right {
                        display: block;
                        width: 50%;
                        height: auto;
                        min-height: 40px;
                        flex-grow: 0;
                        flex-shink: 0;
                    }
                `,
                components: [
                    {
                        tagName: "div",
                        attributes: {class: "scalecms-twoColumnsCustomizable-left"}
                    },
                    {
                        tagName: "div",
                        attributes: {class: "scalecms-twoColumnsCustomizable-right"}
                    },
                    {
                        tagName: "style",
                        attributes: {class: "scalecms-twoColumnsCustomizable-style"}
                    }
                ]
            }
        }
    });


    editor.BlockManager.add('scalecms-twoColumnsCustomizable-blk', {
        label: '2 columns customizable',
        content: {
            type: "scalecms-twoColumnsCustomizable",
        },
        category: {
            label: 'Layout'
        },
        media: `<svg viewBox="0 0 23 24"><path fill="currentColor" d="M2 20h8V4H2v16Zm-1 0V4a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1ZM13 20h8V4h-8v16Zm-1 0V4a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1h-8a1 1 0 0 1-1-1Z"></path></svg>`,
        attributes: {
            title: 'Basic 2 columns customizable block',
        },
        select: true
    });
};