import React from "react";
import "./index.scss";

import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import * as backendModule from "../../../modules/backendModule";
import { updateTimestamp } from "../../../actions/timestampActions";
import { animateBox } from "../../../modules/componentAnimation";
import animateModule from "../../../modules/animateModule";

import { RenameCreativeItem, RemoveCreativeItem } from "../CreativeItems";
import Spinner from "../../../components/customComponents/Spinner";
import Button from "../../../components/customComponents/Button";

const CreativeItem = () => {
    const [data, setData] = React.useState();

    const curParams = useParams();
    const curNavigate = useNavigate();
    const curLocation = useLocation();
    const curDispatch = useDispatch();

    const timestampSelector = useSelector(state => state?.timestamp ?? null);
    const userSelector = useSelector(state => state?.userData?.userData?.UserInfo ?? {});

    const animateNavigate = to => animateModule(curNavigate, to, document.querySelector(".component__mainContent"));

    const goBack = () => {
        let finalLocation = curLocation.pathname.split("/");
        finalLocation.pop();
        animateNavigate(finalLocation.join("/"));
    };

    const getData = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/creatives/item/getCreativeByID`,
            data: {
                ID: curParams.id
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setData(res.data);
            } else {
                setData({status: "error", data: "SERVER_ERROR"});
            };
        }).catch(() => {
            setData({status: "error", data: "SERVER_ERROR"});
        });
    };

    React.useEffect(() => {
        getData();
    }, [timestampSelector]);

    return <div className="route__creativeSingle">
        {data ? <>
            {data.status === "ok" ? <>
                <div className="route__creativeSingle__top">
                    <div className="route__creativeSingle__top__left">
                        <img src={data.data.Image} />
                    </div>
                    <div className="route__creativeSingle__top__right">
                        <h3>{data.data.Name}</h3>

                        <div className="route__creativeSingle__top__right__btns">
                            <Button theme="dark" accent="rgb(63, 124, 234)" value="View image" onClick={(e) => {
                                let w = window.open("", "_blank");
                                w.document.write("<style>*{margin: 0; padding:0; box-sizing: border-box;}</style>")
                                w.document.write(`<div style="display: flex; justify-content: center; align-items: center; width: 100%; height: auto; min-height: 100vh; background-color: #20262d;"><img src="${data.data.Image}" /></div>`);
                                w.document.close();
                            }} />
                            {(userSelector?.Flags?.isAdmin || userSelector?.ID === data.data.UserID) && <Button theme="dark" accent="rgb(63, 124, 234)" value="Rename" onClick={(e) => {
                                animateBox(e, <RenameCreativeItem data={data.data} onChange={() => curDispatch(updateTimestamp())} />);
                            }} />}
                            {(userSelector?.Flags?.isAdmin || userSelector?.ID === data.data.UserID) && <Button theme="dark" accent="rgb(234, 63, 63)" value="Remove" onClick={e => {
                                animateBox(e, <RemoveCreativeItem data={data.data} onChange={goBack} />);
                            }} />}
                        </div>
                    </div>
                </div>

                <div className="route__creativeSingle__bottom">
                    {(userSelector?.Flags?.isAdmin || userSelector?.ID === data.data.UserID) && <div className="route__creativeSingle__bottom__itemAdd" onClick={e => {
                        animateBox(e, <AddText data={data.data} onChange={getData} />)
                    }}>
                        + Add text
                    </div>}
                    {data.data.Text?.map((item, index) => {
                        return <div className="route__creativeSingle__bottom__item">
                            <div className="route__creativeSingle__bottom__item__top">
                                {item}
                            </div>
                            <div className="route__creativeSingle__bottom__item__bottom">
                                {(userSelector?.Flags?.isAdmin || userSelector?.ID === data.data.UserID) && <Button accent="rgb(234, 63, 63)" theme="dark" value="Remove" style={{height: "30px"}} onClick={e => {
                                    animateBox(e, <RemoveText data={data.data} TextID={index} onChange={getData} />)
                                }} />}
                            </div>
                        </div>
                    })}
                </div>
            </> : <p>There was an error while fetching creative item!</p>}
        </> : <Spinner color="#3F7CEA" align="center" />}
    </div>
};

const AddText = props => {
    const [spinner, setSpinner] = React.useState(false);
    const [infoP, setInfoP] = React.useState("");

    const textRef = React.useRef();

    const addText = () => {
        setInfoP("");

        const data = {
            ID: props.data.ID,
            Text: textRef.current.value
        };

        if (!data.Text) return setInfoP("Text can't be empty!");

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/creatives/item/addTextCreative`,
            data,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                props.onClose();
                props.onChange();
            } else {
                setInfoP("Error while adding text");
            };
        }).catch(() => {
            setInfoP("Server timed out.");
        }).finally(() => {
            setSpinner(false);
        });
    };

    return <div className="route__creativeSingle__addText">
        <div className="route__creativeSingle__addText__wrap">
            <div className="route__creativeSingle__addText__wrap__spinner" style={{
                opacity: spinner ? 1 : 0,
                pointerEvents: spinner ? "all" : "none"
            }} onClick={e => spinner && e.stopPropagation()}></div>

            <h3 style={{marginBottom: "20px"}}>Add text</h3>
            <textarea ref={textRef}>

            </textarea>

            <div className="route__creativeSingle__addText__wrap__btns">
                <p onClick={addText}>
                    <img style={{ marginRight: "15px" }} src="/images/saveBtn.png" />
                    <span>Save</span>
                </p>
                <p onClick={props.onClose}>
                    <span>Close</span>
                    <img style={{ marginLeft: "15px" }} src="/images/closeBtn.png" />
                </p>
            </div>

            {infoP && <p className="route__creativeSingle__addText__wrap__infoP">{infoP}</p>}
        </div>
    </div>
};

const RemoveText = props => {
    const [spinner, setSpinner] = React.useState(false);
    const [infoP, setInfoP] = React.useState("");

    const removeText = () => {
        setInfoP("");

        const data = {
            ID: props.data.ID,
            TextID: props.TextID
        };

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/creatives/item/removeTextCreative`,
            data,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                props.onClose();
                props.onChange();
            } else {
                setInfoP("Error while removing text");
            };
        }).catch(() => {
            setInfoP("Server timed out.");
        }).finally(() => {
            setSpinner(false);
        });
    };

    return <div className="route__creativeSingle__addText">
        <div className="route__creativeSingle__addText__wrap">
            <div className="route__creativeSingle__addText__wrap__spinner" style={{
                opacity: spinner ? 1 : 0,
                pointerEvents: spinner ? "all" : "none"
            }} onClick={e => spinner && e.stopPropagation()}></div>

            <h3 style={{marginBottom: "20px"}}>Remove text</h3>
            <p>Are you sure?</p>
            <p>Removal is irreversible!</p>

            <div className="route__creativeSingle__addText__wrap__btns">
                <p onClick={removeText}>
                    <img style={{ marginRight: "15px" }} src="/images/saveBtn.png" />
                    <span>Save</span>
                </p>
                <p onClick={props.onClose}>
                    <span>Close</span>
                    <img style={{ marginLeft: "15px" }} src="/images/closeBtn.png" />
                </p>
            </div>

            {infoP && <p className="route__creativeSingle__addText__wrap__infoP">{infoP}</p>}
        </div>
    </div>
};

export default CreativeItem;