import React from "react";
import "./index.scss";

import axios from "axios";
import moment from "moment";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import * as backendModule from "../../../modules/backendModule";
import countryList from "../../../modules/countryList.json";

import { FilteredCustomTable } from "../../../components/customComponents/Table";
import CustomInput from "../../../components/customComponents/CustomInput";

let curTimeout = null;
const CampaignDisallows = () => {
    const curParams = useParams();
    const [data, setData] = React.useState();
    const [filters, setFilters] = React.useState([]);
    const [dateFilters, setDateFilters] = React.useState("all")
    const [canPaginate, setCanPaginate] = React.useState(false);
    const [secondarySpinner, setSecondarySpinner] = React.useState(false);

    const searchRef = React.useRef();
    const paginationOffset = React.useRef();
    const curPaginationTimestamp = React.useRef();

    const siteSettingsSelector = useSelector(state => state?.siteSettings ?? {});
    const timestampSelector = useSelector(state => state?.timestamp);

    const parseDateFilter = (cDate) => {
        switch (cDate) {
            case "all":
                return [];
            case "today":
                return [{name: "createdAt", op: "deq", value: moment().endOf("day")}];
            case "yesterday":
                return [{name: "createdAt", op: "deq", value: moment().add(-1, "days").endOf("day")}];
            case "7days":
                return [{name: "createdAt", op: "dgeq", value: moment().add(-7, "days").endOf("day")}];
            case "30days":
                return [{name: "createdAt", op: "dgeq", value: moment().add(-30, "days").endOf("day")}];
            case "60days":
                return [{name: "createdAt", op: "dgeq", value: moment().add(-60, "days").endOf("day")}];
            default: return [];
        };
    };

    const getData = () => {
        paginationOffset.current = 0;
        curPaginationTimestamp.current = Date.now();

        setCanPaginate(false);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaignInfo/getAllDisallows`,
            data: {
                offset: paginationOffset.current,
                filters: [
                    ...filters,
                    ...parseDateFilter(dateFilters)
                ],
                orders: [{ name: "createdAt", order: "desc" }],
                CampaignID: curParams.id
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (res.data.data.length === 20) {
                    paginationOffset.current += 20;
                    setTimeout(() => setCanPaginate(true), 500);
                } else {
                    setCanPaginate(false);
                    paginationOffset.current = -1;
                };

                return setData({ ...res.data, timestamp: Date.now() });
            }
            return setData({ status: "error", data: "SERVER_ERROR", timestamp: Date.now() });
        }).catch(() => {
            return setData({ status: "error", data: "SERVER_ERROR", timestamp: Date.now() });
        });
    };

    const continueData = (timestamp) => {
        if (paginationOffset.current === -1) {
            if (timestamp !== curPaginationTimestamp.current) return;
            if (canPaginate) setCanPaginate(false);
            return;
        };

        setSecondarySpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaignInfo/getAllDisallows`,
            data: {
                offset: data?.data?.length ?? 0,
                filters: [
                    ...filters,
                    ...parseDateFilter(dateFilters)
                ],
                orders: [{ name: "createdAt", order: "desc" }],
                CampaignID: curParams.id
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestamp !== curPaginationTimestamp.current) return;
            if (res.data.status === "ok") {
                if (res.data.data.length === 20) {
                    paginationOffset.current += 20;
                    setTimeout(() => setCanPaginate(true));
                } else {
                    setCanPaginate(false);
                    paginationOffset.current = -1;
                };
                return setData(old => {
                    return {
                        ...old,
                        data: [
                            ...old.data,
                            ...res.data.data
                        ], timestamp: Date.now()
                    };
                });
            }
            return setData({ status: "error", data: "SERVER_ERROR", timestamp: Date.now() });
        }).catch(() => {
            return setData({ status: "error", data: "SERVER_ERROR", timestamp: Date.now() });
        }).finally(() => {
            if (timestamp !== curPaginationTimestamp.current) return;
            setSecondarySpinner(false);
        });
    };

    const PaginationData = () => {
        let tmpRef = React.useRef();
        React.useEffect(() => {
            if (!tmpRef?.current) return;
            let observer = null;
            try {
                let observer = new IntersectionObserver((entries) => {
                    entries.forEach(entry => {
                        if (entry.intersectionRatio > 0) {
                            try { observer.unobserve(tmpRef.current); } catch { };
                            if (canPaginate) {
                                continueData(curPaginationTimestamp.current);
                            };
                        };
                    });
                }, { threshold: [1] });
                observer.observe(tmpRef.current);
            } catch { };

            return () => {
                if (tmpRef?.current) {
                    try { observer.unobserve(tmpRef.current); } catch { };
                };
            };
        }, [tmpRef]);

        return <div ref={tmpRef}></div>;
    };

    React.useEffect(() => {
        if (!searchRef.current) return;

        let handler = () => {
            let searchVal = searchRef?.current?.value;
            clearTimeout(curTimeout);
            curTimeout = setTimeout(() => {
                setFilters([{or: [
                    {name: "CountryCode", op: "like", value: searchVal},
                    {name: "City", op: "like", value: searchVal},
                    {name: "DeviceType", op: "like", value: searchVal},
                    {name: "DeviceName", op: "like", value: searchVal},
                    {name: "UserAgent", op: "like", value: searchVal},
                    {name: "IP", op: "like", value: searchVal},
                    {name: "RejectReason", op: "like", value: searchVal}
                ]}]);
            }, 500);
        };

        searchRef.current.addEventListener("input", handler);

        return () => {
            try {
                searchRef.current.removeEventListener("input", handler);
            } catch {};
        };
    }, [searchRef.current]);

    React.useEffect(() => {
        getData();
    }, [curParams, filters, dateFilters, timestampSelector]);

    React.useEffect(() => {
        if (filters?.length > 0) setFilters([]);
        if (dateFilters !== "all") setDateFilters("all");
    }, [siteSettingsSelector?.advancedSearch]);

    return <div className="route__campaigns__disallows" style={{paddingTop: "20px"}}>
        {!siteSettingsSelector?.advancedSearch &&
            <>
                <CustomInput autocomplete="off" ref={searchRef} theme="dark" accent="#3F7CEA" placeholder="Search..." />
                <div className="route__campaignsList__dateFilter">
                    <span className="route__campaignsList__dateFilter__head">Date: </span>
                    <div onClick={() => setDateFilters("all")} className={dateFilters === "all" ? "route__campaignsList__dateFilter__item--active" : ""}><span>All</span></div>
                    <div onClick={() => setDateFilters("today")} className={dateFilters === "today" ? "route__campaignsList__dateFilter__item--active" : ""}><span>Today</span></div>
                    <div onClick={() => setDateFilters("yesterday")} className={dateFilters === "yesterday" ? "route__campaignsList__dateFilter__item--active" : ""}><span>Yesterday</span></div>
                    <div onClick={() => setDateFilters("7days")} className={dateFilters === "7days" ? "route__campaignsList__dateFilter__item--active" : ""}><span>Last 7 days</span></div>
                    <div onClick={() => setDateFilters("30days")} className={dateFilters === "30days" ? "route__campaignsList__dateFilter__item--active" : ""}><span>Last 30 days</span></div>
                    <div onClick={() => setDateFilters("60days")} className={dateFilters === "60days" ? "route__campaignsList__dateFilter__item--active" : ""}><span>Last 60 days</span></div>
                </div>
            </>
        }
        <FilteredCustomTable
            accent="#48515C"
            theme="dark"
            headers={["IP", "Country", "City", "Device type", "Device name", "Date"]}
            filterCB={f => setFilters(f)}
            style={{width: "100%"}}
            filters={siteSettingsSelector.advancedSearch ? [
                {name: "ID", friendlyName: "ID", type: "string"},
                {name: "CountryCode", friendlyName: "Country", type: "custom", data: countryList.map(c => {
                    return {text: c.name, value: c.code}
                }), varType: "string"},
                {name: "City", friendlyName: "City", type: "string"},
                {name: "UserAgent", friendlyName: "User agent", type: "string"},
                {name: "DeviceType", friendlyName: "Device type", type: "custom", data: [
                    {text: "Desktop", value: "desktop"},
                    {text: "TV", value: "TV"},
                    {text: "Tablet", value: "tablet"},
                    {text: "Phone", value: "phone"},
                    {text: "Car", value: "car"},
                    {text: "Bot", value: "Bot"},
                ], varType: "string"},
                {name: "DeviceName", friendlyName: "Device name", type: "string"},
                {name: "RejectReason", friendlyName: "Reject reason", type: "string"}
            ] : undefined}
            data={(() => {
                if (!data) return [[{ keyID: "noData-spinner", type: "spinner" }]];
                if (data.status === "error") return [[{ keyID: "noData-Error", type: "custom", data: <p>There was an error while fetching data!</p> }]];

                let tmp = data.data.map(elem => {
                    let curCountry = countryList.find(c => c.code.toLowerCase() === elem.CountryCode?.toLowerCase());
                    if (curCountry) curCountry = curCountry.name; else curCountry = "?";
                    return [
                        { keyID: elem.ID, type: "text", text: elem.IP ? elem.IP : "?" },
                        { keyID: elem.ID, type: "text", text: curCountry },
                        { keyID: elem.ID, type: "text", text: elem.City },
                        { keyID: elem.ID, type: "text", text: elem.DeviceType ?? "?" },
                        { keyID: elem.ID, type: "text", text: elem.DeviceName ? elem.DeviceName : "?" },
                        { keyID: elem.ID, type: "text", text: new Date(elem.createdAt).toLocaleString() },
                        {
                            keyID: elem.ID, type: "groupNewline", group: [
                                { keyID: elem.ID, type: "custom", data: <p className="route__campaigns__disallows__aInfo">ID: <span>{elem.ID}</span></p> },
                                { keyID: elem.ID, type: "custom", data: <p className="route__campaigns__disallows__aInfo">User-agent: <span>{elem.UserAgent}</span></p> },
                                { keyID: elem.ID, type: "custom", data: <p className="route__campaigns__disallows__aInfo">Reason: <span style={{color: "rgb(234, 145, 63)"}}>{elem.RejectReason}</span></p> },
                            ]
                        }
                    ];
                });

                if (tmp.length === 0) tmp.push([{ keyID: "noData-empty", type: "custom", data: <p>There is nothing to show.</p> }])
                if (secondarySpinner) tmp.push([{ keyID: "data-spinner", type: "spinner" }]);
                if (canPaginate) tmp.push([{ keyID: "data-pagination", type: "custom", data: <PaginationData /> }]);

                return tmp;
            })()}
        />
    </div>
};

export default CampaignDisallows;