import gjs from "grapesjs";

function script() {
  eval(`(()=>{
    console.log("called me in cupidcoast form ")
  if (!this) return;
  let formWrap = document.querySelector(".formWrap");
  let genderInput = document.querySelector(".genderSelect input");
  let genderDrop = document.querySelector(".genderSelect__drop");
  let genderSelect = document.querySelector(".genderSelect");
  let genderItems = document.querySelectorAll(".genderSelect__drop p");
  let formSteps = document.querySelectorAll(".formSteps__item");
  let nextBtn = document.querySelector(".nextBtn");
  let backBtn = document.querySelector(".backBtn");
  let formInputs = document.querySelectorAll(".formSteps input");
  let errorMsg = document.querySelector(".errorMsg");
  let dots = document.querySelectorAll(".progress__dot");
  let timer = document.querySelector(".timer");
  let registerBox = document.querySelector(".formRegistration");
  let confirmBox = document.querySelector(".formConfirmation");
  let confirmationInputs = document.querySelectorAll(".confirmForm input");
  const yearDropdown = document.getElementById('yearDropdown');
  const startYear = 2006;
  const endYear = 1950;
  let yearValue = document.querySelector(".yearValue");
  let userInfo = {
    Country: "",
    Gender: "",
    Username: "",
    Password: "",
    Email: "",
    DateOfBirth: ""
  };
  const countdown = () => {
    let time = 600;
    setInterval(() => {
      let minutes = Math.floor(time / 60);
      let seconds = time % 60;
      let min = \`\${minutes.toString().padStart(2, "0")}\`;
      let sec = \`\${seconds.toString().padStart(2, "0")}\`;
      timer.textContent = \`\${min}:\${sec}\`;
      if (time <= 0) {
        return;
      };
      time--;
    }, 1000);
  };
  const checkMail = (testMail) => {
    let regex = new RegExp("[a-z0-9!#$%&'*+/=?^_\`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_\`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?")
    return regex.test(String(testMail).toLowerCase())
  };
  window.addEventListener("click", () => {
    formWrap.style.display = "block";
    countdown();
  }, {
    once: true
  });
  genderSelect.addEventListener("click", () => {
    genderDrop.style.maxHeight = "200px";
    genderDrop.style.padding = "10px";
  });
  for (let item of genderItems) {
    item.addEventListener("click", (e) => {
      e.stopPropagation();
      genderInput.value = e.currentTarget.textContent;
      genderDrop.style.maxHeight = "";
      genderDrop.style.padding = "0";
    });
  };
  for (let year = startYear; year >= endYear; year--) {
    const yearItem = document.createElement('p');
    yearItem.textContent = year;
    yearDropdown.appendChild(yearItem);
  }
  function toggleDropdown(event) {
    const dropdownContent = event.currentTarget.nextElementSibling;
    dropdownContent.style.display = dropdownContent.style.display === 'block' ? 'none' : 'block';
  }
  // Attach click event to dropdown buttons
  document.querySelectorAll('.dropdown-button').forEach(button => {
    button.addEventListener('click', function(event) {
      // Close all dropdowns
      document.querySelectorAll('.dropdown-content').forEach(dropdown => {
        if (dropdown !== button.nextElementSibling) {
          dropdown.style.display = 'none';
        }
      });
      toggleDropdown(event);
    });
  });

  document.querySelectorAll('.dropdown-content').forEach(dropdown => {
    dropdown.addEventListener('click', function(event) {
      if (event.target.tagName === 'P') {
        const selectedValue = event.target.textContent;
        const dropdownButton = dropdown.previousElementSibling;
        dropdownButton.innerHTML = selectedValue;
        dropdown.style.display = 'none';
      }
    });
  });
  document.addEventListener('click', function(event) {
    if (!event.target.matches('.dropdown-button') && !event.target.matches('.dropdown-button *')) {
      document.querySelectorAll('.dropdown-content').forEach(dropdown => {
        dropdown.style.display = 'none';
      });
    }
  });
  const handleModal = () => {
    errorMsg.textContent = "";
    for (let [index, item] of formSteps.entries()) {
      let inputs = item.querySelectorAll("input");
      if (index === 0 && item.classList.contains("active--step")) {
        if (inputs[0].value === "") {
          errorMsg.textContent = "Please enter your country!";
          return;
        };
        if (inputs[1].value === "") {
          errorMsg.textContent = "Please select your gender!";
          return;
        };
        userInfo.Country = inputs[0].value;
        userInfo.Gender = inputs[1].value;
        item.classList.remove("active--step");
        formSteps[index + 1].classList.add("active--step");
        dots[index + 1].classList.add("active--dot");
        break;
      };
      if (index === 1 && item.classList.contains("active--step")) {
        if (inputs[0].value === "") {
          errorMsg.textContent = "Please enter your username!";
          return;
        };
        if (inputs[0].value.length < 6 || inputs[0].value.length > 64) {
          errorMsg.textContent = "Username must be between 6 and 64 characters long!";
          return;
        };
        if (inputs[1].value === "") {
          errorMsg.textContent = "Please enter your password!";
          return;
        };
        if (inputs[1].value.length < 6 || inputs[1].value.length > 64) {
          errorMsg.textContent = "Password must be between 6 and 64 characters long!";
          return;
        };
        userInfo.Username = inputs[0].value;
        userInfo.Password = inputs[1].value;
        item.classList.remove("active--step");
        formSteps[index + 1].classList.add("active--step");
        dots[index + 1].classList.add("active--dot");
        break;
      };
      if (index === 2 && item.classList.contains("active--step")) {
        if (inputs[0].value === "") {
          errorMsg.textContent = "Please enter your e-mail adress!";
          return;
        };
        let tmpMail = checkMail(inputs[0].value);
        if (tmpMail === false) {
          errorMsg.textContent = "Invalid e-mail adress!";
          return;
        };
        userInfo.Email = inputs[0].value;
        if ((Number(yearValue.textContent))) {
          userInfo.DateOfBirth = new Date(yearValue.textContent, 0, 1).getTime();
        }
        else {
          errorMsgBirthday.textContent = "Please enter your birth day";
          return;
        }
        fetch('https://cupidcoast.com/api/user/checkUserExistsAPI', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
          ,
          body: JSON.stringify({
            Username: userInfo.Username,
            Email: userInfo.Email
          })
        }).then((res) => res.json())
          .then((data) => {
          console.log("ovo je dat ", data)
          if (data.data.status === "error") {
            console.log("called me here ")
            errorMsg.textContent = "User with credentials exists";
          }
          else {
            registerBox.style.display = "none";
            confirmBox.style.display = "none";
            getData();
            naruci();
          }
        })
      };
    };
  };
  nextBtn.addEventListener("click", () => handleModal());
  backBtn.addEventListener("click", () => {
    for (let [index, item] of formSteps.entries()) {
      if (index > 0 && item.classList.contains("active--step")) {
        item.classList.remove("active--step");
        formSteps[index - 1].classList.add("active--step");
        dots[index].classList.remove("active--dot");
      };
    };
  });
  for (let item of formInputs) {
    item.addEventListener("keydown", (e) => {
      if (e.key === "Enter") {
        handleModal();
      };
    });
  };
  const getData = () => {
    for (let item of confirmationInputs) {
      switch (item.name) {
        case "Country":
          item.value = userInfo.Country;
          break;
        case "Gender":
          item.value = userInfo.Gender;
          break;
        case "Username":
          item.value = userInfo.Username;
          break;
        case "Password":
          item.value = userInfo.Password;
          break;
        case "Email":
          item.value = userInfo.Email;
          break;
        case "DateOfBirth":
          let year = yearValue.textContent;
          item.value = \`\${year}-01-01\`;
          break;
      };
    };
  };
  const sendView = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let subs = document.querySelectorAll(".form__sub");
    let subString = "?";
    const sub = [urlParams.get('sub1'),
      urlParams.get('sub2'),
      urlParams.get('sub3'),
      urlParams.get('sub4'),
      urlParams.get('sub5')
    ];
    for (let i = 0; i < sub.length - 1; i++) {
      console.log(i)
      if (subs[i]) {
        subs[i].value = sub[i] ?? 0;
      }
      if (i === sub.length - 1) {
        subString += \`?sub\${i + 1}=\${sub[i] ?? 0}\`
      }else {
        subString += \`sub\${i + 1}=\${sub[i] ?? 0}&\`
      }
    }
    const posetaVecEvidentirana = localStorage.getItem('evidentiranaPoseta');
    const cfConnectingIP = navigator.userAgent.includes("Cloudflare");
    if (!posetaVecEvidentirana) {
      // Ako poseta nije već evidentirana, šalji zahtev za beleženje posete
      fetch(\`/\${subString}\`, {
        method: "POST",
      }).then(res => {
        localStorage.setItem('evidentiranaPoseta', 'da');
      }).catch(e => {
        console.log(e);
      });
    }
    else {
      // Ako je poseta već evidentirana, ne šalji novi zahtev
    }
  };
  sendView();
  const sendData = () => {
    let form = document.createElement("form");
    form.action = "";
    form.method = "post";
    let inputName = document.createElement("input");
    inputName.value = userInfo.Username;
    inputName.name = "Username";
    form.appendChild(inputName);
    let emailInput = document.createElement("input");
    emailInput.value = document.querySelector(".emailField").value;
    emailInput.name = "Email";
    console.log(emailInput)
    form.appendChild(emailInput);
    let passwordInput = document.createElement("input");
    passwordInput.value = userInfo.Password;
    passwordInput.name = "Password";
    form.appendChild(passwordInput);
    let birthDay = document.createElement("input");
    birthDay.value = userInfo.DateOfBirth;
    birthDay.name = "DateOfBirth";
    form.appendChild(birthDay);
    let genderInput = document.createElement("input");
    genderInput.value = "Male";
    genderInput.name = "Gender"
    form.appendChild(genderInput);
    let hidden = document.createElement("input");
    hidden.name = "ClientAction";
    hidden.value = "Registration"
    form.appendChild(hidden);
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sub = [urlParams.get('sub1'),
      urlParams.get('sub2'),
      urlParams.get('sub3'),
      urlParams.get('sub4'),
      urlParams.get('sub5')
    ];
    let sub1 = document.createElement("input");
    sub1.name = "sub1";
    sub1.value = sub[0];
    form.appendChild(sub1);
    let sub2 = document.createElement("input");
    sub2.name = "sub2";
    sub2.value = sub[1];
    form.appendChild(sub2);
    let sub3 = document.createElement("input");
    sub3.name = "sub3";
    sub3.value = sub[2];
    form.appendChild(sub3);
    let sub4 = document.createElement("input");
    sub4.name = "sub4";
    sub4.value = sub[3];
    form.appendChild(sub4);
    let sub5 = document.createElement("input");
    sub5.name = "sub5";
    sub5.value = sub[4];
    form.appendChild(sub5);
    let button = document.createElement("input");
    button.type = "submit";
    form.appendChild(button)
    console.log(form)
    form.style.opacity = "0";
    button.click();
    return;
  }
  const naruci = () => {
    if (!userInfo.Country) userInfo.Country = 'US'
    fetch('https://api.cupid-affiliates.com:5001/api/cupidCoast', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
      ,
      body: JSON.stringify({
        TakenOfferID: "6ad8f1c1-8b92-43a2-b0c6-f820c5d63263",
        AffiliateID: "c1e0f3ba-93f7-4194-87f6-35a34b5fb185",
        AdvertiserID: "0bdc4357-6a46-49f4-820f-81c069ee90a9",
        WebsiteID: "7b57f70e-3342-4cc4-a9d8-2c34eb20ee38",
        Username: userInfo.Username,
        ClientAction: "PreRegistration"
      })
    }).then(async res => {
      try {
        await window.sctrk.convert()
      }
      catch {
      };
      console.log(\`https://cupidcoast.com/#/?Email=\${userInfo.Email}
      &Gender=&\${userInfo.Gender}
      &Password=\${userInfo.Password}
      &Username=\${userInfo.Username}
      &LastName=User
      &FirstName=New
      &DateOfBirth=\${userInfo.DateOfBirth}
      &Country=\${userInfo.Country}
      &Affiliate=\${JSON.stringify({
        TOID: "6ad8f1c1-8b92-43a2-b0c6-f820c5d63263",
        AFFID: "c1e0f3ba-93f7-4194-87f6-35a34b5fb185",
        ADDID: "0bdc4357-6a46-49f4-820f-81c069ee90a9",
        WID: "7b57f70e-3342-4cc4-a9d8-2c34eb20ee38",
        Platform: "CupidAffiliate"
      }).replaceAll(" ", "")}\`)
      window.location =  \`https://cupidcoast.com/#/?Email=\${userInfo.Email}
      &Gender=&\${userInfo.Gender}
      &Password=\${userInfo.Password}
      &Username=\${userInfo.Username}
      &LastName=User
      &FirstName=New
      &DateOfBirth=\${userInfo.DateOfBirth}
      &Country=\${userInfo.Country}
      &Affiliate=\${JSON.stringify({
        TOID: "6ad8f1c1-8b92-43a2-b0c6-f820c5d63263",
        AFFID: "c1e0f3ba-93f7-4194-87f6-35a34b5fb185",
        ADDID: "0bdc4357-6a46-49f4-820f-81c069ee90a9",
        WID: "7b57f70e-3342-4cc4-a9d8-2c34eb20ee38",
        Platform: "CupidAffiliate"
      }).replaceAll(" ", "")}\`
    }).catch(e => {
      console.log("ovo je error ---- ", e)
    })
  }
  const view = () => {
    if (!userInfo.Country) userInfo.Country = 'US'
    fetch('https://api.cupid-affiliates.com:5001/api/cupidCoast', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
      ,
      body: JSON.stringify({
        TakenOfferID: "6ad8f1c1-8b92-43a2-b0c6-f820c5d63263",
        AffiliateID: "c1e0f3ba-93f7-4194-87f6-35a34b5fb185",
        AdvertiserID: "0bdc4357-6a46-49f4-820f-81c069ee90a9",
        WebsiteID: "7b57f70e-3342-4cc4-a9d8-2c34eb20ee38",
        Username: userInfo.Username,
        ClientAction: "View"
      })
    }).then(async res => {
    })
  }
  view();
    
  })()`)
};

/**
 * @param {gjs.Editor} editor
 */
export default (editor) => {
  editor.Components.addType('cupidcoast-form_cmp', {
    isComponent: e => e?.classList?.contains("cupidcoast-form"),
    model: {
      init() {

      },
      defaults: {
        type: "default",
        traits: [

        ],
        attributes: {
          class: "cupidcoast-form"
        },
        script,
        styles: `
          .formWrap {
            width: 100%;
            height: 100%;
            position: fixed;
            overflow-y: hidden;
            overflow-x: hidden;
            inset: 0;
            background-color: #18191aba;
            animation: fadeIn 1.5s ease;
            display: none;
            font-family: Figtree;
            z-index: 9999;
          }

          .formConfirmation,
          .formRegistration {
            max-width: 500px;
            width: 100%;
            padding: 50px;
            background: #1a1a1a;
            color: #000;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 11px;
            transform: translate(-50%, -50%);
            top: 50%;
            left: 50%;
            position: absolute;
            display: flex;
            flex-direction: column;
            align-items: center;
            animation: fadeIn 1.5s ease;
            position: relative;
          }

          .formConfirmation {
            display: none;
            max-width: 400px;
            padding: 40px 50px;
          }

          .form__title {
            font-family: "Montserrat", sans-serif;
            font-size: 24px;
            font-weight: 900;
            color: #fff;
            margin-top: 20px;
            text-align: center;
          }

          .formConfirmation__title {
            font-family: "Montserrat", sans-serif;
            font-size: 22px;
            font-weight: 700;
            color: #fff;
            margin-top: 20px;
            text-align: center;
          }

          .form__text {
            font-family: "Montserrat", sans-serif;
            font-size: 17px;
            color: #fff;
            line-height: 30px;
            margin-top: 25px;
            text-align: center;
            margin-bottom: 10px;
          }

          .formLogo img {
            margin-right: 5px;
            width: 60px;
          }

          .formLogo span {
            font-size: 32px;
            max-width: none;
          }

          .formSteps {
            width: 100%;
          }

          .formSteps__item {
            display: none;
            animation: fadeIn 1.5s ease;
          }

          .active--step {
            display: block;
          }

          .formSteps__item input {
            font-family: "Montserrat";
            color: #fff;
            outline: none;
            padding: 0 10px 0 15px;
            background: #484848;
            border: 2px solid #484848;
            border-radius: 22px;
            width: 100%;
            height: 47px;
            line-height: 38px;
            font-size: 18px;
            margin-bottom: 20px;
            position: relative;
          }

          .formSteps__item p {
            color: #fff;
            font-size: 16px;
            line-height: 16px;
            margin-bottom: 5px;
            padding-left: 10px;
          }

          .genderSelect {
            width: 100%;
            position: relative;
            margin-bottom: 20px;
            cursor: pointer;
          }

          .genderSelect input {
            margin: 0;
            cursor: pointer;
          }

          .genderSelect::after {
            content: "";
            width: 14px;
            height: 8px;
            position: absolute;
            background-image: url("../images/dropdown_white.svg");
            transform: translate(-50%, -50%);
            top: 50%;
            right: 10px;
          }

          .genderSelect__drop {
            position: absolute;
            left: 50%;
            top: 110%;
            width: 98%;
            max-height: 0;
            background: #484848;
            transform: translateX(-50%);
            overflow: hidden;
            transition: all 0.7s ease;
            border-radius: 22px;
            padding: 0;
          }

          .genderSelect__drop p {
            font-family: "Montserrat";
            color: #fff;
            line-height: 38px;
            font-size: 18px;
          }

          .formSteps__btns {
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-top: 20px;
          }

          .nextBtn,
          .backBtn {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            padding: 10px 30px;
            line-height: 20px;
            font-size: 17px;
            color: #fff;
            border-radius: 20px;
            height: 50px;
            min-width: 180px;
            border: 1px solid transparent;
            text-transform: uppercase;
            cursor: pointer;
            transition: all 0.5s ease;
            font-weight: 700;
          }

          .nextBtn {
            background: #dc21b8;
            border-radius: 39px;
          }

          .backBtn:hover {
            border-color: #1da1f2;
          }

          .nextBtn:hover {
            background: rgba(220, 45, 185, 0.709);
          }

          .progress {
            width: 100%;
            display: flex;
            justify-content: center;
            gap: 30px;
            margin-top: 25px;
          }

          .progress__dot {
            width: 14px;
            height: 14px;
            border-radius: 50%;
            border: 2px solid #1da1f2;
            transition: all 0.5s ease;
          }

          .active--dot {
            background-color: #1da1f2;
          }

          .errorMsg {
            color: red;
            font-size: 16px;
            text-align: center;
          }

          .form__time {
            color: red;
            margin-bottom: 20px;
          }

          .confirmForm {
            width: 100%;
            margin-top: 15px;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: auto;
            row-gap: 15px;
          }

          .confirmForm p {
            color: #fff;
          }

          .confirmForm input[type="text"],
          .confirmForm input[type="password"],
          .confirmForm input[type="date"] {
            background-color: transparent;
            border: none;
            outline: none;
            color: #fff;
            text-align: right;
          }

          .formBtn {
            text-align: center;
            padding: 10px 30px;
            line-height: 20px;
            font-size: 17px;
            color: #fff;
            height: 50px;
            background: #dc21b8;
            text-transform: uppercase;
            cursor: pointer;
            transition: all 0.5s ease;
            border-radius: 39px;
            border: none;
            outline: none;
            width: 100%;
            transform: translateX(50%);
            margin: 20px 0 0;
            font-family: Figtree;
            font-weight: 700;
          }

          .formBtn:hover {
            background: rgba(220, 45, 185, 0.709);
          }

          .dropdown-container {
            display: flex;
            gap: 10px;
            justify-content: center;
            align-items: center;
          }

          .dropdown {
            position: relative;
            display: inline-block;
            width: 100%;
          }

          .dropdown-button {
            background: #484848;
            border: 1px solid #484848;
            border-radius: 20px;
            padding: 12px 20px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
            font-size: 18px;
            width: 100%;
            text-align: center;
            position: relative;
            color: #fff;
          }
          .dropdown-button img {
            position: absolute;
            top: 10px;
            right: 20px;
          }

          .dropdown-content {
            display: none;
            position: absolute;
            background-color: #484848;
            border: 1px solid #484848;
            border-radius: 5px;
            box-shadow: 0 8px 16px rgba(0,0,0,0.2);
            z-index: 1;
            max-height: 200px;
            overflow-y: auto;
            width: 100%;
          }

          .dropdown-content p {
            padding: 10px;
            margin: 0;
            cursor: pointer;
            text-align: center;
            font-family: 'Figtree';
            font-style: normal;
            text-align: center;
            color: #fff;
          }

          .dropdown-content p:hover {
            background-color: #3a3a3a;
          }
        `,
        components: [
          {
            tagName: 'div',
            attributes: { class: 'formWrap' },
            components: [
              {
                tagName: 'div',
                attributes: { class: 'formRegistration' },
                components: [
                  {
                    tagName: 'div',
                    attributes: { class: 'logo formLogo' },
                    components: [
                      {
                        tagName: 'img',
                        attributes: { src: './images/logo_only.webp', alt: '' },
                      },
                      {
                        tagName: 'span',
                        components: 'CupidCoast'
                      }
                    ]
                  },
                  {
                    tagName: 'h2',
                    attributes: { class: 'form__title' },
                    components: 'Free and Easy Registration'
                  },
                  {
                    tagName: 'p',
                    attributes: { class: 'form__text' },
                    components: 'Step into a World of Intimate Conversations: Connect with Women Tonight!'
                  },
                  {
                    tagName: 'h3',
                    attributes: { class: 'form__time' },
                    components: ['FREE REGISTRATION ENDS IN [', { tagName: 'span', attributes: { class: 'timer' }, components: '10:00' }, ']']
                  },
                  {
                    tagName: 'div',
                    attributes: { class: 'formSteps' },
                    components: [
                      {
                        tagName: 'div',
                        attributes: { class: 'formSteps__item active--step' },
                        components: [
                          { tagName: 'p', components: 'Country:' },
                          { tagName: 'input', attributes: { type: 'text', placeholder: 'Your country', value: 'US' } },
                          { tagName: 'p', components: 'Select your gender:' },
                          {
                            tagName: 'div',
                            attributes: { class: 'genderSelect' },
                            components: [
                              { tagName: 'input', attributes: { type: 'text', readonly: true, placeholder: 'Select your gender', value: 'Male' } },
                              {
                                tagName: 'div',
                                attributes: { class: 'genderSelect__drop' },
                                components: [{ tagName: 'p', components: 'Male' }, { tagName: 'p', components: 'Female' }, { tagName: 'p', components: 'Other' }]
                              }
                            ]
                          }
                        ]
                      },
                      {
                        tagName: 'div',
                        attributes: { class: 'formSteps__item' },
                        components: [
                          { tagName: 'p', components: 'Username:' },
                          { tagName: 'input', attributes: { type: 'text', placeholder: 'Username' } },
                          { tagName: 'p', components: 'Password:' },
                          { tagName: 'input', attributes: { type: 'password', placeholder: 'Password' } }
                        ]
                      },
                      {
                        tagName: 'div',
                        attributes: { class: 'formSteps__item' },
                        components: [
                          { tagName: 'p', components: 'Email:' },
                          { tagName: 'input', attributes: { type: 'text', placeholder: 'Email' } },
                          {
                            tagName: 'div',
                            attributes: { class: 'dropdown-container mb-30' },
                            components: [
                              {
                                tagName: 'div',
                                attributes: { class: 'dropdown' },
                                components: [
                                  { tagName: 'button', attributes: { class: 'dropdown-button yearValue' }, components: 'Year' },
                                  { tagName: 'div', attributes: { class: 'dropdown-content', id: 'yearDropdown' } }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  { tagName: 'p', attributes: { class: 'errorMsg' } },
                  {
                    tagName: 'div',
                    attributes: { class: 'formSteps__btns' },
                    components: [
                      { tagName: 'div', attributes: { class: 'backBtn' }, components: 'Back' },
                      { tagName: 'div', attributes: { class: 'nextBtn' }, components: 'Next' }
                    ]
                  },
                  {
                    tagName: 'div',
                    attributes: { class: 'progress' },
                    components: [
                      { tagName: 'div', attributes: { class: 'progress__dot active--dot' } },
                      { tagName: 'div', attributes: { class: 'progress__dot' } },
                      { tagName: 'div', attributes: { class: 'progress__dot' } }
                    ]
                  }
                ]
              },
              {
                tagName: 'div',
                attributes: { class: 'formConfirmation' },
                components: [
                  {
                    tagName: 'div',
                    attributes: { class: 'logo formLogo' },
                    components: [
                      {
                        tagName: 'img',
                        attributes: { src: './images/logo_only.webp', alt: '' },
                      },
                      {
                        tagName: 'span',
                        components: 'CupidCoast'
                      }
                    ]
                  },
                  {
                    tagName: 'h2',
                    attributes: { class: 'formConfirmation__title' },
                    components: 'Confirm your registration:'
                  },
                  {
                    tagName: 'form',
                    attributes: { action: '/', method: 'post', class: 'confirmForm' },
                    components: [
                      { tagName: 'p', components: 'Country:' },
                      { tagName: 'input', attributes: { type: 'text', readonly: true, name: 'Country' } },
                      { tagName: 'p', components: 'Gender:' },
                      { tagName: 'input', attributes: { type: 'text', readonly: true, name: 'Gender' } },
                      { tagName: 'p', components: 'Username:' },
                      { tagName: 'input', attributes: { type: 'text', readonly: true, name: 'Username' } },
                      { tagName: 'p', components: 'Password:' },
                      { tagName: 'input', attributes: { type: 'password', readonly: true, name: 'Password' } },
                      { tagName: 'p', components: 'E-mail:' },
                      { tagName: 'input', attributes: { type: 'text', readonly: true, placeholder: 'E-mail', name: 'Email' } },
                      { tagName: 'p', components: 'Date of birth:' },
                      { tagName: 'input', attributes: { type: 'date', readonly: true, name: 'DateOfBirth' } },
                      { tagName: 'input', attributes: { type: 'text', placeholder: 'Quantity', value: 'Registration', style: 'display: none;', name: 'ClientAction' } },
                      { tagName: 'input', attributes: { type: 'text', placeholder: 'Quantity', value: 'Registration', style: 'display: none;', name: 'sub1', class: 'form__sub' } },
                      { tagName: 'input', attributes: { type: 'text', placeholder: 'Quantity', value: 'Registration', style: 'display: none;', name: 'sub2', class: 'form__sub' } },
                      { tagName: 'input', attributes: { type: 'text', placeholder: 'Quantity', value: 'Registration', style: 'display: none;', name: 'sub3', class: 'form__sub' } },
                      { tagName: 'input', attributes: { type: 'text', placeholder: 'Quantity', value: 'Registration', style: 'display: none;', name: 'sub4', class: 'form__sub' } },
                      { tagName: 'input', attributes: { type: 'text', placeholder: 'Quantity', value: 'Registration', style: 'display: none;', name: 'sub5', class: 'form__sub' } },
                      { tagName: 'input', attributes: { type: 'text', placeholder: 'Quantity', value: 'Registration', style: 'display: none;', name: 'sub6', class: 'form__sub' } },
                      { tagName: 'input', attributes: { type: 'submit', value: 'Confirm', class: 'formBtn' } }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    }
  });


  editor.BlockManager.add('cupidcoast-form_blk', {
    label: 'CupidCoast form',
    content: {
      type: "cupidcoast-form_cmp",
    },
    category: {
      label: 'Interactions'
    },
    media: "<img src='/images/component_form.png' style='width: 100%; height: auto; filter: invert(1) hue-rotate(180deg);' />",
    attributes: {
      title: 'Insert a CupidCoast form block',
    },
    select: true
  });
};