import React from "react";
import "./index.scss";

import axios from "axios";
import { useSelector } from "react-redux";
import * as backendModule from "../../modules/backendModule";
import { animateBox } from "../../modules/componentAnimation";

import CustomInput from "../../components/customComponents/CustomInput";
import CustomCheckbox from "../../components/customComponents/CustomCheckbox";
import { FilteredCustomTable } from "../../components/customComponents/Table";
import Spinner from "../../components/customComponents/Spinner";

let curTimeout = null;
const DomainsList = () => {
    const [data, setData] = React.useState();
    const [filters, setFilters] = React.useState([]);
    const [canPaginate, setCanPaginate] = React.useState(false);
    const [secondarySpinner, setSecondarySpinner] = React.useState(false);

    const paginationOffset = React.useRef();
    const curPaginationTimestamp = React.useRef();
    const searchRef = React.useRef();

    const siteSettingsSelector = useSelector(state => state?.siteSettings ?? {});
    const timestampSelector = useSelector(state => state?.timestamp ?? null);
    const userDataSelector = useSelector(state => state?.userData?.userData ?? {});

    const getData = () => {
        paginationOffset.current = 0;
        curPaginationTimestamp.current = Date.now();

        setCanPaginate(false);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/domains/getAllDomains`,
            data: {
                offset: paginationOffset.current,
                filters: [
                    ...filters,
                ],
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (res.data.data.length === 20) {
                    paginationOffset.current += 20;
                    setTimeout(() => setCanPaginate(true), 500);
                } else {
                    setCanPaginate(false);
                    paginationOffset.current = -1;
                };

                return setData({ ...res.data, timestamp: Date.now() });
            }
            return setData({ status: "error", data: "SERVER_ERROR", timestamp: Date.now() });
        }).catch(() => {
            return setData({ status: "error", data: "SERVER_ERROR", timestamp: Date.now() });
        });
    };

    const continueData = (timestamp) => {
        if (paginationOffset.current === -1) {
            if (timestamp !== curPaginationTimestamp.current) return;
            if (canPaginate) setCanPaginate(false);
            return;
        };

        setSecondarySpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/domains/getAllDomains`,
            data: {
                offset: data?.data?.length ?? 0,
                filters: [
                    ...filters,
                ],
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestamp !== curPaginationTimestamp.current) return;
            if (res.data.status === "ok") {
                if (res.data.data.length === 20) {
                    paginationOffset.current += 20;
                    setTimeout(() => setCanPaginate(true));
                } else {
                    setCanPaginate(false);
                    paginationOffset.current = -1;
                };
                return setData(old => {
                    return {
                        ...old,
                        data: [
                            ...old.data,
                            ...res.data.data
                        ], timestamp: Date.now()
                    };
                });
            }
            return setData({ status: "error", data: "SERVER_ERROR", timestamp: Date.now() });
        }).catch(() => {
            return setData({ status: "error", data: "SERVER_ERROR", timestamp: Date.now() });
        }).finally(() => {
            if (timestamp !== curPaginationTimestamp.current) return;
            setSecondarySpinner(false);
        });
    };

    const PaginationData = () => {
        let tmpRef = React.useRef();
        React.useEffect(() => {
            if (!tmpRef?.current) return;
            let observer = null;
            try {
                let observer = new IntersectionObserver((entries) => {
                    entries.forEach(entry => {
                        if (entry.intersectionRatio > 0) {
                            try { observer.unobserve(tmpRef.current); } catch { };
                            if (canPaginate) {
                                continueData(curPaginationTimestamp.current);
                            };
                        };
                    });
                }, { threshold: [1] });
                observer.observe(tmpRef.current);
            } catch { };

            return () => {
                if (tmpRef?.current) {
                    try { observer.unobserve(tmpRef.current); } catch { };
                };
            };
        }, [tmpRef]);

        return <div ref={tmpRef}></div>;
    };

    React.useEffect(() => {
        if (!searchRef.current) return;

        let handler = () => {
            let searchVal = searchRef?.current?.value;
            clearTimeout(curTimeout);
            curTimeout = setTimeout(() => {
                setFilters([{or: [
                    {name: "Name", op: "like", value: searchVal},
                    {name: "Domain", op: "like", value: searchVal}
                ]}]);
            }, 500);
        };

        searchRef.current.addEventListener("input", handler);

        return () => {
            try {
                searchRef.current.removeEventListener("input", handler);
            } catch {};
        };
    }, [searchRef.current]);

    React.useEffect(() => {
        if (filters?.length > 0) setFilters([]);
    }, [siteSettingsSelector.advancedSearch]);

    React.useEffect(() => {
        getData();
    }, [filters, timestampSelector]);

    return <div className="route__domainsList">
        {!siteSettingsSelector.advancedSearch && <CustomInput autocomplete="off" ref={searchRef} theme="dark" accent="#3F7CEA" placeholder="Search..." style={{width: "100%", marginBottom: "20px"}} />}
        <FilteredCustomTable
            key={`domainTable-${siteSettingsSelector.advancedSearch ? "advanced" : "simple"}`}
            theme="dark"
            accent="#48515C"
            style={{width: "100%"}}
            headers={["ID", "Name", "Domain", "SSL"]}
            filters={siteSettingsSelector.advancedSearch ? [
                {name: "ID", friendlyName: "ID", type: "number"},
                {name: "Name", friendlyName: "Name", type: "string"},
                {name: "Domain", friendlyName: "Domain", type: "string"},
            ] : undefined}
            filterCB={f => setFilters(f)}
            data={(()=>{
                if (!data) return [[{keyID: "noDataSpinner", type: "spinner"}]];
                if (data?.status !== "ok") return [[{keyID: "noDataError", type: "custom", data: <p>There was an error while fetching data</p>}]]
                if (data.data.length === 0) return [[{keyID: "noDataError2", type: "custom", data: <p>There is nothing to show.</p>}]]

                let tmp = data.data.map(elem => {
                    return [
                        {keyID: String(elem.ID), type: "text", text: elem.ID},
                        {keyID: String(elem.ID), type: "text", text: elem.Name},
                        {keyID: String(elem.ID), type: "text", text: elem.Domain},
                        {keyID: String(elem.ID), type: "text", text: elem.isSSL ? <span style={{color: "#53ff53"}}>Yes</span> : <span style={{color: "#ff7474"}}>No</span>},
                        (userDataSelector?.UserInfo?.Flags?.isAdmin ? {keyID: String(elem.ID), type: "groupNewline", group: [
                            {keyID: String(elem.ID), type: "button", text: "Edit", onClick: e => animateBox(e, <AddDomain id={elem.ID} onChange={getData} />)},
                            {keyID: String(elem.ID), type: "button", text: "Remove", style:{marginLeft: "10px"}, onClick: e => animateBox(e, <RemoveDomain data={elem} onChange={getData} />)},
                        ]} : null)
                    ].filter(f => f);
                });
                if (secondarySpinner) tmp.push([{keyID: "paginationSpinner", type: "spinner"}]);
                if (canPaginate) tmp.push([{
                    keyID: "paginationData",
                    type: "custom",
                    data: <PaginationData />
                }]);

                return tmp;
            })()}
        />
    </div>
};

const AddDomain = props => {
    const [spinner, setSpinner] = React.useState(false);
    const [infoP, setInfoP] = React.useState("");
    const [isSSL, setIsSSL] = React.useState(false);

    const nameRef = React.useRef();
    const domainRef = React.useRef();

    const getDomain = () => {
        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/domains/getDomainByID`,
            data: {
                ID: props.id
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setSpinner(false);
                nameRef.current.value = res.data.data.Name;
                domainRef.current.value = `${res.data.data.isSSL ? "https" : "http"}://${res.data.data.Domain}`;
                setIsSSL(!!res.data.data.isSSL);
            } else {
                props.onClose();
            };
        }).catch(() => {
            props.onClose();
        });
    };

    const addDomain = () => {
        setInfoP("");
        let data = {
            Name: nameRef.current.value,
            Domain: domainRef.current.value,
            isSSL: isSSL
        };
        if (props.id) data["ID"] = props.id;

        if (!data.Name) return setInfoP("Name can't be empty!");
        if (!data.Domain) return setInfoP("Domain can't be empty!");
        try {
            new URL(data.Domain);
        } catch {
            return setInfoP("Domain is invalid!");
        };
        
        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/domains/${props.id ? "editDomain" : "addDomain"}`,
            data,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                props.onClose().then(() => {
                    if (props.onChange) props.onChange();
                });
            } else {
                setInfoP(`There was an error while ${props.id ? "editing" : "adding"} the domain!`);
            };
        }).catch(() => {
            setInfoP("Server timed out!");
        }).finally(() => {
            setSpinner(false);
        });
    };

    React.useEffect(() => {
        if (!props.id) return;
        getDomain();
    }, []);

    return <div className="route__domainsList__addDomain">
        <div className="route__domainsList__addDomain__wrap">
            <div className="route__domainsList__addDomain__wrap__spinner" style={{
                opacity: spinner ? 1 : 0,
                pointerEvents: spinner ? "all" : "none"
            }} onClick={e => spinner && e.stopPropagation()}>
                <Spinner color="#3F7CEA" />
            </div>

            <h3 style={{marginBottom: "20px"}}>{props.id ? "Edit" : "Add"} domain</h3>
            <CustomInput autocomplete="off" ref={nameRef} accent="#fff" theme="dark" placeholder="Domain name" style={{width: "100%", marginBottom: "20px"}} />
            <CustomInput autocomplete="off" ref={domainRef} accent="#fff" theme="dark" placeholder="Domain (https://...)" style={{width: "100%", marginBottom: "20px"}} />
            <CustomCheckbox accent="#3F7CEA" theme="dark" style={{marginTop: "10px"}} placeholder="SSL" defaultValue={isSSL} onChange={e => setIsSSL(e)}/>

            <div className="route__domainsList__addDomain__wrap__btns">
                <p onClick={addDomain}>
                    <img style={{ marginRight: "15px" }} src="/images/saveBtn.png" />
                    <span>Save</span>
                </p>
                <p onClick={props.onClose}>
                    <span>Cancel</span>
                    <img style={{ marginLeft: "15px" }} src="/images/closeBtn.png" />
                </p>
            </div>

            {infoP && <p className="route__domainsList__addDomain__wrap__infoP">{infoP}</p>}
        </div>
    </div>
};

const RemoveDomain = (props) => {
    const [spinner, setSpinner] = React.useState(false);
    const [infoP, setInfoP] = React.useState("");

    const removeDomain = () => {
        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/domains/removeDomain`,
            data: {
                ID: props.data.ID
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                props.onClose().then(() => {
                    if (props.onChange) props.onChange();
                });
            } else {
                setInfoP("There was an error while removing the domain!");
            };
        }).catch(() => {
            setInfoP("Server timed out!");
        }).finally(() => {
            setSpinner(false);
        });
    };

    return <div className="route__domainsList__addDomain">
        <div className="route__domainsList__addDomain__wrap">
            <div className="route__domainsList__addDomain__wrap__spinner" style={{
                opacity: spinner ? 1 : 0,
                pointerEvents: spinner ? "all" : "none"
            }} onClick={e => spinner && e.stopPropagation()}>
                <Spinner color="#3F7CEA" />
            </div>
            
            <h3>Remove domain</h3>
            <p>Are you sure? You are about to delete <span style={{color: "#3F7CEA"}}>{props.data.Name}</span></p>
            <p>This action cannot be undone</p>

            <div className="route__domainsList__addDomain__wrap__btns">
                <p onClick={removeDomain}>
                    <img style={{ marginRight: "15px" }} src="/images/saveBtn.png" />
                    <span>Save</span>
                </p>
                <p onClick={props.onClose}>
                    <span>Cancel</span>
                    <img style={{ marginLeft: "15px" }} src="/images/closeBtn.png" />
                </p>
            </div>

            {infoP && <p className="route__domainsList__addDomain__wrap__infoP">{infoP}</p>}
        </div>
    </div>
};

export default DomainsList;
export { AddDomain }