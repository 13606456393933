
import "./index.scss";

import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import * as timestampActions from "../../actions/timestampActions";
import * as userDataActions from "../../actions/userDataActions";
import * as backendModule from "../../modules/backendModule";
import animateModule from "../../modules/animateModule";

import Spinner from "../customComponents/Spinner";
import CustomInput from "../customComponents/CustomInput";
import { animateBox } from "../../modules/componentAnimation";

import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';

const Heatmaps = (props) => {
    const [data, setData] = useState();
    const [website, setWebsite] = useState();
    const htmlRef = useRef();
    const iframeRef = useRef();
    const [selectedDevice, setSelectedDevice] = useState();

    // Funkcija za izračunavanje prosečne visine ekrana
    const calculateAverageScreenHeight = () => {
        let totalHeight = 0;
        let numScreens = 0;

        // Iteriraj kroz podatke i saberi visine ekrana
        data.data[selectedDevice].forEach(item => {
            totalHeight += item.UserLocationOnSite.windowHeight;
            numScreens++;
        });

        // Izračunaj prosečnu visinu ekrana
        return totalHeight / numScreens;
    };

    // Funkcija za kreiranje klikova na heatmapu
    const createClicks = (minHeight) => {
        data.data[selectedDevice].forEach(item => {

            item.UserLocationOnSite.clicks.forEach(click => {
                const nclick = document.createElement("div");
                nclick.style.width = "10px";
                nclick.style.height = "10px";
                nclick.style.background = "rgb(200 0 0 / 34%)";
                nclick.style.borderRadius = "50%";
                nclick.style.border = "1px solid white";
                nclick.style.top = (click.y + (item.UserLocationOnSite.scrollTop)) + "px";
                nclick.style.left = click.x + "px";
                nclick.style.position = "absolute";
                nclick.style.zIndex = "99";
                nclick.id = "clicks";
                htmlRef.current.appendChild(nclick);
            });
        });
    };

    // Funkcija za kreiranje ekrana na heatmapu
    const createScreens = (avgHeight, minHeight) => {
        const colors = [
            'rgba(255, 0, 0, 0.1)',       // Crvena
            'rgba(255, 63, 0, 0.1)',      // Tamno narandžasta
            'rgba(255, 95, 0, 0.1)',      // Narandžasta nijansa
            'rgba(255, 127, 0, 0.1)',     // Narandžasta
            'rgba(255, 191, 0, 0.1)',     // Žuta nijansa
            'rgba(255, 223, 0, 0.1)',     // Žuta nijansa
            'rgba(255, 255, 0, 0.1)',     // Žuta
            'rgba(127, 255, 0, 0.1)',     // Svjetlo zelena
            'rgba(63, 255, 0, 0.1)',      // Zelena nijansa
            'rgba(0, 255, 0, 0.1)'        // Zelena
        ];

        const numBoxes = Math.ceil(minHeight / (avgHeight / 2));
        const boxHeight = avgHeight / 2;

        const boxCounts = new Array(numBoxes).fill(0);

        // Brojanje ekrana po visini
        data.data[selectedDevice].forEach(item => {
            let boxIndex = Math.floor((item.UserLocationOnSite.scrollTop) / boxHeight);
            if(boxIndex<0) boxIndex=boxIndex*-1;
            if (boxIndex >= 0 && boxIndex < numBoxes) {
                boxCounts[boxIndex]++;
            }
        });
        console.log(1, boxCounts)
        // Normalizacija broja ekrana
        const maxCount = Math.max(...boxCounts);
        const minCount = Math.min(...boxCounts);
        const normalizedCounts = boxCounts.map(count => {
            console.log("test", (count - minCount), (maxCount - minCount), (count - minCount) / (maxCount - minCount))
            return (count - minCount) / (maxCount - minCount)
        });
        console.log(2, normalizedCounts)
        // Kreiranje ekrana na heatmapi
        normalizedCounts.forEach((count, index) => {

            const userScreen = document.createElement("div");
            userScreen.style.width = "100%";
            userScreen.style.height = boxHeight + "px";
            userScreen.style.background = colors[Math.floor(count * (colors.length - 1))];
            userScreen.style.top = (index * boxHeight) + "px";
            userScreen.style.position = "absolute";
            userScreen.style.opacity = "1";
            userScreen.style.zIndex = 10;
            userScreen.id = "screens";
            htmlRef.current.appendChild(userScreen);
        });
    };


    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (website?.status !== "ok" || data?.status !== "ok" || data?.data?.[selectedDevice]?.length === 0 || !selectedDevice) return;
        var oldClicks = document.querySelectorAll("#clicks");
        var oldScreens = document.querySelectorAll("#screens");
        for (let clc of oldClicks) {
            clc.remove()
        }
        for (let clc of oldScreens) {
            clc.remove()
        }

        const createHeatmap = () => {
            const tempDiv = document.createElement('div');
            tempDiv.innerHTML = website.data.HTML;
            const iframeDocument = iframeRef.current.contentDocument || iframeRef.current.contentWindow.document;
            iframeDocument.body.innerHTML = tempDiv.innerHTML;
            const scriptElements = iframeDocument.getElementsByTagName('script');
            for (let i = scriptElements.length - 1; i >= 0; i--) {
                scriptElements[i].parentNode.removeChild(scriptElements[i]);
            }

            const styleElement = document.createElement('style');
            styleElement.type = 'text/css';
            styleElement.appendChild(document.createTextNode(website.data.CSS));
            iframeDocument.head.appendChild(styleElement);

            iframeRef.current.style.width = selectedDevice + "px";

            const avgHeight = calculateAverageScreenHeight();
            const minHeight = Math.max(...data.data[selectedDevice].map(item => item.UserLocationOnSite.bodyHeight));
            htmlRef.current.style.height = minHeight + "px";
            iframeRef.current.style.height = minHeight + "px";

            createClicks(minHeight);
            createScreens(avgHeight, minHeight);
        };
        createHeatmap();
    }, [website, selectedDevice, data]);

    const fetchData = async () => {
        setSelectedDevice();
        try {
            const res = await axios.post(`${backendModule.backendURL}/heatmaps/getAllHeatmapData`, {
                limit: 1000,
                offset: 0,
                WebsiteID: props.ID
            }, backendModule.axiosConfig);

            setData(res.data);
            const tmpwth = {};
            res.data.data.forEach(wth => {
                const width = JSON.parse(wth.UserLocationOnSite).windowWidth.toFixed(0);
                wth.UserLocationOnSite = JSON.parse(wth.UserLocationOnSite);
                tmpwth[width] = tmpwth[width] || [];
                tmpwth[width].push(wth);
            });
            setData({ status: "ok", data: tmpwth });
        } catch (error) {
            console.error('Greška prilikom dohvata podataka:', error);
        }

        try {
            const res = await axios.post(`${backendModule.backendURL}/websites/getWebsiteByID`, {
                ID: props.ID
            }, backendModule.axiosConfig);
            setWebsite(res.data);
        } catch (error) {
            console.error('Greška prilikom dohvata podataka:', error);
        }
    };

    return (
        <div className="component__heatmaps">
            <div className="component__heatmaps__container">

                <p className="component__heatmaps__container__close" onClick={() => props.onClose()}>&#10005;</p>
                <div className="component__heatmaps__container__devices">
                    {data?.status === "ok" && Object.keys(data.data).map(item => (
                        <p key={item} onClick={() => { setSelectedDevice(item); }}>{Number(item).toFixed(0)} ({data.data[item].length})</p>
                    ))}
                </div>
                <div className="component__heatmaps__container__preview">
                    <div ref={htmlRef}>
                        <iframe id="myIframe" height="1000" scrolling="no" ref={iframeRef}></iframe>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Heatmaps;


