import gjs from "grapesjs";
import axios from "axios";

import * as backendModule from "../../../../modules/backendModule";

function script() {

  eval(`(()=>{
  if (!this) return;

  const commentName = this.getAttribute('commentname');
  const commentText = this.getAttribute('commenttext');
  const userImage = this.getAttribute('userimage');
  const textImage = this.getAttribute('textimage');
  const likes = this.getAttribute('likes');
  const dislikes = this.getAttribute('dislikes');
  const date = this.getAttribute('date');
  const replyComments = this.getAttribute('replycomments');
  const index = this.getAttribute('indexvalue');

  const today = new Date();

  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  const dayBeforeYesterday = new Date();
  dayBeforeYesterday.setDate(dayBeforeYesterday.getDate() - 2);

  const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
  const formattedToday = today.toLocaleDateString('en-GB', options);
  const formattedYesterday = yesterday.toLocaleDateString('en-GB', options);
  const formattedDayBeforeYesterday = dayBeforeYesterday.toLocaleDateString('en-GB', options);

  let preImage = this.querySelector(".scalecms-comment-fb-temp-3-body-image");
  if (textImage) {
    preImage.src = \`\${textImage}\`
  } else {
    preImage.style.display = "none";
  }

  if (likes) {
    let likeImage = this.querySelector(".scalecms-comment-fb-temp-3-body-infoDiv-image-likeImg");
    let likesAmount = this.querySelector(".scalecms-comment-layout3-body-infoDiv-likePara-amount");
    let replyBtn = this.querySelector(".scalecms-comment-fb-temp-3-body-infoDiv-image-replyImg");

    likesAmount.textContent = likes;

    likeImage.src = "https://backend.scale-cms.com:5000/staticContent/images/089e0888-1f44-45b7-bc46-f52d79e146fd-1725264242004.svg";

    let leaveCommentTarget = document.querySelector(".scalecms-leaveComment");
    replyBtn.addEventListener("click", () => {
      if (!leaveCommentTarget) return;
      leaveCommentTarget.scrollIntoView({ behavior: 'smooth' });
    })

    likeImage.addEventListener("click", () => {
      likeImage.src = "https://backend.scale-cms.com:5000/staticContent/images/edff0dc8-0c1f-4f30-a160-18d464d57150-1725273775778.svg";
      let newLikeAmount = Number(likes) + 1;
      likesAmount.textContent = newLikeAmount;
      likesAmount.style.color= '#107BEF';
    })

  } else {
    let infoDiv = this.querySelector(".scalecms-comment-fb-temp-3-body-infoDiv");
    infoDiv.style.display = "none";
  }

  let dateValueText = '';

  if (index && index < 6) {
    dateValueText = formattedToday;
  } else if (index && index <= 14) {
    dateValueText = formattedYesterday;
  } else if (index && index > 14){
    dateValueText = formattedDayBeforeYesterday;
  }else{
    dateValueText = '';
  }

  if (date) {
    let datePara = this.querySelector(".scalecms-comment-fb-temp-3-body-date");
    datePara.textContent = dateValueText;
  } else {
    let datePara = this.querySelector(".scalecms-comment-fb-temp-3-body-date");
    datePara.textContent = '';
  }

  if (replyComments) {
    const parsedReplyComments = JSON.parse(replyComments);
    const replyContainer = this.querySelector(".reply-container");

    parsedReplyComments.forEach(reply => {
      let newReplyComment = document.createElement("div");
      newReplyComment.classList.add("scalecms-comment-fb-temp-3");
      newReplyComment.style.marginTop = "10px";

      // Left section of the comment
      let leftSection = document.createElement("div");
      leftSection.classList.add("scalecms-comment-fb-temp-3-left");

      let imageElement = document.createElement("img");
      imageElement.classList.add("scalecms-comment-fb-temp-3-image");
      imageElement.src = reply.attributes.UserImage;

      leftSection.appendChild(imageElement);
      newReplyComment.appendChild(leftSection);

      // Body section of the comment
      let bodySection = document.createElement("div");
      bodySection.classList.add("scalecms-comment-fb-temp-3-body");

      // Top section within the body
      let topSection = document.createElement("div");
      topSection.classList.add("scalecms-comment-fb-temp-3-body-top");

      let headingElement = document.createElement("div");
      headingElement.classList.add("scalecms-comment-fb-temp-3-body-heading");
      headingElement.textContent = reply.attributes.CommentName;
      topSection.appendChild(headingElement);

      let textElement = document.createElement("div");
      textElement.classList.add("scalecms-comment-fb-temp-3-body-text");
      textElement.innerHTML = reply.attributes.CommentText.replace(/\{\{\{([^}]+)\}\}\}/g, (match, group) => {
        return \`<span class="scalecms-comment-fb-temp-3-body-text-btn scroll-to">\${group}</span>\`;
      });
      topSection.appendChild(textElement);

      let imageElementTop = document.createElement("img");
      imageElementTop.classList.add("scalecms-comment-fb-temp-3-body-image");
      imageElementTop.src = reply.attributes.TextImage;
      topSection.appendChild(imageElementTop);

      bodySection.appendChild(topSection);

      // InfoDiv section within the body
      let infoDiv = document.createElement("div");
      infoDiv.classList.add("scalecms-comment-fb-temp-3-body-top-inner");

      // Like and Reply section inside infoDiv
      if (likes) {
        let infoLikeImg = document.createElement("img");
        infoLikeImg.src = "https://backend.scale-cms.com:5000/staticContent/images/089e0888-1f44-45b7-bc46-f52d79e146fd-1725264242004.svg";
        infoLikeImg.style.width= '20px';
        infoLikeImg.style.height= '20px';
        let infoReplyImg = document.createElement("img");
        infoReplyImg.src = "https://backend.scale-cms.com:5000/staticContent/images/d13bcce3-0cae-49bb-82d9-f35503849ec1-1725264256853.svg";
        infoReplyImg.style.width= '20px';
        infoReplyImg.style.height= '20px';
        let likeParaAmount = document.createElement("div");
        likeParaAmount.classList.add("scalecms-comment-layout3-body-infoDiv-likePara-amount");
        likeParaAmount.textContent = \`\${reply.attributes.Likes}\`;
        infoDiv.appendChild(likeParaAmount);


        let likeIncremented = false;
        infoLikeImg.addEventListener("click", () => {
          if (!likeIncremented) {
            likeIncremented = true;
            let newLikeAmount = parseInt(reply.attributes.Likes) + 1;
            likeParaAmount.textContent = \`\${newLikeAmount}\`;
            infoLikeImg.src = "https://backend.scale-cms.com:5000/staticContent/images/edff0dc8-0c1f-4f30-a160-18d464d57150-1725273775778.svg";
            likeParaAmount.style.color= '#107BEF';
          }
        });

        infoDiv.appendChild(infoLikeImg);
        infoDiv.appendChild(likeParaAmount);
        infoDiv.appendChild(infoReplyImg);
      }

      // Date element inside infoDiv
      if (date) {
        let dateElement = document.createElement("div");
        dateElement.classList.add("scalecms-comment-fb-temp-3-body-date");
        dateElement.textContent = dateValueText;
        infoDiv.appendChild(dateElement);
      }

      topSection.appendChild(infoDiv);
      newReplyComment.appendChild(bodySection);

      // Append the new comment to the reply container
      replyContainer.appendChild(newReplyComment);
    });
  }

  // END OF REPLY COMMENTS SECTION


  const regex = /\{\{\{([^}]+)\}\}\}/g;
  const modifiedCommentText = commentText.replace(regex, (match, group) => {
    return \`<span class="scalecms-comment-fb-temp-3-body-text-btn scroll-to">\${group}</span>\`;
  });

  let headline = this.querySelector(".scalecms-comment-fb-temp-3-body-heading")
  let text = this.querySelector(".scalecms-comment-fb-temp-3-body-text")
  let image = this.querySelector(".scalecms-comment-fb-temp-3-image")

  headline.textContent = commentName;
  text.innerHTML = modifiedCommentText;
  image.src = userImage;

  let commentScrollToBtns = this.querySelectorAll(".scroll-to");
  let commentScrollTarget = document.querySelector(".scroll-target");
  if(!commentScrollTarget) commentScrollTarget = document.querySelector(".present__form");
  if (commentScrollToBtns.length) {
    for (let elem of commentScrollToBtns) {
      elem.addEventListener('click', () => {
        commentScrollTarget.scrollIntoView({ behavior: 'smooth' });
      });
    }
  }

  })()`)

}

/**
 * @param {gjs.Editor} editor
 */
export default editor => {

  editor.Components.addType('scalecms-comment-fb-temp-3', {
    isComponent: e => e?.classList?.contains("scalecms-comment-fb-temp-3"),
    model: {
      async init() {
        if (!this) return;
      },

      defaults: {
        type: "default",
        traits: [
          {
            type: 'select',
            label: 'Dropdown',
            name: 'dropdown',
            options: [],
            changeProp: 1
          },
        ],
        attributes: {
          class: "scalecms-comment-fb-temp-3",
          dataOfferName: "apolo kosa",
        },
        script,
        styles: `
          .popup-content {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100vh;
            background-color: rgba(0, 0, 0, 0.3);
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .scalecms-comment-fb-temp-3 {
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            background: transparent;
            display: flex;
            flex-direction: row;
            gap: 10px;
            min-height: 70px;
            height: fit-content;
            font-family: 'Arial';
            position: relative;
            font-family: sans-serif;
          }
          @media screen and (max-width: 600px) {
            .scalecms-comment-fb-temp-3 {
              padding: 0px 16px;
            }
            .scalecms-comment-fb-temp-3-body{
              width: 100%;
            }
            .scalecms-comment-fb-temp-3-body-top {
              width: 100%;
            }
            .scalecms-comment-fb-temp-3-body-infoDiv{
              width: 100%;
              max-width: 100%;
            }
          }
          @media screen and (max-width: 400px) {
            .scalecms-comment-fb-temp-3 {
              padding: 0px 4px;
            }
          }
          .scalecms-comment-fb-temp-3-left{
            display: flex;
          }
          .scalecms-comment-fb-temp-3-image {
            width: 36px !important;
            height: 36px !important;
            outline: none;
            border-radius: 50%;
          }
          .scalecms-comment-fb-temp-3-body-date{
            font-weight: 400;
            font-size: 13px;
            color: #b3b3b3;
          }
          .scalecms-comment-fb-temp-3-body {
            width: 100%;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            gap: 10px;
          }
          .scalecms-comment-fb-temp-3-body-top{
            width: 100%;
            position: relative;
            background-color: #ebedf0;
            border-radius: 18px;
            line-height: 16px;
            overflow: hidden;
            padding: 6px 12px 7px 12px;
            display: flex;
            flex-direction: column;
            gap: 10px;
          }
          .scalecms-comment-fb-temp-3-body-heading {
            font-size: 15px;
            color: #000;
            margin-bottom: 3px;
            line-height: 22px;
            font-family: 'Arial', sans-serif;
            font-weight: 700;
          }
          
          .scalecms-comment-fb-temp-3-body-text {
            font-size: 16px;
            line-height: 16px;
            margin: 0;
            color: #2b2929;
            font-family: 'Arial', sans-serif;
          }
          .scalecms-comment-fb-temp-3-body-text span{
            font-size: 16px;
            color: #000;
            line-height: 16px;
            font-family: 'Arial', sans-serif;
            font-weight: 600;
            cursor: pointer;
          }
          .scalecms-comment-fb-temp-3-body img{
            width: 100%;
            max-width: 140px;
            height: auto;
            outline: none;
          }
          .scalecms-comment-fb-temp-3-body-infoDiv{
            display: flex;
            gap: 15px;
            align-items: flex-end;
            max-width: 85%;
          }
          .scalecms-comment-fb-temp-3-body-infoDiv img{
            cursor: pointer;
            outline: none;
          }
          .scalecms-comment-fb-temp-3-body-infoDiv-likePara{
            color: #929292;
            font-weight: 600;
            font-size: 12px;
            cursor: pointer;
          }
          .scalecms-comment-fb-temp-3-body-infoDiv img{
            width: 20px;
            height: 20px;
          }
          .reply-container{
            display: felx;
            flex-direction: column;
          }
          .scalecms-comment-fb-temp-3-body-infoDiv-image {
            width: 24px !important;
            height: 24px !important;
            opacity: 0;
          }

          .scalecms-comment-fb-temp-3-body-top-inner{
            display: flex;
            justify-content: flex-end;
          }
          .scalecms-comment-fb-temp-3-body-infoDiv-image-likeImg{
            width: 20px !important;
            height: 20px !important;
            cursor: pointer;
          }
          .scalecms-comment-fb-temp-3-body-infoDiv-image-replyImg{
            width: 20px !important;
            height: 20px !important;
            cursor: pointer;
          }
          .scalecms-comment-fb-temp-3-body-date{
            position: absolute;
            top: 8px;
            right: 12px;
            font-weight: 400;
            font-size: 13px;
            color: #9397AD;
          }
          .scalecms-comment-layout3-body-infoDiv-likePara-amount{
            font-weight: 400px;
            font-size: 14px;
            line-height: 20px;
            color: #9397AD;
            margin-right: 10px;
            margin-left: 2px;
          }

        `,
        components: [
          {
            tagName: 'div',
            attributes: { class: "scalecms-comment-fb-temp-3-left" },
            components: [
              {
                type: 'image',
                attributes: { class: "scalecms-comment-fb-temp-3-image" }
              },
            ]
          },
          {
            tagName: 'div',
            attributes: { class: "scalecms-comment-fb-temp-3-body" },
            components: [
              {
                tagName: 'div',
                attributes: { class: "scalecms-comment-fb-temp-3-body-top" },
                components: [
                  {
                    type: 'text',
                    attributes: { class: "scalecms-comment-fb-temp-3-body-heading" },
                    components: "Ime osobe"
                  },
                  {
                    type: 'text',
                    attributes: { class: "scalecms-comment-fb-temp-3-body-date" },
                    components: "datum"
                  },
                  {
                    type: 'text',
                    attributes: { class: "scalecms-comment-fb-temp-3-body-text" },
                    components: "Text komentara..."
                  },
                  {
                    type: 'image',
                    attributes: { class: "scalecms-comment-fb-temp-3-body-image" }
                  },
                  {
                    tagName: 'div',
                    attributes: { class: "scalecms-comment-fb-temp-3-body-top-inner" },
                    components: [
                      {
                        type: 'image',
                        attributes: { class: "scalecms-comment-fb-temp-3-body-infoDiv-image-likeImg", src: "https://backend.scale-cms.com:5000/staticContent/images/089e0888-1f44-45b7-bc46-f52d79e146fd-1725264242004.svg" }
                      },
                      {
                        type: 'text',
                        attributes: { class: "scalecms-comment-layout3-body-infoDiv-likePara-amount" },
                        components: "00"
                      },
                      {
                        type: 'image',
                        attributes: { class: "scalecms-comment-fb-temp-3-body-infoDiv-image-replyImg", src: "https://backend.scale-cms.com:5000/staticContent/images/d13bcce3-0cae-49bb-82d9-f35503849ec1-1725264256853.svg" }
                      },
                    ]
                  }
                ]
              },
              {
                tagName: 'div',
                attributes: { class: "reply-container" },
              }
            ]
          },
        ]
      }
    }
  });


  editor.BlockManager.add('scalecms-comment-fb-temp-3-blk', {
    label: 'Comment FB Temp 3',
    content: {
      type: "scalecms-comment-fb-temp-3",
    },
    category: {
      label: 'Not Used'
    },
    media: `
      <?xml version="1.0" encoding="utf-8"?>
      <svg version="1.1" id="Layer_1" x="0px" y="0px" width="100px" height="100px" viewBox="0 0 100 100" xml:space="preserve" enable-background="white" background="#fff">
        <g>
          <path fill="#FFFFFF" d="M88.466,16.936c-0.172-1.703-1.592-3.036-3.34-3.036H14.781v0.009c-1.705,0.047-3.079,1.355-3.247,3.027H11.5v56.342h0.068
            c0.305,1.512,1.622,2.65,3.214,2.694v0.009h11.564v6.744c0,1.864,1.51,3.374,3.374,3.374c1.165,0,2.192-0.591,2.798-1.49
            l8.629-8.629h43.979c1.634,0,2.995-1.161,3.306-2.703H88.5V16.936H88.466z"></path>
        </g>
      </svg>
    `,
    attributes: {
      title: 'Comment FB Temp 3',
    },
    select: true
  });
};