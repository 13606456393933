import gjs from "grapesjs";

/**
 * @param {gjs.Editor} editor
 */
export default editor => {
    editor.Components.addType('scalecms-reviewBox', {
        isComponent: e => e?.classList?.contains("scalecms-reviewBox"),
        model: {
            defaults: {
                type: "default",
                attributes: {
                    class: "scalecms-reviewBox"
                },
                styles: `
                    .scalecms-reviewBox{
                        width: 350px;
                        height: fit-content;
                        font-family: 'Arial';
                        display: block;
                        margin-left: auto;
                        margin-right: auto;
                        margin-top: 20px;
                        box-shadow: 0 0 4px gray;
                        padding: 20px;
                        border-radius: 10px;
                    }

                    @media screen and (max-width: 600px){
                        .scalecms-reviewBox{
                            width: 95%;
                        }
                    }

                    .scalecms-reviewBox-fiveStarsImage{
                        width: 150px;
                        height: 30px;
                        display: block;
                        margin-left: auto;
                        margin-right: auto;
                        margin-bottom: 20px;
                    }
                    .scalecms-reviewBox-person{
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        margin-top: 20px;
                    }
                    .scalecms-reviewBox-person-image{
                        width: 50px;
                        height: 50px;
                        border-radius: 50%;
                        margin-right: 10px;
                    }
                    .scalecms-reviewBox-text{
                        text-align: center;
                    }
                `,
                components: [
                    {
                        tagName: 'img',
                        attributes: {class: 'scalecms-reviewBox-fiveStarsImage', src: 'https://i.imgur.com/H0GGQXP.png'}
                    },
                    {
                        type: 'text',
                        attributes: {class: 'scalecms-reviewBox-text'},
                        components: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin ornare felis ac velit semper, non ullamcorper tortor dapibus. Donec nisi dui, malesuada vitae sapien sed, luctus laoreet est.'
                    },
                    {
                        tagName: 'div',
                        attributes: {class: 'scalecms-reviewBox-person'},
                        components: [
                            {
                                type: 'image',
                                attributes: {class: 'scalecms-reviewBox-person-image'}
                            },
                            {
                                type: 'text',
                                components: 'Ime osobe(godine)'
                            }
                        ]
                    }
                ]
            }
        }
    });


    editor.BlockManager.add('scalecms-reviewBox-blk', {
        label: 'Review box',
        content: {
            type: "scalecms-reviewBox",
        },
        category: {
            label: 'Custom assets'
        },
        media: `
        <svg id="Layer_1" data-name="Layer 1" viewBox="0 0 64 64"><path d="M22,48a3,3,0,0,1,3-3h5.656l-2.614,8.713a1,1,0,0,0,.1.8l3,5a1,1,0,0,0,1.714,0l3-5a1,1,0,0,0,.1-.8L33.344,45H39a3,3,0,0,1,3,3V62h2V48a5.006,5.006,0,0,0-5-5H35.448a6.5,6.5,0,1,0-6.9,0H25a5.006,5.006,0,0,0-5,5V62h2Zm11.915,5.864L32,57.056l-1.915-3.192L32,47.48ZM27.5,37.5A4.5,4.5,0,1,1,32,42,4.505,4.505,0,0,1,27.5,37.5Z" id="id_101" style="fill: rgb(255, 255, 255);"></path><path d="M25.426,16.75l-1.21,7.082a1,1,0,0,0,1.452,1.052L32,21.543l6.332,3.341a1,1,0,0,0,1.452-1.052l-1.21-7.082L43.7,11.736a1,1,0,0,0-.554-1.7L36.064,9,32.9,2.559a1.041,1.041,0,0,0-1.794,0L27.936,9l-7.081,1.033a1,1,0,0,0-.554,1.7ZM28.745,10.9a1,1,0,0,0,.753-.548L32,5.266l2.5,5.088a1,1,0,0,0,.753.548l5.6.817L36.8,15.688a1,1,0,0,0-.287.883l.957,5.6-5-2.641a1.006,1.006,0,0,0-.934,0l-5,2.641.957-5.6a1,1,0,0,0-.287-.883l-4.056-3.969Z" id="id_102" style="fill: rgb(255, 255, 255);"></path><path d="M61.51,24.337l-4.4-2.284L56.5,17.114a1,1,0,0,0-1.7-.588l-3.538,3.5-4.871-.932a1,1,0,0,0-1.083,1.428l2.218,4.45-2.4,4.364a1,1,0,0,0,1.027,1.471l4.906-.75,3.388,3.627a1,1,0,0,0,1.717-.519l.817-4.914,4.5-2.121a1,1,0,0,0,.034-1.792Zm-5.858,2.328a1,1,0,0,0-.56.74l-.57,3.43-2.363-2.53a1,1,0,0,0-.731-.317.951.951,0,0,0-.151.012l-3.423.522,1.677-3.046a1,1,0,0,0,.019-.929L48,21.437l3.4.652a1,1,0,0,0,.891-.272l2.467-2.439.426,3.443a1,1,0,0,0,.532.764l3.076,1.6Z" id="id_103" style="fill: rgb(255, 255, 255);"></path><path d="M2.574,26.129l4.5,2.121.816,4.914a1,1,0,0,0,1.717.519l3.388-3.627,4.906.75a1,1,0,0,0,1.027-1.471l-2.4-4.364,2.219-4.45a1,1,0,0,0-1.083-1.428l-4.872.932-3.538-3.5a1,1,0,0,0-1.7.588l-.611,4.939-4.4,2.284a1,1,0,0,0,.034,1.792ZM8.33,23.585a1,1,0,0,0,.532-.764l.426-3.443,2.466,2.439a1.008,1.008,0,0,0,.892.272l3.4-.652-1.55,3.11a1,1,0,0,0,.019.929L16.2,28.522,12.772,28a1,1,0,0,0-.881.3l-2.364,2.53-.569-3.43a1,1,0,0,0-.561-.74L5.254,25.182Z" id="id_104" style="fill: rgb(255, 255, 255);"></path></svg>
        `,
        attributes: {
            title: 'Review box',
        },
        select: true
    });
};