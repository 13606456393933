import gjs from "grapesjs";

function script() {
    if (!this) return;
}

/**
 * @param {gjs.Editor} editor
 */
export default editor => {
    editor.Components.addType('scalecms-horizontalLine', {
        isComponent: e => e?.classList?.contains("scalecms-horizontalLine"),
        model: {
            defaults: {
                type: "default",
                traits: [

                ],
                attributes: {
                    class: "scalecms-horizontalLine"
                },
                script,
                styles: `
                    .scalecms-horizontalLine {
                        display: block;
                        width: 100%;
                        height: 1px;
                        background-color: black;
                    }
                `
            }
        }
    });


    editor.BlockManager.add('scalecms-horizontalLine-blk', {
        label: 'Horizontal line',
        content: {
            type: "scalecms-horizontalLine",
        },
        category: {
            label: 'Layout'
        },
        media: `
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="64px" viewBox="0 0 290.658 290.658" xml:space="preserve">
            <g>
                <g>
                    <rect y="139.474" style="fill:white;" width="290.658" height="11.711"/>
                </g>
            </g>
        </svg>
        `,
        attributes: {
            title: 'Horizontal line',
        },
        select: true
    });
};