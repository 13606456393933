import React from "react";
import "./index.scss";


const DocTranslations = (props) => {

  return <div className="route__documentation">
    <div className="route__documentation__headline">
      <p>Create Translation</p>
    </div>

    <div className="route__documentation__desc">
      <p>Creating Translations through our CMS, editing and deleting them</p>
    </div>


    <div className="route__documentation__introBlock flex-column">
      <div className="route__documentation__introBlock__left">
        <img src="/images/tutorials/translations/DocTranslations1.png" />
      </div>
      <div className="route__documentation__introBlock__right">
        <p>We have a field per current used languages, write the keywords or the phrases, after we finish writting it, click on 'Add Translation' to create it</p>
        <p>TIP: for faster input, after you finish the word, press TAB, to move to the next field, and when you finish inputting phrase on the last field, hit ENTER to create translation</p>
      </div>
    </div>

    <div className="route__documentation__introBlock flex-column">
      <div className="route__documentation__introBlock__left">
        <img src="/images/tutorials/translations/DocTranslations2.png" />
      </div>
      <div className="route__documentation__introBlock__right">
        <p>In a search field we can search the table by translation word</p>
        <p>Every row of the table has button to Edit the current translation or to remove it</p>
      </div>
    </div>
  </div>
};

export default DocTranslations;
export { DocTranslations };