import gjs from "grapesjs";
import axios from "axios";

import * as backendModule from "../../../../modules/backendModule";

/**
 * @param {gjs.Editor} editor
 */
export default editor => {

  const getAllOffers = async () => {
    try {
      const response = await axios({
        method: "POST",
        url: `${backendModule.backendURL}/comments/getAllOffersForComments`,
        ...backendModule.axiosConfig
      });

      if (response.data.status === "ok") {
        return response.data.data;
      } else {
        console.log("Error fetching comments:", response);
        return [];
      }
    } catch (error) {
      console.log("ERROR:", error);
      return [];
    }
  };

  const getAllTemplatesForOffer = async (id) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${backendModule.backendURL}/commentsTemplate/getAllCommentsTemplate`,
        data: { offerID: id },
        ...backendModule.axiosConfig
      });

      if (response.data.status === "ok") {
        return response.data.data;
      } else {
        console.log("Error fetching comments:", response);
        return [];
      }
    } catch (error) {
      console.log("ERROR:", error);
      return [];
    }
  };

  const getAllCommentsByOfferAndTemplate = async (templateID) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${backendModule.backendURL}/comments/getAllCommentsBySelectedTemplate`,
        data: { CommentTemplateID: templateID },
        ...backendModule.axiosConfig
      });

      if (response.data.status === "ok") {
        return response.data.data;
      } else {
        console.log("Error fetching comments:", response);
        return [];
      }
    } catch (error) {
      console.log("ERROR:", error);
      return [];
    }
  }

  const getCommentByID = async (mainCommentID) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${backendModule.backendURL}/comments/getCommentByID`,
        data: { ID: mainCommentID },
        ...backendModule.axiosConfig
      });

      if (response.data.status === "ok") {
        return response.data.data;
      } else {
        console.log("Error fetching comments:", response);
        return [];
      }
    } catch (error) {
      console.log("ERROR:", error);
      return [];
    }
  }

  const updateOfferOptions = (component, data) => {
    if (!component) return;
    const trait = component.getTrait('offers');
    if (trait) {
      trait.set('options', data);
    }
  };
  const updateTemplatesOptions = (component, data) => {
    if (!component) return;
    const trait = component.getTrait('templates');
    if (trait) {
      trait.set('options', data);
    }
  };

  editor.on('component:add', async function (component) {
    if (component.get('type') === 'scalecms-commentWrap') {
      try {
        const allOffers = await getAllOffers();
        this.trigger('component:scalecms-commentWrap:componentInitialized', { component, data: allOffers });
      } catch (error) {
        console.error("Error loading editor:", error);
      }
    }
  });

  editor.on('load', async function () {
    try {
      setTimeout(async () => {
        let myComponent = findComponentByType(editor.DomComponents.getComponents(), 'scalecms-commentWrap');

        if (!myComponent) {
          console.error("Component not found");
          return;
        }
        const allOffers = await getAllOffers();

        this.trigger('component:scalecms-commentWrap:componentInitialized', { component: myComponent, data: allOffers });
      }, 500);

    } catch (error) {
      console.error("Error loading editor:", error);
    }
  });

  const findComponentByType = (components, type) => {
    for (let component of components) {
      if (component.get('type') === type) {
        return component;
      }
      if (component.models && component.models.length > 0) {
        let nestedComponent = findComponentByType(component.components, type);
        if (nestedComponent) {
          return nestedComponent;
        }
      }
    }
    return null;
  }


  editor.on('component:scalecms-commentWrap:componentInitialized', async (data) => {
    const optionsArray = data.data.map(item => ({
      value: item.ID,
      name: `${item.OfferName} (${item.Country})`,
    }));

    const component = data.component;
    updateOfferOptions(component, optionsArray);

    data.component.view.render();

    const offerTrait = component.getTrait('offers');
    if (offerTrait) {
      offerTrait.on('change', async (offerItem) => {
        const templatesForTheOffer = await getAllTemplatesForOffer(offerItem.changed.value);
        if (templatesForTheOffer) {
          const templatesData = templatesForTheOffer.map(item => ({
            value: item.ID,
            name: item.Name
          }))
          updateTemplatesOptions(component, templatesData);
          data.component.view.render();
          const templateTrait = component.getTrait("templates");
        }
      });
    }

  });

  editor.Components.addType('scalecms-commentWrap', {
    isComponent: e => e?.classList?.contains("scalecms-commentWrap"),
    model: {
      init() {
        this.on('change:attributes:templates', this.checkTraitsAndAddComponents);
        this.on('change:attributes:layout', this.checkTraitsAndAddComponents);
        const attributes = this.get("attributes");

        if (attributes.templates && attributes.layout) {
          this.addCommentComponents(attributes.templates, attributes.layout)
        }
      },
      checkTraitsAndAddComponents(model, value) {
        const templatesTrait = model.getTrait('templates');
        const layoutTrait = model.getTrait('layout');
        if (templatesTrait && layoutTrait && templatesTrait.get('value') && layoutTrait.get('value')) {
          this.addCommentComponents();
        }
      },
      clonedTraitsComments(){

      },
      async addCommentComponents(template, layout) {

        this.components().reset();

        let templateValue;
        let templatesTrait;

        if(template){
          templateValue = template
        }else{
          templatesTrait = this.getTrait('templates');
          templateValue = templatesTrait.get('value');
        }

        let layoutValue;
        let layoutTrait;
        if(layout){
          layoutValue = layout
        }else{
          layoutTrait = this.getTrait('layout');
          layoutValue = layoutTrait.get('value');
        }

        const likesTrait = this.getTrait('likes');
        const likesValue = likesTrait.get('value');

        const headlineTrait = this.getTrait('headlineLayout');
        const headlineValue = headlineTrait.get('value');
        const headlineTextTrait = this.getTrait('headline-text');
        const headlineTextValue = headlineTextTrait.get('value');
        const headlineTextPositionTrait = this.getTrait('headlinePosition');
        const headlineTextPositionValue = headlineTextPositionTrait.get('value');

        const datesTrait = this.getTrait('dates');
        const datesValue = datesTrait.get('value');

        //setting dates
        const today = new Date();

        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);

        const dayBeforeYesterday = new Date();
        dayBeforeYesterday.setDate(dayBeforeYesterday.getDate() - 2);

        const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
        const formattedToday = today.toLocaleDateString('en-GB', options);
        const formattedYesterday = yesterday.toLocaleDateString('en-GB', options);
        const formattedDayBeforeYesterday = dayBeforeYesterday.toLocaleDateString('en-GB', options);

        const commentsData = await getAllCommentsByOfferAndTemplate(templateValue);

        this.components().add(
          {
            type: 'text',
            attributes: { class: `scalecms-commentWrap-headline-${headlineValue} headlineTextAlign-${headlineTextPositionValue ? headlineTextPositionValue : 'left'}` },
            components: `${headlineTextValue}`
          },
        );

        commentsData.forEach(async (comment, index) => {

          if (!comment.ReplyComments) {
            comment["ReplyComments"] = [];
          }

          if(comment.ReplyID) return;

          this.components().add({
            type: `scalecms-comment-${layoutValue}`,
            attributes: {
              CommentName: comment.CommentName,
              CommentText: comment.CommentText,
              UserImage: comment.CommentImage ? comment.CommentImage : 'https://backend.scale-cms.com:5000/staticContent/images/89935b0a-08f9-4cc8-b3ae-e37aea7de6f7-1707465661699.webp',
              Likes: likesValue ? comment.Likes : null,
              Dislikes: likesValue ? comment.Dislikes : null,
              TextImage: comment.TextImage ? comment.TextImage : '',
              IndexValue: index,
              Date: (datesValue === 'Yes' || datesValue === true) ? 'Yes' : '',
              ReplyComments: JSON.stringify(comment.ReplyComments.map(reply => ({
                type: `scalecms-comment-${layoutValue}`, // Assuming a different type for reply comments
                attributes: {
                  CommentName: reply.CommentName,
                  CommentText: reply.CommentText,
                  UserImage: reply.CommentImage ? reply.CommentImage : 'https://backend.scale-cms.com:5000/staticContent/images/89935b0a-08f9-4cc8-b3ae-e37aea7de6f7-1707465661699.webp',
                  Likes: likesValue ? reply.Likes : null,
                  Dislikes: likesValue ? reply.Dislikes : null,
                  TextImage: reply.TextImage ? reply.TextImage : '',
                  Date: '',
                }
              })))
            }
          });
        })
      },
      defaults: {
        type: "default",
        traits: [
          {
            type: 'select',
            label: 'Select headline layout',
            name: 'headlineLayout',
            options: [
              'headline-1',
              'headline-2',
            ],
          },
          { name: "headline-text", label: "Headline Text", placeholder: "Komentari:", value: "Komentari:" },
          {
            type: 'select',
            label: 'Headline Position',
            name: 'headlinePosition',
            value: 'left',
            options: [
              'left',
              'center',
              'right',
            ],
          },
          {
            type: 'select',
            label: 'Select an offer',
            name: 'offers',
            options: [],
          },
          {
            type: 'select',
            label: 'Select the template',
            name: 'templates',
            options: [],
          },
          {
            type: 'select',
            label: 'Show likes',
            name: 'likes',
            options: [
              { name: 'Yes', value: true },
              { name: 'No', value: false }
            ],
            value: 'Yes'
          },
          {
            type: 'select',
            label: 'Show date',
            name: 'dates',
            options: [
              { name: 'Yes', value: true },
              { name: 'No', value: false }
            ],
            value: 'Yes'
          },
          {
            type: 'select',
            label: 'Select the layout',
            name: 'layout',
            options: [
              'fb-temp-1',
              'fb-temp-2',
              'fb-temp-3',
              'native-temp-1',
              'native-temp-2',
              'native-temp-3',
              'layout1',
              'layout2',
              'layout3',
              'layout4',
              'layout5',
              'layout6',
              'layout7'
            ],
          },
        ],
        attributes: {
          class: "scalecms-commentWrap",
          dataOfferName: "apolo kosa",
        },
        styles: `
          .scalecms-commentWrap {
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            background: transparent;
            display: flex;
            flex-direction: column;
            gap: 10px;
            min-height: 70px;
            height: fit-content;
            margin-top: 20px;
            font-family: 'Arial';
            position: relative;
            padding: 10px 16px;
          }
          .headlineTextAlign-left{
            text-align: left;
          }
          .headlineTextAlign-center{
            text-align: center;
          }
          .headlineTextAlign-right{
            text-align: right;
          }
          .scalecms-commentWrap-headline-headline-1{
            font-size: 32px;
            margin-bottom: 10px;
          }
          .scalecms-commentWrap-headline-headline-2{
            font-size: 24px;
            margin-bottom: 10px;
            text-transform: uppercase;
          }
        `,
        components: []
      }
    }
  });

  editor.BlockManager.add('scalecms-commentWrap-blk', {
    label: 'Comment Wrap',
    content: {
      type: "scalecms-commentWrap",
    },
    category: {
      label: 'Comments'
    },
    media: `
      <?xml version="1.0" encoding="utf-8"?>
      <svg version="1.1" id="Layer_1" x="0px" y="0px"
        width="100px" height="100px" viewBox="0 0 100 100" enable-background="new 0 0 100 100" xml:space="preserve">
      <g>
          <path d="M88.466,16.936c-0.172-1.703-1.592-3.036-3.34-3.036H14.781v0.009c-1.705,0.047-3.079,1.355-3.247,3.027H11.5v56.342h0.068
            c0.305,1.512,1.622,2.65,3.214,2.694v0.009h11.564v6.744c0,1.864,1.51,3.374,3.374,3.374c1.165,0,2.192-0.591,2.798-1.49
            l8.629-8.629h43.979c1.634,0,2.995-1.161,3.306-2.703H88.5V16.936H88.466z"/>
      </g>
      </svg>
    `,
    attributes: {
      title: 'Comment Wrap',
    },
    select: true
  });
};