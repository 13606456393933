import gjs from "grapesjs";

function script() {
    eval(`(()=>{
        if (!this) return;
        if (this.getAttribute("data-gjs-type")) return;
        let minutesInput = this.getAttribute('data-minutes');
        let scrollButtonClick = this.getAttribute("data-scrollclassbtn");
        let scrollImageClick = this.getAttribute("data-scrollclassimg");
        let scrollBigTextClick = this.getAttribute("data-scrollclassbigtext");

        let articleImage = this.querySelector(".styled-timer-img");
        let articleButton = this.querySelector(".styled-timer-button");
        let articleBigText = this.querySelector(".styled-timer-price2");

        let minutesText = this.querySelector(".styled-timer-timer-minutes > span:first-child");
        let secondsText = this.querySelector(".styled-timer-timer-seconds > span:first-child");

        for (let item of [this, ...this.querySelectorAll("*")]) {
            let customStyle = item?.getAttribute?.("data-style");
            if (customStyle) item.style = customStyle;
        };

        if (scrollButtonClick) {
            if (!scrollButtonClick.startsWith(".")) scrollButtonClick = "." + scrollButtonClick;

            articleButton.addEventListener("click", () => {
                let tmp = document.querySelector(scrollButtonClick);
                if (tmp) tmp.scrollIntoView({behavior: "smooth"});
            });
        };
        if (scrollImageClick) {
            if (!scrollImageClick.startsWith(".")) scrollImageClick = "." + scrollImageClick;

            articleImage.addEventListener("click", () => {
                let tmp = document.querySelector(scrollImageClick);
                if (tmp) tmp.scrollIntoView({behavior: "smooth"});
            });
        };
        if (scrollBigTextClick) {
            if (!scrollBigTextClick.startsWith(".")) scrollBigTextClick = "." + scrollBigTextClick;

            articleBigText.addEventListener("click", () => {
                let tmp = document.querySelector(scrollImageClick);
                if (tmp) tmp.scrollIntoView({behavior: "smooth"});
            });
        };

        if (isNaN(Number(minutesInput))) return;
        if (Number(minutesInput) < 0) return;

        const handleDecrements = () => {
            let mainSpan = this.querySelector(".styled-timer-text1");
            let numberStart = this.getAttribute("data-test1-start");
            let numberEnd = this.getAttribute("data-test1-end");
            let numberTimeout = this.getAttribute("data-test1-interval");
            
            numberStart = Number(numberStart);
            numberEnd = Number(numberEnd);
            numberTimeout = Number(numberTimeout);
            if (isNaN(numberStart) || numberStart <= 0) numberStart = 7;
            if (isNaN(numberEnd) || numberEnd <= 0) numberEnd = 1;
            if (isNaN(numberTimeout) || numberTimeout <= 1) numberTimeout = 1;

            if (!mainSpan) return;
            if (numberStart < numberEnd) [numberStart, numberEnd] = [numberEnd, numberStart];
            console.log(numberStart, numberEnd);

            let curNumber = numberStart;
            let curText = mainSpan.innerHTML;

            mainSpan.innerHTML = curText.replace("{number}", \`<span>\${curNumber}</span>\`);
            let finalSpan = mainSpan.querySelector("span");
            if (!finalSpan) return;
            let numInterval = setInterval(() => {
                curNumber--;
                finalSpan.innerHTML = curNumber;

                if (curNumber <= numberEnd) {
                    clearInterval(numInterval);
                    finalSpan.classList.add("alert");
                };
            }, numberTimeout * 1000);
        };

        let started = false;
        let iObserver = new IntersectionObserver(items => {
            for (let item of items) {
                if (item.isIntersecting && !started) {
                    started = true;
                    iObserver.unobserve(this);

                    handleDecrements();
                    var timer = Number(minutesInput) * 60, minutes, seconds;
                    setInterval(() => {
                        minutes = parseInt(timer / 60, 10);
                        seconds = parseInt(timer % 60, 10);

                        minutes = minutes < 10 ? "0" + minutes : minutes;
                        seconds = seconds < 10 ? "0" + seconds : seconds;

                        minutesText.textContent = minutes;
                        secondsText.textContent = seconds;

                        // this.textContent = minutes + ":" + seconds;

                        if (--timer < 0) {
                            timer = Number(minutesInput) * 60;
                        }
                    }, 1000);
                };
            }
        });

        iObserver.observe(this);
    })()`);
}

const transformStyles = components => {
    for (let component of components) {
        if (typeof(component.attributes) === "object") {
            if (component.attributes["style"]) component.attributes["data-style"] = component.attributes["style"];
        };

        if (Array.isArray(component.components)) {
            component.components = transformStyles(component.components);
        };
    };

    return components;
};

/**
 * @param {gjs.Editor} editor
 */
export default editor => {
    editor.Components.addType('styled-timer', {
        isComponent: e => e?.classList?.contains("styled-timer"),
        model: {
            init: function () {
                const prepare = e => {
                    let attributes = e.get("attributes");
                    if (typeof (attributes) !== "object" || Array.isArray(attributes)) attributes = {};

                    let headline = attributes["data-headline"];
                    let price = attributes["data-price"];
                    let text1 = attributes["data-text1"];
                    let text2 = attributes["data-text2"];
                    let text3 = attributes["data-text3"];

                    let buttonText1 = attributes["data-buttontext1"];
                    let buttonText2 = attributes["data-buttontext2"];

                    const buttonImages = {
                        alert: "https://backend.scale-cms.com:5000/staticContent/images/11790161-4868-406f-ae73-e426cd745302-1720170602246.png",
                        clock: "https://backend.scale-cms.com:5000/staticContent/images/95be7060-c95a-4699-bc86-692174c95984-1720170610609.png",
                        // hourglass: "https://backend.scale-cms.com:5000/staticContent/images/44611f15-d610-4a24-a4fb-215b905f995d-1720170616462.png"
                        hourglass: "https://backend.scale-cms.com:5000/staticContent/images/08d13cda-0e8b-4765-b261-3f37db76e9ff-1720177738807.gif"
                    };
                    const buttonStyles = {
                        orange: "cursor: pointer; background: linear-gradient(90.7deg, #FF6B0A 0.88%, #FF8D43 50.41%, #FF6B0A 99.93%);box-shadow: 0px 4px 0px 0px #CE5607, 0px 0px 12.89px 0px #00000063;",
                        red: "cursor: pointer; background: linear-gradient(90.7deg, #BE0E0E 0.88%, #FF2C2C 50.41%, #BE0E0E 99.93%);box-shadow: 0px 4px 0px 0px #A60909, 0px 0px 12.89px 0px #00000063;",
                        green: "cursor: pointer; background: linear-gradient(90.7deg, #06AB0D 0.88%, #21D628 50.41%, #06AB0D 99.93%);box-shadow: 0px 4px 0px 0px #0D9613, 0px 0px 12.89px 0px #00000063;"
                    };
                    const textColors = {
                        orange: "#EE6308",
                        red: "#C22619",
                        green: "#0DB614"
                    };
                    const counterStyles = {
                        orange: "border-color: #EE6308; box-shadow: 0px 0px 8px 0px #FF944FB0;",
                        red: "border-color: #C22619; box-shadow: 0px 0px 8px 0px #C2261994;",
                        green: "border-color: #0C8211; box-shadow: 0px 0px 8px 0px #52FF4FB0;"
                    };

                    let buttonImage = buttonImages[attributes["data-buttonimage"]] ?? "https://backend.scale-cms.com:5000/staticContent/images/11790161-4868-406f-ae73-e426cd745302-1720170602246.png";
                    let timerStyle = attributes["data-style"] ?? "orange";

                    let components = [
                        { tagName: "h2", components: headline, attributes: { class: "styled-timer-headline", style: `color: ${textColors[timerStyle]}` }, removable: false, selectable: false },
                        { tagName: "p", components: text1, attributes: { class: "styled-timer-text1" }, removable: false, selectable: false },
                        { tagName: "p", components: price, attributes: { class: "styled-timer-price", style: `color: ${textColors[timerStyle]}` }, removable: false, selectable: false },
                        {
                            tagName: "p", components: "BESPLATNO", attributes: { class: "styled-timer-price2", style: `background: ${textColors[timerStyle]}` }, removable: false, selectable: false
                        },
                        { tagName: "img", attributes: { src: attributes["data-image"] ?? "#", class: "styled-timer-img" }, removable: false, selectable: false },
                        {
                            tagName: "div", components: [
                                { tagName: "p", attributes: { class: "styled-timer-timer-minutes", style: counterStyles[timerStyle] }, components: [
                                    {tagName: "span", components: "00", removable: false, selectable: false},
                                    {tagName: "span", components: "min", removable: false, selectable: false}
                                ], removable: false, selectable: false },
                                { tagName: "p", attributes: { class: "styled-timer-timer-seconds", style: counterStyles[timerStyle] }, components: [
                                    {tagName: "span", components: "00", removable: false, selectable: false},
                                    {tagName: "span", components: "sek", removable: false, selectable: false}
                                ], removable: false, selectable: false }
                            ], attributes: { class: "styled-timer-timer" }, removable: false, selectable: false
                        },
                        { tagName: "p", components: text2, attributes: { class: "styled-timer-text2", style: `color: ${textColors[timerStyle]}` }, removable: false, selectable: false },
                        { tagName: "p", components: text3, attributes: { class: "styled-timer-text3" }, removable: false, selectable: false },

                        { tagName: "div", components: [
                            { tagName: "div", components: [
                                { tagName: "img", attributes: { src: buttonImage }, removable: false, selectable: false }
                            ], attributes: { class: "styled-timer-button-left" }, removable: false, selectable: false },
                            { tagName: "div", components: [
                                { tagName: "p", components: buttonText1, attributes: { class: "styled-timer-button-right-head" }, removable: false, selectable: false },
                                { tagName: "p", components: buttonText2, attributes: { class: "styled-timer-button-right-text" }, removable: false, selectable: false }
                            ], attributes: { class: "styled-timer-button-right" }, removable: false, selectable: false }
                        ], attributes: { class: "styled-timer-button", style: buttonStyles[timerStyle] }, removable: false, selectable: false }
                    ];
                    e.components(transformStyles(components));
                };
                this.on("change:attributes", prepare);
                prepare(this);
            },
            defaults: {
                script,
                textable: 1,
                type: "default",
                traits: [
                    { name: "data-minutes", label: "N minutes left", placeholder: "15", default: "15" },
                    { name: "data-headline", label: "Headline", default: "Upozorenje" },
                    { name: "data-text1", label: "Text 1", default: "Još samo {number} paketa na stanju" },
                    { name: "data-test1-start", label: "Start number", default: "7" },
                    { name: "data-test1-end", label: "End number", default: "1" },
                    { name: "data-test1-interval", label: "Number interval (sec)", default: "5" },
                    { name: "data-text2", label: "Text 2", default: "Ekskluzivni link! Dobijte besplatno na samo ograničeno vrijeme!" },
                    { name: "data-text3", label: "Text 3", default: "Iskoristite naše ekskluzivne poveznice za besplatnu dostavu! Zalihe su ograničene." },
                    { name: "data-price", label: "Price", default: "59.90 KM" },
                    { name: "data-image", type: "image", label: "Image", default: "#" },
                    {
                        name: "data-style", label: "Timer style", type: "select", options: [
                            { id: "orange", label: "Orange" },
                            { id: "red", label: "Red" },
                            { id: "green", label: "Green" }
                        ], default: "orange"
                    },
                    { name: "data-buttontext1", label: "Button text 1", default: "Požurite" },
                    { name: "data-buttontext2", label: "Button text 2", default: "Ponuda ističe uskoro" },
                    { name: "data-buttonimage", label: "Button image", type: "select", options: [
                        { id: "clock", label: "Clock" },
                        { id: "hourglass", label: "Hourglass" },
                        { id: "alert", label: "Alert" }
                    ], default: "clock" },
                    { name: "data-buttonstyle", label: "Button style", type: "select", options: [
                        { id: "rectangle", label: "Rectangle" },
                        { id: "rounded", label: "Rounded" }
                    ], default: "rectangle" },
                    { name: "data-scrollclassbtn", label: "Scroll to class on button click", default: "scalelead-form" },
                    { name: "data-scrollclassimg", label: "Scroll to class on image click", default: "" },
                    { name: "data-scrollclassbigtext", label: "Scroll to class on big text click", default: "" },
                ],
                attributes: {
                    class: "styled-timer"
                },
                styles: `
                    @import url('https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
                    @import url('https://fonts.googleapis.com/css2?family=Hind+Madurai:wght@300;400;500;600;700&display=swap');
                    @keyframes styled-timer-button-anim {
                        0% {transform: rotate(0deg)}
                        5% {transform: rotate(7deg)}
                        10% {transform: rotate(-7deg)}
                        15% {transform: rotate(0deg)}
                    }
                    @keyframes styled-timer-glow-animation {
                        0% {text-shadow: 0 0 2px #FFFFFF00, 0 0 5px #FFFFFF00, 0 0 5px #FFFFFF00, 0 0 10px #FFFFFF00;}
                        50% {text-shadow: 0 0 5px #FFFFFF00, 0 0 10px #FFFFFF00, 0 0 10px #FFFFFF00, 0 0 20px #FFFFFF00;}
                        100% {text-shadow: 0 0 2px red, 0 0 5px red, 0 0 5px #ff6200, 0 0 10px red;}
                    }
                    .styled-timer {
                        all: initial;

                        width: 882px;
                        max-width: 100%;
                        height: auto;
                        margin: 0 auto;
                        padding: 20px;
                        border-radius: 6px;

                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        background-color: white;
                    }
                    .styled-timer > * {
                        all: initial;
                        
                        flex-grow: 0;
                        flex-shrink: 0;
                        margin: 0;
                    }
                    .styled-timer-headline {
                        font-family: Prompt;
                        font-size: 40px;
                        font-weight: 700;
                        line-height: 60.48px;
                        margin-bottom: 8px;
                    }
                    .styled-timer-text1 {
                        font-family: Hind Madurai;
                        font-size: 34px;
                        font-weight: 400;
                        line-height: 25.8px;
                        letter-spacing: 0.7000567317008972px;
                        margin-bottom: 16px;
                        text-align: center;
                    }
                    .styled-timer-text1 > span {
                        animation: styled-timer-glow-animation 1s infinite alternate;
                    }
                    .styled-timer-text1 > span.alert {
                        animation: none !important;
                        text-shadow: 0 0 2px red, 0 0 5px red, 0 0 5px #ff6200, 0 0 10px red;
                    }
                    .styled-timer-price {
                        font-family: Prompt;
                        font-size: 34px;
                        font-weight: 700;
                        line-height: 49.39px;
                        text-align: left;
                        text-decoration: line-through;
                        margin-bottom: 0;
                    }
                    .styled-timer-price2 {
                        font-family: Prompt;
                        font-size: 40.51px;
                        font-weight: 700;
                        line-height: 66.28px;
                        text-align: left;
                        padding: 12px 50px;
                        color: white;
                        background-color: black;
                        margin-bottom: 14px;
                        border-radius: 5px
                    }
                    .styled-timer-img {
                        width: auto;
                        height: auto;
                        max-width: 201px;
                        max-height: 279px;
                        margin-bottom: 8px;
                    }
                    .styled-timer-timer {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: 20px;
                        margin-bottom: 20px;
                    }
                    .styled-timer-timer > p {
                        width: auto;
                        height: auto;
                        padding: 15px 20px;

                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        gap: 10px;

                        border-radius: 7px;
                        border: 2px solid black;
                        box-shadow: 0px 0px 8px 0px #FF944FB0;
                    }
                    .styled-timer-timer > p > span:first-child {
                        font-family: Hind Madurai;
                        font-size: 50px;
                        font-weight: 600;
                        line-height: 64.11px;
                        text-align: center;
                    }
                    .styled-timer-timer > p > span:last-child {
                        font-family: Hind Madurai;
                        font-size: 20px;
                        font-weight: 400;
                        line-height: 25.8px;
                        letter-spacing: 0.7000567317008972px;
                        text-align: left;
                    }
                    .styled-timer-text2 {
                        font-family: Hind Madurai;
                        font-size: 24px;
                        font-weight: 400;
                        line-height: 25.8px;
                        margin-bottom: 20px;
                        text-align: center;
                    }
                    .styled-timer-text3 {
                        font-family: Hind Madurai;
                        font-size: 24px;
                        font-weight: 400;
                        line-height: 25.8px;
                        margin-bottom: 40px;
                        text-align: center;
                    }
                    .styled-timer .styled-timer-button {
                        width: auto;
                        height: auto;
                        padding: 0 9px;
                        display: grid;
                        grid-template-columns: 82px 1fr;
                        grid-template-rows: auto;
                        column-gap: 11px;
                        justify-content: center;
                        align-items: center;

                        color: white;
                        background: linear-gradient(90.7deg, #FF6B0A 0.88%, #FF8D43 50.41%, #FF6B0A 99.93%);;
                        box-shadow: 0px 4px 0px 0px #CE5607, 0px 0px 12.89px 0px #00000063;
                        border-radius: 10px;

                        animation-name: styled-timer-button-anim;
                        animation-direction: normal;
                        animation-duration: 5s;
                        animation-fill-mode: forwards;
                        animation-iteration-count: infinite;
                        transform-origin: center;

                        cursor: pointer;
                    }
                    .styled-timer[data-buttonstyle=rounded] .styled-timer-button {
                        border-radius: 9999px;
                    }
                    .styled-timer .styled-timer-button-left {
                        width: 100%;
                        height: auto;

                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                    .styled-timer[data-buttonstyle=rounded] .styled-timer-button-left {
                        border-radius: 50%;
                        background-color: white;
                        overflow: hidden;
                        height: 90px;
                        width: 90px
                    }
                    .styled-timer .styled-timer-button-left > img {
                        width: auto;
                        max-width: 100%;
                        height: auto;
                        max-height: 100%;
                    }
                    .styled-timer .styled-timer-button-right {
                        display: flex;
                        flex-direction: column;
                        gap: 2px;
                        justify-content: center;
                        align-items: center;
                        padding: 20px 15px;
                    }
                    .styled-timer .styled-timer-button-right-head {
                        font-family: Raleway;
                        font-size: 28px;
                        font-weight: 700;
                        line-height: 33.46px;
                        text-align: center;
                        margin: 0;
                    }
                    .styled-timer .styled-timer-button-right-text {
                        font-family: Raleway;
                        font-size: 21px;
                        font-weight: 500;
                        line-height: 25.1px;
                        text-align: center;
                        margin: 0;
                    }
                `,
                components: []
            }
        }
    });


    editor.BlockManager.add('styled-timer-blk', {
        label: 'Styled timer',
        content: {
            type: "styled-timer",
        },
        category: {
            label: 'Timers'
        },
        media: `
        <?xml version="1.0" encoding="iso-8859-1"?>
        <svg version="1.1" id="Layer_1" x="0px" y="0px"
             viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
        <g>
            <g>
                <g>
                    <path d="M276.193,58.507V40.389h14.578c11.153,0,20.194-9.042,20.194-20.194S301.923,0,290.771,0h-69.544
                        c-11.153,0-20.194,9.042-20.194,20.194s9.042,20.194,20.194,20.194h14.578v18.118C119.952,68.76,28.799,166.327,28.799,284.799
                        C28.799,410.078,130.721,512,256,512s227.201-101.922,227.201-227.201C483.2,166.327,392.046,68.76,276.193,58.507z
                         M276.193,470.516v-20.124c0-11.153-9.042-20.194-20.194-20.194c-11.153,0-20.194,9.042-20.194,20.194v20.124
                        c-86.91-9.385-156.137-78.614-165.522-165.522h20.124c11.153,0,20.194-9.042,20.194-20.194s-9.042-20.194-20.194-20.194H70.282
                        c9.385-86.91,78.614-156.137,165.522-165.523v20.124c0,11.153,9.042,20.194,20.194,20.194c11.153,0,20.194-9.042,20.194-20.194
                        V99.081c86.91,9.385,156.137,78.614,165.522,165.523h-20.124c-11.153,0-20.194,9.042-20.194,20.194s9.042,20.194,20.194,20.194
                        h20.126C432.331,391.903,363.103,461.132,276.193,470.516z"/>
                    <path d="M317.248,194.99l-58.179,58.18c-1.011-0.097-2.034-0.151-3.071-0.151c-17.552,0-31.779,14.229-31.779,31.779
                        c0,17.552,14.228,31.779,31.779,31.779s31.779-14.229,31.779-31.779c0-1.037-0.054-2.06-0.151-3.07l58.178-58.18
                        c7.887-7.885,7.887-20.672,0-28.559C337.922,187.103,325.135,187.103,317.248,194.99z"/>
                </g>
        </g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>
        `,
        attributes: {
            title: 'Generic timer',
        },
        select: true
    });
};