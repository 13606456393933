import React from "react";
import "./index.scss";

import propTypes, { bool } from "prop-types";

const CustomCheckbox = props => {
    const accent = props.accent ?? "#3F7CEA";
    const theme = props.theme === "dark" ? "dark" : "light";
    const [active, setActive] = React.useState(props.defaultValue ?? false);

    React.useEffect(() => {
        if (props.onChange) props.onChange(active);
    }, [active]);

    React.useEffect(() => {
        if (!!(props.defaultValue) !== active) setActive(!!props.defaultValue);
    }, [props.defaultValue]);

    return <div className={`components__customComponents__checkbox components__customComponents__checkbox--${theme}`} onClick={() => setActive(a => !a)} style={{
        ...(props.style ?? {})
    }}>
        <div className={`components__customComponents__checkbox__check ${active ? "components__customComponents__checkbox__check--active" : ""}`}>
            <div className={`components__customComponents__checkbox__check__circle ${active ? "components__customComponents__checkbox__check__circle--active" : ""}`} style={{
                backgroundColor: active ? accent : null
            }}></div>
        </div>
        <div className="components__customComponents__checkbox__text">
            {props.placeholder}
        </div>
    </div>
};

CustomCheckbox.propTypes = {
    accent: propTypes.string,
    placeholder: propTypes.string,
    theme: propTypes.oneOf(["light", "dark"]),
    defaultValue: propTypes.bool,
    onChange: propTypes.func
};

export default CustomCheckbox;