import gjs from "grapesjs";
import axios from "axios";

import * as backendModule from "../../../../modules/backendModule";

function script() {

  eval(`(()=>{
  if (!this) return;

  const commentName = this.getAttribute('commentname');
  const commentText = this.getAttribute('commenttext');
  const userImage = this.getAttribute('userimage');
  const textImage = this.getAttribute('textimage');
  const likes = this.getAttribute('likes');
  const dislikes = this.getAttribute('dislikes');
  const date = this.getAttribute('date');
  const replyComments = this.getAttribute('replycomments');
  const index = this.getAttribute('indexvalue');

  const today = new Date();

  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  const dayBeforeYesterday = new Date();
  dayBeforeYesterday.setDate(dayBeforeYesterday.getDate() - 2);

  const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
  const formattedToday = today.toLocaleDateString('en-GB', options);
  const formattedYesterday = yesterday.toLocaleDateString('en-GB', options);
  const formattedDayBeforeYesterday = dayBeforeYesterday.toLocaleDateString('en-GB', options);

  let preImage = this.querySelector(".scalecms-comment-layout6-body-image");
  if (textImage) {
    preImage.src = \`\${textImage}\`
  } else {
    preImage.style.display = "none";
  }

  if (likes) {
    let likeImage = this.querySelector(".scalecms-comment-layout6-top-likes-image");
    let likesAmount = this.querySelector(".scalecms-comment-layout6-top-likes-amount");
    let likeBtn = this.querySelector(".scalecms-comment-layout6-top-likes");
    let replyBtn = this.querySelector(".scalecms-comment-layout6-body-odgovori");

    likeImage.src = "https://backend.scale-cms.com:5000/staticContent/images/01483fc1-0053-4603-bd40-8087f056df59-1716990330771.png";
    likesAmount.textContent = likes;


    let leaveCommentTarget = document.querySelector(".scalecms-leaveComment");
    replyBtn.addEventListener("click", () => {
      if (!leaveCommentTarget) return;
      leaveCommentTarget.scrollIntoView({ behavior: 'smooth' });
    })

    likeBtn.addEventListener("click", () => {
      let newLikeAmount = Number(likes) + 1;
      likesAmount.textContent = newLikeAmount;
    })
    likeImage.addEventListener("click", () => {
      let newLikeAmount = Number(likes) + 1;
      likesAmount.textContent = newLikeAmount;
    })
  } else {
    let infoDiv = this.querySelector(".scalecms-comment-layout6-top-likes");
    infoDiv.style.display = "none";
  }

  let dateValueText = '';

  if (index && index < 6) {
    dateValueText = formattedToday;
  } else if (index && index <= 14) {
    dateValueText = formattedYesterday;
  } else if (index && index > 14){
    dateValueText = formattedDayBeforeYesterday;
  }else{
    dateValueText = '';
  }

  if (date) {
    let datePara = this.querySelector(".scalecms-comment-layout6-top-date");
    datePara.textContent = dateValueText;
  } else {
    let datePara = this.querySelector(".scalecms-comment-layout6-top-date");
    datePara.textContent = '';
  }

  // REPLY COMMENTS SECTIONS !!!!

  if (replyComments) {
    const parsedReplyComments = JSON.parse(replyComments);
  const replyContainer = this.querySelector(".reply-container");

  parsedReplyComments.forEach(reply => {
    // Create a new comment element
    let newReplyComment = document.createElement("div");
    newReplyComment.classList.add("scalecms-comment-layout6");
    newReplyComment.style.paddingLeft = "50px";

    // Create the top section of the comment
    let topSection = document.createElement("div");
    topSection.classList.add("scalecms-comment-layout6-top");

    // Create and set attributes for the likes section in the top section
    let likesSection = document.createElement("div");
    likesSection.classList.add("scalecms-comment-layout6-top-likes");

    let likesImage = document.createElement("img");
    likesImage.classList.add("scalecms-comment-layout6-top-likes-image");
    likesImage.src = 'https://backend.scale-cms.com:5000/staticContent/images/01483fc1-0053-4603-bd40-8087f056df59-1716990330771.png';
    likesSection.appendChild(likesImage);

    let likesAmount = document.createElement("div");
    likesAmount.classList.add("scalecms-comment-layout6-top-likes-amount");
    likesAmount.textContent = reply.attributes.Likes;
    likesSection.appendChild(likesAmount);

    topSection.appendChild(likesSection);

    // Create and set attributes for image element in the top section
    let imageElement = document.createElement("img");
    imageElement.classList.add("scalecms-comment-layout6-top-image");
    imageElement.src = reply.attributes.UserImage;
    topSection.appendChild(imageElement);

    // Create and set attributes for heading element in the top section
    let headingElement = document.createElement("div");
    headingElement.classList.add("scalecms-comment-layout6-top-heading");
    headingElement.textContent = reply.attributes.CommentName;
    topSection.appendChild(headingElement);

    // Create and set attributes for date element in the top section
    let dateElement = document.createElement("div");
    dateElement.classList.add("scalecms-comment-layout6-top-date");
    dateElement.textContent = dateValueText;
    topSection.appendChild(dateElement);

    newReplyComment.appendChild(topSection);

    // Create the body section of the comment
    let bodySection = document.createElement("div");
    bodySection.classList.add("scalecms-comment-layout6-body");

    // Create and set attributes for text element in the body section
    let textElement = document.createElement("div");
    textElement.classList.add("scalecms-comment-layout6-body-text");
    textElement.innerHTML = reply.attributes.CommentText.replace(/\{\{\{([^}]+)\}\}\}/g, (match, group) => {
      return \`<span class="scalecms-comment-layout6-body-text-btn scroll-to">\${group}</span>\`;
    });
    bodySection.appendChild(textElement);

    // Create and set attributes for image element in the body section
    let imageElementBody = document.createElement("img");
    imageElementBody.classList.add("scalecms-comment-layout6-body-image");
    imageElementBody.src = reply.attributes.TextImage;
    bodySection.appendChild(imageElementBody);

    // Create and set attributes for reply button in the body section
    let replyButton = document.createElement("div");
    replyButton.classList.add("scalecms-comment-layout6-body-odgovori");
    replyButton.textContent = "Odgovori";

    // Add event listener to scroll to scalecms-leaveComment element
    replyButton.addEventListener("click", () => {
      let leaveCommentTarget = document.querySelector(".scalecms-leaveComment");
      if (leaveCommentTarget) {
        leaveCommentTarget.scrollIntoView({ behavior: 'smooth' });
      }
    });

    bodySection.appendChild(replyButton);

    newReplyComment.appendChild(bodySection);

    // Append newReplyComment to replyContainer
    replyContainer.appendChild(newReplyComment);
  });
  }

  /// END OF REPLY COMMENTS SECTION


  const regex = /\{\{\{([^}]+)\}\}\}/g;
  const modifiedCommentText = commentText.replace(regex, (match, group) => {
    return \`<span class="scalecms-comment-layout6-body-text-btn scroll-to">\${group}</span>\`;
  });

  let headline = this.querySelector(".scalecms-comment-layout6-top-heading")
  let text = this.querySelector(".scalecms-comment-layout6-body-text")
  let image = this.querySelector(".scalecms-comment-layout6-top-image")

  headline.textContent = commentName;
  text.innerHTML = modifiedCommentText;
  image.src = userImage;

  let commentScrollToBtns = this.querySelectorAll(".scroll-to");
  let commentScrollTarget = document.querySelector(".scroll-target");
  if(!commentScrollTarget) commentScrollTarget = document.querySelector(".present__form");
  if (commentScrollToBtns.length) {
    for (let elem of commentScrollToBtns) {
      elem.addEventListener('click', () => {
        commentScrollTarget.scrollIntoView({ behavior: 'smooth' });
      });
    }
  }

  })()`)

}

/**
 * @param {gjs.Editor} editor
 */
export default editor => {

  editor.Components.addType('scalecms-comment-layout6', {
    isComponent: e => e?.classList?.contains("scalecms-comment-layout6"),
    model: {
      async init() {
        if (!this) return;
      },

      defaults: {
        type: "default",
        traits: [
          {
            type: 'select',
            label: 'Dropdown',
            name: 'dropdown',
            options: [],
            changeProp: 1
          },
        ],
        attributes: {
          class: "scalecms-comment-layout6",
          dataOfferName: "apolo kosa",
        },
        script,
        styles: `
          
          .scalecms-comment-layout6 {
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 5px;
            background: transparent;
            padding: 5px;
            display: flex;
            flex-direction: column;
            min-height: 70px;
            height: fit-content;
            font-family: '"Open Sans", helvetica, arial, sans-serif;';
            position: relative;
          }
          .scalecms-comment-layout6-top{
            display: flex;
            align-items: center;
            gap: 8px;
            margin-bottom: 8px
          }
          .scalecms-comment-layout6-top-likes{
            display: flex;
            align-items: center;
            gap: 4px;
            padding: 4px;
            border-radius: 5px;
            background: #22272b;
          }
          .scalecms-comment-layout6-top-likes-image{
            width: 16px;
            height: 16px;
          }
          .scalecms-comment-layout6-top-likes-amount{
            color: #6a6f76;
          }
          .scalecms-comment-layout6-top-image{
            width: 20px;
            height: 20px;
            border-radius: 50%;
          }
          .scalecms-comment-layout6-top-heading{
            font-size: 14px;
            line-height: 22px;
            font-weight: 400;
          }
          .scalecms-comment-layout6-top-date{
            color: #8f939d;
          }
          .scalecms-comment-layout6-body-odgovori{
            color: #638c47;
          }
          .scalecms-comment-layout6-body-infoDiv-image {
            width: 24px !important;
            height: 24px !important;
          }
          @media screen and (max-width: 600px) {
            .scalecms-comment-layout6 {
              flex-direction: column;
            }
          }
        `,
        components: [
          {
            tagName: 'div',
            attributes: { class: "scalecms-comment-layout6-top" },
            components: [
              {
                tagName: 'div',
                attributes: { class: "scalecms-comment-layout6-top-likes" },
                components: [
                  {
                    type: 'image',
                    attributes: { class: "scalecms-comment-layout6-top-likes-image", src: 'https://backend.scale-cms.com:5000/staticContent/images/01483fc1-0053-4603-bd40-8087f056df59-1716990330771.png' }

                  },
                  {
                    type: 'text',
                    attributes: { class: "scalecms-comment-layout6-top-likes-amount" },
                    components: "0"
                  },
                ]
              },
              {
                type: 'image',
                attributes: { class: "scalecms-comment-layout6-top-image" }
              },
              {
                type: 'text',
                attributes: { class: "scalecms-comment-layout6-top-heading" },
                components: "Ime osobe"
              },
              {
                type: 'text',
                attributes: { class: "scalecms-comment-layout6-top-date" },
                components: "datum"
              },
            ]
          },
          {
            tagName: 'div',
            attributes: { class: "scalecms-comment-layout6-body" },
            components: [
              {
                type: 'text',
                attributes: { class: "scalecms-comment-layout6-body-text" },
                components: "Text komentara..."
              },
              {
                type: 'image',
                attributes: { class: "scalecms-comment-layout6-body-image" }
              },
              {
                type: 'text',
                attributes: { class: "scalecms-comment-layout6-body-odgovori" },
                components: "Odgovori"
              },
              {
                tagName: 'div',
                attributes: { class: "reply-container" },
              }
            ]
          },
        ]
      }
    }
  });


  editor.BlockManager.add('scalecms-comment-layout6-blk', {
    label: 'Comment Layout 6',
    content: {
      type: "scalecms-comment-layout6",
    },
    category: {
      label: 'Not Used'
    },
    media: `
      <?xml version="1.0" encoding="utf-8"?>
      <svg version="1.1" id="Layer_1" x="0px" y="0px" width="100px" height="100px" viewBox="0 0 100 100" xml:space="preserve" enable-background="white" background="#fff">
        <g>
          <path fill="#FFFFFF" d="M88.466,16.936c-0.172-1.703-1.592-3.036-3.34-3.036H14.781v0.009c-1.705,0.047-3.079,1.355-3.247,3.027H11.5v56.342h0.068
            c0.305,1.512,1.622,2.65,3.214,2.694v0.009h11.564v6.744c0,1.864,1.51,3.374,3.374,3.374c1.165,0,2.192-0.591,2.798-1.49
            l8.629-8.629h43.979c1.634,0,2.995-1.161,3.306-2.703H88.5V16.936H88.466z"></path>
        </g>
      </svg>
    `,
    attributes: {
      title: 'Comment Layout 6',
    },
    select: true
  });
};