import { combineReducers } from "redux";
import timestampReducer from "./timestampReducer";
import userDataReducer from "./userDataReducer";
import siteSettingsReducer from "./siteSettingsReducer";
import siteSavedReducer from "./siteSavedReducer";
import siteCreatedCampaignReducer from "./siteCreatedCampaignReducer";

export default combineReducers({
    timestamp: timestampReducer,
    userData: userDataReducer,
    siteSettings: siteSettingsReducer,
    siteSaved: siteSavedReducer,
    siteCreatedCampaign: siteCreatedCampaignReducer,
});