import gjs from "grapesjs";

function script() {
    if (!this) return;
    if (this.getAttribute("data-gjs-type")) return;

    let buttonType = this.getAttribute('button-type');
    let button = this.querySelector('.scalecms-ctaButton-button');
    let scrollTo = this.getAttribute('scroll-to');
    let popup = this.querySelector('.scalecms-ctaButton-popup');
    let closePopupButton = this.querySelector('.scalecms-ctaButton-popup-box-close');
    let genericClass = this.getAttribute('data-customstyle');
    let align = this.getAttribute('data-align');

    switch (buttonType) {
        case 'goto':
            button.onclick = () => {
                let elem = document.querySelector(`.${scrollTo}`);
                if(elem){
                    elem.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
                }
            }
        break;
        case 'popup':
            button.onclick = () => {
                popup.style.display = 'block';
            }
            closePopupButton.onclick = () => {
                popup.style.display = 'none';
            }
        break;
    }
    switch (genericClass){
        case 'generic1':
            button.classList.add('generic1');
        break;
        case 'generic2':
            button.classList.add('generic2');
        break;
        case 'generic3':
            button.classList.add('generic3');
        break;
        case 'generic4':
            button.classList.add('generic4');
        break;
        case 'generic5':
            button.classList.add('generic5');
        break;
        case 'generic6':
            button.classList.add('generic6');
        break;
        case 'generic7':
            button.classList.add('generic7');
        break;
    }

    switch (align){
        case 'left':
            button.classList.add('align-left');
        break;
        case 'center':
            button.classList.add('align-center');
        break;
        case 'right':
            button.classList.add('align-right');
        break;
    }

}
/**
 * @param {gjs.Editor} editor
 */
export default editor => {
    editor.Components.addType('scalecms-ctaButton_cmp', {
        isComponent: e => e?.classList?.contains("scalecms-ctaButton"),
        model: {
            init() {
                this.on('change', e => {
                    let generic = e?.attributes?.attributes?.['data-customstyle'];
                    let align = e?.attributes?.attributes?.['data-align'];
                    let buttonText = e?.attributes?.attributes?.['button-text'];
                    let buttonMainText = e?.attributes?.attributes?.['button-text-main'];
                    let upperText = e?.attributes?.attributes?.["data-upper-text"];
                    let lowerText = e?.attributes?.attributes?.["data-lower-text"];
                    let imageIncluded = e?.attributes?.attributes?.["data-arrow"];
                    let animationIncluded = e?.attributes?.attributes?.["data-pulse-animation"];

                    let changedAttribute = ''

                    const previousAttributes = e?.previousAttributes()?.attributes || {};
                    const currentAttributes = e?.changed?.attributes || {};
                    if(previousAttributes && currentAttributes){
                        for (let key in currentAttributes) {
                            if (previousAttributes[key] !== currentAttributes[key]) {
                                changedAttribute = key;
                            }
                        }
                    }

                    let parentStyle = e.getStyle();
                    if (typeof(parentStyle) !== "object" || Array.isArray(parentStyle)) parentStyle = {};
                    switch (align) {
                        case "start":
                            parentStyle["width"] = "fit-content";
                            parentStyle["margin"] = 0;
                            break;
                        case "center":
                            parentStyle["width"] = "fit-content";
                            parentStyle["margin"] = "0 auto";
                            break;
                        case "100":
                            parentStyle["width"] = "100%";
                            parentStyle["margin"] = 0;
                        default: break;
                    };
                    e.setStyle(parentStyle);

                    this.get("components").each(child => {
                        if(child?.attributes?.tagName === 'button'){
                            let oldStyle = child.getStyle();
                            if (typeof(oldStyle) !== "object" || Array.isArray(oldStyle)) oldStyle = {};

                            const removeCTABtnClasses = (child) => {
                                const classList = child.getClasses();  // Get current classes
                                classList.forEach(cls => {
                                    if (cls.startsWith('main-cta-btn-')) {
                                        child.removeClass(cls);  // Remove only the classes that start with 'main-cta-btn-'
                                    }
                                });
                            };
                            
                            switch (generic){
                                case 'main-cta-btn-1':
                                    removeCTABtnClasses(child);
                                    child.addClass('main-cta-btn-1');
                                    child.setStyle({ 'color': '#fff', 'font-size': '16px', 'padding': '17px 40px', 'background-color': '#FF5c00', 'line-height': '16px', 'border-radius': '10px', 'text-transform': 'uppercase' })
                                break;
                                case 'main-cta-btn-2':
                                    removeCTABtnClasses(child);
                                    child.addClass('main-cta-btn-2');
                                    child.setStyle({ 'color': '#fff', 'font-size': '16px', 'padding': '17px 40px', 'background-color': '#74DC21', 'line-height': '16px', 'border-radius': '10px', 'text-transform': 'uppercase' })
                                break;
                                case 'main-cta-btn-3':
                                    removeCTABtnClasses(child);
                                    child.addClass('main-cta-btn-3');
                                    child.setStyle({ 'color': '#fff', 'font-size': '16px', 'padding': '17px 40px', 'background-color': '#4c8bf5', 'line-height': '16px', 'border-radius': '10px', 'text-transform': 'uppercase' })
                                break;
                                case 'main-cta-btn-4':
                                    removeCTABtnClasses(child);
                                    child.addClass('main-cta-btn-4');
                                    child.setStyle({ 'color': '#fff', 'font-size': '16px', 'padding': '17px 40px', 'background-color': '#e60023', 'line-height': '16px', 'border-radius': '10px', 'text-transform': 'uppercase' })
                                break;
                                case 'generic-no_color':
                                    child.setStyle({...oldStyle, 'padding': '10px 15px', 'font-size': '20px', 'text-align': 'center', 'font-weight': '700' })
                                break;
                                case 'generic1':
                                    child.setStyle({ 'padding': '10px 15px', 'font-size': '20px', 'text-align': 'center', 'background-color': '#5643b9', 'color': '#fff', 'font-weight': '700' })
                                break;
                                case 'generic2':
                                    child.setStyle({ 'color': '#fff', 'font-size': '25px', 'padding': '10px 30px', 'background-color': '#FF1453', 'box-shadow': '2px 3px 3px #999', 'line-height': '27px' })
                                break;
                                case 'generic3':
                                    child.setStyle({ 'padding': '10px 15px 10px 15px', 'font-size': '20px', 'font-weight': '700', 'color': 'white', 'background-color': '#001fca', 'text-align': 'center', 'border-radius': '8px 8px 8px 8px', 'font-family': 'Arial, Helvetica, sans-serif', 'border': '0 solid #0048df' });
                                break;
                                case 'generic4':
                                    child.setStyle({ 'color': 'white', 'text-align': 'center', 'background-color':'#FF4D4D', 'padding': '10px', 'border-radius': '10px' })
                                break;
                                case 'generic5':
                                    child.setStyle({ 'color': '#fff','background-color':'#249d3d', 'font-size': '18px', 'padding': '10px 20px', 'font-weight': '700', 'border-radius': '4px', 'text-transform': 'uppercase', 'display': 'block', 'width': 'max-content' })
                                break;
                                case 'generic6':
                                    child.setStyle({ 'color': 'white', 'text-align': 'center', 'background-color':'#FF4D4D', 'border-radius': '10px', 'max-width': '300px',
                                    'width': '100%', 'font-weight':'700', 'font-size': '16px', 'text-transform': 'uppercase', 'letter-spacing': '1px', 'border-radius': '40px',
                                    'box-shadow': '0 4px 0 0 rgba(17, 123, 153, 0.7), 0 6px 0 0 rgba(34, 213, 255, 0.7)', 'height': '60px', 'padding': '0px 20px',
                                    'background': 'linear-gradient(to bottom, rgba(71, 213, 255, 1) 0%, rgba(30, 204, 255, 1) 37%, rgba(28, 200, 250, 1) 71%, rgba(23, 164, 205, 1) 100%)' })
                                break;
                                case 'generic7':
                                    child.setStyle({'background-color': '#C00D13', 'max-width': 'max-content', 'padding': '15px 20px', 'text-transform': 'uppercase', 'font-weight': '700', 'color': '#fff', 'border-radius': '22px', 'font-family': 'Arial, Helvetica, sans-serif'})
                                break;
                            }
                        }
                    })

                    this.get("components").each(child => {

                        if (child?.attributes?.tagName === 'button') {

                            if(animationIncluded === 'yes'){
                                child.addClass('button-pulse');
                            }else{
                                child.removeClass('button-pulse');
                            }

                            child.get("components").each(item => {
                                if (item?.attributes?.tagName === 'img') {
                                    let currentStyle = item.getStyle();
                                    if(imageIncluded === 'no'){
                                        item.addStyle({
                                            'display': 'none'
                                        });
                                    }else{
                                        item.addStyle({
                                            'display': 'block'
                                        });
                                    }
                                }else if(item?.attributes?.type === 'text'){
                                    if(changedAttribute === 'button-text-main'){
                                        item?.components(buttonMainText)
                                    }else if(changedAttribute === 'button-text'){
                                        item?.components(buttonText)
                                    }
                                }
                            })
                        }
                        if (child?.attributes?.attributes?.class === 'scalecms-ctaButton-upperText') {
                            child.components(upperText);
                        }
                        if (child?.attributes?.attributes?.class === 'scalecms-ctaButton-lowerText') {
                            child.components(lowerText);
                        }
                    })
                });
            },
            defaults: {
                script,
                textable: 1,
                type: "default",
                traits: [
                    {
                        name: "button-text", label: "Button text", type: "select", options: [
                            { id: "Naruči Sada!", name: "Naruči Sada!" },
                            { id: "Popuni Obrazac", name: "Popuni Obrazac", selected: true },
                            { id: "Rezerviši Svoj Proizvod", name: "Rezerviši Svoj Proizvod" },
                            { id: "Započni Naručivanje", name: "Započni Naručivanje" },
                            { id: "Poruči Odmah", name: "Poruči Odmah" },
                            { id: "Naruči Sada – Ostalo je Malo!", name: "Naruči Sada – Ostalo je Malo!" },
                            { id: "Poslednja Šansa – Popuni Formu!", name: "Poslednja Šansa – Popuni Formu!" },
                            { id: "Naruči Odmah, Zalihe su Ograničene!", name: "Naruči Odmah, Zalihe su Ograničene!" },
                            { id: "Požuri, Akcija se Završava Danas!", name: "Požuri, Akcija se Završava Danas!" },
                            { id: "Zgrabi Svoje Pre Nego što Nestane!", name: "Zgrabi Svoje Pre Nego što Nestane!" },
                        ], default: "Naruči Sada!"
                    },
                    { name: "button-text-main", label: "Custom Button Text", placeholder: "Naruči Sada!", value: "Naruči Sada!" },
                    {
                        name: "button-type", type: "select", options: [
                            { id: "goto", name: "Scroll to", selected: true },
                            { id: "popup", name: "Pop up" },
                        ], value: "goto"
                    },
                    { name: "scroll-to", label: "Scroll To", placeholder: "buynow-form", value: 'scroll-target' },
                    { type: "button", text: "Open popup", full: true, command: editor => {
                       let el = editor.getSelected().getEl();
                       if (el.getAttribute("button-type") !== "popup") {
                        alert("Button is not a popup!");
                       } else {
                        let popup = el.querySelector('.scalecms-ctaButton-popup');
                        let closePopupButton = el.querySelector('.scalecms-ctaButton-popup-box-close');
                        popup.style.display = "block";
                        closePopupButton.onclick = () => popup.style.display = "none";
                       };
                    } },
                    {
                        name: "data-customstyle", type: "select", options: [
                            { id: "generic-no_color", name: "generic-no_color" },
                            { id: "main-cta-btn-1", name: "main-cta-btn-1", selected: true },
                            { id: "main-cta-btn-2", name: "main-cta-btn-2"},
                            { id: "main-cta-btn-3", name: "main-cta-btn-3"},
                            { id: "main-cta-btn-4", name: "main-cta-btn-4"},
                            { id: "generic1", name: "generic1" },
                            { id: "generic2", name: "generic2" },
                            { id: "generic3", name: "generic3" },
                            { id: "generic4", name: "generic4" },
                            { id: "generic5", name: "generic5" },
                            { id: "generic6", name: "generic6" },
                            { id: "generic7", name: "generic7" },
                        ], default: "main-cta-btn-1"
                    },
                    {
                        name: "data-align", type: "select", options: [
                            { id: "start", name: "start" },
                            { id: "center", name: "center" },
                            { id: "100", name: "100%" },
                            { id: "custom", name: "custom", selected: true }
                        ], default: "custom"
                    },
                    { 
                        name: "data-arrow", type: "select", options: [
                            {id: "yes", name: "yes", selected: true},
                            {id: "no", name: "no"}
                        ], default: 'yes'
                    },
                    { 
                        name: "data-pulse-animation", type: "select", options: [
                            {id: "yes", name: "yes"},
                            {id: "no", name: "no", selected: true}
                        ], default: 'no'
                    },
                    { name: "data-upper-text", label: "Upper text", placeholder: "Upper text", default: "" },
                    { name: "data-lower-text", label: "Lower text", placeholder: "Lower text", default: "" },
                ],
                attributes: {
                    class: "scalecms-ctaButton"
                },
                styles: `
                    .scalecms-ctaButton{
                       padding: 0;
                       height: fit-content;
                       width: fit-content; 
                       display: block;
                       cursor: pointer;
                       position: relative;
                       flex-grow: 0;
                       flex-shrink: 0;
                    }
                    .scalecms-ctaButton *{
                       flex-grow: 0;
                       flex-shrink: 0;
                    }
                    .scalecms-ctaButton-button {
                        width: max-content;
                        height: 100%;
                        margin: 0 auto;
                        display: flex;
                        gap: 10px;
                    }
                    .scalecms-ctaButton-popup{
                        position: fixed;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: #00000063;
                        display: none;
                    }
                    .scalecms-ctaButton-popup-box{
                        width: 350px;
                        height: 500px;
                        background: white;
                        padding: 20px;
                        display: block;
                        margin-left: auto;
                        margin-right: auto;
                        margin-top: 50px;
                        box-shadow: 0 0 8px gray;
                        border-radius: 5px;
                    }
                    .scalecms-ctaButton-popup-box-close{
                        display: block;
                        margin-left: auto;
                        margin-right: 0;
                        width: 24px;
                        height: 24px;
                        cursor: pointer;
                    }
                    .scalecms-ctaButton .main-cta-btn-1{
                        background-color: #FF5c00;
                        color: #fff;
                        padding: 10px 30px;
                        font-size: 25px;
                        box-shadow: 2px 3px 3px #999;
                        text-decoration: none;
                        line-height: 27px;
                        text-transform: uppercase;
                        cursor: pointer;
                        transition: 0.3s ease;
                    }
                    .main-cta-btn-1:hover{
                        background-color: #FF772B !important;
                    }
                    .main-cta-btn-2:hover{
                        background-color: #74DC21 !important;
                    }
                    .main-cta-btn-3:hover{
                        background-color: #67A0FF !important;
                    }
                    .main-cta-btn-4:hover{
                        background-color: #F93F16 !important;
                    }
                    .scalecms-ctaButton .generic1{
                        padding: 10px 15px;
                        font-size: 20px;
                        text-align: center;
                        background-color: #5643b9;
                        color: #fff;
                        font-weight: 700;
                        cursor: pointer;
                    }
                    .scalecms-ctaButton .generic2{
                        background-color: rgb(255, 20, 81);
                        color: #fff;
                        padding: 10px 30px;
                        font-size: 25px;
                        box-shadow: 2px 3px 3px #999;
                        text-decoration: none;
                        line-height: 27px;
                        text-transform: uppercase;
                        cursor: pointer;
                    }
                    .scalecms-ctaButton .generic3{
                        padding: 10px 15px 10px 15px;
                        font-size: 20px;
                        font-weight: 700;
                        color: white;
                        text-align: center;
                        background-color: #001fca;
                        border-radius: 8px 8px 8px 8px;
                        transition: all 0.5s ease;
                        border: 0 solid #0048df;
                        font-family: Arial, Helvetica, sans-serif;
                        cursor: pointer;
                    }
                    .scalecms-ctaButton .generic4{
                        text-align: center;
                        background-color: #FF4D4D;
                        color: white;
                        padding: 10px;
                        border-radius: 10px;
                        margin-bottom: 20px;
                        cursor: pointer;
                    }
                    .scalecms-ctaButton .generic5{
                        background-color: #249d3d;
                        color: #fff !important;
                        padding: 10px 20px;
                        font-size: 18px;
                        margin: 0 auto;
                        font-weight: 700;
                        border-radius: 4px;
                        text-transform: uppercase;
                        display: block;
                        width: max-content;
                        cursor: pointer;
                    }
                    .scalecms-ctaButton .generic6{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        max-width: 300px;
                        width: 100%;
                        margin: 20px auto;
                        font-weight: 700;
                        font-size: 16px;
                        text-align: center;
                        color: #ffffff;
                        text-transform: uppercase;
                        letter-spacing: 1px;
                        background: linear-gradient(to bottom, rgba(71, 213, 255, 1) 0%, rgba(30, 204, 255, 1) 37%, rgba(28, 200, 250, 1) 71%, rgba(23, 164, 205, 1) 100%);
                        border-radius: 40px;
                        box-shadow: 0 4px 0 0 rgba(17, 123, 153, 0.7), 0 6px 0 0 rgba(34, 213, 255, 0.7);
                        transition: 0.5s;
                        height: 60px;
                    }
                    .scalecms-ctaButton .generic7{
                        background-color: #C00D13;
                        display: block;
                        max-width: max-content;
                        padding: 15px 20px;
                        margin: 20px auto;
                        text-transform: uppercase;
                        font-weight: 700;
                        color: #fff;
                        border-radius: 22px;
                        font-family: Arial, Helvetica, sans-serif;
                        transition: all 0.5s ease;
                    }
                    .scalecms-ctaButton .align-left{
                        display: block;
                        margin-right: auto;
                    }
                    .scalecms-ctaButton .align-center{
                        display: block;
                        margin: 0px auto;
                    }
                    .scalecms-ctaButton .align-right{
                        display: block;
                        margin-left: auto;
                    }
                    .scalecms-ctaButton-upperText{
                        font-family: Lato;
                        font-size: 16px;
                        font-weight: 700;
                        line-height: 19.2px;
                        text-align: left;
                        color: #020E22;
                        text-align: center;
                        margin-bottom: 15px;
                    }
                    .scalecms-ctaButton-lowerText{
                        font-family: Lato;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 14.4px;
                        text-align: left;
                        color: #020E22B2;
                        text-align: center;
                        margin-top: 15px;
                    }
                    .scalecms-ctaButton-withText{
                        width: max-content;
                        display: flex;
                        align-items: center;
                    }
                    .scalecms-ctaButton-button-img{
                        opacity: 1;
                    }
                    .button-pulse {
                        animation: pulse 2s infinite 3s cubic-bezier(0.25, 0, 0, 1);
                        box-shadow: 0 0 0 0 white;
                    }
                    
                `,
                components: [
                    {
                        type: "text",
                        attributes: { class: "scalecms-ctaButton-upperText" },
                        components: ""
                    },
                    {
                        tagName: 'div',
                        attributes: {class: 'scalecms-ctaButton-popup'},
                        components: {
                            tagName: 'div',
                            attributes: {class: 'scalecms-ctaButton-popup-box'},
                            components: {
                                tagName: 'img',
                                attributes: {class: 'scalecms-ctaButton-popup-box-close', src: 'https://i.imgur.com/fD91m2q.png'}
                            }
                        }
                    },
                    {
                        tagName: 'button',
                        attributes: { class: 'scalecms-ctaButton-button' },
                        components: [
                            {
                                type: "text",
                                components: "Naruči sada!"
                            },
                            {
                                tagName: 'img',
                                attributes: { class: 'scalecms-ctaButton-button-img', src: 'https://backend.scale-cms.com:5000/staticContent/images/83704b63-6cd7-4af3-8bdb-ab058f85c6c6-1725863650842.svg' }
                            },
                        ],
                        draggable: false,
                        removable: false,
                        selectable: false,
                    },
                    {
                        type: "text",
                        attributes: { class: "scalecms-ctaButton-lowerText" },
                        components: ""
                    },
                    
                ]
            }
        }
    });


    editor.BlockManager.add('scalecms-ctaButton-blk', {
        label: 'CTA button',
        content: {
            type: "scalecms-ctaButton_cmp",
        },
        category: {
            label: 'Custom assets',
            label: 'Most Used'
        },
        media: `
        <svg version="1.1" id="Icons" x="0px" y="0px" viewBox="0 0 32 32" style="enable-background:new 0 0 32 32;" xml:space="preserve">
        <style type="text/css">
            .st0{fill:none;stroke:#000000;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
        </style>
        <path class="st0" d="M29,23H3c-1.1,0-2-0.9-2-2V11c0-1.1,0.9-2,2-2h26c1.1,0,2,0.9,2,2v10C31,22.1,30.1,23,29,23z" id="id_101" style="stroke: rgb(255, 255, 255);"></path>
        <path class="st0" d="M13,19L13,19c-1.1,0-2-0.9-2-2v-2c0-1.1,0.9-2,2-2h0c1.1,0,2,0.9,2,2v2C15,18.1,14.1,19,13,19z" id="id_102" style="stroke: rgb(255, 255, 255);"></path>
        <line class="st0" x1="18" y1="13" x2="18" y2="19" id="id_103" style="stroke: rgb(255, 255, 255);"></line>
        <line class="st0" x1="21" y1="13" x2="18" y2="17" id="id_104" style="stroke: rgb(255, 255, 255);"></line>
        <line class="st0" x1="21" y1="19" x2="19" y2="16" id="id_105" style="stroke: rgb(255, 255, 255);"></line>
        </svg>
        `,
        attributes: {
            title: 'CTA button',
        },
        select: true
    });
};