import React from "react";
import "./index.scss";

import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import * as backendModule from "../../modules/backendModule";
import { animateBox } from "../../modules/componentAnimation";

import CustomInput from "../../components/customComponents/CustomInput";
import CustomTextarea from "../../components/customComponents/CustomTextarea";
import CustomCheckbox from "../../components/customComponents/CustomCheckbox";
import { FilteredCustomTable } from "../../components/customComponents/Table";
import Spinner from "../../components/customComponents/Spinner";
import Dropdown from "../../components/customComponents/Dropdown";
import Button from "../../components/customComponents/Button";
import Checkbox from "../../components/customComponents/Checkbox";
import MultiSelect from "../../components/customComponents/MultiSelect";
import countrySpeakingLangList from "../../modules/countrySpeakingLangList.json";
// import countrySpeakingLangList from "../../modules/countrySpeakingLangList.json";
import recensionNameList from "./commentName.json";
import recensionTextList from "./commentText.json";
import { updateTimestamp } from "../../actions/timestampActions";

import { AddImage } from "../../routes/ImagesList";
import ImagesList from "../../routes/ImagesList";

let curTimeout = null;
const RecensionsList = () => {

  const curDispatch = useDispatch();

  const [data, setData] = React.useState();
  const [filters, setFilters] = React.useState([]);
  const [canPaginate, setCanPaginate] = React.useState(false);
  const [spinner, setSpinner] = React.useState(false);
  const [secondarySpinner, setSecondarySpinner] = React.useState(false);
  const [offers, setOffers] = React.useState([]);
  const [baseOffers, setBaseOffers] = React.useState([]);
  const [offersTab, setOffersTab] = React.useState(true);
  const [selectedOffer, setSelectedOffer] = React.useState();

  const [templates, setTemplates] = React.useState([]);
  const [templateTab, setTemplateTab] = React.useState(false);
  const [selectedTemplate, setSelectedTemplate] = React.useState();

  const paginationOffset = React.useRef();
  const curPaginationTimestamp = React.useRef();
  const searchRef = React.useRef();

  const siteSettingsSelector = useSelector(state => state?.siteSettings ?? {});
  const timestampSelector = useSelector(state => state?.timestamp ?? null);
  const userDataSelector = useSelector(state => state?.userData?.userData ?? {});

  const getData = () => {
    paginationOffset.current = 0;
    curPaginationTimestamp.current = Date.now();
    if (!selectedTemplate) return;

    setCanPaginate(false);
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/recensions/getAllRecensionsBySelectedTemplate`,
      data: {
        offset: paginationOffset.current,
        filters: [
          ...filters,
        ],
        RecensionTemplateID: selectedTemplate.ID
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        if (res.data.data.length === 20) {
          paginationOffset.current += 20;
          setTimeout(() => setCanPaginate(true), 500);
        } else {
          setCanPaginate(false);
          paginationOffset.current = -1;
        };
        return setData({ ...res.data, timestamp: Date.now() });
      }
      return setData({ status: "error", data: "SERVER_ERROR", timestamp: Date.now() });
    }).catch(() => {
      return setData({ status: "error", data: "SERVER_ERROR", timestamp: Date.now() });
    });
  };

  const removeRecension = (ID) => {
    setSpinner(true);
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/recensions/removeRecension`,
      data: {
        ID: ID
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === 'ok') {
        getData();
      }
      else {

      }
    }).catch(() => {

    }).finally(() => {
      setSpinner(false);
    });
  }

  const getAllRecensionsTemplate = () => {
    if (!selectedOffer) return;
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/recensionsTemplate/getAllRecensionsTemplate`,
      data: {
        offerID: selectedOffer.ID
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        setTemplates(res.data.data)
      } else {
      };
    }).catch(() => {
    }).finally(() => {
      setSpinner(false);
    });
  }

  const getAllOffersForRecensions = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/recensions/getAllOffersForRecensions`,
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        setBaseOffers(res.data.data)
        setOffers(res.data.data)
      } else {
      };
    }).catch(() => {
    }).finally(() => {
      setSpinner(false);
    });
  }

  const performSearch = () => {
    if (!baseOffers) return;
    let searchString = searchRef.current.value.toLowerCase();

    clearTimeout(curTimeout);
    curTimeout = setTimeout(() => {
      
      let out = baseOffers.filter(offer => offer.OfferName.toLowerCase().includes(searchString));
      setOffers(out)

    }, 500);
  };

  const refreshSiteTemplatesAndRecensions = () => {
    getAllRecensionsTemplate();
    getData();
  }

  React.useEffect(() => {
    getAllOffersForRecensions();
  }, []);

  React.useEffect(() => {
    getAllRecensionsTemplate();
  }, [selectedOffer]);

  React.useEffect(() => {
    getData();
  }, [selectedTemplate]);

  return <div className="route__recensionsList">
    <div className="route__recensionsList__menu">
      <div className="route__recensionsList__offers" style={{ maxHeight: offersTab ? '500px' : '42px' }}>
        <p className="route__recensionsList__offers__head" onClick={() => setOffersTab(e => !e)}>OFFERS TAB {selectedOffer && selectedOffer.name ? <span>{selectedOffer.name}</span> : null}</p>
        <div className="route__recensionsList__offers__itemList" style={{ maxHeight: offersTab ? '500px' : '0px', padding: offers ? '10px 16px' : '0px 16px' }}>
        <CustomInput autocomplete="off" theme="dark" accent="#3F7CEA" placeholder="Search..." style={{width: "250px"}} ref={searchRef} onChange={performSearch} />
          {offers.length ? offers.map(el => {
            return <p className="route__recensionsList__offers__item" onClick={() => { setSelectedOffer({ name: el.OfferName, ID: el.ID, country: el.Country }); setOffersTab(false) }}>{el.OfferName} ({el.Country})</p>
          }) : <p>No Data</p>}
        </div>
      </div>

      <div className="route__recensionsList__offers" style={{ maxHeight: templateTab ? '500px' : '42px', overflow: templateTab ? 'scroll' : 'hidden' }}>
        <p className="route__recensionsList__offers__head" onClick={() => setTemplateTab(e => !e)}>TEMPLATES TAB {selectedTemplate && selectedTemplate.name ? <span>{selectedTemplate.name}</span> : null}</p>
        <div className="route__recensionsList__offers__itemList" style={{ maxHeight: templateTab ? '500px' : '0px' }}>
          {templates && templates.length ? templates.map(el => {
            return <p className="route__recensionsList__offers__item" onClick={() => { setSelectedTemplate({ name: el.Name, ID: el.ID }); setTemplateTab(false) }}>{el.Name}</p>
          }) : <p>No Data</p>}
        </div>
      </div>

      <div className="route__recensionsList__offers" style={{ maxHeight: '42px' }}>
        <p onClick={e => selectedOffer && animateBox(e, <TemplateSettings offer={selectedOffer} allOffers={offers} userData={userDataSelector} setSelectedOffer={setSelectedOffer} onChange={refreshSiteTemplatesAndRecensions} />)}
          className="route__recensionsList__offers__head">
          Template Settings
        </p>
      </div>
    </div>

    {data && data.data.length && <FilteredCustomTable
      key={`domainTable-${siteSettingsSelector.advancedSearch ? "advanced" : "simple"}`}
      theme="dark"
      accent="#48515C"
      style={{ width: "100%" }}
      customColumns={["100px", "180px", "1fr", "70px", "70px"]}
      headers={["ID", "RecensionName", "RecensionText", "Rating", "Country"]}
      filters={siteSettingsSelector.advancedSearch ? [
        { name: "ID", friendlyName: "ID", type: "number" },
        { name: "Name", friendlyName: "Name", type: "string" },
        { name: "Domain", friendlyName: "Domain", type: "string" },
      ] : undefined}
      filterCB={f => setFilters(f)}
      data={(() => {
        if (!data) return [[{ keyID: "noDataSpinner", type: "spinner" }]];
        if (data?.status !== "ok") return [[{ keyID: "noDataError", type: "custom", data: <p>There was an error while fetching data</p> }]]
        if (data.data.length === 0) return [[{ keyID: "noDataError2", type: "custom", data: <p>There is nothing to show.</p> }]]

        let tmp = data.data.map(elem => {
          if (!elem) return;
          return [
            { keyID: String(elem.ID), type: "text", text: elem.ID },
            { keyID: String(elem.ID), type: "text", text: elem.RecensionName },
            { keyID: String(elem.ID), type: "text", text: elem.RecensionText },
            { keyID: String(elem.ID), type: "text", text: elem.Rating },
            { keyID: String(elem.ID), type: "text", text: elem.Country },
            {
              keyID: String(elem.ID), type: "groupNewline", group: [
                {
                  keyID: String(elem.ID), type: "button", text: "Delete", triggerDropdown: true, triggerData: e => {
                    return (<div style={{ display: 'flex', flexDirection: 'column', justfyContent: 'center', alignItems: 'center' }}>
                      <p style={{ color: 'white' }}>Are you sure?</p>
                      <br></br>
                      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Button style={{ display: spinner ? 'none' : 'block', marginRight: '10px' }} accent='rgb(72, 82, 92)' value='YES' onClick={() => removeRecension(elem.ID)} />
                        <Button style={{ display: spinner ? 'none' : 'block' }} accent='rgb(72, 82, 92)' value='NO' onClick={c => { e() }} />
                        <div style={{ display: spinner ? 'block' : 'none' }}><Spinner color='white' width='32px' /></div>
                      </div>
                    </div>);
                  },
                },
                { keyID: String(elem.ID), type: "button", text: "Edit", style: { marginLeft: "10px" }, onClick: e => animateBox(e, <EditRecension id={elem.ID} onChange={getData} data={elem} allRecensions={data?.data} />) },
                {
                  keyID: String(elem.ID), type: "button", text: "Open image", style: { marginLeft: "10px" }, triggerDropdown: true, onClick: e => {
                    let curImg = elem.RecensionImage;
                    if (!curImg) return;
                    let w = window.open("", "_blank");
                    w.document.write("<style>*{margin: 0; padding:0; box-sizing: border-box;}</style>")
                    w.document.write(`<div style="display: flex; justify-content: center; align-items: center; width: 100%; height: auto; min-height: 100vh; background-color: #20262d;"><img src="${curImg}" style="max-width:50%; height:auto;" /></div>`);
                    w.document.close();
                  }
                },
                // { keyID: String(elem.ID), type: "button", text: "Remove", style: { marginLeft: "10px" }, onClick: e => animateBox(e, <RemoveRecension data={elem} onChange={getData} />) },
              ]
            }
          ].filter(f => f);
        }).filter(f => f);
        if (secondarySpinner) tmp.push([{ keyID: "paginationSpinner", type: "spinner" }]);
        return tmp;
      })()}
    />}
  </div>
};

const CreateRecension = props => {

  const curDispatch = useDispatch();

  const [spinner, setSpinner] = React.useState(false);
  const [infoP, setInfoP] = React.useState("");
  const [offers, setOffers] = React.useState([]);
  const [selectedOfferID, setSelectedOfferID] = React.useState();
  const [selectedGender, setSelectedGender] = React.useState();
  const [selectedOfferName, setSelectedOfferName] = React.useState();
  const [selectedOfferCountry, setSelectedOfferCountry] = React.useState();
  const [translations, setTranslations] = React.useState();

  const [selectedImage, setSelectedImage] = React.useState();
  const [textImage, setTextImage] = React.useState();

  const [rating, setRating] = React.useState(0); // To store the selected rating
  const [hover, setHover] = React.useState(0); // To store the hover state

  const [cleanRecensions, setCleanRecensions] = React.useState();

  const recensionNameRef = React.useRef();
  const recensionTextRef = React.useRef();

  const createRecension = () => {
    setInfoP("");
    let data = {
      RecensionName: recensionNameRef.current.value,
      RecensionText: recensionTextRef.current.value,
      Gender: selectedGender ? selectedGender : 'Male',
      RecensionImage: selectedImage ? selectedImage.URL : "https://backend.scale-cms.com:5000/staticContent/images/89935b0a-08f9-4cc8-b3ae-e37aea7de6f7-1707465661699.webp",
      TextImage: textImage ? textImage.URL : null,
      createdBy: props?.createdBy ? props.createdBy : null,
      offerName: props?.data ? props.data.offerName : selectedOfferName,
      offerID: props?.data ? props.data.offerID : selectedOfferID,
      Country: props?.data ? props.data.Country : selectedOfferCountry ? selectedOfferCountry : 'BA',
      Rating: rating
    };

    // for(let elem of translations){
    //   createRecensionTranslation(data, elem)
    // }

    setSpinner(true);
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/recensions/createRecension`,
      data,
      ...backendModule.axiosConfig
    }).then(res => {

      if (res.data.status === "ok") {

        props.onClose().then(() => {
          if (props.onChange) props.onChange();
        });
      } else {
        setInfoP(`There was an error while creating the recension`);
      };
    }).catch((e) => {
      console.log("error ", e)
      setInfoP("Server timed out!");
    }).finally(() => {
      setSpinner(false);
    });
  };


  const createRecensionTranslation = async (recensionData, countryObj) => {
    recensionData.Country = countryObj.code;
    let newOfferData = offers.data.find(elem => elem.OfferName === recensionData.offerName && elem.Country === countryObj.code);
    if (!newOfferData) return;
    recensionData.offerID = newOfferData.ID;

    if (recensionNameList[countryObj.code][selectedGender.toLowerCase()]) {

      let firstName = getRandomElement(recensionNameList[countryObj.code][selectedGender.toLowerCase()]);
      let lastName = getRandomElement(recensionNameList[countryObj.code]['last']);

      recensionData.RecensionName = `${firstName} ${lastName}`;
    }

    const translateMessage = async (text, language, sex) => {
      axios({
        method: "POST",
        url: `${backendModule.backendURL}/openai/openaiTranslate`,
        data: {
          text: text,
          language: language,
          sex: sex
        },
        ...backendModule.axiosConfig
      }).then(res => {
      }).catch(() => {
      }).finally(() => {
        setSpinner(false);
      });
    }

    recensionData.RecensionText = await translateMessage(recensionData.RecensionText, countryObj.language, recensionData.Gender)
  }


  const getAllOffersForRecensions = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/recensions/getAllOffersForRecensions`,
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        setOffers(res.data)
      } else {
        setInfoP(`There was an error while creating the recension`);
      };
    }).catch(() => {
      setInfoP("Server timed out!");
    }).finally(() => {
      setSpinner(false);
    });
  }

  const placeRecensionInfo = async (offerName, offerID) => {

    let firstName = '';
    let lastName = '';
    let text = '';

    let usedRecensions = [];

    await axios({
      method: "POST",
      url: `${backendModule.backendURL}/recensions/getAllRecensionsByOffer`,
      data: {
        offerID: offerID
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        usedRecensions = res.data.data.map(el => el.RecensionText);
      }
    }).catch(() => {
    });


    if (selectedOfferName && selectedGender && selectedOfferCountry) {
      if (recensionNameList[selectedOfferCountry][selectedGender.toLowerCase()] && recensionTextList[selectedOfferCountry][selectedGender.toLowerCase()][offerName]) {
        firstName = getRandomElement(recensionNameList[selectedOfferCountry][selectedGender.toLowerCase()]);
        lastName = getRandomElement(recensionNameList[selectedOfferCountry]['last']);
        text = getRandomElement(recensionTextList[selectedOfferCountry][selectedGender.toLowerCase()][offerName], usedRecensions);


        recensionNameRef.current.value = `${firstName} ${lastName}`;
        recensionTextRef.current.value = `${text}`;
      } else {
        recensionNameRef.current.value = `Test Testic`;
        recensionTextRef.current.value = `Text test komenara`
      }
    }
  }

  const getRandomElement = (mainArray, excludeArray) => {

    if (excludeArray) {

      let availableElements = [];

      availableElements = filterArray(mainArray, excludeArray)

      if (availableElements.length === 0) {
        return '***WRITE Recension***';
      }

      const randomIndex = Math.floor(Math.random() * availableElements.length);
      return availableElements[randomIndex];
    } else {
      const randomIndex = Math.floor(Math.random() * mainArray.length);
      return mainArray[randomIndex];
    }
  }

  const filterArray = (array1, array2) => {
    const excludeSet = new Set(array2);

    const filteredArray = array1.filter(element =>
      !excludeSet.has(element)
    );

    return filteredArray;
  };

  const getCleanRecensions = async() => {
    // if(!props?.data?.offerID) return;
    // console.log("called me here lol ");
    await axios({
      method: "POST",
      url: `${backendModule.backendURL}/recensions/getAllCleanRecensions`,
      data: {
        offerID: props?.data?.offerID
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if(res.data.status === 'ok'){
        setCleanRecensions(res.data.data)
      }else{
        setCleanRecensions([])
      }
    }).catch((err) => {
      console.log("getting clean recensions error ::: ", err)
    });
  }

  React.useEffect(() => {
    getAllOffersForRecensions();
  }, []);

  React.useEffect(() => {
    if (!selectedOfferName) return;
    placeRecensionInfo(selectedOfferName, selectedOfferID);
  }, [selectedOfferName, selectedGender, selectedOfferCountry, selectedOfferID]);


  return <div className="route__recensionsList__createRecension">
    <div className="route__recensionsList__createRecension__wrap">
      <div className="route__recensionsList__createRecension__wrap__spinner" style={{
        opacity: spinner ? 1 : 0,
        pointerEvents: spinner ? "all" : "none"
      }} onClick={e => spinner && e.stopPropagation()}>
        <Spinner color="#3F7CEA" style={{pointerEvents: "none"}} />
      </div>

      <h3 style={{ marginBottom: "20px" }}>{props.id ? "Edit" : "Add"} Recension</h3>

      {!props?.data?.offerID && offers.data && <Dropdown
        inlinePlaceholder="Select Offer"
        theme="dark"
        accent="#fff"
        style={{ marginBottom: '20px' }}
        data={offers?.data.map(offer => {
          return { name: `${offer.OfferName} (${offer.Country})`, value: offer.ID, country: offer.Country, offerName: offer.OfferName };
        })}
        onChange={e => { e?.value && setSelectedOfferID(e?.value); e?.name && setSelectedOfferName(e?.offerName); e?.country && setSelectedOfferCountry(e?.country) }}
        selected={(() => {
          if (offers?.status !== "ok") return null;
          return offers.data.indexOf(offers.data.find(u => u.ID === selectedOfferID));
        })()}
      />}

      <Dropdown
        inlinePlaceholder="Select Recension Gender"
        theme="dark"
        accent="#fff"
        style={{ marginBottom: '20px' }}
        data={[
          { name: 'Male', value: 'Male' },
          { name: 'Female', value: 'Female' },
        ]}
        onChange={e => { e?.value && setSelectedGender(e?.value) }}
        selected={(() => {
          return [
            { name: 'Male', value: 'Male' },
            { name: 'Female', value: 'Female' },
          ].indexOf([
            { name: 'Male', value: 'Male' },
            { name: 'Female', value: 'Female' },
          ].find(u => u.value === selectedGender));
        })()}
      />

      {!props?.data?.Country && countrySpeakingLangList.length && <Dropdown
        inlinePlaceholder="Select Recension Language"
        theme="dark"
        accent="#fff"
        style={{ marginBottom: '20px' }}
        data={countrySpeakingLangList.map(country => {
          return { name: country.name, value: country.code, language: country.language };
        })}

        onChange={e => { e?.value && setSelectedOfferCountry(e?.value) }}
        selected={(() => {
          return countrySpeakingLangList.indexOf(countrySpeakingLangList.find(cr => cr.code === selectedOfferCountry));
        })()}
      />}

      <CustomInput autocomplete="off" ref={recensionNameRef} accent="#fff" theme="dark" placeholder="Recension name" style={{ width: "100%", marginBottom: "20px" }} />
      <CustomInput autocomplete="off" ref={recensionTextRef} accent="#fff" theme="dark" placeholder="Recension.." style={{ width: "100%", marginBottom: "6px" }} />

      {/* <MultiSelect
        headline="Select Translations"
        theme="dark"
        accent="#fff"
        selectBy="name"
        data={countrySpeakingLangList}
        onChange={e => setTranslations(e)}
        suggestedData={[
          { name: 'Bosnia and Herzegovina', code: 'BA', language: 'bosnian' },
          { name: 'Croatia', code: 'HR', language: 'croatian' },
          { name: 'Serbia', code: 'RS', language: 'serbian' },
          { name: 'Montenegro', code: 'ME', language: 'serbian' },
          { name: 'Slovenia', code: 'SI', language: 'slovenian' },
          { name: 'Romania', code: 'RO', language: 'romanian' },
        ]}
        style={{ marginBottom: '20px' }}
      /> */}

      <div className="route__recensionsList__createRecension__wrap__btns" style={{ justifyContent: 'space-evenly' }}>
        <p style={{ marginBottom: '0px', padding: '10px' }} onClick={(e) => {
          animateBox(e, <ImagesList fromComments={true} tag='userImage' onChange={() => curDispatch(updateTimestamp())} style={{ padding: '20px' }} select={true} setSelectedImage={setSelectedImage} />)
        }}>Select User Image</p>
        <p style={{ marginBottom: '0px', padding: '10px' }} onClick={(e) => {
          animateBox(e, <AddImage tag='userImage' onChange={() => curDispatch(updateTimestamp())} />)
        }}>Upload User Image</p>
      </div>
      {selectedImage ? <a href={`${selectedImage.URL}`} target="_blank" style={{ color: 'white' }}>{selectedImage.URL}</a> : null}

      <div className="route__recensionsList__createRecension__wrap__btns" style={{ justifyContent: 'space-evenly' }}>
        <p style={{ marginBottom: '0px', padding: '10px' }} onClick={(e) => {
          animateBox(e, <ImagesList fromComments={true} tag={`${selectedOfferName ? `offername-${selectedOfferName}` : 'textImage'}`} onChange={() => curDispatch(updateTimestamp())} style={{ padding: '20px' }} select={true} setSelectedImage={setTextImage} />)
        }}>Select Text Image</p>
        <p style={{ marginBottom: '0px', padding: '10px' }} onClick={(e) => {
          animateBox(e, <AddImage tag={`${selectedOfferName ? `offername-${selectedOfferName}` : 'textImage'}`} onChange={() => curDispatch(updateTimestamp())} />)
        }}>Upload Text Image</p>
      </div>
      {textImage ? <a href={`${textImage.URL}`} target="_blank" style={{ color: 'white' }}>{textImage.URL}</a> : null}

      <div className="star-rating">
      {[...Array(5)].map((star, index) => {
        const starIndex = index + 1;
          return (
            <span
              key={starIndex}
              className={starIndex <= (hover || rating) ? "star filled" : "star"}
              onClick={() => setRating(starIndex)}
              onMouseEnter={() => setHover(starIndex)}
              onMouseLeave={() => setHover(rating)}
            >
              &#9733;
            </span>
          );
        })}
      </div>

      <div className="route__recensionsList__createRecension__wrap__btns">
        <p onClick={createRecension}>
          <img style={{ marginRight: "15px" }} src="/images/saveBtn.png" />
          <span>Save</span>
        </p>
        <p onClick={props.onClose}>
          <span>Cancel</span>
          <img style={{ marginLeft: "15px" }} src="/images/closeBtn.png" />
        </p>
      </div>

      {infoP && <p className="route__recensionsList__createRecension__wrap__infoP">{infoP}</p>}
    </div>
  </div>

};


const EditRecension = props => {

  const curDispatch = useDispatch();

  const [spinner, setSpinner] = React.useState(false);
  const [infoP, setInfoP] = React.useState("");
  const [offers, setOffers] = React.useState([]);
  const [selectedOfferID, setSelectedOfferID] = React.useState();
  const [selectedGender, setSelectedGender] = React.useState();
  const [selectedOfferName, setSelectedOfferName] = React.useState();
  const [selectedOfferCountry, setSelectedOfferCountry] = React.useState();
  const [selectedImage, setSelectedImage] = React.useState();
  const [textImage, setTextImage] = React.useState();
  const [textareaValue, setTextAreaValue] = React.useState();

  const recensionNameRef = React.useRef();
  const recensionTextRef = React.useRef();

  const editRecension = () => {
    setInfoP("");
    let data = {
      ID: props.data.ID,
      RecensionName: recensionNameRef.current.value ? recensionNameRef.current.value : props.data.RecensionName,
      RecensionText: recensionTextRef.current.value ? recensionTextRef.current.value : props.data.RecensionText,
      createdBy: props.data.createdBy ? props.data.createdBy : null,
      offerName: selectedOfferName ? selectedOfferName : props.data.offerName,
      offerID: selectedOfferID ? selectedOfferID : props.data.offerID,
      Country: selectedOfferCountry ? selectedOfferCountry : props.data.Country,
      Gender: selectedGender ? selectedGender : props.data.Gender,
      RecensionImage: selectedImage ? selectedImage.URL : props.data.RecensionImage,
      TextImage: textImage ? textImage.URL : props.data.TextImage,
    };

    setSpinner(true);
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/recensions/editRecension`,
      data,
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        props.onClose().then(() => {
          if (props.onChange) props.onChange();
        });
      } else {
        setInfoP(`There was an error while editting the recension`);
      };
    }).catch((e) => {
      setInfoP("Server timed out!");
    }).finally(() => {
      setSpinner(false);
    });
  };


  const getAllOffersForRecensions = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/recensions/getAllOffersForRecensions`,
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        setOffers(res.data)
      } else {
        setInfoP(`There was an error while creating the recension`);
      };
    }).catch(() => {
      setInfoP("Server timed out!");
    }).finally(() => {
      setSpinner(false);
    });
  }

  React.useEffect(() => {
    if (recensionTextRef.current) {
      recensionTextRef.current.style.height = 'auto';
      recensionTextRef.current.style.height = `${recensionTextRef.current.scrollHeight}px`;
    }
  }, [textareaValue]);

  const handleTextareaChange = (e) => {
    setTextAreaValue(e.target.value);
  };

  React.useEffect(() => {
    getAllOffersForRecensions();
  }, []);

  

  return <div className="route__recensionsList__createRecension">
    <div className="route__recensionsList__createRecension__wrap">
      <div className="route__recensionsList__createRecension__wrap__spinner" style={{
        opacity: spinner ? 1 : 0,
        pointerEvents: spinner ? "all" : "none"
      }} onClick={e => spinner && e.stopPropagation()}>
        <Spinner color="#3F7CEA" />
      </div>

      <h3 style={{ marginBottom: "20px" }}>{props.id ? "Edit" : "Add"} Recension</h3>
      <CustomInput autocomplete="off" ref={recensionNameRef} accent="#fff" theme="dark" placeholder={`${props?.data?.RecensionName}`} style={{ width: "100%", marginBottom: "20px" }} />
      {/* <CustomInput autocomplete="off" ref={recensionTextRef} accent="#fff" theme="dark" placeholder={`${props?.data?.RecensionText}`} style={{ width: "100%", marginBottom: "20px" }} /> */}
      <textarea placeholder={`${props?.data?.RecensionText}`} ref={recensionTextRef} defaultValue={props?.data?.RecensionText} onChange={handleTextareaChange} style={{maxHeight: "40px"}} />

      <div className="route__recensionsList__createRecension__wrap__btns" style={{ justifyContent: 'space-between', marginTop: "-8px", marginBottom: "12px" }}>
        <p style={{ marginBottom: '0px', padding: '4px 12px', minWidth: '200px', borderBottomColor: "#3f7cea" }} onClick={(e) => {
          animateBox(e, <RecensionBuilder value={recensionTextRef.current.value} onChange={e => {
            recensionTextRef.current.value = e;
            setTextAreaValue(e);
          }} />);
        }}>Recension builder</p>
      </div>

      {offers.data && <Dropdown
        inlinePlaceholder="Select Offer"
        theme="dark"
        accent="#fff"
        style={{ marginBottom: '20px' }}
        data={offers?.data.map(offer => {
          return { name: `${offer.OfferName} (${offer.Country})`, value: offer.ID, country: offer.Country, offerName: offer.offerName };
        })}
        onChange={e => { e?.value && setSelectedOfferID(e?.value); e?.name && setSelectedOfferName(e?.offerName); e?.country && setSelectedOfferCountry(e?.country) }}
        selected={(() => {
          if (offers?.status !== "ok") return null;
          return offers.data.indexOf(offers.data.find(u => u.ID === selectedOfferID));
        })()}
      />}

      <Dropdown
        inlinePlaceholder="Select Recension Gender"
        theme="dark"
        accent="#fff"
        style={{ marginBottom: '20px' }}
        data={[
          { name: 'Male', value: 'Male' },
          { name: 'Female', value: 'Female' },
        ]}
        onChange={e => { e?.value && setSelectedGender(e?.value) }}
        selected={(() => {
          return [
            { name: 'Male', value: 'Male' },
            { name: 'Female', value: 'Female' },
          ].indexOf([
            { name: 'Male', value: 'Male' },
            { name: 'Female', value: 'Female' },
          ].find(u => u.value === selectedGender));
        })()}
      />

      {countrySpeakingLangList.length && <Dropdown
        inlinePlaceholder="Select Recension Language"
        theme="dark"
        accent="#fff"
        style={{ marginBottom: '20px' }}
        data={countrySpeakingLangList.map(country => {
          return { name: country.name, value: country.code, language: country.language };
        })}

        onChange={e => { e?.value && setSelectedOfferCountry(e?.value) }}
        selected={(() => {
          return countrySpeakingLangList.indexOf(countrySpeakingLangList.find(cr => cr.code === selectedOfferCountry));
        })()}
      />}

      {/* <MultiSelect
        headline="Select Translations"
        theme="dark"
        accent="#fff"
        data={countrySpeakingLangList}
        onChange={e => setTranslations(e)}
        suggestedData={[
          { name: 'Bosnia and Herzegovina', code: 'BA', language: 'bosnian' },
          { name: 'Croatia', code: 'HR', language: 'croatian' },
          { name: 'Serbia', code: 'RS', language: 'serbian' },
          { name: 'Montenegro', code: 'ME', language: 'serbian' },
          { name: 'Slovenia', code: 'SI', language: 'slovenian' },
          { name: 'Romania', code: 'RO', language: 'romanian' },
        ]}
        style={{ marginBottom: '20px' }}
      /> */}

      <div className="route__recensionsList__createRecension__wrap__btns" style={{ justifyContent: 'space-evenly' }}>
        <p style={{ marginBottom: '0px', padding: '10px' }} onClick={(e) => {
          animateBox(e, <ImagesList tag='userImage' fromRecensions={true} onChange={() => curDispatch(updateTimestamp())} style={{ padding: '20px' }} select={true} setSelectedImage={setSelectedImage} />)
        }}>Select User Image</p>
        <p style={{ marginBottom: '0px', padding: '10px' }} onClick={(e) => {
          animateBox(e, <AddImage tag='userImage' onChange={() => curDispatch(updateTimestamp())} />)
        }}>Upload User Image</p>
        <p style={{ marginBottom: '0px', padding: '10px' }} onClick={(e) => setSelectedImage('https://backend.scale-cms.com:5000/staticContent/images/89935b0a-08f9-4cc8-b3ae-e37aea7de6f7-1707465661699.webp')}>Remove Image</p>
      </div>
      {selectedImage ? <a href={`${selectedImage.URL}`} target="_blank" style={{ color: 'white' }}>{selectedImage.URL}</a> : props?.data?.RecensionImage ? <a href={`${props?.data?.RecensionImage}`} target="_blank" style={{ color: 'white' }}>{props?.data?.RecensionImage}</a> : null}

      <div className="route__recensionsList__createRecension__wrap__btns" style={{ justifyContent: 'space-evenly' }}>
        <p style={{ marginBottom: '0px', padding: '10px' }} onClick={(e) => {
          animateBox(e, <ImagesList tag='textImage' fromRecensions={true} onChange={() => curDispatch(updateTimestamp())} style={{ padding: '20px' }} select={true} setSelectedImage={setTextImage} />)
        }}>Select Text Image</p>
        <p style={{ marginBottom: '0px', padding: '10px' }} onClick={(e) => {
          animateBox(e, <AddImage tag='textImage' onChange={() => curDispatch(updateTimestamp())} />)
        }}>Upload Text Image</p>
      </div>
      {textImage ? <a href={`${textImage.URL}`} target="_blank" style={{ color: 'white' }}>{textImage.URL}</a> : props?.data?.TextImage ? <a href={`${props?.data?.TextImage}`} target="_blank" style={{ color: 'white' }}>{props?.data?.TextImage}</a> : null}

      <div className="route__recensionsList__createRecension__wrap__btns">
        <p onClick={editRecension}>
          <img style={{ marginRight: "15px" }} src="/images/saveBtn.png" />
          <span>Save</span>
        </p>
        <p onClick={props.onClose}>
          <span>Cancel</span>
          <img style={{ marginLeft: "15px" }} src="/images/closeBtn.png" />
        </p>
      </div>

      {infoP && <p className="route__recensionsList__createRecension__wrap__infoP">{infoP}</p>}
    </div>
  </div>

};

const RecensionBuilder = props => {
  const textareaRef = React.useRef();

  const saveRecension = () => {
    props.onChange(textareaRef.current.value);
    props.onClose();
  };

  const replaceAndWrap = (tag, additionalProps) => {
    if (!textareaRef.current) return;

    let finalProps = [];
    if (additionalProps) {
      for (let key of Object.keys(additionalProps)) {
        if (additionalProps[key].startsWith("??")) {
          let text = prompt(additionalProps[key].substring(2, additionalProps[key].length));
          if (text) {
            additionalProps[key] = text;
          } else {
            return;
          };
        };
        finalProps.push(`${key}="${additionalProps[key]}"`);
      };
    };

    let start = textareaRef.current.value.slice(0, textareaRef.current.selectionStart);
    let selectedText = textareaRef.current.value.slice(textareaRef.current.selectionStart, textareaRef.current.selectionEnd);
    let end = textareaRef.current.value.slice(textareaRef.current.selectionEnd, textareaRef.current.value.length);
    if (!selectedText) return alert("Select the text first!");

    let preparedText = `<${tag}${finalProps.length > 0 ? ` ${finalProps.join(" ")}` : ""}>${selectedText}</${tag}>`;
    let final = `${start}${preparedText}${end}`;
    textareaRef.current.value = final;
  };

  return <div className="route__recensionsList__createRecension">
    <div className="route__recensionsList__createRecension__wrap">
      <h3 style={{marginBottom: "20px"}}>Recension builder</h3>

      <textarea defaultValue={props?.value ?? ""} ref={textareaRef} placeholder="Enter your recension here..." className="route__recensionsList__createRecension__wrap__textarea"></textarea>
      <div className="route__recensionsList__createRecension__wrap__builderButtons">
        <button onClick={() => replaceAndWrap("b")}>Bold text</button>
        <button onClick={() => replaceAndWrap("a", {
          href: "#",
          "data-scrollonclick": "??Enter the class of the form to scroll to"
        })}>Scroll to form</button>
      </div>

      <div className="route__recensionsList__createRecension__wrap__btns">
        <p onClick={saveRecension}>
          <img style={{ marginRight: "15px" }} src="/images/saveBtn.png" />
          <span>Save</span>
        </p>
        <p onClick={props.onClose}>
          <span>Cancel</span>
          <img style={{ marginLeft: "15px" }} src="/images/closeBtn.png" />
        </p>
      </div>
    </div>
  </div>
};

const TemplateSettings = props => {

  const [offersTab, setOffersTab] = React.useState(false);
  const [newSelectedOffer, setNewSelectedOffer] = React.useState(props.offer ? props.offer : null)

  const userDataSelector = useSelector(state => state?.userData?.userData ?? {});
  const siteSettingsSelector = useSelector(state => state?.siteSettings ?? {});
  const [filters, setFilters] = React.useState([]);
  const [spinner, setSpinner] = React.useState(false);

  const [usersTab, setUsersTab] = React.useState(false);
  const [newSelectedUser, setNewSelectedUser] = React.useState();
  const [allUsers, setAllUsers] = React.useState(false);

  const [recensionsTemplates, setRecensionsTemplates] = React.useState([]);

  const getAllUsers = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/users/getAllUsers`,
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        setAllUsers(res.data.data);
      } else {
        setAllUsers({ status: "error", data: "SERVER_ERROR" });
      };
    }).catch(() => {
      setAllUsers({ status: "error", data: "SERVER_ERROR" });
    });
  };

  const getAllRecensionsTemplate = () => {
    if (!newSelectedOffer) return;
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/recensionsTemplate/getAllRecensionsTemplate`,
      data: {
        offerID: newSelectedOffer.ID
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        setRecensionsTemplates(res.data)
        if (props.onChange) props.onChange();
      } else {
      };
    }).catch(() => {
    }).finally(() => {
    });
  }

  const removeRecensionTemplate = (id) => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/recensionsTemplate/removeRecensionsTemplate`,
      data: {
        ID: id
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        getAllRecensionsTemplate();
      } else {
      };
    }).catch(() => {
    }).finally(() => {
    });
  }

  React.useEffect(() => {
    getAllUsers();
  }, []);

  React.useEffect(() => {
    if (newSelectedOffer) {
      getAllRecensionsTemplate();
    }
  }, [newSelectedOffer]);

  return <div className="route__recensionsList__templateSettings">
    <div className="route__recensionsList__templateSettings__wrapper">

      <div className="route__recensionsList__templateSettings__wrapper__close" onClick={() => {props.onClose(); props.onChange()}}>Close</div>

      <div className="route__recensionsList__templateSettings__wrapper__head">

        <div className="route__recensionsList__templateSettings__wrapper__head__item">
          <p>Selected Offer: </p>
          <div className="route__recensionsList__offers" style={{ maxHeight: offersTab ? '500px' : '42px' }}>
            <p className="route__recensionsList__offers__head" onClick={() => setOffersTab(e => !e)}>{newSelectedOffer ? <span>{newSelectedOffer.name}</span> : <span>Select Offer</span>}</p>
            <div className="route__recensionsList__offers__itemList" style={{ maxHeight: offersTab ? '500px' : '0px' }}>
              {props.allOffers.length ? props.allOffers.map(el => {
                return <p className="route__recensionsList__offers__item" onClick={() => { props.setSelectedOffer({ name: el.OfferName, ID: el.ID, country: el.Country }); setNewSelectedOffer({ name: el.OfferName, ID: el.ID, country: el.Country }); setOffersTab(false) }}>{el.OfferName}</p>
              }) : <p>No Data</p>}
            </div>
          </div>
        </div>
        <div className="route__recensionsList__templateSettings__wrapper__head__item">
          <p>Templates By: </p>
          <div className="route__recensionsList__offers" style={{ maxHeight: usersTab ? '500px' : '42px' }}>
            <p className="route__recensionsList__offers__head" onClick={() => setUsersTab(e => !e)}>{newSelectedUser ? <span>{newSelectedUser.name}</span> : props.userData && props.userData.UserInfo.Username ? <span>{props.userData.UserInfo.Username}</span> : null}</p>
            <div className="route__recensionsList__offers__itemList" style={{ maxHeight: usersTab ? '500px' : '0px' }}>
              {allUsers && allUsers.length ? allUsers.map(el => {
                return <p className="route__recensionsList__offers__item" onClick={() => { setNewSelectedUser({ name: el.Username, ID: el.ID }); setUsersTab(false) }}>{el.Username}</p>
              }) : <p>No Data</p>}
            </div>
          </div>
        </div>
        <div className="route__recensionsList__templateSettings__wrapper__head__item" style={{ cursor: 'pointer' }}
          onClick={e => animateBox(e, <CreateTemplate offer={newSelectedOffer} onChange={getAllRecensionsTemplate} />)}>
          <p>Create Template</p>
        </div>
      </div>



      <div className="route__recensionsList__templateSettings__wrapper__body">

        {recensionsTemplates && recensionsTemplates.data && recensionsTemplates?.data?.length && <FilteredCustomTable
          key={`domainTable-${siteSettingsSelector.advancedSearch ? "advanced" : "simple"}`}
          theme="dark"
          accent="#48515C"
          style={{ width: "100%" }}
          customColumns={["100px", "250px", "1fr", "100px", "100px"]}
          headers={["ID", "Template Name", "OfferID", "Comm Amount", "Country"]}
          filters={siteSettingsSelector.advancedSearch ? [
            { name: "ID", friendlyName: "ID", type: "number" },
            { name: "Name", friendlyName: "Name", type: "string" },
            { name: "Domain", friendlyName: "Domain", type: "string" },
          ] : undefined}
          filterCB={f => setFilters(f)}
          data={(() => {
            if (!recensionsTemplates) return [[{ keyID: "noDataSpinner", type: "spinner" }]];
            if (recensionsTemplates?.status !== "ok") return [[{ keyID: "noDataError", type: "custom", data: <p>There was an error while fetching data</p> }]]
            if (recensionsTemplates.data.length === 0) return [[{ keyID: "noDataError2", type: "custom", data: <p>There is nothing to show.</p> }]]


            let tmp = recensionsTemplates.data.map(elem => {
              return [
                { keyID: String(elem.ID), type: "text", text: elem.ID },
                { keyID: String(elem.ID), type: "text", text: elem.Name },
                { keyID: String(elem.ID), type: "text", text: elem.offerID },
                { keyID: String(elem.ID), type: "text", text: JSON.parse(elem.Recensions).length },
                { keyID: String(elem.ID), type: "text", text: elem.Country },
                {
                  keyID: String(elem.ID), type: "groupNewline", group: [
                    {
                      keyID: String(elem.ID), type: "button", text: "Delete", triggerDropdown: true, triggerData: e => {
                        return (<div style={{ display: 'flex', flexDirection: 'column', justfyContent: 'center', alignItems: 'center' }}>
                          <p style={{ color: 'white' }}>Are you sure?</p>
                          <br></br>
                          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Button style={{ display: spinner ? 'none' : 'block', marginRight: '10px' }} accent='rgb(72, 82, 92)' value='YES' onClick={() => removeRecensionTemplate(elem.ID)} />
                            <Button style={{ display: spinner ? 'none' : 'block' }} accent='rgb(72, 82, 92)' value='NO' onClick={c => { e() }} />
                            <div style={{ display: spinner ? 'block' : 'none' }}><Spinner color='white' width='32px' /></div>
                          </div>
                        </div>);
                      },
                    },
                    { keyID: String(elem.ID), type: "button", text: "Edit", style: { marginLeft: "10px" }, onClick: e => animateBox(e, <EditTemplate id={elem.ID} onChange={props.onChange()} offer={elem} />) },
                  ]
                }
              ].filter(f => f);
            });
            // if (secondarySpinner) tmp.push([{ keyID: "paginationSpinner", type: "spinner" }]);
            return tmp;
          })()}
        />}

      </div>

    </div>
  </div>
}

const CreateTemplate = props => {

  const [spinner, setSpinner] = React.useState(false);
  const [infoP, setInfoP] = React.useState("");

  const [offerID, setOfferID] = React.useState(props?.offer?.ID);
  const [offerName, setOfferName] = React.useState(props?.offer?.name);
  const [selectedTemplateCountry, setSelectedTemplateCountry] = React.useState(props?.offer?.country);

  const [selectedRecensions, setSelectedRecensions] = React.useState([]);

  const [recensionsByOfferID, setRecensionsByOfferID] = React.useState([]);

  const nameRef = React.useRef();


  const createRecensionTemplate = () => {
    setInfoP("");
    let data = {
      Name: nameRef.current.value,
      offerName: offerName,
      offerID: offerID,
      Country: selectedTemplateCountry,
      Recensions: selectedRecensions.map(el => el)
    };

    setSpinner(true);

    axios({
      method: "POST",
      url: `${backendModule.backendURL}/recensionsTemplate/createRecensionsTemplate`,
      data,
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        //ovdje implementiraj ChatGPT sa prevodima sa jezike iz translations state
        props.onClose().then(() => {
          if (props.onChange) props.onChange();
        });
      } else {
        setInfoP(`There was an error while creating the recension`);
      };
    }).catch(() => {
      setInfoP("Server timed out!");
    }).finally(() => {
      setSpinner(false);
    });
  };

  //TODO get Recensions with certain offer
  const getAllRecensionsByOffer = () => {

    axios({
      method: "POST",
      url: `${backendModule.backendURL}/recensions/getAllRecensionsByOffer`,
      data: {
        offerID: props.offer.ID
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        return setRecensionsByOfferID({ ...res.data, timestamp: Date.now() });
      }
      return setRecensionsByOfferID({ status: "error", data: "SERVER_ERROR", timestamp: Date.now() });
    }).catch(() => {
      return setRecensionsByOfferID({ status: "error", data: "SERVER_ERROR", timestamp: Date.now() });
    });
  };

  React.useEffect(() => {
    getAllRecensionsByOffer();
  }, [])


  return <div className="create__template">
    <div className="create__template__wrapper">

      <CustomInput autocomplete="off" ref={nameRef} accent="#fff" theme="dark" placeholder="Template name" style={{ width: "100%", marginBottom: "20px" }} />

      {countrySpeakingLangList.length && <Dropdown
        inlinePlaceholder="Select Recension Language"
        theme="dark"
        accent="#fff"
        style={{ marginBottom: '20px' }}
        data={countrySpeakingLangList.map(country => {
          return { name: country.name, value: country.code, language: country.language };
        })}

        onChange={e => { e?.value && setSelectedTemplateCountry(e?.value) }}
        selected={(() => {
          return countrySpeakingLangList.indexOf(countrySpeakingLangList.find(cr => cr.code === selectedTemplateCountry));
        })()}
      />}

      <p onClick={(e) => animateBox(e,
        <MultiSelectRecensions
          headline="Select Recensions"
          theme="dark"
          accent="#fff"
          selectBy="ID"
          data={recensionsByOfferID.data}
          onChange={e => setSelectedRecensions(e)}
          style={{ marginBottom: '20px' }}
        />)}>Select Recensions</p>

      <div className="route__recensionsList__createRecension__wrap__btns">
        <p onClick={() => createRecensionTemplate()}>
          <img style={{ marginRight: "15px" }} src="/images/saveBtn.png" />
          <span>Save</span>
        </p>
        <p onClick={props.onClose}>
          <span>Cancel</span>
          <img style={{ marginLeft: "15px" }} src="/images/closeBtn.png" />
        </p>
      </div>

      {infoP && <p className="route__recensionsList__createRecension__wrap__infoP">{infoP}</p>}

    </div>
  </div>
}

const EditTemplate = props => {

  const [spinner, setSpinner] = React.useState(false);
  const [infoP, setInfoP] = React.useState("");

  const [offerID, setOfferID] = React.useState(props?.offer?.ID);
  const [offerName, setOfferName] = React.useState(props?.offer?.name);
  const [selectedTemplateCountry, setSelectedTemplateCountry] = React.useState(props.offer.Country);

  const [selectedRecensions, setSelectedRecensions] = React.useState(JSON.parse(props.offer.Recensions));
  const [recensionsByOfferID, setRecensionsByOfferID] = React.useState([]);

  const nameRef = React.useRef();

  const editRecensionTemplate = () => {
    setInfoP("");
    let data = {
      ID: props.offer.ID,
      Name: nameRef.current.value ? nameRef.current.value : props.offer.Name,
      Country: selectedTemplateCountry ? selectedTemplateCountry : props.offer.Country,
      Recensions: selectedRecensions.map(el => el)
    };

    setSpinner(true);

    axios({
      method: "POST",
      url: `${backendModule.backendURL}/recensionsTemplate/editRecensionsTemplate`,
      data,
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        //ovdje implementiraj ChatGPT sa prevodima sa jezike iz translations state
        props.onClose().then(() => {
          if (props.onChange) props.onChange();
        });
      } else {
        setInfoP(`There was an error while creating the recension`);
      };
    }).catch(() => {
      setInfoP("Server timed out!");
    }).finally(() => {
      setSpinner(false);
    });
  };

  const getAllRecensionsByOffer = () => {

    axios({
      method: "POST",
      url: `${backendModule.backendURL}/recensions/getAllRecensionsByOffer`,
      data: {
        offerID: props.offer.offerID
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        return setRecensionsByOfferID({ ...res.data, timestamp: Date.now() });
      }
      return setRecensionsByOfferID({ status: "error", data: "SERVER_ERROR", timestamp: Date.now() });
    }).catch(() => {
      return setRecensionsByOfferID({ status: "error", data: "SERVER_ERROR", timestamp: Date.now() });
    });
  };

  React.useEffect(() => {
    getAllRecensionsByOffer();
  }, [])


  return <div className="create__template">
    <div className="create__template__wrapper">

      <CustomInput autocomplete="off" ref={nameRef} accent="#fff" theme="dark" placeholder="Template name" style={{ width: "100%", marginBottom: "20px" }} />

      {countrySpeakingLangList.length && <Dropdown
        inlinePlaceholder={`Select Recension Language - Current: ${props.offer.Country}`}
        theme="dark"
        accent="#fff"
        style={{ marginBottom: '20px' }}
        data={countrySpeakingLangList.map(country => {
          return { name: country.name, value: country.code, language: country.language };
        })}

        onChange={e => { e?.value && setSelectedTemplateCountry(e?.value) }}
        selected={(() => {
          return countrySpeakingLangList.indexOf(countrySpeakingLangList.find(cr => cr.code === selectedTemplateCountry));
        })()}
      />}

      <p onClick={(e) => animateBox(e,
        <MultiSelectRecensions
          headline="Select Recensions"
          theme="dark"
          accent="#fff"
          selectBy="ID"
          data={recensionsByOfferID.data}
          selectedData={selectedRecensions ? selectedRecensions : []}
          onChange={e => setSelectedRecensions(e)}
          style={{ marginBottom: '20px' }}
        />)}>Select Recensions</p>


      <div className="route__recensionsList__createRecension__wrap__btns">
        <p onClick={() => editRecensionTemplate()}>
          <img style={{ marginRight: "15px" }} src="/images/saveBtn.png" />
          <span>Save</span>
        </p>
        <p onClick={props.onClose}>
          <span>Cancel</span>
          <img style={{ marginLeft: "15px" }} src="/images/closeBtn.png" />
        </p>
      </div>

      {infoP && <p className="route__recensionsList__createRecension__wrap__infoP">{infoP}</p>}

    </div>
  </div>
}


const MultiSelectRecensions = props => {

  const [selectedData, setSelectedData] = React.useState(props.selectedData ? props.selectedData : []);
  const [hoveredDataElem, setHoveredDataElem] = React.useState(null);
  const [hoveredDataSuggestedElem, setHoveredDataSuggestedElem] = React.useState(null);
  const [data, setData] = React.useState(props.data ? props.data : []);
  const [suggestedData, setSuggestedData] = React.useState(props.suggestedData ? props.suggestedData : []);
  const [selectedGender, setSelectedGender] = React.useState('');

  const addSelectedData = (item) => {
    let foundElem;
    foundElem = selectedData.find(elem => elem === item);

    if (!foundElem) {
      setSelectedData([...selectedData, item]);
    } else {
      setSelectedData(selectedData.filter(obj => obj !== item));
    }
  }

  const checkIfItemIsSelected = (id) => {
    let checker = selectedData.find(el => el === id)
    if (checker) {
      return 'data--item--selected'
    }
  }

  const changeGenderFilter = (gender) => {
    if(!selectedGender) setSelectedGender(gender);
    if(selectedGender === gender) setSelectedGender('');
    if(selectedGender && selectedGender !== gender) setSelectedGender(gender)
  }


  return <div className="route__recensionsList__multiselect" >
    <div className="route__recensionsList__multiselect__inner" style={{ ...(props.style ?? {}), ...(props.popupStyle ?? {}) }}>

      <div className="route__recensionsList__multiselect__inner__genderBtns">
        <p onClick={() => changeGenderFilter('Male')} style={{borderColor: selectedGender === 'Male' ? 'green' : 'white', color: selectedGender === 'Male' ? 'green' : 'white'}}>Male</p>
        <p onClick={() => changeGenderFilter('Female')} style={{borderColor: selectedGender === 'Female' ? 'green' : 'white', color: selectedGender === 'Female' ? 'green' : 'white'}}>Female</p>
      </div>
      
      {suggestedData && suggestedData.length ? <p className="route__recensionsList__multiselect__inner__suggestedHeadline"
        style={{ color: props.accent ? props.accent : '#fff' }}
      >Suggested:</p> : null}
      {suggestedData && suggestedData.length ? <div className="route__recensionsList__multiselect__inner__data"
        style={{ marginBottom: '20px' }}>
        {suggestedData.map((elem, index) => {
          const isHovered = index === hoveredDataSuggestedElem;

          return <div className={''}
            style={{
              color: props.accent ? props.accent : '#fff',
              border: `1px solid ${props.accent}`,
              boxShadow: isHovered ? `inset 0 0 8px 2px ${props.accent ? props.accent : null}` : 'none',
            }}
            onMouseEnter={(e) => { setHoveredDataSuggestedElem(index) }}
            onMouseLeave={(e) => setHoveredDataSuggestedElem(null)}
            onClick={(e) => { addSelectedData(elem); e.currentTarget.classList.contains("data--item--selected") ? e.currentTarget.classList.remove("data--item--selected") : e.currentTarget.classList.add("data--item--selected") }}
          >
            <p>{elem.name} </p>
          </div>
        })}
      </div> : null}

      <div className="route__recensionsList__multiselect__inner__data">
        {data.map((elem, index) => {
          if(props.currentRecension === elem.ID) return;
          if(selectedGender){
            if(elem.Gender !== selectedGender) return;
          }
          const isHovered = index === hoveredDataElem;
          return <div className={`${checkIfItemIsSelected(elem.ID)}`}
            style={{
              color: props.accent ? props.accent : '#fff',
              border: `1px solid ${props.accent}`,
              boxShadow: isHovered ? `inset 0 0 8px 2px ${props.accent ? props.accent : null}` : 'none',
            }}
            onMouseEnter={(e) => { setHoveredDataElem(index) }}
            onMouseLeave={(e) => setHoveredDataElem(null)}
            onClick={(e) => { addSelectedData(elem.ID); e.currentTarget.classList.contains("data--item--selected") ? e.currentTarget.classList.remove("data--item--selected") : e.currentTarget.classList.add("data--item--selected") }}
          >
            <p>{elem.RecensionName} : {elem.RecensionText}</p>
          </div>
        })}

      </div>

      <div className="route__recensionsList__createRecension__wrap__btns">
        <p onClick={() => { props.onChange(selectedData); props.onClose() }}>
          <img style={{ marginRight: "15px" }} src="/images/saveBtn.png" />
          <span style={{ color: props.accent ? props.accent : '#FFF' }}>Select</span>
        </p>
        <p onClick={props.onClose}>
          <span style={{ color: props.accent ? props.accent : '#FFF' }}>Cancel</span>
          <img style={{ marginLeft: "15px" }} src="/images/closeBtn.png" />
        </p>
      </div>

    </div>
  </div>
}


export default RecensionsList;
export { CreateRecension }