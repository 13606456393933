import gjs from "grapesjs";

/**
 * @param {gjs.Editor} editor
 */
export default editor => {
    editor.Components.addType('scalecms-sidebarThree', {
        isComponent: e => e?.classList?.contains("scalecms-sidebarThree"),
        model: {
            init(){
                this.on('change', e => {
                    let visibleUntil = e?.attributes?.attributes?.['data-maxwidth'];
                    if(isNaN(Number(visibleUntil))) return;
                    if(Number(visibleUntil) < 0) return;

                    this.get("components").each(child => {
                        if(child?.attributes?.tagName === 'style'){
                            child.components(
                            `
                                @media screen and (max-width: ${visibleUntil}px){
                                    .scalecms-sidebarThree{
                                        display: none;
                                    }
                                }
                            `
                            );
                        }
                    })
                });
            },
            defaults: {
                type: "default",
                traits: [
                    { name: "data-maxwidth", label: "Visible until", placeholder: "600", value: "0" },
                ],
                attributes: {
                    class: "scalecms-sidebarThree"
                },
                styles: `
                    .scalecms-sidebarThree {
                        width: 100%;
                        background: transparent;
                        padding: 20px;
                        font-family: 'Arial';
                    }
                    .scalecms-sidebarThree-filters{
                        border-bottom: 3px solid black;
                        border-top: 3px solid black;
                        padding-top: 10px;
                        padding-left: 10px;
                        padding-right: 10px;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: baseline;
                        background: #ecececb5;
                    }
                    .scalecms-sidebarThree-filters-heading{
                        font-size: 25px;
                        font-weight: 700;
                        margin-bottom: 20px;
                    }
                    .scalecms-sidebarThree-filters-item{
                        display: flex;
                        flex-direction: row;
                        justify-content: baseline;
                        align-items: center;
                        margin-bottom: 10px;
                    }
                    .scalecms-sidebarThree-filters-item-checkbox{
                        margin-right: 10px;
                    }
                    .scalecms-sidebarThree-highlites{
                        border-bottom: 3px solid black;
                        border-top: 3px solid black;
                        padding-top: 10px;
                        padding-left: 10px;
                        padding-right: 10px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        background: #ecececb5;
                        margin-top: 30px;
                    }
                    .scalecms-sidebarThree-highlites-item{
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        margin-bottom: 20px;
                        background: #80808026;
                        padding: 10px;
                    }
                    .scalecms-sidebarThree-highlites-item-image{
                        width: 150px;
                        height: 150px;
                    }
                    .scalecms-sidebarThree-highlites-heading{
                        font-size: 25px;
                        font-weight: 700;
                        margin-bottom: 20px;
                        display: block;
                        margin-left: 0;
                        margin-right: auto;
                    }
                    .scalecms-sidebarThree-highlites-item-text{
                        margin-top: 10px;
                        text-align: center;
                    }
                `,
                components: [
                    {
                        tagName: 'style'
                    },
                    {
                        tagName: 'div',
                        attributes: {class: 'scalecms-sidebarThree-filters'},
                        components: [
                            {
                                type: 'text',
                                attributes: {class: 'scalecms-sidebarThree-filters-heading'},
                                components: 'FILTERI:'
                            },
                            {
                                tagName: 'div',
                                attributes: {class: 'scalecms-sidebarThree-filters-item'},
                                components: [
                                    {
                                        type: 'checkbox',
                                        attributes: {class: 'scalecms-sidebarThree-filters-item-checkbox'},
                                    },
                                    {
                                        type: 'text',
                                        components: 'filter-name'
                                    }
                                ]
                            },
                            {
                                tagName: 'div',
                                attributes: {class: 'scalecms-sidebarThree-filters-item'},
                                components: [
                                    {
                                        type: 'checkbox',
                                        attributes: {class: 'scalecms-sidebarThree-filters-item-checkbox'},
                                    },
                                    {
                                        type: 'text',
                                        components: 'filter-name'
                                    }
                                ]
                            },
                            {
                                tagName: 'div',
                                attributes: {class: 'scalecms-sidebarThree-filters-item'},
                                components: [
                                    {
                                        type: 'checkbox',
                                        attributes: {class: 'scalecms-sidebarThree-filters-item-checkbox'},
                                    },
                                    {
                                        type: 'text',
                                        components: 'filter-name'
                                    }
                                ]
                            },
                            {
                                tagName: 'div',
                                attributes: {class: 'scalecms-sidebarThree-filters-item'},
                                components: [
                                    {
                                        type: 'checkbox',
                                        attributes: {class: 'scalecms-sidebarThree-filters-item-checkbox'},
                                    },
                                    {
                                        type: 'text',
                                        components: 'filter-name'
                                    }
                                ]
                            },
                            {
                                tagName: 'div',
                                attributes: {class: 'scalecms-sidebarThree-filters-item'},
                                components: [
                                    {
                                        type: 'checkbox',
                                        attributes: {class: 'scalecms-sidebarThree-filters-item-checkbox'},
                                    },
                                    {
                                        type: 'text',
                                        components: 'filter-name'
                                    }
                                ]
                            },
                        ]
                    },
                    {
                        tagName: 'div',
                        attributes: {class: 'scalecms-sidebarThree-highlites'},
                        components: [
                            {
                                type: 'text',
                                attributes: {class: 'scalecms-sidebarThree-highlites-heading'},
                                components: 'IZJAVE:'
                            },
                            {
                                tagName: 'div',
                                attributes: {class: 'scalecms-sidebarThree-highlites-item'},
                                components: [
                                    {
                                        type: 'image',
                                        attributes: {class: 'scalecms-sidebarThree-highlites-item-image'}
                                    },
                                    {
                                        type: 'text',
                                        attributes: {class: 'scalecms-sidebarThree-highlites-item-text'},
                                        components: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus consectetur nisl nec egestas tincidunt. Proin id varius justo.'
                                    }
                                ]
                            },
                            {
                                tagName: 'div',
                                attributes: {class: 'scalecms-sidebarThree-highlites-item'},
                                components: [
                                    {
                                        type: 'image',
                                        attributes: {class: 'scalecms-sidebarThree-highlites-item-image'}
                                    },
                                    {
                                        type: 'text',
                                        attributes: {class: 'scalecms-sidebarThree-highlites-item-text'},
                                        components: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus consectetur nisl nec egestas tincidunt. Proin id varius justo.'
                                    }
                                ]
                            },
                            {
                                tagName: 'div',
                                attributes: {class: 'scalecms-sidebarThree-highlites-item'},
                                components: [
                                    {
                                        type: 'image',
                                        attributes: {class: 'scalecms-sidebarThree-highlites-item-image'}
                                    },
                                    {
                                        type: 'text',
                                        attributes: {class: 'scalecms-sidebarThree-highlites-item-text'},
                                        components: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus consectetur nisl nec egestas tincidunt. Proin id varius justo.'
                                    }
                                ]
                            },
                            {
                                tagName: 'div',
                                attributes: {class: 'scalecms-sidebarThree-highlites-item'},
                                components: [
                                    {
                                        type: 'image',
                                        attributes: {class: 'scalecms-sidebarThree-highlites-item-image'}
                                    },
                                    {
                                        type: 'text',
                                        attributes: {class: 'scalecms-sidebarThree-highlites-item-text'},
                                        components: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus consectetur nisl nec egestas tincidunt. Proin id varius justo.'
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        }
    });


    editor.BlockManager.add('scalecms-sidebarThree-blk', {
        label: 'Sidebar 3',
        content: {
            type: "scalecms-sidebarThree",
        },
        category: {
            label: 'Sidebars'
        },
        media: `<svg width="100" height="100" viewBox="0 0 24 24"><path d="M19.25,6a.5.5,0,0,1-.5.5h-2a.5.5,0,0,1,0-1h2A.5.5,0,0,1,19.25,6Zm-.5,2.5h-2a.5.5,0,0,0,0,1h2a.5.5,0,0,0,0-1Zm0,3h-2a.5.5,0,0,0,0,1h2a.5.5,0,0,0,0-1Zm2.5-6v13a2.5026,2.5026,0,0,1-2.5,2.5h-14a2,2,0,0,1-2-2V5a2,2,0,0,1,2-2h14A2.5026,2.5026,0,0,1,21.25,5.5Zm-1,0A1.5017,1.5017,0,0,0,18.75,4h-3.5V20h3.5a1.5017,1.5017,0,0,0,1.5-1.5Z"/></svg>`,
        attributes: {
            title: 'Basic sidebar 3',
        },
        select: true
    });
};