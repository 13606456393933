import gjs from "grapesjs";

function script() {

  let scaleLeadForm = document.querySelector(".scalelead-form-open");

  eval(``)
};

/**
 * @param {gjs.Editor} editor
 */
export default (editor, data, trackData) => {


  const findComponentByType = (components, type) => {
    for (let component of components) {
      if (component.get('type') === type) {
        return component;
      }
      if (component.models && component.models.length > 0) {
        let nestedComponent = findComponentByType(component.components, type);
        if (nestedComponent) {
          return nestedComponent;
        }
      } else {
        if (typeof (component.components) === "function") {
          let nestedComponent = findComponentByType(component.components(), type);
          if (nestedComponent) {
            return nestedComponent;
          }
        }
      }
    }
    return null;
  }

  function findForKeyInAttributes(object, changed) {
    // console.log(inner, "OBJECTS ARRAY --- ", object)
    if (!changed) return

    if (object?.attributes?.components?.models) {
      object?.attributes?.components?.models.forEach(elem => {
        findForKeyInAttributes(elem, changed)
      })
    }
  }


  editor.Components.addType('open-form-fb-2_cmp', {
    isComponent: e => e?.classList?.contains("open-form"),
    model: {
      init() {
        this.on("change", e => {

          let formComponent = findComponentByType(editor.DomComponents.getComponents(), 'scalelead-form-open_cmp');

          if(formComponent){
            if (e?.attributes?.attributes?.["data-offers"]) {
              // formComponent.attributes.attributes["data-tokenselect"] = e?.attributes?.attributes?.["data-offers"];
              let curOffer = data.find(o => o.UniqueToken === e?.attributes?.attributes?.["data-offers"]);
              if (curOffer) {
                // formComponent.attributes.attributes["data-domain"] = curOffer.Domain;
                formComponent.set({
                  'attributes': {
                    'data-tokenselect': e?.attributes?.attributes?.["data-offers"],
                    'data-domain': curOffer?.Domain,
                    'data-article': e?.attributes?.attributes?.["data-suffix"],
                    'data-custombuttontext': e?.attributes?.attributes?.["custombuttontext"]
                  }
                });
              }
            }
  
            // formComponent.attributes.attributes["data-tokenselect"] = e?.attributes?.attributes?.["data-offers"];
            // formComponent.attributes.attributes["data-article"] = e?.attributes?.attributes?.["data-suffix"];
            // formComponent.attributes.attributes["data-custombuttontext"] = e?.attributes?.attributes?.["custombuttontext"];

            formComponent.set({
              'attributes': {
                'data-tokenselect': e?.attributes?.attributes?.["data-offers"],
                'data-article': e?.attributes?.attributes?.["data-suffix"],
                'data-custombuttontext': e?.attributes?.attributes?.["custombuttontext"]
              }
            });

          }

        });
      },
      defaults: {
        type: "default",
        traits(component) {

          let offerData = data.map(o => {
            return {
              id: o.UniqueToken,
              name: o.FriendlyName ? `${o.FriendlyName} (${o.TakenOfferName})` : o.TakenOfferName
            };
          }).sort((a, b) => {
            return String(a.name).localeCompare(b.name);
          });
          offerData = [{ id: null, name: "Not selected" }, ...offerData];
          return [
            {
              type: 'select',
              label: 'Select an offer',
              name: 'data-offers',
              options: offerData,
            },
            {
              name: "country", type: "select", options: [
                { id: "custom", name: "Custom" },
                { id: "ba", name: "Bosna", selected: true },
                { id: "rs", name: "Srbija" },
                { id: "hr", name: "Hrvatska" },
                { id: "me", name: "Crna Gora" }
              ], value: "ba"
            },
            { name: "data-suffix", label: "Lead UID (Name suffix in CRM)", placeholder: "CRM, LV...", value: "" },
            { name: "custombuttontext", label: "Button text", placeholder: "Buy now, Order now...", value: "" }
          ]
        },
        attributes: {
          class: "open-form-fb-2"
        },
        script,
        styles: `
          .open-form-fb-2{
            border: 1px solid #cfcfcf;
            border-radius: 10px;
            padding: 16px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 10px;
            padding: 16px;
            max-width: 600px;
            margin: 0 auto;
          }
          .open-form-fb-2 .scalelead-form-open{
            width: 100%;
            padding: 0px;
            max-width: 560px;
          }
          .open-form-fb-2 .scalelead-form-open .scalelead-form-open-head{
            display: flex;
            gap: 10px;
            flex-direction: row;
          }
          .open-form-fb-2 .scalelead-form-open .scalelead-form-open-head > label{
            font-size: 22px;
            margin-bottom: 10px;
            display: none;
          }
          .open-form-fb-2 .scalelead-form-open .scalelead-form-open-head > input{
            padding-left: 5px;
            height: 42px;
            width: 100%;
            border: 1px solid #cfcfcf;
            border-radius: 5px;
            font-size: 16px;
            outline: none;
          }
          .open-form-fb-2 .scalelead-form-open > button{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 62px;
            border-radius: 10px;
            font-family: Merriweather, serif;
            font-size: 19px;
            background-color: #0C6CDA;
            transition: background-color 0.3s;
            cursor: pointer;
            line-height: 26px;
            color: #fff;
            font-weight: 700;
          }
          .open-form-fb-2 .scalelead-form-open > button:hover{
            background-color: #0b5bb8;
          }
        `,
        components: [
          {
            type: 'scalelead-form-open_cmp',
            attributes: {
              class: 'scalelead-form-open',
              'data-token': '',
              'data-tokenselect': '',
              'data-domain': '',
              'data-article': '',
              'language': '',
              'data-custombuttontext': ''
            }
          }
        ]
      }
    }
  });


  editor.BlockManager.add('open-form-fb-2_blk', {
    label: 'Open form FB 2',
    content: {
      type: "open-form-fb-2_cmp",
    },
    category: {
      label: 'Open Forms'
    },
    media: "<img src='/images/openForm1.svg' style='width: 100%; height: auto; filter: invert(1) hue-rotate(180deg);' />",
    attributes: {
      title: 'Open Form FB 2',
    },
    select: true
  });
};