import gjs from "grapesjs";

/**
 * @param {gjs.Editor} editor
 */
export default editor => {
    editor.Components.addType('scalecms-statementBox', {
        isComponent: e => e?.classList?.contains("scalecms-statementBox"),
        model: {
            defaults: {
                type: "default",
                attributes: {
                    class: "scalecms-statementBox"
                },
                styles: `
                    .scalecms-statementBox{
                        display: flex;
                        flex-direction: row;
                        width: 95%;
                        padding: 20px;
                        box-shadow: 0 0 4px gray;
                        margin-left: auto;
                        margin-right: auto;
                        margin-top: 20px;
                        background: #b9b6b640;
                        border-bottom: 10px solid gray;
                        border-radius: 10px;
                        font-family: 'Arial';
                    }
                    .scalecms-statementBox-left{
                        margin-right: 10px;
                        width: 150px;
                        height: 150px;
                        max-width: 500px;
                        max-height: 500px;
                    }

                    @media screen and (max-width: 600px){
                        .scalecms-statementBox{
                            flex-direction: column;
                        }
                        .scalecms-statementBox-left{
                            display: block;
                            margin-left: auto;
                            margin-right: auto;
                        }
                        .scalecms-statementBox-right{
                            margin-top: 10px;
                        }
                    }
                `,
                components: [
                    {
                        type: 'image',
                        attributes: {class: 'scalecms-statementBox-left'}
                    },
                    {
                        type: 'text',
                        attributes: {class: 'scalecms-statementBox-right'},
                        components: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin ornare felis ac velit semper, non ullamcorper tortor dapibus. Donec nisi dui, malesuada vitae sapien sed, luctus laoreet est.'
                    }
                ]
            }
        }
    });


    editor.BlockManager.add('scalecms-statementBox-blk', {
        label: 'Statement box',
        content: {
            type: "scalecms-statementBox",
        },
        category: {
            label: 'Custom assets'
        },
        media: `
        <svg version="1.1" x="0px" y="0px" width="100px" height="100px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
        <g id="_x36_35_x2C__Bill_x2C__excel_x2C__file_x2C__invoice_x2C__statement">
        <g>
        <g>
        <g>
        <g>
        <path style="fill: rgb(255, 255, 255);" d="M434.451,442.389H145.998c-1.657,0-3-1.343-3-3V43.543c0-1.657,1.343-3,3-3h198.863
                                c0.796,0,1.559,0.316,2.121,0.879l89.59,89.592c0.563,0.563,0.879,1.326,0.879,2.121v306.254
                                C437.451,441.046,436.108,442.389,434.451,442.389z M148.998,436.389h282.453V134.377l-87.833-87.834h-194.62V436.389z" id="id_101"></path>
        </g>
        <g>
        <path style="fill: rgb(255, 255, 255);" d="M434.471,136.166c-0.006,0-0.014-0.001-0.02,0h-89.59c-1.657,0-3-1.343-3-3V43.543
                                c0-1.213,0.731-2.308,1.853-2.772c1.122-0.464,2.411-0.207,3.27,0.651l89.394,89.427c0.668,0.55,1.094,1.384,1.094,2.317
                                C437.471,134.823,436.128,136.166,434.471,136.166z M347.861,130.166h79.349l-79.349-79.378V130.166z" id="id_102"></path>
        </g>
        <g>
        <path style="fill: rgb(255, 255, 255);" d="M145.998,425.09h-27.074c-1.657,0-3-1.343-3-3V26.244c0-1.657,1.343-3,3-3h198.862
                                c1.091,0,2.096,0.592,2.624,1.546l9.588,17.299c0.515,0.929,0.5,2.062-0.039,2.977c-0.539,0.916-1.522,1.478-2.585,1.478
                                H148.998V422.09C148.998,423.747,147.655,425.09,145.998,425.09z M121.924,419.09h21.074V43.543c0-1.657,1.343-3,3-3h176.283
                                l-6.263-11.299H121.924V419.09z" id="id_103"></path>
        </g>
        <g>
        <path style="fill: rgb(255, 255, 255);" d="M218.229,107.586h-53.397c-1.657,0-3-1.343-3-3V89.545c0-1.657,1.343-3,3-3h53.397
                                c1.657,0,3,1.343,3,3v15.041C221.229,106.243,219.886,107.586,218.229,107.586z M167.832,101.586h47.397v-9.041h-47.397
                                V101.586z" id="id_104"></path>
        </g>
        <g>
        <path style="fill: rgb(255, 255, 255);" d="M287.42,107.586h-53.397c-1.657,0-3-1.343-3-3V89.545c0-1.657,1.343-3,3-3h53.397
                                c1.657,0,3,1.343,3,3v15.041C290.42,106.243,289.077,107.586,287.42,107.586z M237.022,101.586h47.397v-9.041h-47.397V101.586z
                                " id="id_105"></path>
        </g>
        <g>
        <path style="fill: rgb(255, 255, 255);" d="M218.229,148.199h-53.397c-1.657,0-3-1.343-3-3v-15.042c0-1.657,1.343-3,3-3h53.397
                                c1.657,0,3,1.343,3,3v15.042C221.229,146.856,219.886,148.199,218.229,148.199z M167.832,142.199h47.397v-9.042h-47.397
                                V142.199z" id="id_106"></path>
        </g>
        <g>
        <path style="fill: rgb(255, 255, 255);" d="M287.42,148.199h-53.397c-1.657,0-3-1.343-3-3v-15.042c0-1.657,1.343-3,3-3h53.397
                                c1.657,0,3,1.343,3,3v15.042C290.42,146.856,289.077,148.199,287.42,148.199z M237.022,142.199h47.397v-9.042h-47.397V142.199z
                                " id="id_107"></path>
        </g>
        <g>
        <path style="fill: rgb(255, 255, 255);" d="M218.229,188.843h-53.397c-1.657,0-3-1.343-3-3V170.77c0-1.657,1.343-3,3-3h53.397
                                c1.657,0,3,1.343,3,3v15.073C221.229,187.5,219.886,188.843,218.229,188.843z M167.832,182.843h47.397v-9.073h-47.397V182.843z
                                " id="id_108"></path>
        </g>
        <g>
        <path style="fill: rgb(255, 255, 255);" d="M405.873,188.843H234.022c-1.657,0-3-1.343-3-3V170.77c0-1.657,1.343-3,3-3h171.851
                                c1.657,0,3,1.343,3,3v15.073C408.873,187.5,407.53,188.843,405.873,188.843z M237.022,182.843h165.851v-9.073H237.022V182.843z
                                " id="id_109"></path>
        </g>
        <g>
        <path style="fill: rgb(255, 255, 255);" d="M218.229,229.455h-53.397c-1.657,0-3-1.343-3-3v-15.041c0-1.657,1.343-3,3-3h53.397
                                c1.657,0,3,1.343,3,3v15.041C221.229,228.112,219.886,229.455,218.229,229.455z M167.832,223.455h47.397v-9.041h-47.397
                                V223.455z" id="id_110"></path>
        </g>
        <g>
        <path style="fill: rgb(255, 255, 255);" d="M405.873,229.455H234.022c-1.657,0-3-1.343-3-3v-15.041c0-1.657,1.343-3,3-3h171.851
                                c1.657,0,3,1.343,3,3v15.041C408.873,228.112,407.53,229.455,405.873,229.455z M237.022,223.455h165.851v-9.041H237.022
                                V223.455z" id="id_111"></path>
        </g>
        <g>
        <path style="fill: rgb(255, 255, 255);" d="M218.229,270.066h-53.397c-1.657,0-3-1.343-3-3v-15.041c0-1.657,1.343-3,3-3h53.397
                                c1.657,0,3,1.343,3,3v15.041C221.229,268.724,219.886,270.066,218.229,270.066z M167.832,264.066h47.397v-9.041h-47.397
                                V264.066z" id="id_112"></path>
        </g>
        <g>
        <path style="fill: rgb(255, 255, 255);" d="M405.873,270.066H234.022c-1.657,0-3-1.343-3-3v-15.041c0-1.657,1.343-3,3-3h171.851
                                c1.657,0,3,1.343,3,3v15.041C408.873,268.724,407.53,270.066,405.873,270.066z M237.022,264.066h165.851v-9.041H237.022
                                V264.066z" id="id_113"></path>
        </g>
        <g>
        <path style="fill: rgb(255, 255, 255);" d="M218.229,310.68h-53.397c-1.657,0-3-1.343-3-3v-15.042c0-1.657,1.343-3,3-3h53.397
                                c1.657,0,3,1.343,3,3v15.042C221.229,309.337,219.886,310.68,218.229,310.68z M167.832,304.68h47.397v-9.042h-47.397V304.68z" id="id_114"></path>
        </g>
        <g>
        <path style="fill: rgb(255, 255, 255);" d="M405.873,310.68H234.022c-1.657,0-3-1.343-3-3v-15.042c0-1.657,1.343-3,3-3h171.851
                                c1.657,0,3,1.343,3,3v15.042C408.873,309.337,407.53,310.68,405.873,310.68z M237.022,304.68h165.851v-9.042H237.022V304.68z" id="id_115"></path>
        </g>
        <g>
        <path style="fill: rgb(255, 255, 255);" d="M218.229,351.292h-53.397c-1.657,0-3-1.343-3-3V333.25c0-1.657,1.343-3,3-3h53.397
                                c1.657,0,3,1.343,3,3v15.042C221.229,349.949,219.886,351.292,218.229,351.292z M167.832,345.292h47.397v-9.042h-47.397
                                V345.292z" id="id_116"></path>
        </g>
        <g>
        <path style="fill: rgb(255, 255, 255);" d="M405.873,351.292H234.022c-1.657,0-3-1.343-3-3V333.25c0-1.657,1.343-3,3-3h171.851
                                c1.657,0,3,1.343,3,3v15.042C408.873,349.949,407.53,351.292,405.873,351.292z M237.022,345.292h165.851v-9.042H237.022
                                V345.292z" id="id_117"></path>
        </g>
        <g>
        <path style="fill: rgb(255, 255, 255);" d="M218.229,391.937h-53.397c-1.657,0-3-1.343-3-3v-15.073c0-1.657,1.343-3,3-3h53.397
                                c1.657,0,3,1.343,3,3v15.073C221.229,390.594,219.886,391.937,218.229,391.937z M167.832,385.937h47.397v-9.073h-47.397
                                V385.937z" id="id_118"></path>
        </g>
        <g>
        <path style="fill: rgb(255, 255, 255);" d="M405.873,391.937H234.022c-1.657,0-3-1.343-3-3v-15.073c0-1.657,1.343-3,3-3h171.851
                                c1.657,0,3,1.343,3,3v15.073C408.873,390.594,407.53,391.937,405.873,391.937z M237.022,385.937h165.851v-9.073H237.022
                                V385.937z" id="id_119"></path>
        </g>
        <g>
        <path style="fill: rgb(255, 255, 255);" d="M324.805,468.587H125.723c-1.657,0-3-1.343-3-3s1.343-3,3-3h199.082c1.657,0,3,1.343,3,3
                                S326.462,468.587,324.805,468.587z" id="id_120"></path>
        </g>
        <g>
        <path style="fill: rgb(255, 255, 255);" d="M390.705,489.236H226.407c-1.657,0-3-1.343-3-3s1.343-3,3-3h164.298c1.657,0,3,1.343,3,3
                                S392.362,489.236,390.705,489.236z" id="id_121"></path>
        </g>
        <g>
        <path style="fill: rgb(255, 255, 255);" d="M428.06,489.236h-20.526c-1.657,0-3-1.343-3-3s1.343-3,3-3h20.526c1.657,0,3,1.343,3,3
                                S429.717,489.236,428.06,489.236z" id="id_122"></path>
        </g>
        <g>
        <path style="fill: rgb(255, 255, 255);" d="M155.869,488.609H78.028c-1.657,0-3-1.343-3-3s1.343-3,3-3h77.841c1.657,0,3,1.343,3,3
                                S157.526,488.609,155.869,488.609z" id="id_123"></path>
        </g>
        </g>
        </g>
        </g>
        </g>
        <g id="Layer_1">
        </g>
        </svg>
        `,
        attributes: {
            title: 'Statement box',
        },
        select: true
    });
};