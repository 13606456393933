import gjs from "grapesjs";

function script() {
    if (!this) return;
}

/**
 * @param {gjs.Editor} editor
 */
export default editor => {
    editor.Components.addType('scalecms-footerFour', {
        isComponent: e => e?.classList?.contains("scalecms-footerFour"),
        model: {
            defaults: {
                type: "default",
                traits: [

                ],
                attributes: {
                    class: "scalecms-footerFour"
                },
                script,
                styles: `
                    .scalecms-footerFour {
                        width: 100%;
                        background: #292929;
                        padding-left: 200px;
                        padding-right: 200px;
                        padding-top: 30px;
                        padding-bottom: 30px;
                        font-family: 'Arial';
                    }
                    .scalecms-footerFour-gridbox{
                        display: grid;
                        grid-template-columns: 1fr 1fr 1fr 1fr;
                    }
                    .scalecms-footerFour-gridbox-left{
                        display: flex;
                        flex-direction: column;
                        align-items: baseline;
                        justify-content: flex-start;
                    }
                    .scalecms-footerFour-gridbox-logo{
                        width: 200px;
                        height: 100px;
                        margin-bottom: 10px;
                    }
                    .scalecms-footerFour-gridbox-text{
                        font-size: 16px;
                        color: white;
                    }
                    .scalecms-footerFour-gridbox-phone{
                        font-size: 18px;
                        font-weight: 700;
                        color: white;
                        margin-top: 20px;
                    }
                    .scalecms-footerFour-gridbox-center{
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: baseline;
                    }
                    .scalecms-footerFour-gridbox-center-heading{
                        color: white;
                        font-weight: 700;
                    }
                    .scalecms-footerFour-gridbox-center-link{
                        color: white;
                        margin-top: 10px;
                        cursor: pointer;
                    }
                    .scalecms-footerFour-gridbox-right-link{
                        color: white;
                        margin-top: 10px;
                        cursor: pointer;
                    }
                    .scalecms-footerFour-gridbox-right-heading{
                        color: white;
                        font-weight: 700;
                    }
                    .scalecms-footerFour-gridbox-right{
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: baseline;
                    }
                    .scalecms-footerFour-socials{
                        width: 200px;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        margin-top: 30px;
                        margin-left: auto;
                        margin-right: auto;
                    }
                    .scalecms-footerFour-socials-item{
                        width: 36px;
                        height: 36px;
                        cursor: pointer;
                    }

                    @media screen and (max-width: 1462px){
                        .scalecms-footerFour{
                            padding-left: 50px;
                            padding-right: 50px;
                        }
                    }

                    @media screen and (max-width: 1100px){
                        .scalecms-footerFour{
                            padding-left: 20px;
                            padding-right: 20px;
                        }
                        .scalecms-footerFour-gridbox {
                            grid-template-columns: 1fr 1fr;
                            gap: 15px;
                        }
                    }
                    @media screen and (max-width: 600px){
                        .scalecms-footerFour-gridbox {
                            grid-template-columns: 1fr;
                            gap: 30px;
                        }
                    }
                `,
                components: [
                    {
                        tagName: 'div',
                        attributes: {class: 'scalecms-footerFour-gridbox'},
                        components: [
                            {
                                tagName: 'div',
                                attributes: {class: 'scalecms-footerFour-gridbox-left'},
                                components: [
                                    {
                                        type: 'image',
                                        attributes: {class: 'scalecms-footerFour-gridbox-logo'}
                                    },
                                    {
                                        type: 'text',
                                        attributes: {class: 'scalecms-footerFour-gridbox-text'},
                                        components: 'Lorem ispum address 14'
                                    },
                                    {
                                        type: 'text',
                                        attributes: {class: 'scalecms-footerFour-gridbox-phone'},
                                        components: '+387624578921'
                                    }
                                ]
                            },
                            {
                                tagName: 'div',
                                attributes: {class: 'scalecms-footerFour-gridbox-center'},
                                components: [
                                    {
                                        type: 'text',
                                        attributes: {class: 'scalecms-footerFour-gridbox-center-heading'},
                                        components: 'NAVIGACIJA'
                                    },
                                    {
                                        type: 'link',
                                        attributes: {class: 'scalecms-footerFour-gridbox-center-link'},
                                        components: 'Početna'
                                    },
                                    {
                                        type: 'link',
                                        attributes: {class: 'scalecms-footerFour-gridbox-center-link'},
                                        components: 'O nama'
                                    },
                                    {
                                        type: 'link',
                                        attributes: {class: 'scalecms-footerFour-gridbox-center-link'},
                                        components: 'Kontakt'
                                    },
                                    {
                                        type: 'link',
                                        attributes: {class: 'scalecms-footerFour-gridbox-center-link'},
                                        components: 'Arhiva'
                                    },
                                ]
                            },
                            {
                                tagName: 'div',
                                attributes: {class: 'scalecms-footerFour-gridbox-center'},
                                components: [
                                    {
                                        type: 'text',
                                        attributes: {class: 'scalecms-footerFour-gridbox-center-heading'},
                                        components: 'INFORMACIJE'
                                    },
                                    {
                                        type: 'link',
                                        attributes: {class: 'scalecms-footerFour-gridbox-center-link'},
                                        components: 'Kako se prijaviti'
                                    },
                                    {
                                        type: 'link',
                                        attributes: {class: 'scalecms-footerFour-gridbox-center-link'},
                                        components: 'Studentske aktivnosti'
                                    },
                                    {
                                        type: 'link',
                                        attributes: {class: 'scalecms-footerFour-gridbox-center-link'},
                                        components: 'Podrška'
                                    },
                                    {
                                        type: 'link',
                                        attributes: {class: 'scalecms-footerFour-gridbox-center-link'},
                                        components: 'Raspored'
                                    },
                                ]
                            },
                            {
                                tagName: 'div',
                                attributes: {class: 'scalecms-footerFour-gridbox-right'},
                                components: [
                                    {
                                        type: 'text',
                                        attributes: {class: 'scalecms-footerFour-gridbox-right-heading'},
                                        components: 'LINKOVI'
                                    },
                                    {
                                        type: 'link',
                                        attributes: {class: 'scalecms-footerFour-gridbox-right-link'},
                                        components: 'Pošalji prijavu odmah'
                                    },
                                    {
                                        type: 'link',
                                        attributes: {class: 'scalecms-footerFour-gridbox-right-link'},
                                        components: 'Blog'
                                    },
                                    {
                                        type: 'link',
                                        attributes: {class: 'scalecms-footerFour-gridbox-right-link'},
                                        components: 'Novosti'
                                    },
                                    {
                                        type: 'link',
                                        attributes: {class: 'scalecms-footerFour-gridbox-right-link'},
                                        components: 'API'
                                    },
                                ]
                            }
                        ]
                    },
                    {
                        tagName: 'div',
                        attributes: {class: 'scalecms-footerFour-socials'},
                        components: [
                            {
                                tagName: 'img',
                                attributes: {class: 'scalecms-footerFour-socials-item', src: 'https://i.imgur.com/yh45vCH.png'}
                            },
                            {
                                tagName: 'img',
                                attributes: {class: 'scalecms.footerFour-socials-item', src: 'https://i.imgur.com/5ceONGU.png'}
                            },
                            {
                                tagName: 'img',
                                attributes: {class: 'scalecms-footerFour-socials-item', src: 'https://i.imgur.com/Ig9si7C.png'}
                            },
                            {
                                tagName: 'img',
                                attributes: {class: 'scalecms-footerFour-socials-item', src: 'https://i.imgur.com/tSuXN8P.png'}
                            }
                        ]
                    }
                ]
            }
        }
    });


    editor.BlockManager.add('scalecms-footerFour-blk', {
        label: 'Footer 4',
        content: {
            type: "scalecms-footerFour",
        },
        category: {
            label: 'Footers'
        },
        media: `<svg viewBox="0 0 24 24"><g data-name="layout 35"><path d="M19,2H5A3,3,0,0,0,2,5V19a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V5A3,3,0,0,0,19,2Zm1,17a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V5A1,1,0,0,1,5,4H19a1,1,0,0,1,1,1Z"/><path d="M17,14H7a2,2,0,0,0-2,2v1a2,2,0,0,0,2,2H17a2,2,0,0,0,2-2V16A2,2,0,0,0,17,14Zm0,3H7V16H17Z"/></g></svg>`,
        attributes: {
            title: 'Basic Footer 4',
        },
        select: true
    });
};