import React from "react";
import "./index.scss";

import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import * as timestampActions from "../../actions/timestampActions";
import * as userDataActions from "../../actions/userDataActions";
import * as backendModule from "../../modules/backendModule";
import animateModule from "../../modules/animateModule";

import Spinner from "../customComponents/Spinner";
import CustomInput from "../customComponents/CustomInput";
import { animateBox } from "../../modules/componentAnimation";

const Sidebar = () => {
    const mainNavigate = useNavigate();
    const mainLocation = useLocation();
    const mainDispatch = useDispatch();
    const userDataSelector = useSelector(state => state?.userData?.userData ?? {});

    const [selected, setSelected] = React.useState("");
    const [modalVisible, setModalVisible] = React.useState();
    const [modalSpinner, setModalSpinner] = React.useState();

    const modalRef = React.useRef();

    const animateNavigate = to => animateModule(mainNavigate, to, document.querySelector(".component__mainContent"));

    const logOut = () => {
        setModalVisible();
        setModalSpinner(true);
        axios({
            method: "GET",
            url: `${backendModule.backendURL}/auth/logout`,
            ...backendModule.axiosConfig
        }).then((res) => {
            if (res.data.status === "ok") {
                mainDispatch(userDataActions.setUserData(false, null));
                mainDispatch(timestampActions.updateTimestamp());
            };
        }).catch(() => null).finally(() => {
            setModalSpinner(false);
        });
    };

    React.useEffect(() => {
        if (!selected) return;
        if (selected === "campaign") return;
        if (mainLocation.pathname.startsWith(`/main/${selected}`)) return;
        animateNavigate(`/main/${selected}`);
    }, [selected]);

    React.useEffect(() => {
        if (modalVisible) {
            if (!modalRef.current) return;
            modalRef.current.style.top = `${modalVisible.y}px`;
            modalRef.current.style.left = `${modalVisible.x}px`;
        };
    }, [modalVisible]);

    React.useEffect(() => {
        if (!selected) {
            if (mainLocation.pathname.startsWith("/main")) {
                let tmp = mainLocation.pathname.replace("/main/", "");
                tmp = tmp.split("/")[0];
                setSelected(tmp);
            };
        };
        if (mainLocation.pathname.startsWith("/main/campaign/")) {
            if (selected !== "campaign") setSelected("campaign");
        }
    }, [mainLocation.pathname]);

    React.useEffect(() => {
        let handler = e => {
            e.stopPropagation();
            if (modalVisible) setModalVisible();
        };

        document.body.addEventListener("click", handler);

        return () => {
            try {
                document.body.removeEventListener("click", handler);
            } catch {};
        };
    }, [modalVisible]);


    return <div className="component__sidebar">
        <div className="component__sidebar__modal" ref={modalRef} style={{
            opacity: modalVisible ? 1 : 0,
            pointerEvents: modalVisible ? "all" : "none",
        }}>
            <p onClick={(e) => {
                setModalSpinner(true);
                animateBox(e, <ChangePassword onChange={() => setModalSpinner(false)} />);
            }}><img src="/images/logOut.png" /> <span>Change password</span></p>

            <p onClick={() => {
                setModalSpinner(true);
                logOut();
            }}><img src="/images/logOut.png" /> <span>Log out</span></p>
        </div>


        {selected === "campaign" && <p className={`component__sidebar__item ${selected === "campaign" ? "component__sidebar__item--active" : ""}`}><img src="/images/campaignIcon.png" /> Selected campaign</p>}
        <p onClick={() => setSelected("campaign-list")} className={`component__sidebar__item ${selected === "campaign-list" ? "component__sidebar__item--active" : ""}`}><img src="/images/campaignIcon.png" /> Campaign list</p>
        <p onClick={() => setSelected("route-list")} className={`component__sidebar__item ${selected === "route-list" ? "component__sidebar__item--active" : ""}`}><img src="/images/staticWebsiteIcon.png" /> Routes</p>
        <p onClick={() => setSelected("domain-list")} className={`component__sidebar__item ${selected === "domain-list" ? "component__sidebar__item--active" : ""}`}><img src="/images/domainIcon.png" /> Domain list</p>
        <div className="component__sidebar__itemCollapsed" onClick={e => e.currentTarget.classList.toggle("component__sidebar__itemCollapsed--active")}>
            <img src="/images/websiteGroup.png" />
            <p>Website groups</p>
            <img src="/images/downArrow.png" className="component__sidebar__itemCollapsed__arrow" />

            <div className="component__sidebar__itemCollapsed__children">
                <p onClick={(e) => {e.stopPropagation(); setSelected("prelanding-list")}} className={`component__sidebar__item ${selected === "prelanding-list" ? "component__sidebar__item--active" : ""}`}><img src="/images/prelanderIcon.png" /> Pre-landing list</p>
                <p onClick={(e) => {e.stopPropagation(); setSelected("landing-list")}} className={`component__sidebar__item ${selected === "landing-list" ? "component__sidebar__item--active" : ""}`}><img src="/images/landerIcon.png" /> Landing (offer) list</p>
                <p onClick={(e) => {e.stopPropagation(); setSelected("thankyou-list")}} className={`component__sidebar__item ${selected === "thankyou-list" ? "component__sidebar__item--active" : ""}`}><img src="/images/thankyouIcon.png" /> Thankyou list</p>
                <p onClick={(e) => {e.stopPropagation(); setSelected("static-list")}} className={`component__sidebar__item ${selected === "static-list" ? "component__sidebar__item--active" : ""}`}><img src="/images/wwwIcon.png" /> Static website list</p>
                <p onClick={(e) => {e.stopPropagation(); setSelected("templates")}} className={`component__sidebar__item ${selected === "templates" ? "component__sidebar__item--active" : ""}`}><img src="/images/filterImg.png" /> Templates</p>
                {userDataSelector?.UserInfo?.Flags?.isCopywriter && <p onClick={(e) => {e.stopPropagation(); setSelected("copywriter")}} className={`component__sidebar__item ${selected === "copywriter" ? "component__sidebar__item--active" : ""}`}><img src="/images/filterImg.png" /> Copywriter</p>}
                {userDataSelector?.UserInfo?.Flags?.isAdmin && <p onClick={(e) => {e.stopPropagation(); setSelected("copywriter-admin")}} className={`component__sidebar__item ${selected === "copywriter-admin" ? "component__sidebar__item--active" : ""}`}><img src="/images/filterImg.png" /> Copywriters Info</p>}
                {userDataSelector?.UserInfo?.Flags?.isAdmin && <p onClick={(e) => {e.stopPropagation(); setSelected("deleted-websites")}} className={`component__sidebar__item ${selected === "deleted-websites" ? "component__sidebar__item--active" : ""}`}><img src="/images/landerIcon.png" />Deleted Websites</p>}
            </div>
        </div>
        <p onClick={() => setSelected("asset-list")} className={`component__sidebar__item ${selected === "asset-list" ? "component__sidebar__item--active" : ""}`}><img src="/images/assetsImg.png" /> Images</p>
        <p onClick={() => setSelected("creative-list")} className={`component__sidebar__item ${selected === "creative-list" ? "component__sidebar__item--active" : ""}`}><img src="/images/folderImg.png" /> Creatives</p>
        {(userDataSelector?.UserInfo?.Flags?.isAdmin || userDataSelector?.UserInfo?.Flags?.canRequestLander) && <p onClick={() => setSelected("lander-requests")} className={`component__sidebar__item ${selected === "lander-requests" ? "component__sidebar__item--active" : ""}`}><img src="/images/thankyouIcon.png" /> Lander requests</p>}
        {(userDataSelector?.UserInfo?.Flags?.isAdmin || userDataSelector?.UserInfo?.Flags?.isCopywriter) && <p onClick={() => setSelected("comment-list")} className={`component__sidebar__item ${selected === "comment-list" ? "component__sidebar__item--active" : ""}`}><img src="/images/doc_createComment.png" /> Comments</p>}
        <p onClick={() => setSelected("recension-list")} className={`component__sidebar__item ${selected === "recension-list" ? "component__sidebar__item--active" : ""}`}><img src="/images/createRecension.png" /> Recensions</p>
        <p onClick={() => setSelected("translations")} className={`component__sidebar__item ${selected === "translations" ? "component__sidebar__item--active" : ""}`}><img src="/images/translationIcon.svg" />Translations</p>
        {userDataSelector?.UserInfo?.Flags?.isAdmin && <p onClick={() => setSelected("users-list")} className={`component__sidebar__item ${selected === "users-list" ? "component__sidebar__item--active" : ""}`}><img src="/images/usersIcon.png" /> Users</p>}
        {userDataSelector?.UserInfo?.Flags?.isAdmin && <p onClick={() => setSelected("api-tokens-list")} className={`component__sidebar__item ${selected === "api-tokens-list" ? "component__sidebar__item--active" : ""}`}><img src="/images/apiTokensIcon.svg" /> API Tokens</p>}
        <div className="component__sidebar__itemCollapsed" onClick={e => e.currentTarget.classList.toggle("component__sidebar__itemCollapsed--active")}>
            <img src="/images/cms__documentation.png" />
            <p>Documentation</p>
            <img src="/images/downArrow.png" className="component__sidebar__itemCollapsed__arrow" />

            <div className="component__sidebar__itemCollapsed__children">
                <p onClick={(e) => {e.stopPropagation(); setSelected("gateways")}} className={`component__sidebar__item ${selected === "gateways" ? "component__sidebar__item--active" : ""}`}><img src="/images/cms__gateway.png" /> ClosedForms</p>
                <p onClick={(e) => {e.stopPropagation(); setSelected("openforms")}} className={`component__sidebar__item ${selected === "openforms" ? "component__sidebar__item--active" : ""}`}><img src="/images/cms__openforms.png" /> OpenForms</p>
                <p onClick={(e) => {e.stopPropagation(); setSelected("mobile-templates")}} className={`component__sidebar__item ${selected === "mobile-templates" ? "component__sidebar__item--active" : ""}`}><img src="/images/cms__openforms.png" /> MobileTemplates</p>
                <p onClick={(e) => {e.stopPropagation(); setSelected("doc-timers")}} className={`component__sidebar__item ${selected === "doc-timers" ? "component__sidebar__item--active" : ""}`}><img src="/images/doc_timer_icon.png" /> Timers</p>
                <p onClick={(e) => {e.stopPropagation(); setSelected("doc-custom-assets")}} className={`component__sidebar__item ${selected === "doc-custom-assets" ? "component__sidebar__item--active" : ""}`}><img src="/images/doc_customAssets.png" />CustomAssets</p>
                <p onClick={(e) => {e.stopPropagation(); setSelected("doc-create-comments")}} className={`component__sidebar__item ${selected === "doc-create-comments" ? "component__sidebar__item--active" : ""}`}><img src="/images/doc_createComment.png" />CreateComments</p>
                <p onClick={(e) => {e.stopPropagation(); setSelected("doc-apply-comments")}} className={`component__sidebar__item ${selected === "doc-apply-comments" ? "component__sidebar__item--active" : ""}`}><img src="/images/doc_createComment.png" />ApplyComments</p>
                <p onClick={(e) => {e.stopPropagation(); setSelected("doc-translations")}} className={`component__sidebar__item ${selected === "doc-translations" ? "component__sidebar__item--active" : ""}`}><img src="/images/translationIcon.svg" />Translations</p>
            </div>
        </div>
        {userDataSelector?.UserInfo?.Flags?.isAdmin && <p onClick={() => setSelected("cron-tasks")} className={`component__sidebar__item ${selected === "cron-tasks" ? "component__sidebar__item--active" : ""}`}><img src="/images/cronTasksIcon.png" />Cron Tasks</p>}
        {userDataSelector?.UserInfo?.Flags?.isAdmin && <p onClick={() => setSelected("open-ai-usage")} className={`component__sidebar__item ${selected === "open-ai-usage" ? "component__sidebar__item--active" : ""}`}><img src="/images/cronTasksIcon.png" />Open AI Usage</p>}


        <p className="component__sidebar__userInfo" onClick={e => {
            e.stopPropagation();
            if (modalSpinner) return;
            if (modalVisible) {
                setModalVisible();
            } else {
                setModalVisible({x: e.pageX, y: e.pageY});
            };
        }}>
            <div className="component__sidebar__userInfo__spinner" style={{
                opacity: modalSpinner ? 1 : 0,
                pointerEvents: modalSpinner ? "all" : "none"
            }} onClick={e => modalSpinner && e.stopPropagation()}>
                <Spinner color="#3F7CEA" style={{width: "32px", height: "32px"}} />
            </div>

            <p className="component__sidebar__userInfo__circle">{userDataSelector?.UserInfo?.Username[0]?.toUpperCase()}</p>
            <p className="component__sidebar__userInfo__name">{userDataSelector?.UserInfo?.Username}</p>
            <img className="component__sidebar__userInfo__moreInfo" src="/images/moreActionsBtn.png" />
        </p>
    </div>
};

const ChangePassword = props => {
    const [spinner, setSpinner] = React.useState(false);
    const [infoP, setInfoP] = React.useState("");

    const oldPasswordRef = React.useRef();
    const newPasswordRef = React.useRef();
    const confirmPasswordRef = React.useRef();

    const changePassword = () => {
        setInfoP("");

        if (newPasswordRef.current.value !== confirmPasswordRef.current.value) return setInfoP("Confirm password doesn't match the New password!");

        let data = {
            oldPassword: oldPasswordRef.current.value,
            newPassword: newPasswordRef.current.value
        };
        if (!data.oldPassword) return setInfoP("Old password can't be empty!");
        if (!data.newPassword) return setInfoP("New password | Confirm new password can't be empty!");
        if (data.newPassword.length < 8 || data.newPassword.length > 64) return setInfoP("New password must be between 8 and 64 characters!");

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/changePassword`,
            data,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                onClose();
            } else {
                setInfoP("Error while changing password! Old password is invalid.");
            };
        }).catch(() => {
            setInfoP("Server timed out!");
        }).finally(() => {
            setSpinner(false);
        });
    };

    const onClose = () => {
        props.onChange && props.onChange();
        props.onClose();
    };

    return <div className="component__sidebar__changePassword">
        <div className="component__sidebar__changePassword__wrap">
            <div className="route__domainsList__addDomain__wrap__spinner" style={{
                opacity: spinner ? 1 : 0,
                pointerEvents: spinner ? "all" : "none"
            }} onClick={e => spinner && e.stopPropagation()}>
                <Spinner color="#3F7CEA" />
            </div>
            
            <h3 style={{marginBottom: "20px"}}>Change password</h3>
            <CustomInput autocomplete="on" ref={oldPasswordRef} accent="#fff" theme="dark" type="password" placeholder="Old password" style={{marginBottom: "20px", width: "100%"}} />
            <CustomInput autocomplete="on" ref={newPasswordRef} accent="#fff" theme="dark" type="password" placeholder="New password" style={{marginBottom: "20px", width: "100%"}} />
            <CustomInput autocomplete="on" ref={confirmPasswordRef} accent="#fff" theme="dark" type="password" placeholder="Confirm new password" style={{width: "100%"}} />

            <div className="route__domainsList__addDomain__wrap__btns">
                <p onClick={changePassword}>
                    <img style={{ marginRight: "15px" }} src="/images/saveBtn.png" />
                    <span>Change</span>
                </p>
                <p onClick={onClose}>
                    <span>Cancel</span>
                    <img style={{ marginLeft: "15px" }} src="/images/closeBtn.png" />
                </p>
            </div>

            {infoP && <p className="route__domainsList__addDomain__wrap__infoP">{infoP}</p>}
        </div>
    </div>
};

export default Sidebar;