import React from "react";
import "./index.scss";


const DocApplyComments = (props) => {

  return <div className="route__documentation">
    <div className="route__documentation__headline">
      <p>Apply Comments</p>
    </div>

    <div className="route__documentation__desc">
      <p>Apply Comments to the lander</p>
    </div>

    <div className="route__documentation__introBlock flex-column">
      <div className="route__documentation__introBlock__right">
        <p>During the creation or editting of the lander, on the Sidebar of the Editor, we should find a tab that sais 'Comments'</p>
      </div>
      <div className="route__documentation__introBlock__left">
        <img src="/images/tutorials/applyComments/applyComments1.png" />
      </div>
      <div className="route__documentation__introBlock__right">
        <p>Then we find the component called 'Comment Wrap' and we drag it to the spot where we want it to be.</p>
        <p>After we've inserted the Component Wrap to our lander, it's important to click the settings button of the component</p>
      </div>
    </div>

    <div className="route__documentation__introBlock">
      <div className="route__documentation__introBlock__left">
        <img style={{minWidth: '250px'}} src="/images/tutorials/applyComments/applyComments2.png" />
      </div>
      <div className="route__documentation__introBlock__right">
        <p>Select an offer - select offer for this comment section</p>
        <p>Selecte the template - select template that you want to use on this section</p>
        <p>Show Likes - Choose if you want to show the likes on the comments</p>
        <p>Show date - choose if you want to show the dates on the comments</p>
        <p>Selecte the layout - Select the layout, how will comments look</p>
      </div>
    </div>

    <div className="route__documentation__introBlock flex-column">
      <div className="route__documentation__introBlock__left">
        <img src="/images/tutorials/applyComments/applyComments3.png" />
      </div>
    </div>
  </div>
};

export default DocApplyComments;
export { DocApplyComments };