import gjs from "grapesjs";


/**
 * @param {gjs.Editor} editor
 */
export default editor => {
    editor.Components.addType('scalecms-twoColumns', {
        isComponent: e => e?.classList?.contains("scalecms-twoColumns"),
        model: {
            defaults: {
                droppable: false,
                type: "default",
                traits: [

                ],
                attributes: {
                    class: "scalecms-twoColumns"
                },
                styles: `
                    .scalecms-twoColumns {
                        width: 100%;
                        height: auto;
                        min-height: 40px;
                        display: grid;
                        grid-template-rows: max-content;
                        grid-template-columns: repeat(2, 1fr);
                    }
                    .scalecms-twoColumns-left, .scalecms-twoColumns-right {
                        display: block;
                        width: 100%;
                        height: auto;
                        min-height: 40px;
                    }

                    @media screen and (max-width: 600px) {
                        .scalecms-twoColumns {
                            grid-template-columns: 1fr;
                        }
                    }
                `,
                components: [
                    {
                        tagName: "div",
                        attributes: {class: "scalecms-twoColumns-left"}
                    },
                    {
                        tagName: "div",
                        attributes: {class: "scalecms-twoColumns-right"}
                    },
                ]
            }
        }
    });


    editor.BlockManager.add('scalecms-twoColumns-blk', {
        label: '2 columns',
        content: {
            type: "scalecms-twoColumns",
        },
        category: {
            label: 'Layout'
        },
        media: `<svg viewBox="0 0 23 24"><path fill="currentColor" d="M2 20h8V4H2v16Zm-1 0V4a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1ZM13 20h8V4h-8v16Zm-1 0V4a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1h-8a1 1 0 0 1-1-1Z"></path></svg>`,
        attributes: {
            title: 'Basic 2 columns block',
        },
        select: true
    });
};