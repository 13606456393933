import React from "react";
import "./index.scss";

import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import * as backendModule from "../../modules/backendModule";
import { animateBox } from "../../modules/componentAnimation";

import CustomInput from "../../components/customComponents/CustomInput";
import CustomTextarea from "../../components/customComponents/CustomTextarea";
import CustomCheckbox from "../../components/customComponents/CustomCheckbox";
import { FilteredCustomTable } from "../../components/customComponents/Table";
import Spinner from "../../components/customComponents/Spinner";
import Dropdown from "../../components/customComponents/Dropdown";
import Button from "../../components/customComponents/Button";
import Checkbox from "../../components/customComponents/Checkbox";
import MultiSelect from "../../components/customComponents/MultiSelect";
import countrySpeakingLangList from "../../modules/countrySpeakingLangList.json";
// import countrySpeakingLangList from "../../modules/countrySpeakingLangList.json";
import commentNameList from "./commentName.json";
import commentTextList from "./commentText.json";
import { updateTimestamp } from "../../actions/timestampActions";

import { AddImage } from "../../routes/ImagesList";
import ImagesList from "../../routes/ImagesList";

let curTimeout = null;
const CommentsList = () => {

  const curDispatch = useDispatch();

  const [data, setData] = React.useState();
  const [filters, setFilters] = React.useState([]);
  const [canPaginate, setCanPaginate] = React.useState(false);
  const [spinner, setSpinner] = React.useState(false);
  const [secondarySpinner, setSecondarySpinner] = React.useState(false);
  const [offers, setOffers] = React.useState([]);
  const [baseOffers, setBaseOffers] = React.useState([]);
  const [offersTab, setOffersTab] = React.useState(true);
  const [selectedOffer, setSelectedOffer] = React.useState();

  const [templates, setTemplates] = React.useState([]);
  const [templateTab, setTemplateTab] = React.useState(false);
  const [selectedTemplate, setSelectedTemplate] = React.useState();

  const paginationOffset = React.useRef();
  const curPaginationTimestamp = React.useRef();
  const searchRef = React.useRef();

  const siteSettingsSelector = useSelector(state => state?.siteSettings ?? {});
  const timestampSelector = useSelector(state => state?.timestamp ?? null);
  const userDataSelector = useSelector(state => state?.userData?.userData ?? {});

  const getData = () => {
    paginationOffset.current = 0;
    curPaginationTimestamp.current = Date.now();
    if (!selectedTemplate) return;

    setCanPaginate(false);
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/comments/getAllCommentsBySelectedTemplate`,
      data: {
        offset: paginationOffset.current,
        filters: [
          ...filters,
        ],
        CommentTemplateID: selectedTemplate.ID
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        if (res.data.data.length === 20) {
          paginationOffset.current += 20;
          setTimeout(() => setCanPaginate(true), 500);
        } else {
          setCanPaginate(false);
          paginationOffset.current = -1;
        };
        return setData({ ...res.data, timestamp: Date.now() });
      }
      return setData({ status: "error", data: "SERVER_ERROR", timestamp: Date.now() });
    }).catch(() => {
      return setData({ status: "error", data: "SERVER_ERROR", timestamp: Date.now() });
    });
  };

  const removeComment = (ID) => {
    setSpinner(true);
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/comments/removeComment`,
      data: {
        ID: ID
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === 'ok') {
        getData();
      }
      else {

      }
    }).catch(() => {

    }).finally(() => {
      setSpinner(false);
    });
  }

  const getAllCommentsTemplate = () => {
    if (!selectedOffer) return;
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/commentsTemplate/getAllCommentsTemplate`,
      data: {
        offerID: selectedOffer.ID
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        setTemplates(res.data.data)
      } else {
      };
    }).catch(() => {
    }).finally(() => {
      setSpinner(false);
    });
  }

  const getAllOffersForComments = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/comments/getAllOffersForComments`,
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        setBaseOffers(res.data.data)
        setOffers(res.data.data)
      } else {
      };
    }).catch(() => {
    }).finally(() => {
      setSpinner(false);
    });
  }

  const performSearch = () => {
    if (!baseOffers) return;
    let searchString = searchRef.current.value.toLowerCase();

    clearTimeout(curTimeout);
    curTimeout = setTimeout(() => {
      
      let out = baseOffers.filter(offer => offer.OfferName.toLowerCase().includes(searchString));
      setOffers(out)

    }, 500);
  };

  const refreshSiteTemplatesAndComments = () => {
    getAllCommentsTemplate();
    getData();
  }

  React.useEffect(() => {
    getAllOffersForComments();
  }, []);

  React.useEffect(() => {
    getAllCommentsTemplate();
  }, [selectedOffer]);

  React.useEffect(() => {
    getData();
  }, [selectedTemplate]);

  return <div className="route__commentsList">
    <div className="route__commentsList__menu">
      <div className="route__commentsList__offers" style={{ maxHeight: offersTab ? '500px' : '42px' }}>
        <p className="route__commentsList__offers__head" onClick={() => setOffersTab(e => !e)}>OFFERS TAB {selectedOffer && selectedOffer.name ? <span>{selectedOffer.name}</span> : null}</p>
        <div className="route__commentsList__offers__itemList" style={{ maxHeight: offersTab ? '500px' : '0px', padding: offers ? '10px 16px' : '0px 16px' }}>
        <CustomInput autocomplete="off" theme="dark" accent="#3F7CEA" placeholder="Search..." style={{width: "250px"}} ref={searchRef} onChange={performSearch} />
          {offers.length ? offers.map(el => {
            return <p className="route__commentsList__offers__item" onClick={() => { setSelectedOffer({ name: el.OfferName, ID: el.ID, country: el.Country }); setOffersTab(false) }}>{el.OfferName} ({el.Country})</p>
          }) : <p>No Data</p>}
        </div>
      </div>

      <div className="route__commentsList__offers" style={{ maxHeight: templateTab ? '500px' : '42px', overflow: templateTab ? 'scroll' : 'hidden' }}>
        <p className="route__commentsList__offers__head" onClick={() => setTemplateTab(e => !e)}>TEMPLATES TAB {selectedTemplate && selectedTemplate.name ? <span>{selectedTemplate.name}</span> : null}</p>
        <div className="route__commentsList__offers__itemList" style={{ maxHeight: templateTab ? '500px' : '0px' }}>
          {templates && templates.length ? templates.map(el => {
            return <p className="route__commentsList__offers__item" onClick={() => { setSelectedTemplate({ name: el.Name, ID: el.ID }); setTemplateTab(false) }}>{el.Name}</p>
          }) : <p>No Data</p>}
        </div>
      </div>

      <div className="route__commentsList__offers" style={{ maxHeight: '42px' }}>
        <p onClick={e => selectedOffer && animateBox(e, <TemplateSettings offer={selectedOffer} allOffers={offers} userData={userDataSelector} setSelectedOffer={setSelectedOffer} onChange={refreshSiteTemplatesAndComments} />)}
          className="route__commentsList__offers__head">
          Template Settings
        </p>
      </div>
    </div>

    {data && data.data.length && <FilteredCustomTable
      key={`domainTable-${siteSettingsSelector.advancedSearch ? "advanced" : "simple"}`}
      theme="dark"
      accent="#48515C"
      style={{ width: "100%" }}
      customColumns={["100px", "180px", "1fr", "70px", "70px", "70px"]}
      headers={["ID", "CommentName", "CommentText", "Likes", "Dislikes", "Country"]}
      filters={siteSettingsSelector.advancedSearch ? [
        { name: "ID", friendlyName: "ID", type: "number" },
        { name: "Name", friendlyName: "Name", type: "string" },
        { name: "Domain", friendlyName: "Domain", type: "string" },
      ] : undefined}
      filterCB={f => setFilters(f)}
      data={(() => {
        if (!data) return [[{ keyID: "noDataSpinner", type: "spinner" }]];
        if (data?.status !== "ok") return [[{ keyID: "noDataError", type: "custom", data: <p>There was an error while fetching data</p> }]]
        if (data.data.length === 0) return [[{ keyID: "noDataError2", type: "custom", data: <p>There is nothing to show.</p> }]]

        let tmp = data.data.map(elem => {
          console.log("called me ellem ", elem)
          if (!elem || elem.ReplyID) return;
          return [
            { keyID: String(elem.ID), type: "text", text: elem.ID },
            { keyID: String(elem.ID), type: "text", text: elem.CommentName },
            { keyID: String(elem.ID), type: "text", text: elem.CommentText },
            { keyID: String(elem.ID), type: "text", text: elem.Likes },
            { keyID: String(elem.ID), type: "text", text: elem.Dislikes },
            { keyID: String(elem.ID), type: "text", text: elem.Country },
            {
              keyID: String(elem.ID), type: "groupNewline", group: [
                {
                  keyID: String(elem.ID), type: "button", text: "Delete", triggerDropdown: true, triggerData: e => {
                    return (<div style={{ display: 'flex', flexDirection: 'column', justfyContent: 'center', alignItems: 'center' }}>
                      <p style={{ color: 'white' }}>Are you sure?</p>
                      <br></br>
                      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Button style={{ display: spinner ? 'none' : 'block', marginRight: '10px' }} accent='rgb(72, 82, 92)' value='YES' onClick={() => removeComment(elem.ID)} />
                        <Button style={{ display: spinner ? 'none' : 'block' }} accent='rgb(72, 82, 92)' value='NO' onClick={c => { e() }} />
                        <div style={{ display: spinner ? 'block' : 'none' }}><Spinner color='white' width='32px' /></div>
                      </div>
                    </div>);
                  },
                },
                { keyID: String(elem.ID), type: "button", text: "Edit", style: { marginLeft: "10px" }, onClick: e => animateBox(e, <EditComment id={elem.ID} onChange={getData} data={elem} allComments={data?.data} />) },
                {
                  keyID: String(elem.ID), type: "button", text: "Open image", style: { marginLeft: "10px" }, triggerDropdown: true, onClick: e => {
                    let curImg = elem.CommentImage;
                    if (!curImg) return;
                    let w = window.open("", "_blank");
                    w.document.write("<style>*{margin: 0; padding:0; box-sizing: border-box;}</style>")
                    w.document.write(`<div style="display: flex; justify-content: center; align-items: center; width: 100%; height: auto; min-height: 100vh; background-color: #20262d;"><img src="${curImg}" style="max-width:50%; height:auto;" /></div>`);
                    w.document.close();
                  }
                },
                { keyID: String(elem.ID), type: "button", text: "Add Reply", style: { marginLeft: "10px" }, onClick: e => animateBox(e, <CreateComment createdBy={userDataSelector?.UserInfo?.Username} replyID={elem.ID} data={elem} onChange={getData} />) },
                { keyID: String(elem.ID), type: "button", text: "View Replies", style: { marginLeft: "10px" }, onClick: e => animateBox(e, <ViewCommentReplies mainComment={elem} />) },
                // { keyID: String(elem.ID), type: "button", text: "Remove", style: { marginLeft: "10px" }, onClick: e => animateBox(e, <RemoveComment data={elem} onChange={getData} />) },
              ]
            }
          ].filter(f => f);
        }).filter(f => f);
        if (secondarySpinner) tmp.push([{ keyID: "paginationSpinner", type: "spinner" }]);
        return tmp;
      })()}
    />}
  </div>
};

const CreateComment = props => {

  const curDispatch = useDispatch();

  const [spinner, setSpinner] = React.useState(false);
  const [infoP, setInfoP] = React.useState("");
  const [offers, setOffers] = React.useState([]);
  const [selectedOfferID, setSelectedOfferID] = React.useState();
  const [selectedGender, setSelectedGender] = React.useState();
  const [selectedOfferName, setSelectedOfferName] = React.useState();
  const [selectedOfferCondition, setSelectedOfferCondition] = React.useState();
  const [selectedOfferCountry, setSelectedOfferCountry] = React.useState();
  const [translations, setTranslations] = React.useState();
  const [showFirst, setShowFirst] = React.useState(props?.data?.showFirst ?? false);

  const [selectedImage, setSelectedImage] = React.useState();
  const [textImage, setTextImage] = React.useState();

  const [cleanComments, setCleanComments] = React.useState();
  const [commentsAsReplies, setCommentsAsReplies] = React.useState([]);
  const [replyCommentsSpinner, setReplyCommentsSpinner] = React.useState(false);
  const [repliesUpdated, setRepliesUpdated] = React.useState(false);

  const [aiTone, setAiTone] = React.useState();

  const commentNameRef = React.useRef();
  const commentTextRef = React.useRef();
  const likesRef = React.useRef();
  const dislikesRef = React.useRef();

  const createComment = () => {
    setInfoP("");
    let data = {
      CommentName: commentNameRef.current.value,
      CommentText: commentTextRef.current.value,
      Gender: selectedGender ? selectedGender : 'Male',
      CommentImage: selectedImage ? selectedImage.URL : "https://backend.scale-cms.com:5000/staticContent/images/89935b0a-08f9-4cc8-b3ae-e37aea7de6f7-1707465661699.webp",
      TextImage: textImage ? textImage.URL : null,
      createdBy: props?.createdBy ? props.createdBy : null,
      offerName: props?.data ? props.data.offerName : selectedOfferName,
      offerID: props?.data ? props.data.offerID : selectedOfferID,
      Country: props?.data ? props.data.Country : selectedOfferCountry ? selectedOfferCountry : 'BA',
      Likes: likesRef.current.value,
      Dislikes: dislikesRef.current.value,
      ReplyID: props?.replyID ? props.replyID : null,
      Replies: [],
      showFirst: !!showFirst
    };

    // for(let elem of translations){
    //   createCommentTranslation(data, elem)
    // }

    setSpinner(true);
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/comments/createComment`,
      data,
      ...backendModule.axiosConfig
    }).then(res => {

      if (res.data.status === "ok") {

        if (props.replyID) {
          let currParentReplies = JSON.parse(props.data.Replies);
          let data = {
            ID: props.data.ID,
            Replies: [...currParentReplies, res.data.data.ID]
          }

          axios({
            method: "POST",
            url: `${backendModule.backendURL}/comments/editComment`,
            data,
            ...backendModule.axiosConfig
          }).then(res => {
            if (res.data.status === "ok") {
            } else {
            };
          }).catch(() => {
            setInfoP("Server timed out!");
          }).finally(() => {
            setSpinner(false);
          });
        }

        props.onClose().then(() => {
          if (props.onChange) props.onChange();
        });
      } else {
        setInfoP(`There was an error while creating the comment`);
      };
    }).catch((e) => {
      console.log("error ", e)
      setInfoP("Server timed out!");
    }).finally(() => {
      setSpinner(false);
    });
  };

  const addMultipleRepliesToTheComment = () => {
    if(!commentsAsReplies || !props.data.ID) return;
    setReplyCommentsSpinner(true)

    let data = {
      mainCommentID: props.data.ID,
      replyCommentsIDs: commentsAsReplies
    }

    axios({
      method: "POST",
      url: `${backendModule.backendURL}/comments/addMultipleRepliesToTheComment`,
      data,
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        props.onChange();
        setRepliesUpdated(true)
      } else {
        setInfoP(`There was an error while adding replies`);
      };
    }).catch(err => {
      console.log("error ", err)
      setInfoP("Server timed out!");
    }).finally(() => {
      setReplyCommentsSpinner(false);
    })
  }


  const createCommentTranslation = async (commentData, countryObj) => {
    commentData.Country = countryObj.code;
    let newOfferData = offers.data.find(elem => elem.OfferName === commentData.offerName && elem.Country === countryObj.code);
    if (!newOfferData) return;
    commentData.offerID = newOfferData.ID;

    if (commentNameList[countryObj.code][selectedGender.toLowerCase()]) {

      let firstName = getRandomElement(commentNameList[countryObj.code][selectedGender.toLowerCase()]);
      let lastName = getRandomElement(commentNameList[countryObj.code]['last']);

      commentData.CommentName = `${firstName} ${lastName}`;
    }

    const translateMessage = async (text, language, sex) => {
      axios({
        method: "POST",
        url: `${backendModule.backendURL}/openai/openaiTranslate`,
        data: {
          text: text,
          language: language,
          sex: sex
        },
        ...backendModule.axiosConfig
      }).then(res => {
      }).catch(() => {
      }).finally(() => {
        setSpinner(false);
      });
    }

    commentData.CommentText = await translateMessage(commentData.CommentText, countryObj.language, commentData.Gender)
  }


  const getAllOffersForComments = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/comments/getAllOffersForComments`,
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        setOffers(res.data)
      } else {
        setInfoP(`There was an error while creating the comment`);
      };
    }).catch(() => {
      setInfoP("Server timed out!");
    }).finally(() => {
      setSpinner(false);
    });
  }

  const placeCommentInfo = async (offerName, offerID) => {

    let firstName = '';
    let lastName = '';
    let text = '';
    let likes;
    let dislikes;

    let usedComments = [];

    await axios({
      method: "POST",
      url: `${backendModule.backendURL}/comments/getAllCommentsByOffer`,
      data: {
        offerID: offerID
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        usedComments = res.data.data.map(el => el.CommentText);
      }
    }).catch(() => {
    });

    if (selectedOfferName && selectedGender && selectedOfferCountry) {
      if (commentNameList[selectedOfferCountry][selectedGender.toLowerCase()] && commentTextList[selectedOfferCountry][selectedGender.toLowerCase()][offerName]) {
        firstName = getRandomElement(commentNameList[selectedOfferCountry][selectedGender.toLowerCase()]);
        lastName = getRandomElement(commentNameList[selectedOfferCountry]['last']);
        text = getRandomElement(commentTextList[selectedOfferCountry][selectedGender.toLowerCase()][offerName], usedComments);
        likes = getRandomElement([22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84]);
        dislikes = getRandomElement([1, 2, 3, 4, 5, 6, 7])


        commentNameRef.current.value = `${firstName} ${lastName}`;
        commentTextRef.current.value = `${text}`;
        likesRef.current.value = likes;
        dislikesRef.current.value = dislikes;
      } else {
        commentNameRef.current.value = `Test Testic`;
        commentTextRef.current.value = `Text test komenara`
      }
    }
  }

  const getRandomElement = (mainArray, excludeArray) => {

    if (excludeArray) {

      let availableElements = [];

      availableElements = filterArray(mainArray, excludeArray)

      if (availableElements.length === 0) {
        return '***WRITE COMMENT***';
      }

      const randomIndex = Math.floor(Math.random() * availableElements.length);
      return availableElements[randomIndex];
    } else {
      const randomIndex = Math.floor(Math.random() * mainArray.length);
      return mainArray[randomIndex];
    }
  }

  const filterArray = (array1, array2) => {
    const excludeSet = new Set(array2);

    const filteredArray = array1.filter(element =>
      !excludeSet.has(element)
    );

    return filteredArray;
  };

  //get all clean comments here - comments that dont have replies, and that are not reply to any comment ( ?? binded to the same offer ?)
  const getCleanComments = async() => {
    // if(!props?.data?.offerID) return;
    // console.log("called me here lol ");
    await axios({
      method: "POST",
      url: `${backendModule.backendURL}/comments/getAllCleanComments`,
      data: {
        offerID: props?.data?.offerID
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if(res.data.status === 'ok'){
        setCleanComments(res.data.data)
      }else{
        setCleanComments([])
      }
    }).catch((err) => {
      console.log("getting clean comments error ::: ", err)
    });
  }

  const getAISuggestionForComment = () => {
    console.log("called me aiTone ", aiTone)
    console.log("called me selectedGender ", selectedGender)
    console.log("called me selectedOfferCondition ", selectedOfferCondition)
    console.log("called me selectedOfferCountry ", selectedOfferCountry)

    axios({
      method: "POST",
      url: `${backendModule.backendURL}/openai/openaiGenerateComment`,
      data: {
        aiTone: aiTone,
        gender: selectedGender,
        condition: selectedOfferCondition,
        country: selectedOfferCountry,
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if(res.data.status === 'ok'){
        console.log("RES DATA DATA ", res.data.data)
      }else{
      }
    }).catch(() => {
    }).finally(() => {
    });

  }

  React.useEffect(() => {
    getAllOffersForComments();
  }, []);

  React.useEffect(() => {
    getCleanComments();
  }, [props?.replyID]);

  React.useEffect(() => {
    if (!selectedOfferName) return;
    placeCommentInfo(selectedOfferName, selectedOfferID);
  }, [selectedOfferName, selectedGender, selectedOfferCountry, selectedOfferID]);


  return <div className="route__commentsList__createComment">
    <div className="route__commentsList__createComment__wrap">
      <div className="route__commentsList__createComment__wrap__spinner" style={{
        opacity: spinner ? 1 : 0,
        pointerEvents: spinner ? "all" : "none"
      }} onClick={e => spinner && e.stopPropagation()}>
        <Spinner color="#3F7CEA" style={{pointerEvents: "none"}} />
      </div>

      <h3 style={{ marginBottom: "20px" }}>{props.id ? "Edit" : "Add"} Comment {props.replyID ? "Reply" : ""}</h3>
      {props.replyID && <p className="route__commentsList__createComment__wrap__selectComms" 
      onClick={(e) => animateBox(e,
        <MultiSelectComments
          headline="Select Comments To Be Replies"
          theme="dark"
          accent="#fff"
          selectBy="ID"
          data={cleanComments}
          currentComment={props.data.ID}
          selectedData={commentsAsReplies ? commentsAsReplies : []}
          onChange={e => setCommentsAsReplies(e)}
          style={{ marginBottom: '20px' }}
        />)}
      >{cleanComments ? 'Select comments for replies' : <Spinner color='white' width='32px' style={{width:'24px', height:'24px'}} />}</p>}
      {commentsAsReplies && commentsAsReplies.length > 0 && !replyCommentsSpinner && !repliesUpdated && <p style={{marginBottom: '4px', textAlign: 'center', color: '#f75c7d'}}>NOTE: comments {commentsAsReplies.map(el => <span style={{marginLeft: '4px', marginRight: '4px'}}>{el}</span>)} will become a reply to this comment</p>}
      {commentsAsReplies.length > 0 && !replyCommentsSpinner && !repliesUpdated && <p style={{marginBottom: '16px', textAlign: 'center', color: '#f75c7d', textDecoration:'underline', fontSize:'18px'}}
      onClick={addMultipleRepliesToTheComment}>
        Click to confirm setting Reply Comments
      </p>}
      {replyCommentsSpinner && <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '12px'}}><Spinner color='white' width='32px' style={{width:'24px', height:'24px', margin: '0 auto'}} /></div>}
      {repliesUpdated && <p style={{marginBottom: '16px', textAlign: 'center', color: '#81fc81', textDecoration:'underline', fontSize:'18px'}}>Replies added successfully !!!</p>}

      {!props?.data?.offerID && offers.data && <Dropdown
        inlinePlaceholder="Select Offer"
        theme="dark"
        accent="#fff"
        style={{ marginBottom: '20px' }}
        data={offers?.data.map(offer => {
          return { name: `${offer.OfferName} (${offer.Country})`, value: offer.ID, country: offer.Country, offerName: offer.OfferName, offerCondition: offer.OfferCondition };
        })}
        onChange={e => { e?.value && setSelectedOfferID(e?.value); e?.name && setSelectedOfferName(e?.offerName); e?.country && setSelectedOfferCountry(e?.country); e?.offerCondition && setSelectedOfferCondition(e?.offerCondition) }}
        selected={(() => {
          if (offers?.status !== "ok") return null;
          return offers.data.indexOf(offers.data.find(u => u.ID === selectedOfferID));
        })()}
      />}

      <Dropdown
        inlinePlaceholder="Select Comment Gender"
        theme="dark"
        accent="#fff"
        style={{ marginBottom: '20px' }}
        data={[
          { name: 'Male', value: 'Male' },
          { name: 'Female', value: 'Female' },
        ]}
        onChange={e => { e?.value && setSelectedGender(e?.value) }}
        selected={(() => {
          return [
            { name: 'Male', value: 'Male' },
            { name: 'Female', value: 'Female' },
          ].indexOf([
            { name: 'Male', value: 'Male' },
            { name: 'Female', value: 'Female' },
          ].find(u => u.value === selectedGender));
        })()}
      />

      {!props?.data?.Country && countrySpeakingLangList.length && <Dropdown
        inlinePlaceholder="Select Comment Language"
        theme="dark"
        accent="#fff"
        style={{ marginBottom: '20px' }}
        data={countrySpeakingLangList.map(country => {
          return { name: country.name, value: country.code, language: country.language };
        })}

        onChange={e => { e?.value && setSelectedOfferCountry(e?.value) }}
        selected={(() => {
          return countrySpeakingLangList.indexOf(countrySpeakingLangList.find(cr => cr.code === selectedOfferCountry));
        })()}
      />}

      <div className="route__commentsList__createComment__wrap__aiHelper">
        <Dropdown
          inlinePlaceholder="Tone for AI Reply"
          theme="dark"
          accent="#fff"
          style={{ marginBottom: '20px' }}
          data={[
            { name: 'Friendly', value: 'Friendly' },
            { name: 'Professional', value: 'Professional' },
            { name: 'Casual', value: 'Casual' },
            { name: 'Encouraging', value: 'Encouraging' },
            { name: 'Optimistic', value: 'Optimistic' },
            { name: 'Polite', value: 'Polite' },
            { name: 'Neutral', value: 'Neutral' },
            { name: 'Aggressive', value: 'Aggressive' },
          ]}
          onChange={e => { e?.value && setAiTone(e?.value) }}
          selected={(() => {
            return [
              { name: 'Friendly', value: 'Friendly' },
              { name: 'Professional', value: 'Professional' },
              { name: 'Casual', value: 'Casual' },
              { name: 'Encouraging', value: 'Encouraging' },
              { name: 'Optimistic', value: 'Optimistic' },
              { name: 'Polite', value: 'Polite' },
              { name: 'Neutral', value: 'Neutral' },
              { name: 'Aggressive', value: 'Aggressive' },
            ].indexOf([
              { name: 'Friendly', value: 'Friendly' },
              { name: 'Professional', value: 'Professional' },
              { name: 'Casual', value: 'Casual' },
              { name: 'Encouraging', value: 'Encouraging' },
              { name: 'Optimistic', value: 'Optimistic' },
              { name: 'Polite', value: 'Polite' },
              { name: 'Neutral', value: 'Neutral' },
              { name: 'Aggressive', value: 'Aggressive' },
            ].find(u => u.value === selectedGender));
          })()}
        />
        <p onClick={getAISuggestionForComment}>Generate AI Response</p>
      </div>

      <CustomInput autocomplete="off" ref={commentNameRef} accent="#fff" theme="dark" placeholder="Comment name" style={{ width: "100%", marginBottom: "20px" }} />
      <CustomInput autocomplete="off" ref={commentTextRef} accent="#fff" theme="dark" placeholder="Comment.." style={{ width: "100%", marginBottom: "6px" }} />

      {!props.replyID && <div className="route__commentsList__createComment__wrap__btns" style={{ justifyContent: 'space-between', marginTop: "-8px" }}>
        <p style={{ marginBottom: '0px', padding: '4px 12px', mindWidth: '200px' }} onClick={(e) => {
          placeCommentInfo(selectedOfferName, selectedOfferID)
        }}>Regenerate</p>
        <p style={{ marginBottom: '0px', padding: '4px 12px', mindWidth: '200px' }} onClick={(e) => {
          placeCommentInfo('generic', selectedOfferID)
        }}>Generic Comment</p>
        <p style={{ marginBottom: '0px', padding: '4px 12px', mindWidth: '200px', borderBottomColor: "#3f7cea" }} onClick={(e) => {
          animateBox(e, <CommentBuilder value={commentTextRef.current.value} onChange={e => {
            commentTextRef.current.value = e;
          }} />);
        }}>Comment builder</p>
      </div>}

      {/* <MultiSelect
        headline="Select Translations"
        theme="dark"
        accent="#fff"
        selectBy="name"
        data={countrySpeakingLangList}
        onChange={e => setTranslations(e)}
        suggestedData={[
          { name: 'Bosnia and Herzegovina', code: 'BA', language: 'bosnian' },
          { name: 'Croatia', code: 'HR', language: 'croatian' },
          { name: 'Serbia', code: 'RS', language: 'serbian' },
          { name: 'Montenegro', code: 'ME', language: 'serbian' },
          { name: 'Slovenia', code: 'SI', language: 'slovenian' },
          { name: 'Romania', code: 'RO', language: 'romanian' },
        ]}
        style={{ marginBottom: '20px' }}
      /> */}

      <div className="route__commentsList__createComment__wrap__btns" style={{ justifyContent: 'space-evenly' }}>
        <p style={{ marginBottom: '0px', padding: '10px' }} onClick={(e) => {
          animateBox(e, <ImagesList fromComments={true} tag='userImage' onChange={() => curDispatch(updateTimestamp())} style={{ padding: '20px' }} select={true} setSelectedImage={setSelectedImage} />)
        }}>Select User Image</p>
        <p style={{ marginBottom: '0px', padding: '10px' }} onClick={(e) => {
          animateBox(e, <AddImage tag='userImage' onChange={() => curDispatch(updateTimestamp())} />)
        }}>Upload User Image</p>
      </div>
      {selectedImage ? <a href={`${selectedImage.URL}`} target="_blank" style={{ color: 'white' }}>{selectedImage.URL}</a> : null}

      <div className="route__commentsList__createComment__wrap__btns" style={{ justifyContent: 'space-evenly' }}>
        <p style={{ marginBottom: '0px', padding: '10px' }} onClick={(e) => {
          animateBox(e, <ImagesList fromComments={true} tag={`${selectedOfferName ? `offername-${selectedOfferName}` : 'textImage'}`} onChange={() => curDispatch(updateTimestamp())} style={{ padding: '20px' }} select={true} setSelectedImage={setTextImage} />)
        }}>Select Text Image</p>
        <p style={{ marginBottom: '0px', padding: '10px' }} onClick={(e) => {
          animateBox(e, <AddImage tag={`${selectedOfferName ? `offername-${selectedOfferName}` : 'textImage'}`} onChange={() => curDispatch(updateTimestamp())} />)
        }}>Upload Text Image</p>
      </div>
      {textImage ? <a href={`${textImage.URL}`} target="_blank" style={{ color: 'white' }}>{textImage.URL}</a> : null}


      <div className="route__commentsList__createComment__wrap__likes">
        <CustomInput ref={likesRef} accent="#fff" theme="dark" placeholder="Likes Amount" type="number" />
        <CustomInput ref={dislikesRef} accent="#fff" theme="dark" placeholder="Dislikes Amount" type="number" />
      </div>

      <p style={{display: "flex", alignItems: "center", gap: "10px", margin: "10px 0"}}>
        <Checkbox checked={showFirst} onChange={e => showFirst !== e && setShowFirst(!!e)} />
        Show First
      </p>

      <div className="route__commentsList__createComment__wrap__btns">
        <p onClick={createComment}>
          <img style={{ marginRight: "15px" }} src="/images/saveBtn.png" />
          <span>Save</span>
        </p>
        <p onClick={props.onClose}>
          <span>Cancel</span>
          <img style={{ marginLeft: "15px" }} src="/images/closeBtn.png" />
        </p>
      </div>

      {infoP && <p className="route__commentsList__createComment__wrap__infoP">{infoP}</p>}
    </div>
  </div>

};


const EditComment = props => {

  const curDispatch = useDispatch();

  const [spinner, setSpinner] = React.useState(false);
  const [infoP, setInfoP] = React.useState("");
  const [offers, setOffers] = React.useState([]);
  const [selectedOfferID, setSelectedOfferID] = React.useState();
  const [selectedGender, setSelectedGender] = React.useState();
  const [selectedOfferName, setSelectedOfferName] = React.useState();
  const [selectedOfferCountry, setSelectedOfferCountry] = React.useState();
  const [translations, setTranslations] = React.useState();
  const [selectedImage, setSelectedImage] = React.useState();
  const [textImage, setTextImage] = React.useState();
  const [textareaValue, setTextAreaValue] = React.useState();
  const [showFirst, setShowFirst] = React.useState(props?.data?.showFirst ?? false);
  const [replyTo, setReplyTo] = React.useState();

  const commentNameRef = React.useRef();
  const commentTextRef = React.useRef();
  const likesRef = React.useRef();
  const dislikesRef = React.useRef();

  const editComment = () => {
    setInfoP("");
    let data = {
      ID: props.data.ID,
      CommentName: commentNameRef.current.value ? commentNameRef.current.value : props.data.CommentName,
      CommentText: commentTextRef.current.value ? commentTextRef.current.value : props.data.CommentText,
      createdBy: props.data.createdBy ? props.data.createdBy : null,
      offerName: selectedOfferName ? selectedOfferName : props.data.offerName,
      offerID: selectedOfferID ? selectedOfferID : props.data.offerID,
      Country: selectedOfferCountry ? selectedOfferCountry : props.data.Country,
      Gender: selectedGender ? selectedGender : props.data.Gender,
      ReplyID: replyTo ? replyTo : null,
      CommentImage: selectedImage ? selectedImage.URL : props.data.CommentImage,
      TextImage: textImage ? textImage.URL : props.data.TextImage,
      Likes: likesRef.current.value ? likesRef.current.value : props.data.Likes,
      Dislikes: dislikesRef.current.value ? dislikesRef.current.value : props.data.Dislikes,
      showFirst: !!showFirst
    };

    setSpinner(true);
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/comments/editComment`,
      data,
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        props.onClose().then(() => {
          if (props.onChange) props.onChange();
        });
      } else {
        setInfoP(`There was an error while editting the comment`);
      };
    }).catch((e) => {
      setInfoP("Server timed out!");
    }).finally(() => {
      setSpinner(false);
    });
  };


  const getAllOffersForComments = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/comments/getAllOffersForComments`,
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        setOffers(res.data)
      } else {
        setInfoP(`There was an error while creating the comment`);
      };
    }).catch(() => {
      setInfoP("Server timed out!");
    }).finally(() => {
      setSpinner(false);
    });
  }

  React.useEffect(() => {
    if (commentTextRef.current) {
      commentTextRef.current.style.height = 'auto';
      commentTextRef.current.style.height = `${commentTextRef.current.scrollHeight}px`;
    }
  }, [textareaValue]);

  const handleTextareaChange = (e) => {
    setTextAreaValue(e.target.value);
  };

  React.useEffect(() => {
    getAllOffersForComments();
  }, []);

  

  return <div className="route__commentsList__createComment">
    <div className="route__commentsList__createComment__wrap">
      <div className="route__commentsList__createComment__wrap__spinner" style={{
        opacity: spinner ? 1 : 0,
        pointerEvents: spinner ? "all" : "none"
      }} onClick={e => spinner && e.stopPropagation()}>
        <Spinner color="#3F7CEA" />
      </div>

      <h3 style={{ marginBottom: "20px" }}>{props.id ? "Edit" : "Add"} Comment</h3>
      <CustomInput autocomplete="off" ref={commentNameRef} accent="#fff" theme="dark" placeholder={`${props?.data?.CommentName}`} style={{ width: "100%", marginBottom: "20px" }} />
      {/* <CustomInput autocomplete="off" ref={commentTextRef} accent="#fff" theme="dark" placeholder={`${props?.data?.CommentText}`} style={{ width: "100%", marginBottom: "20px" }} /> */}
      <textarea placeholder={`${props?.data?.CommentText}`} ref={commentTextRef} defaultValue={props?.data?.CommentText} onChange={handleTextareaChange} style={{maxHeight: "40px"}} />

      <div className="route__commentsList__createComment__wrap__btns" style={{ justifyContent: 'space-between', marginTop: "-8px", marginBottom: "12px" }}>
        <p style={{ marginBottom: '0px', padding: '4px 12px', minWidth: '200px', borderBottomColor: "#3f7cea" }} onClick={(e) => {
          animateBox(e, <CommentBuilder value={commentTextRef.current.value} onChange={e => {
            commentTextRef.current.value = e;
            setTextAreaValue(e);
          }} />);
        }}>Comment builder</p>
        {!props?.data?.ReplyComments.length && <p style={{ marginBottom: '0px', padding: '4px 12px', minWidth: '200px', borderBottomColor: "#3f7cea" }} 
         onClick={(e) => animateBox(e,
          <SelectReplyIDComment
            headline="Select Comments"
            theme="dark"
            accent="#fff"
            selectBy="ID"
            data={props.allComments}
            currentComment={props.data.ID}
            selectedData={replyTo ? replyTo : null}
            onChange={e => setReplyTo(e)}
            style={{ marginBottom: '20px' }}
          />)}
        >Set as Reply To : {replyTo && <span style={{color: '#f75c7d', marginLeft: '4px'}}>{replyTo}</span>}</p>}
      </div>

      {replyTo && <p style={{marginBottom: '8px', textAlign: 'center', color: '#f75c7d'}}>NOTE: this comment will become a reply to comment with ID : {replyTo}</p>}

      {offers.data && <Dropdown
        inlinePlaceholder="Select Offer"
        theme="dark"
        accent="#fff"
        style={{ marginBottom: '20px' }}
        data={offers?.data.map(offer => {
          return { name: `${offer.OfferName} (${offer.Country})`, value: offer.ID, country: offer.Country, offerName: offer.offerName };
        })}
        onChange={e => { e?.value && setSelectedOfferID(e?.value); e?.name && setSelectedOfferName(e?.offerName); e?.country && setSelectedOfferCountry(e?.country) }}
        selected={(() => {
          if (offers?.status !== "ok") return null;
          return offers.data.indexOf(offers.data.find(u => u.ID === selectedOfferID));
        })()}
      />}

      <Dropdown
        inlinePlaceholder="Select Comment Gender"
        theme="dark"
        accent="#fff"
        style={{ marginBottom: '20px' }}
        data={[
          { name: 'Male', value: 'Male' },
          { name: 'Female', value: 'Female' },
        ]}
        onChange={e => { e?.value && setSelectedGender(e?.value) }}
        selected={(() => {
          return [
            { name: 'Male', value: 'Male' },
            { name: 'Female', value: 'Female' },
          ].indexOf([
            { name: 'Male', value: 'Male' },
            { name: 'Female', value: 'Female' },
          ].find(u => u.value === selectedGender));
        })()}
      />

      {countrySpeakingLangList.length && <Dropdown
        inlinePlaceholder="Select Comment Language"
        theme="dark"
        accent="#fff"
        style={{ marginBottom: '20px' }}
        data={countrySpeakingLangList.map(country => {
          return { name: country.name, value: country.code, language: country.language };
        })}

        onChange={e => { e?.value && setSelectedOfferCountry(e?.value) }}
        selected={(() => {
          return countrySpeakingLangList.indexOf(countrySpeakingLangList.find(cr => cr.code === selectedOfferCountry));
        })()}
      />}

      {/* <MultiSelect
        headline="Select Translations"
        theme="dark"
        accent="#fff"
        data={countrySpeakingLangList}
        onChange={e => setTranslations(e)}
        suggestedData={[
          { name: 'Bosnia and Herzegovina', code: 'BA', language: 'bosnian' },
          { name: 'Croatia', code: 'HR', language: 'croatian' },
          { name: 'Serbia', code: 'RS', language: 'serbian' },
          { name: 'Montenegro', code: 'ME', language: 'serbian' },
          { name: 'Slovenia', code: 'SI', language: 'slovenian' },
          { name: 'Romania', code: 'RO', language: 'romanian' },
        ]}
        style={{ marginBottom: '20px' }}
      /> */}

      <div className="route__commentsList__createComment__wrap__btns" style={{ justifyContent: 'space-evenly' }}>
        <p style={{ marginBottom: '0px', padding: '10px' }} onClick={(e) => {
          animateBox(e, <ImagesList tag='userImage' fromComments={true} onChange={() => curDispatch(updateTimestamp())} style={{ padding: '20px' }} select={true} setSelectedImage={setSelectedImage} />)
        }}>Select User Image</p>
        <p style={{ marginBottom: '0px', padding: '10px' }} onClick={(e) => {
          animateBox(e, <AddImage tag='userImage' onChange={() => curDispatch(updateTimestamp())} />)
        }}>Upload User Image</p>
        <p style={{ marginBottom: '0px', padding: '10px' }} onClick={(e) => setSelectedImage('https://backend.scale-cms.com:5000/staticContent/images/89935b0a-08f9-4cc8-b3ae-e37aea7de6f7-1707465661699.webp')}>Remove Image</p>
      </div>
      {selectedImage ? <a href={`${selectedImage.URL}`} target="_blank" style={{ color: 'white' }}>{selectedImage.URL}</a> : props?.data?.CommentImage ? <a href={`${props?.data?.CommentImage}`} target="_blank" style={{ color: 'white' }}>{props?.data?.CommentImage}</a> : null}

      <div className="route__commentsList__createComment__wrap__btns" style={{ justifyContent: 'space-evenly' }}>
        <p style={{ marginBottom: '0px', padding: '10px' }} onClick={(e) => {
          animateBox(e, <ImagesList tag='textImage' fromComments={true} onChange={() => curDispatch(updateTimestamp())} style={{ padding: '20px' }} select={true} setSelectedImage={setTextImage} />)
        }}>Select Text Image</p>
        <p style={{ marginBottom: '0px', padding: '10px' }} onClick={(e) => {
          animateBox(e, <AddImage tag='textImage' onChange={() => curDispatch(updateTimestamp())} />)
        }}>Upload Text Image</p>
      </div>
      {textImage && console.log("ovo je text image ", textImage)}
      {props?.data?.TextImage && console.log("ovo je text image ", props?.data?.TextImage)}
      {textImage ? <a href={`${textImage.URL}`} target="_blank" style={{ color: 'white' }}>{textImage.URL}</a> : props?.data?.TextImage ? <a href={`${props?.data?.TextImage}`} target="_blank" style={{ color: 'white' }}>{props?.data?.TextImage}</a> : null}


      <div className="route__commentsList__createComment__wrap__likes">
        <CustomInput ref={likesRef} accent="#fff" theme="dark" placeholder="Likes Amount" type="number" />
        <CustomInput ref={dislikesRef} accent="#fff" theme="dark" placeholder="Dislikes Amount" type="number" />
      </div>

      <p style={{display: "flex", alignItems: "center", gap: "10px", margin: "10px 0"}}>
        <Checkbox checked={showFirst} onChange={e => showFirst !== e && setShowFirst(!!e)} />
        Show First
      </p>

      <div className="route__commentsList__createComment__wrap__btns">
        <p onClick={editComment}>
          <img style={{ marginRight: "15px" }} src="/images/saveBtn.png" />
          <span>Save</span>
        </p>
        <p onClick={props.onClose}>
          <span>Cancel</span>
          <img style={{ marginLeft: "15px" }} src="/images/closeBtn.png" />
        </p>
      </div>

      {infoP && <p className="route__commentsList__createComment__wrap__infoP">{infoP}</p>}
    </div>
  </div>

};

const CommentBuilder = props => {
  const textareaRef = React.useRef();

  const saveComment = () => {
    props.onChange(textareaRef.current.value);
    props.onClose();
  };

  const replaceAndWrap = (tag, additionalProps) => {
    if (!textareaRef.current) return;

    let finalProps = [];
    if (additionalProps) {
      for (let key of Object.keys(additionalProps)) {
        if (additionalProps[key].startsWith("??")) {
          let text = prompt(additionalProps[key].substring(2, additionalProps[key].length));
          if (text) {
            additionalProps[key] = text;
          } else {
            return;
          };
        };
        finalProps.push(`${key}="${additionalProps[key]}"`);
      };
    };

    let start = textareaRef.current.value.slice(0, textareaRef.current.selectionStart);
    let selectedText = textareaRef.current.value.slice(textareaRef.current.selectionStart, textareaRef.current.selectionEnd);
    let end = textareaRef.current.value.slice(textareaRef.current.selectionEnd, textareaRef.current.value.length);
    if (!selectedText) return alert("Select the text first!");

    let preparedText = `<${tag}${finalProps.length > 0 ? ` ${finalProps.join(" ")}` : ""}>${selectedText}</${tag}>`;
    let final = `${start}${preparedText}${end}`;
    textareaRef.current.value = final;
  };

  return <div className="route__commentsList__createComment">
    <div className="route__commentsList__createComment__wrap">
      <h3 style={{marginBottom: "20px"}}>Comment builder</h3>

      <textarea defaultValue={props?.value ?? ""} ref={textareaRef} placeholder="Enter your comment here..." className="route__commentsList__createComment__wrap__textarea"></textarea>
      <div className="route__commentsList__createComment__wrap__builderButtons">
        <button onClick={() => replaceAndWrap("b")}>Bold text</button>
        <button onClick={() => replaceAndWrap("a", {
          href: "#",
          "data-scrollonclick": "??Enter the class of the form to scroll to"
        })}>Scroll to form</button>
      </div>

      <div className="route__commentsList__createComment__wrap__btns">
        <p onClick={saveComment}>
          <img style={{ marginRight: "15px" }} src="/images/saveBtn.png" />
          <span>Save</span>
        </p>
        <p onClick={props.onClose}>
          <span>Cancel</span>
          <img style={{ marginLeft: "15px" }} src="/images/closeBtn.png" />
        </p>
      </div>
    </div>
  </div>
};

const ViewCommentReplies = props => {

  const [spinner, setSpinner] = React.useState(false);
  const [infoP, setInfoP] = React.useState("");
  const [data, setData] = React.useState([]);
  const [selectedOfferID, setSelectedOfferID] = React.useState();
  const [filters, setFilters] = React.useState([]);

  const siteSettingsSelector = useSelector(state => state?.siteSettings ?? {});
  const timestampSelector = useSelector(state => state?.timestamp ?? null);
  const userDataSelector = useSelector(state => state?.userData?.userData ?? {});

  const removeComment = (ID) => {
    setSpinner(true);
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/comments/removeComment`,
      data: {
        ID: ID
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === 'ok') {
        getAllCommentReplies();
      }
      else {
      }
    }).catch(() => {
    }).finally(() => {
      setSpinner(false);
    });
  }

  const getAllCommentReplies = () => {
    setSpinner(true);
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/comments/getAllCommentReplies`,
      data: {
        commentID: props.mainComment.ID
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        setData(res.data)
      } else {
        setInfoP(`There was an error while creating the comment`);
      };
    }).catch(() => {
      setInfoP("Server timed out!");
    }).finally(() => {
      setSpinner(false);
    });
  }

  React.useEffect(() => {
    getAllCommentReplies();
  }, []);


  return <div className="route__commentsList__viewReplies">
    <div className="route__commentsList__viewReplies__wrapper">
      <div style={{ display: spinner ? 'block' : 'none' }}><Spinner color='white' width='32px' /> ...Loading Replies</div>
      <div className="route__commentsList__templateSettings__wrapper__close" onClick={() => props.onClose()}>Close</div>

      {data && data.data && <FilteredCustomTable
        key={`domainTable-${siteSettingsSelector.advancedSearch ? "advanced" : "simple"}`}
        theme="dark"
        accent="#48515C"
        style={{ width: "100%" }}
        customColumns={["100px", "200px", "1fr", "80px", "80px", "80px"]}
        headers={["ID", "CommentName", "CommentText", "Likes", "Dislikes", "Country"]}
        filters={siteSettingsSelector.advancedSearch ? [
          { name: "ID", friendlyName: "ID", type: "number" },
          { name: "Name", friendlyName: "Name", type: "string" },
          { name: "Domain", friendlyName: "Domain", type: "string" },
        ] : undefined}
        filterCB={f => setFilters(f)}
        data={(() => {
          if (!data) return [[{ keyID: "noDataSpinner", type: "spinner" }]];
          if (data?.status !== "ok") return [[{ keyID: "noDataError", type: "custom", data: <p>There was an error while fetching data</p> }]]
          if (data.data.length === 0) return [[{ keyID: "noDataError2", type: "custom", data: <p>There is nothing to show.</p> }]]


          let tmp = data.data.map(elem => {
            return [
              { keyID: String(elem.ID), type: "text", text: elem.ID },
              { keyID: String(elem.ID), type: "text", text: elem.CommentName },
              { keyID: String(elem.ID), type: "text", text: elem.CommentText },
              { keyID: String(elem.ID), type: "text", text: elem.Likes },
              { keyID: String(elem.ID), type: "text", text: elem.Dislikes },
              { keyID: String(elem.ID), type: "text", text: elem.Country },
              {
                keyID: String(elem.ID), type: "groupNewline", group: [
                  {
                    keyID: String(elem.ID), type: "button", text: "Delete", triggerDropdown: true, triggerData: e => {
                      return (<div style={{ display: 'flex', flexDirection: 'column', justfyContent: 'center', alignItems: 'center' }}>
                        <p style={{ color: 'white' }}>Are you sure?</p>
                        <br></br>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                          <Button style={{ display: spinner ? 'none' : 'block', marginRight: '10px' }} accent='rgb(72, 82, 92)' value='YES' onClick={() => removeComment(elem.ID)} />
                          <Button style={{ display: spinner ? 'none' : 'block' }} accent='rgb(72, 82, 92)' value='NO' onClick={c => { e() }} />
                          <div style={{ display: spinner ? 'block' : 'none' }}><Spinner color='white' width='32px' /></div>
                        </div>
                      </div>);
                    },
                  },
                  { keyID: String(elem.ID), type: "button", text: "Edit", style: { marginLeft: "10px" }, onClick: e => animateBox(e, <EditComment id={elem.ID} onChange={getAllCommentReplies} data={elem} />) },
                ]
              }
            ].filter(f => f);
          });
          // if (secondarySpinner) tmp.push([{ keyID: "paginationSpinner", type: "spinner" }]);
          return tmp;
        })()}
      />}

    </div>
  </div>

};

const TemplateSettings = props => {

  const [offersTab, setOffersTab] = React.useState(false);
  const [newSelectedOffer, setNewSelectedOffer] = React.useState(props.offer ? props.offer : null)

  const userDataSelector = useSelector(state => state?.userData?.userData ?? {});
  const siteSettingsSelector = useSelector(state => state?.siteSettings ?? {});
  const [filters, setFilters] = React.useState([]);
  const [spinner, setSpinner] = React.useState(false);

  const [usersTab, setUsersTab] = React.useState(false);
  const [newSelectedUser, setNewSelectedUser] = React.useState();
  const [allUsers, setAllUsers] = React.useState(false);

  const [commentsTemplates, setCommentsTemplates] = React.useState([]);

  const getAllUsers = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/users/getAllUsers`,
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        setAllUsers(res.data.data);
      } else {
        setAllUsers({ status: "error", data: "SERVER_ERROR" });
      };
    }).catch(() => {
      setAllUsers({ status: "error", data: "SERVER_ERROR" });
    });
  };

  const getAllCommentsTemplate = () => {
    if (!newSelectedOffer) return;
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/commentsTemplate/getAllCommentsTemplate`,
      data: {
        offerID: newSelectedOffer.ID
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        setCommentsTemplates(res.data)
        if (props.onChange) props.onChange();
      } else {
      };
    }).catch(() => {
    }).finally(() => {
    });
  }

  const removeCommentTemplate = (id) => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/commentsTemplate/removeCommentsTemplate`,
      data: {
        ID: id
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        getAllCommentsTemplate();
      } else {
      };
    }).catch(() => {
    }).finally(() => {
    });
  }

  React.useEffect(() => {
    getAllUsers();
  }, []);

  React.useEffect(() => {
    if (newSelectedOffer) {
      getAllCommentsTemplate();
    }
  }, [newSelectedOffer]);

  return <div className="route__commentsList__templateSettings">
    <div className="route__commentsList__templateSettings__wrapper">

      <div className="route__commentsList__templateSettings__wrapper__close" onClick={() => {props.onClose(); props.onChange()}}>Close</div>

      <div className="route__commentsList__templateSettings__wrapper__head">

        <div className="route__commentsList__templateSettings__wrapper__head__item">
          <p>Selected Offer: </p>
          <div className="route__commentsList__offers" style={{ maxHeight: offersTab ? '500px' : '42px' }}>
            <p className="route__commentsList__offers__head" onClick={() => setOffersTab(e => !e)}>{newSelectedOffer ? <span>{newSelectedOffer.name}</span> : <span>Select Offer</span>}</p>
            <div className="route__commentsList__offers__itemList" style={{ maxHeight: offersTab ? '500px' : '0px' }}>
              {props.allOffers.length ? props.allOffers.map(el => {
                return <p className="route__commentsList__offers__item" onClick={() => { props.setSelectedOffer({ name: el.OfferName, ID: el.ID, country: el.Country }); setNewSelectedOffer({ name: el.OfferName, ID: el.ID, country: el.Country }); setOffersTab(false) }}>{el.OfferName}</p>
              }) : <p>No Data</p>}
            </div>
          </div>
        </div>
        <div className="route__commentsList__templateSettings__wrapper__head__item">
          <p>Templates By: </p>
          <div className="route__commentsList__offers" style={{ maxHeight: usersTab ? '500px' : '42px' }}>
            <p className="route__commentsList__offers__head" onClick={() => setUsersTab(e => !e)}>{newSelectedUser ? <span>{newSelectedUser.name}</span> : props.userData && props.userData.UserInfo.Username ? <span>{props.userData.UserInfo.Username}</span> : null}</p>
            <div className="route__commentsList__offers__itemList" style={{ maxHeight: usersTab ? '500px' : '0px' }}>
              {allUsers && allUsers.length ? allUsers.map(el => {
                return <p className="route__commentsList__offers__item" onClick={() => { setNewSelectedUser({ name: el.Username, ID: el.ID }); setUsersTab(false) }}>{el.Username}</p>
              }) : <p>No Data</p>}
            </div>
          </div>
        </div>
        <div className="route__commentsList__templateSettings__wrapper__head__item" style={{ cursor: 'pointer' }}
          onClick={e => animateBox(e, <CreateTemplate offer={newSelectedOffer} onChange={getAllCommentsTemplate} />)}>
          <p>Create Template</p>
        </div>
      </div>



      <div className="route__commentsList__templateSettings__wrapper__body">

        {commentsTemplates && commentsTemplates.data && commentsTemplates?.data?.length && <FilteredCustomTable
          key={`domainTable-${siteSettingsSelector.advancedSearch ? "advanced" : "simple"}`}
          theme="dark"
          accent="#48515C"
          style={{ width: "100%" }}
          customColumns={["100px", "250px", "1fr", "100px", "100px"]}
          headers={["ID", "Template Name", "OfferID", "Comm Amount", "Country"]}
          filters={siteSettingsSelector.advancedSearch ? [
            { name: "ID", friendlyName: "ID", type: "number" },
            { name: "Name", friendlyName: "Name", type: "string" },
            { name: "Domain", friendlyName: "Domain", type: "string" },
          ] : undefined}
          filterCB={f => setFilters(f)}
          data={(() => {
            if (!commentsTemplates) return [[{ keyID: "noDataSpinner", type: "spinner" }]];
            if (commentsTemplates?.status !== "ok") return [[{ keyID: "noDataError", type: "custom", data: <p>There was an error while fetching data</p> }]]
            if (commentsTemplates.data.length === 0) return [[{ keyID: "noDataError2", type: "custom", data: <p>There is nothing to show.</p> }]]


            let tmp = commentsTemplates.data.map(elem => {
              return [
                { keyID: String(elem.ID), type: "text", text: elem.ID },
                { keyID: String(elem.ID), type: "text", text: elem.Name },
                { keyID: String(elem.ID), type: "text", text: elem.offerID },
                { keyID: String(elem.ID), type: "text", text: JSON.parse(elem.Comments).length },
                { keyID: String(elem.ID), type: "text", text: elem.Country },
                {
                  keyID: String(elem.ID), type: "groupNewline", group: [
                    {
                      keyID: String(elem.ID), type: "button", text: "Delete", triggerDropdown: true, triggerData: e => {
                        return (<div style={{ display: 'flex', flexDirection: 'column', justfyContent: 'center', alignItems: 'center' }}>
                          <p style={{ color: 'white' }}>Are you sure?</p>
                          <br></br>
                          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Button style={{ display: spinner ? 'none' : 'block', marginRight: '10px' }} accent='rgb(72, 82, 92)' value='YES' onClick={() => removeCommentTemplate(elem.ID)} />
                            <Button style={{ display: spinner ? 'none' : 'block' }} accent='rgb(72, 82, 92)' value='NO' onClick={c => { e() }} />
                            <div style={{ display: spinner ? 'block' : 'none' }}><Spinner color='white' width='32px' /></div>
                          </div>
                        </div>);
                      },
                    },
                    { keyID: String(elem.ID), type: "button", text: "Edit", style: { marginLeft: "10px" }, onClick: e => animateBox(e, <EditTemplate id={elem.ID} onChange={props.onChange()} offer={elem} />) },
                  ]
                }
              ].filter(f => f);
            });
            // if (secondarySpinner) tmp.push([{ keyID: "paginationSpinner", type: "spinner" }]);
            return tmp;
          })()}
        />}

      </div>

    </div>
  </div>
}

const CreateTemplate = props => {

  const [spinner, setSpinner] = React.useState(false);
  const [infoP, setInfoP] = React.useState("");

  const [offerID, setOfferID] = React.useState(props?.offer?.ID);
  const [offerName, setOfferName] = React.useState(props?.offer?.name);
  const [selectedTemplateCountry, setSelectedTemplateCountry] = React.useState(props?.offer?.country);

  const [selectedComments, setSelectedComments] = React.useState([]);

  const [commentsByOfferID, setCommentsByOfferID] = React.useState([]);

  const nameRef = React.useRef();


  const createCommentTemplate = () => {
    setInfoP("");
    let data = {
      Name: nameRef.current.value,
      offerName: offerName,
      offerID: offerID,
      Country: selectedTemplateCountry,
      Comments: selectedComments.map(el => el)
    };

    setSpinner(true);

    axios({
      method: "POST",
      url: `${backendModule.backendURL}/commentsTemplate/createCommentsTemplate`,
      data,
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        //ovdje implementiraj ChatGPT sa prevodima sa jezike iz translations state
        props.onClose().then(() => {
          if (props.onChange) props.onChange();
        });
      } else {
        setInfoP(`There was an error while creating the comment`);
      };
    }).catch(() => {
      setInfoP("Server timed out!");
    }).finally(() => {
      setSpinner(false);
    });
  };

  //TODO get comments with certain offer
  const getAllCommentsByOffer = () => {

    axios({
      method: "POST",
      url: `${backendModule.backendURL}/comments/getAllCommentsByOffer`,
      data: {
        offerID: props.offer.ID
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        return setCommentsByOfferID({ ...res.data, timestamp: Date.now() });
      }
      return setCommentsByOfferID({ status: "error", data: "SERVER_ERROR", timestamp: Date.now() });
    }).catch(() => {
      return setCommentsByOfferID({ status: "error", data: "SERVER_ERROR", timestamp: Date.now() });
    });
  };

  React.useEffect(() => {
    getAllCommentsByOffer();
  }, [])


  return <div className="create__template">
    <div className="create__template__wrapper">

      <CustomInput autocomplete="off" ref={nameRef} accent="#fff" theme="dark" placeholder="Template name" style={{ width: "100%", marginBottom: "20px" }} />

      {countrySpeakingLangList.length && <Dropdown
        inlinePlaceholder="Select Comment Language"
        theme="dark"
        accent="#fff"
        style={{ marginBottom: '20px' }}
        data={countrySpeakingLangList.map(country => {
          return { name: country.name, value: country.code, language: country.language };
        })}

        onChange={e => { e?.value && setSelectedTemplateCountry(e?.value) }}
        selected={(() => {
          return countrySpeakingLangList.indexOf(countrySpeakingLangList.find(cr => cr.code === selectedTemplateCountry));
        })()}
      />}

      <p onClick={(e) => animateBox(e,
        <MultiSelectComments
          headline="Select Comments"
          theme="dark"
          accent="#fff"
          selectBy="ID"
          data={commentsByOfferID.data}
          onChange={e => setSelectedComments(e)}
          style={{ marginBottom: '20px' }}
        />)}>Select Comments</p>

      <div className="route__commentsList__createComment__wrap__btns">
        <p onClick={() => createCommentTemplate()}>
          <img style={{ marginRight: "15px" }} src="/images/saveBtn.png" />
          <span>Save</span>
        </p>
        <p onClick={props.onClose}>
          <span>Cancel</span>
          <img style={{ marginLeft: "15px" }} src="/images/closeBtn.png" />
        </p>
      </div>

      {infoP && <p className="route__commentsList__createComment__wrap__infoP">{infoP}</p>}

    </div>
  </div>
}

const EditTemplate = props => {

  const [spinner, setSpinner] = React.useState(false);
  const [infoP, setInfoP] = React.useState("");

  const [offerID, setOfferID] = React.useState(props?.offer?.ID);
  const [offerName, setOfferName] = React.useState(props?.offer?.name);
  const [selectedTemplateCountry, setSelectedTemplateCountry] = React.useState(props.offer.Country);

  const [selectedComments, setSelectedComments] = React.useState(JSON.parse(props.offer.Comments));
  const [commentsByOfferID, setCommentsByOfferID] = React.useState([]);

  const nameRef = React.useRef();

  const editCommentTemplate = () => {
    setInfoP("");
    let data = {
      ID: props.offer.ID,
      Name: nameRef.current.value ? nameRef.current.value : props.offer.Name,
      Country: selectedTemplateCountry ? selectedTemplateCountry : props.offer.Country,
      Comments: selectedComments.map(el => el)
    };

    setSpinner(true);

    axios({
      method: "POST",
      url: `${backendModule.backendURL}/commentsTemplate/editCommentsTemplate`,
      data,
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        //ovdje implementiraj ChatGPT sa prevodima sa jezike iz translations state
        props.onClose().then(() => {
          if (props.onChange) props.onChange();
        });
      } else {
        setInfoP(`There was an error while creating the comment`);
      };
    }).catch(() => {
      setInfoP("Server timed out!");
    }).finally(() => {
      setSpinner(false);
    });
  };

  const getAllCommentsByOffer = () => {

    axios({
      method: "POST",
      url: `${backendModule.backendURL}/comments/getAllCommentsByOffer`,
      data: {
        offerID: props.offer.offerID
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        return setCommentsByOfferID({ ...res.data, timestamp: Date.now() });
      }
      return setCommentsByOfferID({ status: "error", data: "SERVER_ERROR", timestamp: Date.now() });
    }).catch(() => {
      return setCommentsByOfferID({ status: "error", data: "SERVER_ERROR", timestamp: Date.now() });
    });
  };

  React.useEffect(() => {
    getAllCommentsByOffer();
  }, [])


  return <div className="create__template">
    <div className="create__template__wrapper">

      <CustomInput autocomplete="off" ref={nameRef} accent="#fff" theme="dark" placeholder="Template name" style={{ width: "100%", marginBottom: "20px" }} />

      {countrySpeakingLangList.length && <Dropdown
        inlinePlaceholder={`Select Comment Language - Current: ${props.offer.Country}`}
        theme="dark"
        accent="#fff"
        style={{ marginBottom: '20px' }}
        data={countrySpeakingLangList.map(country => {
          return { name: country.name, value: country.code, language: country.language };
        })}

        onChange={e => { e?.value && setSelectedTemplateCountry(e?.value) }}
        selected={(() => {
          return countrySpeakingLangList.indexOf(countrySpeakingLangList.find(cr => cr.code === selectedTemplateCountry));
        })()}
      />}

      <p onClick={(e) => animateBox(e,
        <MultiSelectComments
          headline="Select Comments"
          theme="dark"
          accent="#fff"
          selectBy="ID"
          data={commentsByOfferID.data}
          selectedData={selectedComments ? selectedComments : []}
          onChange={e => setSelectedComments(e)}
          style={{ marginBottom: '20px' }}
        />)}>Select Comments</p>


      <div className="route__commentsList__createComment__wrap__btns">
        <p onClick={() => editCommentTemplate()}>
          <img style={{ marginRight: "15px" }} src="/images/saveBtn.png" />
          <span>Save</span>
        </p>
        <p onClick={props.onClose}>
          <span>Cancel</span>
          <img style={{ marginLeft: "15px" }} src="/images/closeBtn.png" />
        </p>
      </div>

      {infoP && <p className="route__commentsList__createComment__wrap__infoP">{infoP}</p>}

    </div>
  </div>
}


const MultiSelectComments = props => {

  const [selectedData, setSelectedData] = React.useState(props.selectedData ? props.selectedData : []);
  const [hoveredDataElem, setHoveredDataElem] = React.useState(null);
  const [hoveredDataSuggestedElem, setHoveredDataSuggestedElem] = React.useState(null);
  const [data, setData] = React.useState(props.data ? props.data : []);
  const [suggestedData, setSuggestedData] = React.useState(props.suggestedData ? props.suggestedData : []);
  const [selectedGender, setSelectedGender] = React.useState('');

  const addSelectedData = (item) => {
    let foundElem;
    foundElem = selectedData.find(elem => elem === item);

    if (!foundElem) {
      setSelectedData([...selectedData, item]);
    } else {
      setSelectedData(selectedData.filter(obj => obj !== item));
    }
  }

  const checkIfItemIsSelected = (id) => {
    let checker = selectedData.find(el => el === id)
    if (checker) {
      return 'data--item--selected'
    }
  }

  const changeGenderFilter = (gender) => {
    if(!selectedGender) setSelectedGender(gender);
    if(selectedGender === gender) setSelectedGender('');
    if(selectedGender && selectedGender !== gender) setSelectedGender(gender)
  }


  return <div className="route__commentsList__multiselect" >
    <div className="route__commentsList__multiselect__inner" style={{ ...(props.style ?? {}), ...(props.popupStyle ?? {}) }}>

      <div className="route__commentsList__multiselect__inner__genderBtns">
        <p onClick={() => changeGenderFilter('Male')} style={{borderColor: selectedGender === 'Male' ? 'green' : 'white', color: selectedGender === 'Male' ? 'green' : 'white'}}>Male</p>
        <p onClick={() => changeGenderFilter('Female')} style={{borderColor: selectedGender === 'Female' ? 'green' : 'white', color: selectedGender === 'Female' ? 'green' : 'white'}}>Female</p>
      </div>
      
      {suggestedData && suggestedData.length ? <p className="route__commentsList__multiselect__inner__suggestedHeadline"
        style={{ color: props.accent ? props.accent : '#fff' }}
      >Suggested:</p> : null}
      {suggestedData && suggestedData.length ? <div className="route__commentsList__multiselect__inner__data"
        style={{ marginBottom: '20px' }}>
        {suggestedData.map((elem, index) => {
          const isHovered = index === hoveredDataSuggestedElem;

          return <div className={''}
            style={{
              color: props.accent ? props.accent : '#fff',
              border: `1px solid ${props.accent}`,
              boxShadow: isHovered ? `inset 0 0 8px 2px ${props.accent ? props.accent : null}` : 'none',
            }}
            onMouseEnter={(e) => { setHoveredDataSuggestedElem(index) }}
            onMouseLeave={(e) => setHoveredDataSuggestedElem(null)}
            onClick={(e) => { addSelectedData(elem); e.currentTarget.classList.contains("data--item--selected") ? e.currentTarget.classList.remove("data--item--selected") : e.currentTarget.classList.add("data--item--selected") }}
          >
            <p>{elem.name} </p>
          </div>
        })}
      </div> : null}

      <div className="route__commentsList__multiselect__inner__data">
        {data.map((elem, index) => {
          if(props.currentComment === elem.ID) return;
          if(selectedGender){
            if(elem.Gender !== selectedGender) return;
          }
          const isHovered = index === hoveredDataElem;
          return <div className={`${checkIfItemIsSelected(elem.ID)}`}
            style={{
              color: props.accent ? props.accent : '#fff',
              border: `1px solid ${props.accent}`,
              boxShadow: isHovered ? `inset 0 0 8px 2px ${props.accent ? props.accent : null}` : 'none',
            }}
            onMouseEnter={(e) => { setHoveredDataElem(index) }}
            onMouseLeave={(e) => setHoveredDataElem(null)}
            onClick={(e) => { addSelectedData(elem.ID); e.currentTarget.classList.contains("data--item--selected") ? e.currentTarget.classList.remove("data--item--selected") : e.currentTarget.classList.add("data--item--selected") }}
          >
            <p>{elem.CommentName} : {elem.CommentText}</p>
          </div>
        })}

      </div>

      <div className="route__commentsList__createComment__wrap__btns">
        <p onClick={() => { props.onChange(selectedData); props.onClose() }}>
          <img style={{ marginRight: "15px" }} src="/images/saveBtn.png" />
          <span style={{ color: props.accent ? props.accent : '#FFF' }}>Select</span>
        </p>
        <p onClick={props.onClose}>
          <span style={{ color: props.accent ? props.accent : '#FFF' }}>Cancel</span>
          <img style={{ marginLeft: "15px" }} src="/images/closeBtn.png" />
        </p>
      </div>

    </div>
  </div>
}

const SelectReplyIDComment = props => {

  const [selectedData, setSelectedData] = React.useState(props.selectedData ? props.selectedData : '');
  const [hoveredDataElem, setHoveredDataElem] = React.useState(null);
  const [hoveredDataSuggestedElem, setHoveredDataSuggestedElem] = React.useState(null);
  const [data, setData] = React.useState(props.data ? props.data : []);
  const [suggestedData, setSuggestedData] = React.useState(props.suggestedData ? props.suggestedData : []);
  const [selectedGender, setSelectedGender] = React.useState('');

  const addSelectedData = (item) => {
    let foundElem;
    foundElem = selectedData === item;

    if (!foundElem) {
      setSelectedData(item);
    } else {
      setSelectedData(null);
    }
  }

  const checkIfItemIsSelected = (id) => {
    let checker = selectedData === id;
    if (checker) {
      return 'data--item--selected'
    }
  }

  const changeGenderFilter = (gender) => {
    if(!selectedGender) setSelectedGender(gender);
    if(selectedGender === gender) setSelectedGender('');
    if(selectedGender && selectedGender !== gender) setSelectedGender(gender)
  }

  return <div className="route__commentsList__multiselect" >
    <div className="route__commentsList__multiselect__inner" style={{ ...(props.style ?? {}), ...(props.popupStyle ?? {}) }}>

      <div className="route__commentsList__multiselect__inner__genderBtns">
        <p onClick={() => changeGenderFilter('Male')} style={{borderColor: selectedGender === 'Male' ? 'green' : 'white', color: selectedGender === 'Male' ? 'green' : 'white'}}>Male</p>
        <p onClick={() => changeGenderFilter('Female')} style={{borderColor: selectedGender === 'Female' ? 'green' : 'white', color: selectedGender === 'Female' ? 'green' : 'white'}}>Female</p>
      </div>
      
      {suggestedData && suggestedData.length ? <p className="route__commentsList__multiselect__inner__suggestedHeadline"
        style={{ color: props.accent ? props.accent : '#fff' }}
      >Suggested:</p> : null}
      {suggestedData && suggestedData.length ? <div className="route__commentsList__multiselect__inner__data"
        style={{ marginBottom: '20px' }}>
        {suggestedData.map((elem, index) => {
          const isHovered = index === hoveredDataSuggestedElem;

          return <div className={''}
            style={{
              color: props.accent ? props.accent : '#fff',
              border: `1px solid ${props.accent}`,
              boxShadow: isHovered ? `inset 0 0 8px 2px ${props.accent ? props.accent : null}` : 'none',
            }}
            onMouseEnter={(e) => { setHoveredDataSuggestedElem(index) }}
            onMouseLeave={(e) => setHoveredDataSuggestedElem(null)}
            onClick={(e) => { addSelectedData(elem); e.currentTarget.classList.contains("data--item--selected") ? e.currentTarget.classList.remove("data--item--selected") : e.currentTarget.classList.add("data--item--selected") }}
          >
            <p>{elem.name} </p>
          </div>
        })}
      </div> : null}

      <div className="route__commentsList__multiselect__inner__data">
        {data.map((elem, index) => {
          if(elem.ID === props.currentComment) return;
          if(selectedGender){
            if(elem.Gender !== selectedGender) return;
          }
          const isHovered = index === hoveredDataElem;
          return <div className={`${checkIfItemIsSelected(elem.ID)}`}
            style={{
              color: props.accent ? props.accent : '#fff',
              border: `1px solid ${props.accent}`,
              boxShadow: isHovered ? `inset 0 0 8px 2px ${props.accent ? props.accent : null}` : 'none',
            }}
            onMouseEnter={(e) => { setHoveredDataElem(index) }}
            onMouseLeave={(e) => setHoveredDataElem(null)}
            onClick={(e) => { addSelectedData(elem.ID); e.currentTarget.classList.contains("data--item--selected") ? e.currentTarget.classList.remove("data--item--selected") : e.currentTarget.classList.add("data--item--selected") }}
          >
            <p>{elem.CommentName} : {elem.CommentText}</p>
          </div>
        })}

      </div>

      <div className="route__commentsList__createComment__wrap__btns">
        <p onClick={() => { props.onChange(selectedData); props.onClose() }}>
          <img style={{ marginRight: "15px" }} src="/images/saveBtn.png" />
          <span style={{ color: props.accent ? props.accent : '#FFF' }}>Select</span>
        </p>
        <p onClick={props.onClose}>
          <span style={{ color: props.accent ? props.accent : '#FFF' }}>Cancel</span>
          <img style={{ marginLeft: "15px" }} src="/images/closeBtn.png" />
        </p>
      </div>

    </div>
  </div>
}



export default CommentsList;
export { CreateComment }