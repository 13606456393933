import React from "react";

import { Routes, Route } from "react-router-dom";

import CampaignDisallows from "./CampaignDisallows";

const Campaign = () => {
    return <Routes>
        <Route path="/:id/disallows" element={<CampaignDisallows />} />
        <Route path="*" element={<p>Campaign not found!</p>} />
    </Routes>
};

export default Campaign;