import gjs from "grapesjs";

function script() {
    if (!this) return;
}

/**
 * @param {gjs.Editor} editor
 */
export default editor => {
    editor.Components.addType('scalecms-footerTwo', {
        isComponent: e => e?.classList?.contains("scalecms-footerTwo"),
        model: {
            defaults: {
                type: "default",
                traits: [

                ],
                attributes: {
                    class: "scalecms-footerTwo"
                },
                script,
                styles: `
                    .scalecms-footerTwo {
                        width: 100%;
                        background: #333333;
                        padding: 20px;
                        display: grid;
                        grid-template-columns: 1fr 1fr 1fr;
                        font-family: 'Arial';
                    }
                    .scalecms-footerTwo-left-logo{
                        max-width: 80%;
                        width: 150px;
                        max-height: 300px;
                        height: 80px;
                    }
                    .scalecms-footerTwo-left-copyright{
                        color: white;
                        margin-top: 10px;
                    }
                    .scalecms-footerTwo-right-image{
                        margin-left: auto;
                        margin-right: 0;
                        width: 70%;
                        max-height: 300px; 
                    }

                    .scalecms-footerTwo-center{
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                    }
                    .scalecms-footerTwo-center-siteName{
                        text-align: center;
                        font-size: 25px;
                        color: white;
                    }

                    .scalecms-footerTwo-center-links{
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;
                        color: white;
                    }

                    .scalecms-footerTwo-center-links-item{
                        margin-left: 10px;
                        text-decoration: none;
                        color: white;
                    }

                    @media screen and (max-width: 1200px){
                        .scalecms-footerTwo-center-links{
                            flex-direction: column;
                        }
                    }

                    @media screen and (max-width: 600px){
                        .scalecms-footerTwo {
                            grid-template-columns: 1fr;
                            place-items: center;
                        }
                        .scalecms-footerTwo-right{
                            width: 100%;
                            margin-top: 20px;
                        }
                        .scalecms-footerTwo-right-image{
                            width: 100%;
                        }
                        .scalecms-footerTwo-center{
                            margin-top: 20px;
                        }
                        .scalecms-footerTwo-left-logo{
                            width: 100%;
                            max-width: 100%;
                        }
                    }
                `,
                components: [
                    {
                        tagName: "div",
                        attributes: { class: "scalecms-footerTwo-left" },
                        components: [
                            {
                                type: 'image',
                                attributes: {class: "scalecms-footerTwo-left-logo"},
                            },
                            {
                                type: 'text',
                                attributes: {class: 'scalecms-footerTwo-left-copyright'},
                                components: 'Sva prava zadržana 2022 &#x24B8;'
                            }
                        ]
                    },
                    {
                        tagName: 'div',
                        attributes: {class: 'scalecms-footerTwo-center'},
                        components: [
                            {
                                type: 'text',
                                attributes: {class: 'scalecms-footerTwo-center-siteName'},
                                components: 'NAZIV STRANICE'
                            },
                            {
                                tagName: 'div',
                                attributes: {class: 'scalecms-footerTwo-center-links'},
                                components: [
                                    {
                                        type: 'text',
                                        components: 'O nama',
                                        attributes: {class: 'scalecms-footerTwo-center-links-item'}
                                    },
                                    {
                                        type: 'link',
                                        components: 'Odricanje odgovornosti',
                                        attributes: {href: '#', class: 'scalecms-footerTwo-center-links-item'},
                                    },
                                    {
                                        type: 'text',
                                        components: 'Kontakt',
                                        attributes: {class: 'scalecms-footerTwo-center-links-item'}
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        tagName: 'div',
                        attributes: {class: 'scalecms-footerTwo-right'},
                        components: {
                            type: 'image',
                            attributes: {class: 'scalecms-footerTwo-right-image'},
                        }
                    }
                ]
            }
        }
    });


    editor.BlockManager.add('scalecms-footerTwo-blk', {
        label: 'Footer 2',
        content: {
            type: "scalecms-footerTwo",
        },
        category: {
            label: 'Footers'
        },
        media: `<svg viewBox="0 0 24 24"><g data-name="layout 35"><path d="M19,2H5A3,3,0,0,0,2,5V19a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V5A3,3,0,0,0,19,2Zm1,17a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V5A1,1,0,0,1,5,4H19a1,1,0,0,1,1,1Z"/><path d="M17,14H7a2,2,0,0,0-2,2v1a2,2,0,0,0,2,2H17a2,2,0,0,0,2-2V16A2,2,0,0,0,17,14Zm0,3H7V16H17Z"/></g></svg>`,
        attributes: {
            title: 'Basic footerTwo',
        },
        select: true
    });
};