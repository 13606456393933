import React from "react";
import "./index.scss";

import moment from "moment";
import axios from "axios";
import * as backendModule from "../../modules/backendModule";
import { useSelector, useDispatch } from "react-redux";
import { updateTimestamp } from "../../actions/timestampActions";
import { animateBox } from "../../modules/componentAnimation";
import { SaveWebsite } from "../CreateWebsite";

import FilterPanel from "../../components/customComponents/FilterPanel";
import Spinner from "../../components/customComponents/Spinner";
import Button from "../../components/customComponents/Button";
import CustomInput from "../../components/customComponents/CustomInput";
import Heatmaps from "../../components/Heatmaps";

let curTimeout = null;
const CopyWriter = (props) => {
    const [data, setData] = React.useState();
    const [filters, setFilters] = React.useState([]);
    const [canPaginate, setCanPaginate] = React.useState(false);

    const paginationOffset = React.useRef();
    const curPaginationTimestamp = React.useRef();
    const searchRef = React.useRef();

    const timestampSelector = useSelector(state => state?.timestamp);
    const siteSettingsSelector = useSelector(state => state?.siteSettings ?? {});
    const userSelector = useSelector(state => state?.userData?.userData?.UserInfo ?? {});

    const [menuItem, setMenuItem] = React.useState(1)
    const [menuItemInner, setMenuItemInner] = React.useState(1)

    const getData = () => {
        paginationOffset.current = 0;
        curPaginationTimestamp.current = Date.now();

        setCanPaginate(false);
        if (menuItem === 1 && menuItemInner === 1 && !filters.length) {
            filters.push({ name: "Status", op: "eq", value: 0 })
        }
        
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/websites/getAllWebsites`,
            data: {
                offset: paginationOffset.current,
                filters: [
                    ...filters,
                ],
                orders: [{ name: "createdAt", order: "desc" }],
                copyWriterLandings: menuItem === 1 ? true : false
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (res.data.data.length === 20) {
                    paginationOffset.current += 20;
                    setTimeout(() => setCanPaginate(true), 500);
                } else {
                    setCanPaginate(false);
                    paginationOffset.current = -1;
                };

                return setData({ ...res.data, timestamp: Date.now() });
            }
            return setData({ status: "error", data: "SERVER_ERROR", timestamp: Date.now() });
        }).catch(() => {
            return setData({ status: "error", data: "SERVER_ERROR", timestamp: Date.now() });
        });
    };

    const continueData = (timestamp) => {
        if (paginationOffset.current === -1) {
            if (timestamp !== curPaginationTimestamp.current) return;
            if (canPaginate) setCanPaginate(false);
            return;
        };

        if (!filters.length) filters.push({ name: "Status", op: "eq", value: 0 })
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/websites/getAllWebsites`,
            data: {
                offset: data?.data?.length ?? 0,
                filters: [
                    ...filters,
                ],
                orders: [{ name: "createdAt", order: "desc" }]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestamp !== curPaginationTimestamp.current) return;
            if (res.data.status === "ok") {
                if (res.data.data.length === 20) {
                    paginationOffset.current += 20;
                    setTimeout(() => setCanPaginate(true));
                } else {
                    setCanPaginate(false);
                    paginationOffset.current = -1;
                };
                return setData(old => {
                    return {
                        ...old,
                        data: [
                            ...old.data,
                            ...res.data.data
                        ], timestamp: Date.now()
                    };
                });
            }
            return setData({ status: "error", data: "SERVER_ERROR", timestamp: Date.now() });
        }).catch(() => {
            return setData({ status: "error", data: "SERVER_ERROR", timestamp: Date.now() });
        }).finally(() => {
            if (timestamp !== curPaginationTimestamp.current) return;
        });
    };

    const PaginationData = () => {
        let tmpRef = React.useRef();
        React.useEffect(() => {
            if (!tmpRef?.current) return;
            let observer = null;
            try {
                let observer = new IntersectionObserver((entries) => {
                    entries.forEach(entry => {
                        if (entry.intersectionRatio > 0) {
                            try { observer.unobserve(tmpRef.current); } catch { };
                            if (canPaginate) {
                                continueData(curPaginationTimestamp.current);
                            };
                        };
                    });
                }, { threshold: [1] });
                observer.observe(tmpRef.current);
            } catch { };

            return () => {
                if (tmpRef?.current) {
                    try { observer.unobserve(tmpRef.current); } catch { };
                };
            };
        }, [tmpRef]);

        return <div ref={tmpRef} style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        }}>
            <Spinner color="#3F7CEA" />
        </div>;
    };

    React.useEffect(() => {
        setData();
        getData();
    }, [timestampSelector, filters]);

    React.useEffect(() => {
        if (!searchRef.current) return;

        let handler = () => {
            let searchVal = searchRef?.current?.value;
            clearTimeout(curTimeout);
            curTimeout = setTimeout(() => {
                setFilters([{ name: "--searcher", value: searchVal }])
            }, 500);
        };

        searchRef.current.addEventListener("input", handler);

        return () => {
            try {
                searchRef.current.removeEventListener("input", handler);
            } catch { };
        };
    }, [searchRef.current]);

    React.useEffect(() => {
        if (filters?.length > 0) setFilters([]);
    }, [siteSettingsSelector.advancedSearch]);

    return <div className="route__landingList">
        <div className="route__landingList__menu">
            <p onClick={() => { setMenuItem(1); setFilters([{ name: "Status", op: "eq", value: 0 }]);}} className={`${menuItem === 1 ? 'active' : ''}`}>Landing Websites</p>
            <p onClick={() => { setMenuItem(2); setFilters([{ name: "ApprovedBy", op: "eq", value: userSelector.ID }]);}} className={`${menuItem === 2 ? 'active' : ''}`}>Dashboard</p>
        </div>
        {menuItem === 1 && <>

            <div className="route__landingList--filters">
                <FilterPanel key="landing-advancedFilter" theme="dark" accent="#3F7CEA" style={{ width: "100%" }} filters={[
                    { name: "ID", friendlyName: "ID", type: "number" },
                    { name: "ClonedID", friendlyName: "Cloned ID", type: "number" },
                    { name: "Status", friendlyName: "Status", type: "number" },
                    { name: "Name", friendlyName: "Name", type: "string" },
                    { name: "createdAt", friendlyName: "Created at", type: "date" },
                    { name: "updatedAt", friendlyName: "Updated at", type: "date" },
                ]} filterCB={f => setFilters(f)} />
                <div className="route__landingList--filters__advanced">
                    <CustomInput autocomplete="off" key="landing-simpleFilter" ref={searchRef} accent="#3F7CEA" theme="dark" placeholder="Search" style={{ width: "100%" }} />  
                    <div className="route__landingList__menu">
                        <p style={{color: '#ff6b6b'}} onClick={() => {setMenuItemInner(1); setFilters([{ name: "Status", op: "eq", value: 0 }]);}} className={`${menuItemInner === 1 ? 'active' : ''}`}>Review Pending</p>
                        <p style={{color: '#866bff'}} onClick={() => {setMenuItemInner(2); setFilters([{ name: "Status", op: "eq", value: 110 }]);}} className={`${menuItemInner === 2 ? 'active' : ''}`}>Review Required</p>
                    </div>
                </div>
            </div>

            <div className="route__landingList--data">
                {data ? <>
                    {data.status === "ok" ? <>
                        {data.data.length === 0 && <p>There is nothing to display.</p>}
                        {data.data.map((elem) => {
                            return <LandingItem
                                key={`${elem.ID}-${elem.updatedAt}`}
                                data={elem}
                                onDelete={() => {
                                    return setData(d => {
                                        return {
                                            ...d,
                                            data: d.data.filter(dt => dt.ID !== elem.ID)
                                        };
                                    });
                                }}
                                onRefresh={() => getData()}
                            />
                        })}
                        {canPaginate && <PaginationData />}
                    </> : "Server timed out!"}
                </> : <Spinner color="white" />}
            </div>
        </>}

        {menuItem === 2 && <>
            <div className="route__landingList--data">
                {data ? <>
                    {data.status === "ok" ? <>
                        {data.data.length === 0 && <p>There is nothing to display.</p>}
                        {data.data.map((elem) => {
                            return <LandingItem
                                key={`${elem.ID}-${elem.updatedAt}`}
                                data={elem}
                                onDelete={() => {
                                    return setData(d => {
                                        return {
                                            ...d,
                                            data: d.data.filter(dt => dt.ID !== elem.ID)
                                        };
                                    });
                                }}
                                onRefresh={() => getData()}
                            />
                        })}
                        {canPaginate && <PaginationData />}
                    </> : "Server timed out!"}
                </> : <Spinner color="white" />}
            </div>
        </>}

    </div>
};

const LandingItem = props => {

    const mainRef = React.useRef();
    const curDispatch = useDispatch();

    const [quickEdit, setQuickEdit] = React.useState(false);
    const newSiteNameRef = React.useRef();

    const [spinner, setSpinner] = React.useState(false);

    const userSelector = useSelector(state => state?.userData?.userData?.UserInfo ?? {});

    const editItemsName = (id, name) => {

        setSpinner(true)
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/websites/editWebsitesName`,
            data: {
                ID: id,
                Name: name
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                props.onRefresh();
                setQuickEdit(false);
            }
        }).catch((err) => {
            console.log("error while changing websites name ", err)
        }).finally(() => {
            setTimeout(() => {
                setSpinner(false)
            }, 200);
        });
    }

    const editItemsStatus = (id, status) => {

        setSpinner(true)
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/websites/editWebsitesStatus`,
            data: {
                ID: id,
                Status: status
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
            }
        }).catch((err) => {
            console.log("error while changing websites status ", err)
        }).finally(() => {
            setTimeout(() => {
                setSpinner(false)
            }, 200);
        });
    }

    const handleKeyDown = (event, id, name) => {
        if (event.key === 'Enter') {
            editItemsName(id, name);
            newSiteNameRef.current.blur();
        }
    };

    const DeleteItem = (props2) => {
        const [infoP, setInfoP] = React.useState("");
        const [spinner, setSpinner] = React.useState(false);

        const deleteItem = () => {
            setInfoP();
            setSpinner(true);

            axios({
                method: "POST",
                url: `${backendModule.backendURL}/websites/removeWebsite`,
                data: {
                    ID: props.data.ID
                },
                ...backendModule.axiosConfig
            }).then(res => {
                if (res.data.status === "ok") {
                    props2.onClose().then(() => {
                        props2.onChange();
                    });
                } else {
                    setInfoP("Error while deleting a website");
                };
            }).catch(() => {
                setInfoP("Server timed out!");
            }).finally(() => {
                setSpinner(false);
            });
        };

        return <div className="route__landingList__delete">
            <div className="route__landingList__delete__wrap">
                <div className="route__landingList__delete__wrap__spinner" style={{
                    opacity: spinner ? 1 : 0,
                    pointerEvents: spinner ? "all" : "none"
                }}>
                    <Spinner color="white" />
                </div>

                <h3>Are you sure?</h3>
                <p>Deleting the website <span style={{ color: "rgb(63, 124, 234)" }}>{props.data.Name}</span> is permanent!</p>

                <div className="route__landingList__delete__wrap__btns">
                    <p onClick={deleteItem}>
                        <img style={{ marginRight: "15px" }} src="/images/saveBtn.png" />
                        <span>Save</span>
                    </p>
                    <p onClick={props2.onClose}>
                        <span>Cancel</span>
                        <img style={{ marginLeft: "15px" }} src="/images/closeBtn.png" />
                    </p>
                </div>

                {infoP && <p style={{ marginTop: "10px", color: "rgb(234, 63, 63)" }}>{infoP}</p>}
            </div>
        </div>
    };

    return <div ref={mainRef} className="route__landingList__item" onClick={e => {
        e.stopPropagation();
        window.open(`${backendModule.backendURL}/websites/preview/${props.data.ID}`, "_blank");
    }}>
        <p className="route__landingList__item__id">
            {props.data.ClonedID && <span style={{ marginRight: "auto" }}>Cloned ID:{props.data.ClonedID}</span>}
            <span style={{ marginLeft: "auto", position: 'relative' }}>ID:{props.data.ID}
                <span className="status-desc" style={{
                    position: 'absolute', top: '-18px', right: '0px', width: 'max-content'
                }}>{props.data.Status === 0 ? 'Review Pending' : (props.data.Status > 0 && props.data.Status < 10) ? 'Under Review' : (props.data.Status > 99 && props.data.Status < 109) ? 'Approved' : (props.data.Status > 108 && props.data.Status < 120) ? 'Review Required' : ''}</span>
                <span className="status-dot" style={{
                    backgroundColor: props.data.Status === 0 ? 'red' : (props.data.Status > 0 && props.data.Status < 10) ? 'yellow' : (props.data.Status > 99 && props.data.Status < 109) ? 'green' : (props.data.Status > 108 && props.data.Status < 120) ? 'blue' : '',
                    width: '8px', height: '8px', borderRadius: '50%', position: 'absolute', top: '-12px', right: '-10px'
                }}></span>
            </span>
        </p>
        <LazyImage src={`${backendModule.backendURL}/StaticContent/websitePreviews/${props.data.RenderImage}?rand=${Date.now()}`} />
        <p className="route__landingList__item__name">
            <div className="route__landingList__delete__wrap__spinner" style={{
                opacity: spinner ? 1 : 0,
                pointerEvents: spinner ? "all" : "none"
            }}>
                <Spinner color="white" />
            </div>
            {(userSelector?.Flags?.isAdmin || userSelector?.Flags?.canEditWebsite || userSelector?.ID === props.data.UserID || userSelector?.Flags?.isCopywriter) && <>
                {quickEdit ? <input onClick={(e) => e.stopPropagation()} autoFocus={quickEdit} onKeyDown={(e) => handleKeyDown(e, props.data.ID, newSiteNameRef?.current?.value)} ref={newSiteNameRef} placeholder={`${props.data.Name}`} /> : <span>{props.data.Name}</span>}
            </>}

            <span style={{
                color: "gray",
                fontSize: 14
            }}>&nbsp;({props.data.Country}, {props.data.Article})</span>
            {props.data.lastEditBy && <>
                <br />
                <span style={{
                    color: "gray",
                    fontSize: 14,
                    display: "block",
                    marginTop: "5px"
                }}>Last edit: {props.data.lastEditByName}</span>
                <span style={{
                    color: "gray",
                    fontSize: 14,
                    display: "block",
                    marginTop: "2px"
                }}>Created at: {moment(props.data.createdAt).toDate().toLocaleString()}</span>
                <span style={{
                    color: "gray",
                    fontSize: 14,
                    display: "block",
                    marginTop: "2px"
                }}>Edit date: {moment(props.data.updatedAt).toDate().toLocaleString()}</span>
            </>}
        </p>

        {(props.data.Status < 100 || props.data.Status > 108) ? <div className="route__landingList__item__btns">
            {props.buttons ? props.buttons : <>
                <Button value="Clone" accent="#3F7CEA" onClick={e => {
                    e.stopPropagation();
                    animateBox(e, <SaveWebsite existing={props.data} clonedID={props.data.ID} type={props.data.Type} onChange={() => {
                        props.onRefresh();
                    }} />);
                }} />
                {(userSelector?.Flags?.isAdmin || userSelector?.Flags?.canEditWebsite || userSelector?.ID === props.data.UserID || userSelector?.Flags?.isCopywriter) && <Button value="Edit" accent="#454F5B" onClick={e => {
                    editItemsStatus(props.data.ID, 1);
                    let wnd = window.open(`${window.location.origin}/#/create-website/${props.data.Type}/${props.data.ID}?Status=${props.data.Status}`, "_blank");
                    wnd.addEventListener("beforeunload", () => curDispatch(updateTimestamp()));
                    e.stopPropagation();
                }} />}
                {(userSelector?.Flags?.isAdmin || userSelector?.ID === props.data.UserID) && <Button value="Remove" accent="#EA3F3F" onClick={e => {
                    e.stopPropagation();
                    animateBox(e, <DeleteItem onChange={() => {
                        if (!mainRef.current) return props.onDelete();
                        mainRef.current.animate([
                            { opacity: getComputedStyle(mainRef.current).opacity },
                            { opacity: 0 }
                        ], {
                            duration: 300,
                            iterations: 1,
                            fill: "both",
                            easing: "ease"
                        }).onfinish = () => props.onDelete();
                    }} />);
                }} />}
            </>}
        </div> : <div>
            {props?.data?.AssignedToCampaign?.length ? <Button value="Show info" style={{ width: "100%" }} accent="#3F7CEA" onClick={e => {
                e.stopPropagation();

                animateBox(e, <LanderInfo lander={props.data} ID={props.data.ID} type={props.data.Type} />);
            }} /> : <p>Lander isnt used in campaigns</p>}
        </div>}
    </div>
};

const LazyImage = props => {
    const mainRef = React.useRef();
    const [spinnerVisible, setSpinnerVisible] = React.useState(true);

    React.useEffect(() => {
        if (!mainRef.current) return;

        let observer = new IntersectionObserver(entries => {
            for (let entry of entries) {
                if (entry.isIntersecting) {
                    entry.target.src = entry.target.getAttribute("data-src");
                };
            };
        }, { threshold: [0.5] });

        let img = mainRef.current.querySelector("img");
        if (!img) return setSpinnerVisible(false);
        observer.observe(img);

        return () => {
            try {
                observer.unobserve(img);
            } catch { };
        };
    }, [mainRef]);

    return <div className="route__landingList__item__img" ref={mainRef}>
        <div className="route__landingList__item__img__spinner" style={{
            opacity: spinnerVisible ? 1 : 0,
            pointerEvents: spinnerVisible ? "all" : "none"
        }}>
            <Spinner color="white" />
            <p>Loading image...</p>
        </div>
        <img data-src={props.src} onLoad={() => setSpinnerVisible(false)} />
    </div>
};

const LanderInfo = props => {

    let formatter = Intl.NumberFormat('en', { notation: 'standard' });

    const [campaignIDs, setCampaignIDs] = React.useState(JSON.parse(props?.lander?.AssignedToCampaign));
    const [campaigns, setCampaigns] = React.useState([]);
    const [spinner, setSpinner] = React.useState(false);

    const getCampaignInfo = async (array) => {
        setSpinner(true);
        let currCampaigns = [];
        for (let id of array) {
            await axios({
                method: "POST",
                url: `${backendModule.backendURL}/campaigns/getAllCampaigns`,
                data: {
                    filters: [{ name: "ID", op: "eq", value: id }]
                },
                ...backendModule.axiosConfig
            }).then(res => {
                if (res.data.status === "ok") {
                    currCampaigns.push(res.data.data[0])
                } else {
                    console.log("pozvan u elsu ", res)
                };
            }).catch((e) => {
                console.log("pozvan u catchu ", e)
            });
        }

        setCampaigns(currCampaigns);
        setSpinner(false);
    }

    React.useEffect(() => {
        getCampaignInfo(campaignIDs)
    }, [campaignIDs])


    return <div className="landerInfo">
        <p className="landerInfo__closeBtn" onClick={() => props.onClose()}>&#10005;</p>
        <p className="landerInfo__headline">lander info</p>

        {spinner && <Spinner align="center" color="#3F7CEA" marginTop="20px" />}

        {campaigns.length > 0 && <div className="route__campaignsList__item__row" style={{ padding: "15px" }}>
            <p>Campaign name</p>
            <p>Visits</p>
            <p>Conversions</p>
            <p style={{ textAlign: "end" }}>Actions</p>
        </div>}

        <div className="landerInfo__itemsContainer">
        {campaigns && campaigns.map(elem => {
            return <div className={`route__campaignsList__item route__campaignsList__item--${elem.ID}`} key={`campaign-${elem.ID}`}>
                <div className="route__campaignsList__item__row">
                    <p>{elem.Name} {elem.FlowType === "rotation" && ` (Rotation)`} {elem.BotsDisallowed && ` (Bots disallowed)`} {elem.RespectCountry && ` (unknown country disallowed)`} {elem.SiteGuard && ` (SiteGuard enabled)`}</p>
                    <p>{formatter.format(elem.clicks)}</p>
                    <p>{formatter.format(elem.conversions)}</p>
                    <div className="route__campaignsList__item__row__actions">

                    </div>
                </div>
                {(elem.BotsDisallowed || elem.RespectCountry || elem.SiteGuard) && elem.BotFinal.length > 0 && <>
                    <div className="route__campaignsList__item__row route__campaignsList__item__row--inset">
                        <p>{[(elem.BotsDisallowed ? "Bot" : null), (elem.RespectCountry ? "Country failover" : null), (elem.SiteGuard ? "Site guard" : null)].filter(t => t).join(" / ")} pages</p>
                        <p>{formatter.format(elem.totalBotClicks)}</p>
                        <p>-</p>
                    </div>
                    {elem.BotFinal.map(elemBot => {
                        return <div className="route__campaignsList__item__row route__campaignsList__item__row--inset route__campaignsList__item__row--inset2">
                            <p>{elemBot.Name} (ID: {elemBot.ID})</p>
                            <p>{formatter.format(elemBot.clicks)}</p>
                            <p>-</p>
                            <div className="route__campaignsList__item__row__actions">
                                <p title="View page in new tab" onClick={e => {
                                    e.stopPropagation();
                                    window.open(`${backendModule.backendURL}/websites/preview/${elemBot.ID}`, "_blank");
                                }}><img src="/images/showWebsiteCampaign.png" /></p>
                            </div>
                        </div>
                    })}
                </>}
                {elem.PreLanderList.length > 0 && <>
                    <div className="route__campaignsList__item__row route__campaignsList__item__row--inset">
                        <p>Pre-landing pages</p>
                        <p>{formatter.format(elem.totalPrelanderClicks)}</p>
                        <p>-</p>
                    </div>
                    {elem.PreLanderFinal.map(elemLander => {
                        return <div className="route__campaignsList__item__row route__campaignsList__item__row--inset route__campaignsList__item__row--inset2">
                            <p>{elemLander.Name} (ID: {elemLander.ID})</p>
                            <p>{formatter.format(elemLander.clicks)}</p>
                            <p>-</p>
                            <div className="route__campaignsList__item__row__actions">
                                <p title="View page in new tab" onClick={e => {
                                    e.stopPropagation();
                                    window.open(`${backendModule.backendURL}/websites/preview/${elemLander.ID}`, "_blank");
                                }}><img src="/images/showWebsiteCampaign.png" /></p>
                            </div>
                        </div>
                    })}
                </>}

                {elem.LanderList.length > 0 && <>
                    <div className="route__campaignsList__item__row route__campaignsList__item__row--inset">
                        <p>Landing pages</p>
                        <p>{formatter.format(elem.totalLanderClicks)}</p>
                        <p>{formatter.format(elem.conversions)}</p>
                    </div>
                    {elem.LanderFinal.map(elemLander => {
                        return <div className="route__campaignsList__item__row route__campaignsList__item__row--inset route__campaignsList__item__row--inset2">
                            <p>{elemLander.Name} (ID: {elemLander.ID})</p>
                            <p>{formatter.format(elemLander.clicks)}</p>
                            <p>{formatter.format(elemLander.conversions)}</p>
                            <div className="route__campaignsList__item__row__actions">
                                <p title="View page in new tab" onClick={e => {
                                    e.stopPropagation();
                                    window.open(`${backendModule.backendURL}/websites/preview/${elemLander.ID}`, "_blank");
                                }}><img src="/images/showWebsiteCampaign.png" /></p>
                            </div>
                        </div>
                    })}
                </>}

                {elem.ThankyouList.length > 0 && <>
                    <div className="route__campaignsList__item__row route__campaignsList__item__row--inset">
                        <p>Thank you pages</p>
                        <p>{formatter.format(elem.totalThankyouClicks)}</p>
                        <p>-</p>
                    </div>
                    {elem.ThankyouFinal.map(elemLander => {
                        return <div className="route__campaignsList__item__row route__campaignsList__item__row--inset route__campaignsList__item__row--inset2">
                            <p>{elemLander.Name} (ID: {elemLander.ID})</p>
                            <p>{formatter.format(elemLander.clicks)}</p>
                            <p>-</p>
                            <div className="route__campaignsList__item__row__actions">
                                <p title="View page in new tab" onClick={e => {
                                    e.stopPropagation();
                                    window.open(`${backendModule.backendURL}/websites/preview/${elemLander.ID}`, "_blank");
                                }}><img src="/images/showWebsiteCampaign.png" /></p>
                            </div>
                        </div>
                    })}
                </>}
                {elem.BackButtonRedirectURL && <>
                    <div className="route__campaignsList__item__row route__campaignsList__item__row--inset">
                        <p>Back button redirect URL</p>
                        <p></p>
                        <p></p>
                    </div>
                    <div className="route__campaignsList__item__row route__campaignsList__item__row--inset route__campaignsList__item__row--inset2">
                        <p style={{ gridColumn: "1 / span all" }}>{elem.BackButtonRedirectURL}</p>
                    </div>
                </>}
                {/* {isToday(elem.createdAt) ? <img className="badgeImage" src="./images/newBadge.png" /> : null} */}
            </div>
        })}
        </div>
    </div>
}

export default CopyWriter;