import gjs from "grapesjs";

function script() {
    if (!this) return;
}

/**
 * @param {gjs.Editor} editor
 */
export default editor => {
    editor.Components.addType('scalecms-unorderedList', {
        isComponent: e => e?.classList?.contains("scalecms-unorderedList"),
        model: {
            defaults: {
                script,
                textable: 1,
                type: "default",
                traits: [
                    { name: "date-days", type: "Date days", placeholder: "-1/0/+1", value: "0" },
                ],
                attributes: {
                    class: "scalecms-unorderedList"
                },
                styles: `
                    .scalecms-unorderedList{
                       padding: 0;
                       height: fit-content;
                       width: fit-content; 
                    }
                    .scalecms-unorderedList-box-item{
                        margin-top: 10px;
                    }
                `,
                components: [
                    {
                        tagName: 'div',
                        attributes: { class: 'scalecms-unorderedList-box' },
                        components: [
                            {
                                type: "text",
                                attributes: {class: 'scalecms-unorderedList-box-item'},
                                components: '&#9654; ul-list-item'
                            },
                            {
                                type: "text",
                                attributes: {class: 'scalecms-unorderedList-box-item'},
                                components: '&#9654; ul-list-item'
                            },
                            {
                                type: "text",
                                attributes: {class: 'scalecms-unorderedList-box-item'},
                                components: '&#9654; ul-list-item'
                            },
                            {
                                type: "text",
                                attributes: {class: 'scalecms-unorderedList-box-item'},
                                components: '&#9654; ul-list-item'
                            },
                            {
                                type: "text",
                                attributes: {class: 'scalecms-unorderedList-box-item'},
                                components: '&#9654; ul-list-item'
                            },
                            {
                                type: "text",
                                attributes: {class: 'scalecms-unorderedList-box-item'},
                                components: '&#9654; ul-list-item'
                            },
                        ]
                    }
                ]
            }
        }
    });


    editor.BlockManager.add('scalecms-unorderedList-blk', {
        label: 'Unordered list',
        content: {
            type: "scalecms-unorderedList",
        },
        category: {
            label: 'Basic'
        },
        media: `
        <svg width="100px" height="100px" viewBox="0 0 19 16" version="1.1" >

        <title>format_list_bulleted</title>
        <desc>Created with Sketch.</desc>
        <g id="Icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Rounded" transform="translate(-612.000000, -2106.000000)">
        <g id="Editor" transform="translate(100.000000, 1960.000000)">
        <g id="-Round-/-Editor-/-format_list_bulleted" transform="translate(510.000000, 142.000000)">
        <g>
        <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
        <path d="M4,10.5 C3.17,10.5 2.5,11.17 2.5,12 C2.5,12.83 3.17,13.5 4,13.5 C4.83,13.5 5.5,12.83 5.5,12 C5.5,11.17 4.83,10.5 4,10.5 Z M4,4.5 C3.17,4.5 2.5,5.17 2.5,6 C2.5,6.83 3.17,7.5 4,7.5 C4.83,7.5 5.5,6.83 5.5,6 C5.5,5.17 4.83,4.5 4,4.5 Z M4,16.5 C3.17,16.5 2.5,17.18 2.5,18 C2.5,18.82 3.18,19.5 4,19.5 C4.82,19.5 5.5,18.82 5.5,18 C5.5,17.18 4.83,16.5 4,16.5 Z M8,19 L20,19 C20.55,19 21,18.55 21,18 C21,17.45 20.55,17 20,17 L8,17 C7.45,17 7,17.45 7,18 C7,18.55 7.45,19 8,19 Z M8,13 L20,13 C20.55,13 21,12.55 21,12 C21,11.45 20.55,11 20,11 L8,11 C7.45,11 7,11.45 7,12 C7,12.55 7.45,13 8,13 Z M7,6 C7,6.55 7.45,7 8,7 L20,7 C20.55,7 21,6.55 21,6 C21,5.45 20.55,5 20,5 L8,5 C7.45,5 7,5.45 7,6 Z" id="🔹-Icon-Color" fill="#ffffff"></path>
        </g>
        </g>
        </g>
        </g>
        </g>
        </svg>
        `,
        attributes: {
            title: 'Unordered list',
        },
        select: true
    });
};