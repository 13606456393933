import React from "react";
import "./index.scss";

import axios from "axios";
import moment from "moment";
import { useSelector } from "react-redux";
import * as backendModule from "../../modules/backendModule";
import { animateBox } from "../../modules/componentAnimation";

import CustomInput from "../../components/customComponents/CustomInput";
import CustomCheckbox from "../../components/customComponents/CustomCheckbox";
import { FilteredCustomTable } from "../../components/customComponents/Table";
import Spinner from "../../components/customComponents/Spinner";

let curTimeout = null;
const OpenAiUsage = () => {
    
    const [spinner, setSpinner] = React.useState(false);

    const [dataTotalUsage, setDataTotalUsage] = React.useState([]);
    const [dataTotalFilters, setDataTotalFilters] = React.useState([]);
    const [canTotalPaginate, setCanTotalPaginate] = React.useState(false);
    const [spinnerTotal, setSpinnerTotal] = React.useState(false);
    const paginationTotalOffset = React.useRef();
    const curPaginationTotalTimestamp = React.useRef();

    const [dataUsageHistory, setDataUsageHistory] = React.useState([]);
    const [dataHistoryFilters, setDataHistoryFilters] = React.useState([]);
    const [canHistoryPaginate, setCanHistoryPaginate] = React.useState(false);
    const [spinnerHistory, setSpinnerHistory] = React.useState(false);
    const paginationHistoryOffset = React.useRef();
    const curPaginationHistoryTimestamp = React.useRef();

    const pricePerSentToken = 0.000005;
    const pricePerReceivedToken = 0.000015;

    const getDataTotalUsage = () => {
        paginationTotalOffset.current = 0;
        curPaginationTotalTimestamp.current = Date.now();

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/openai/getTotalUsage`,
            data: {
                filters: [
                    ...dataTotalFilters,
                ],
            },
            ...backendModule.axiosConfig
        }).then((res) => {
            if(res.data.status === "ok"){
                if (res.data.data.length === 20) {
                    paginationTotalOffset.current += 20;
                    setTimeout(() => setCanTotalPaginate(true), 500);
                } else {
                    setCanTotalPaginate(false);
                    paginationTotalOffset.current = -1;
                };
                setDataTotalUsage({...res.data, timestamp: Date.now()})
            }else{
                setDataTotalUsage({ status: "error", data: "SERVER_ERROR", timestamp: Date.now() });
            }
        }).catch((err) => {
        }).finally(() => {
            setSpinnerTotal(false);
        });
    }

    const continueDataTotalUsage = (timestamp) => {
        if (paginationTotalOffset.current === -1) {
        if (timestamp !== curPaginationTotalTimestamp.current) return;
        if (canTotalPaginate) setCanTotalPaginate(false);
        return;
        };

        setSpinnerTotal(true);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/openai/getTotalUsage`,
            data: {
                offset: dataTotalUsage?.data?.length ?? 0,
                filters: [
                    ...dataTotalFilters,
                ],
            },
            ...backendModule.axiosConfig
        }).then((res) => {
        if (timestamp !== curPaginationTotalTimestamp.current) return;
        if (res.data.status === "ok") {
            if (res.data.data.length === 20) {
                paginationTotalOffset.current += 20;
                setTimeout(() => setCanTotalPaginate(true), 500);
            } else {
                setCanTotalPaginate(false);
                paginationTotalOffset.current = -1;
            };
            setDataTotalUsage(old => {
                return {
                    ...old,
                    data: [
                    ...old.data,
                    ...res.data.data
                    ], timestamp: Date.now()
                };
            });
        }else{
            setDataTotalUsage({ status: "error", data: "SERVER_ERROR", timestamp: Date.now() });
        }
        }).catch(() => {
            setDataTotalUsage({ status: "error", data: "SERVER_ERROR", timestamp: Date.now() });
        }).finally(() => {
            if (timestamp !== curPaginationTotalTimestamp.current) return;
            setSpinnerTotal(false);
        });
    };

    const PaginationDataTotal = () => {
        let tmpRef = React.useRef();
        React.useEffect(() => {
          if (!tmpRef?.current) return;
          let observer = null;
          try {
            let observer = new IntersectionObserver((entries) => {
              entries.forEach(entry => {
                if (entry.intersectionRatio > 0) {
                  try { observer.unobserve(tmpRef.current); } catch { };
                  if (canTotalPaginate) {
                    continueDataTotalUsage(curPaginationTotalTimestamp.current);
                  };
                };
              });
            }, { threshold: [1] });
            observer.observe(tmpRef.current);
          } catch { };
    
          return () => {
            if (tmpRef?.current) {
              try { observer.unobserve(tmpRef.current); } catch { };
            };
          };
        }, [tmpRef]);
    
        return <div ref={tmpRef}></div>;
    };

    const getDataUsageHistory = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/openai/getUsageHistory`,
            data: {
                filters: [
                    ...dataHistoryFilters,
                ],
            },
            ...backendModule.axiosConfig
        }).then((res) => {
            if(res.data.status === 'ok'){
                if (res.data.data.length === 20) {
                    paginationHistoryOffset.current += 20;
                    setTimeout(() => setCanHistoryPaginate(true), 500);
                } else {
                    setCanHistoryPaginate(false);
                    paginationHistoryOffset.current = -1;
                };
                setDataUsageHistory({...res.data, timestamp: Date.now()})
            }else{
                setDataTotalUsage({ status: "error", data: "SERVER_ERROR", timestamp: Date.now() });
            }
        }).catch((e) => {console.log("error 2, ", e)}).finally(() => {
            setSpinner(false);
        });
    }
    
    const continueDataUsageHistory = (timestamp) => {
        if (paginationHistoryOffset.current === -1) {
        if (timestamp !== curPaginationHistoryTimestamp.current) return;
        if (canHistoryPaginate) setCanHistoryPaginate(false);
        return;
        };

        setSpinnerHistory(true);

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/openai/getUsageHistory`,
            data: {
                offset: dataUsageHistory?.data?.length ?? 0,
                filters: [
                    ...dataTotalFilters,
                ],
            },
            ...backendModule.axiosConfig
        }).then((res) => {
            if (timestamp !== curPaginationHistoryTimestamp.current) return;
            if (res.data.status === "ok") {
                if (res.data.data.length === 20) {
                    paginationHistoryOffset.current += 20;
                    setTimeout(() => setCanHistoryPaginate(true), 500);
                } else {
                    setCanHistoryPaginate(false);
                    paginationHistoryOffset.current = -1;
                };
                setDataUsageHistory(old => {
                return {
                    ...old,
                    data: [
                    ...old.data,
                    ...res.data.data
                    ], timestamp: Date.now()
                };
                });
            }else{
                setDataUsageHistory({ status: "error", data: "SERVER_ERROR", timestamp: Date.now() });
            }
            
        }).catch(() => {
            setDataUsageHistory({ status: "error", data: "SERVER_ERROR", timestamp: Date.now() });
        }).finally(() => {
            if (timestamp !== curPaginationHistoryTimestamp.current) return;
            setSpinnerHistory(false);
        });
    };

    const PaginationDataHistory = () => {
        let tmpRef = React.useRef();
        React.useEffect(() => {
          if (!tmpRef?.current) return;
          let observer = null;
          try {
            let observer = new IntersectionObserver((entries) => {
              entries.forEach(entry => {
                if (entry.intersectionRatio > 0) {
                  try { observer.unobserve(tmpRef.current); } catch { };
                  if (canHistoryPaginate) {
                    continueDataUsageHistory(curPaginationHistoryTimestamp.current);
                  };
                };
              });
            }, { threshold: [1] });
            observer.observe(tmpRef.current);
          } catch { };
    
          return () => {
            if (tmpRef?.current) {
              try { observer.unobserve(tmpRef.current); } catch { };
            };
          };
        }, [tmpRef]);
    
        return <div ref={tmpRef}></div>;
    };

    React.useEffect(() => {
        getDataTotalUsage();
    }, [dataTotalFilters, curPaginationTotalTimestamp])

    React.useEffect(() => {
        getDataUsageHistory();
    }, [dataHistoryFilters, curPaginationHistoryTimestamp])

    return <div className="route__openAiUsage">
        <p className="route__openAiUsage__headline">Total token usage per User</p>
        {dataTotalUsage?.data?.length ? <FilteredCustomTable
            theme="dark"
            accent="#48515C"
            style={{width: "100%"}}
            customColumns={["1fr", "150px", "150px", "150px", "150px"]}
            headers={["UserID", "Username", "Prompt", "Completion", "Total"]}
            filters={ [
                { name: "UserID", friendlyName: "UserID", type: "string" },
                { name: "Username", friendlyName: "Username", type: "string" },
                { name: "TotalTokenCost", friendlyName: "Total", type: "number" },
                { name: "PromptTokenCost", friendlyName: "Prompt", type: "number" },
                { name: "CompletionTokenCost", friendlyName: "Completion", type: "number" },
            ] }
            filterCB={f => setDataTotalFilters(f)}
            data={(() => {
                if (!dataTotalUsage.data) return [[{ keyID: "noData-spinner", type: "spinner" }]];
                if (dataTotalUsage.data.length === 0) return [[{ keyID: "noData-text", type: "custom", data: <p>There was an error while fetching users</p> }]];

                let tmp = dataTotalUsage.data.map(elem => {
                    let totalSend = Number(elem.PromptTokenCost * pricePerSentToken).toFixed(2);
                    let totalReceived = Number(elem.CompletionTokenCost * pricePerReceivedToken).toFixed(2);
                    let totalTotal = Number(totalSend) + Number(totalReceived);
                    return [
                        {keyID: elem.UserID, type: "text", text: elem.UserID},
                        {keyID: elem?.Username, type: "text", text: elem?.Username ? elem?.Username : ''},
                        {keyID: elem.PromptTokenCost, type: "text", text: `${elem.PromptTokenCost} (${totalSend}$)`},
                        {keyID: elem.CompletionTokenCost, type: "text", text: `${elem.CompletionTokenCost} (${totalReceived}$)`},
                        {keyID: elem.TotalTokenCost, type: "text", text: `${elem.TotalTokenCost} (${totalTotal}$)`},
                    ];
                });

                if (spinnerTotal) tmp.push([{ keyID: "paginationSpinner", type: "spinner" }]);
                if (canTotalPaginate) tmp.push([{
                    keyID: "paginationData",
                    type: "custom",
                    data: <PaginationDataTotal />
                }])

                if (tmp.length === 0) tmp.push([{ keyID: "noData-empty", type: "custom", data: <p>Nothing to show.</p> }])
                return tmp;
            })()}
        /> : ''}


        <p style={{marginTop: '30px'}} className="route__openAiUsage__headline">Usage History per Request</p>
        {dataUsageHistory?.data?.length ? <FilteredCustomTable
            theme="dark"
            accent="#48515C"
            style={{width: "100%"}}
            customColumns={["1fr", "150px", "150px", "150px", "150px"]}
            headers={["UserID", "Username", "Prompt", "Completion", "Total", "createdAt"]}
            filters={ [
                { name: "UserID", friendlyName: "UserID", type: "string" },
                { name: "Username", friendlyName: "Username", type: "string" },
                { name: "TotalTokenCost", friendlyName: "Total", type: "number" },
                { name: "PromptTokenCost", friendlyName: "Prompt", type: "number" },
                { name: "CompletionTokenCost", friendlyName: "Completion", type: "number" },
                { name: "createdAt", friendlyName: "Created at", type: "date" }
            ] }
            filterCB={f => setDataHistoryFilters(f)}
            data={(() => {
                if (!dataUsageHistory.data) return [[{ keyID: "noData-spinner", type: "spinner" }]];
                if (dataUsageHistory.data.length === 0) return [[{ keyID: "noData-text", type: "custom", data: <p>There was an error while fetching users</p> }]];

                let tmp = dataUsageHistory.data.map(elem => {
                    return [
                        {keyID: elem.UserID, type: "text", text: elem.UserID},
                        {keyID: elem.Username, type: "text", text: elem?.Username ? elem?.Username : ''},
                        {keyID: elem.PromptTokenCost, type: "text", text: elem.PromptTokenCost},
                        {keyID: elem.CompletionTokenCost, type: "text", text: elem.CompletionTokenCost},
                        {keyID: elem.TotalTokenCost, type: "text", text: elem.TotalTokenCost},
                        {keyID: elem.TotalTokenCost, type: "text", text: moment(elem.createdAt).format('DD/MM/YYYY HH:mm')},
                        {keyID: elem.ID, type: "groupNewline", group: [
                            {keyID: elem.ID, type: "button", text: "View query", triggerDropdown: true, triggerData: c => {
                                return <div>
                                    <p className="route__apiTokens__flags" style={{color: '#ff5e74'}}> {elem.Query}</p>
                                    <p className="route__apiTokens__flags" style={{color: '#85f765'}}> {elem.AiResponse}</p>
                                </div>
                            }},
                        ]},
                    ];
                });

                if (spinnerHistory) tmp.push([{ keyID: "paginationSpinner", type: "spinner" }]);
                if (canHistoryPaginate) tmp.push([{
                    keyID: "paginationData",
                    type: "custom",
                    data: <PaginationDataHistory />
                }])

                if (tmp.length === 0) tmp.push([{ keyID: "noData-empty", type: "custom", data: <p>Nothing to show.</p> }])
                return tmp;
            })()}
        /> : ''}

    </div>
};


export default OpenAiUsage;