import gjs from "grapesjs";

function script() {
    if(!this) return;

    const hamburger = this.querySelector('.scalecms-headerSix-hamburger');
    const navbar = this.querySelector('.scalecms-headerSix-navbar');
    if (!hamburger || !navbar) return;

    hamburger.addEventListener('click', () => {
        if(!navbar.classList.contains('active')){
            navbar.style.display = 'flex';
            navbar.classList.add('active');
        }
        else {
            navbar.style.display = 'none';
            navbar.classList.remove('active');
        }
    });
}

/**
 * @param {gjs.Editor} editor
 */
export default editor => {
    editor.Components.addType('scalecms-headerSix', {
        isComponent: e => e?.classList?.contains("scalecms-headerSix"),
        model: {
            defaults: {
                type: "default",
                traits: [

                ],
                attributes: {
                    class: "scalecms-headerSix"
                },
                script,
                styles: `
                    .scalecms-headerSix {
                        position: relative;
                        width: 100%;
                        min-height: 38px;
                        background: red;
                        padding-left: 310px;
                        padding-right: 310px;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        font-family: 'Arial';
                        color: white;
                    }
                    .scalecms-headerSix-hamburger{
                        width: 30px;
                        height: 30px;
                    }
                    .scalecms-headerSix-siteName{
                        font-size: 32px;
                        font-weight: 700;
                    }
                    .scalecms-headerSix-navbar{
                        display: flex;
                        flex-direction: row;
                        justify-content: space-evenly;
                        align-items: center;
                    }
                    .scalecms-headerSix-navbar-item{
                        margin-left: 10px;
                        cursor: pointer;
                    }
                    .scalecms-headerSix-searchbar{
                        outlone: none;
                        border: none;
                        padding: 5px;
                        border-radius: 5px;
                    }

                    @media screen and (max-width: 1600px){
                        .scalecms-headerSix {
                            padding-left: 100px;
                            paddinr-right: 100px;
                        }
                    }

                    @media screen and (max-width: 1100px){
                        .scalecms-headerSix {
                            padding-left: 20px;
                            padding-right: 20px;
                        }
                        .scalecms-headerSix-siteName{
                            font-size: 22px;
                        }
                        .scalecms-headerSix-searchbar{
                            display: none;
                        }
                    }
                    @media screen and (max-width: 600px){
                        .scalecms-headerSix-siteName{
                            font-size: 22px;
                        }
                        .scalecms-headerSix-navbar{
                            display: none;
                            flex-direction: column;
                            position: absolute;
                            top: 38px;
                            left: 0;
                            background: red;
                            width: 100%;
                            justify-content: baseline;
                            align-items: baseline;
                        }
                        .scalecms-headerSix-navbar-item{
                            margin-bottom: 10px;
                            margin-left: 20px;
                        }
                        
                    }
                `,
                components: [
                    {
                        tagName: 'img',
                        attributes: {class: 'scalecms-headerSix-hamburger', src: 'https://i.imgur.com/boqY7Lz.png'},
                    },
                    {
                        type: 'text',
                        attributes: {class: 'scalecms-headerSix-siteName'},
                        components: 'NAZIV STRANICE'
                    },
                    {
                        tagName: 'div',
                        attributes: {class: 'scalecms-headerSix-navbar'},
                        components: [
                            {
                                type: 'text',
                                attributes: {class: 'scalecms-headerSix-navbar-item'},
                                components: 'NAV-ITEM'
                            },
                            {
                                type: 'text',
                                attributes: {class: 'scalecms-headerSix-navbar-item'},
                                components: 'NAV-ITEM'
                            },
                            {
                                type: 'text',
                                attributes: {class: 'scalecms-headerSix-navbar-item'},
                                components: 'NAV-ITEM'
                            },
                            {
                                type: 'text',
                                attributes: {class: 'scalecms-headerSix-navbar-item'},
                                components: 'NAV-ITEM'
                            },
                            {
                                type: 'text',
                                attributes: {class: 'scalecms-headerSix-navbar-item'},
                                components: 'NAV-ITEM'
                            },
                        ]
                    },
                    {
                        tagName: 'input',
                        attributes: {class: 'scalecms-headerSix-searchbar', placeholder: 'Pretraži...'}
                    }
                ]
            }
        }
    });


    editor.BlockManager.add('scalecms-headerSix-blk', {
        label: 'Header 6',
        content: {
            type: "scalecms-headerSix",
        },
        category: {
            label: 'Headers'
        },
        media: `<svg viewBox="0 0 24 24"><g data-name="layout 34"><path d="M19,2H5A3,3,0,0,0,2,5V19a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V5A3,3,0,0,0,19,2Zm1,17a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V5A1,1,0,0,1,5,4H19a1,1,0,0,1,1,1Z"/><path d="M17,5H7A2,2,0,0,0,5,7V8a2,2,0,0,0,2,2H17a2,2,0,0,0,2-2V7A2,2,0,0,0,17,5Zm0,3H7V7H17Z"/></g></svg>`,
        attributes: {
            title: 'Basic header 6',
        },
        select: true
    });
};