import React from "react";
import "./index.scss";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import axios from "axios";
import * as backendModule from "../../../modules/backendModule";
import animateModule from "../../../modules/animateModule";
import { animateBox } from "../../../modules/componentAnimation";

import CustomInput from "../../../components/customComponents/CustomInput";
import FilterPanel from "../../../components/customComponents/FilterPanel";
import Spinner from "../../../components/customComponents/Spinner";
import Button from "../../../components/customComponents/Button";

let curTimeout = null;
const CreativesList = () => {
    const [data, setData] = React.useState();
    const [filters, setFilters] = React.useState([]);

    const searchRef = React.useRef();

    const userTimestampSelector = useSelector(state => state?.timestamp ?? null);
    const siteSettingsSelector = useSelector(state => state?.siteSettings ?? {});
    const userSelector = useSelector(state => state?.userData?.userData?.UserInfo ?? {});

    const curNavigate = useNavigate();

    const animateNavigate = to => animateModule(curNavigate, to, document.querySelector(".component__mainContent"));

    let searchHandler = () => {
        let searchVal = searchRef?.current?.value;
        clearTimeout(curTimeout);
        curTimeout = setTimeout(() => {
            setFilters([{or: [
                {name: "Name", op: "like", value: searchVal}
            ]}]);
        }, 500);
    };

    const getData = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/creatives/list/getAllCreatives`,
            data: {
                filters
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setData(res.data);
            } else {
                setData({status: "error", data: "SERVER_ERROR"});
            };
        }).catch(() => {
            setData({status: "error", data: "SERVER_ERROR"});
        });
    };

    React.useEffect(() => {
        if (filters.length > 0) setFilters([]);
    }, [siteSettingsSelector?.advancedSearch]);

    React.useEffect(() => {
        getData();
    }, [userTimestampSelector, filters]);

    return <div className="route__creativeList">
        <div className="route__creativeList__wrap">
            <div className="route__creativeList__wrap__filter">
                {siteSettingsSelector?.advancedSearch ?
                <FilterPanel accent="rgb(63, 124, 234)" theme="dark" filters={[
                    {name: "ID", friendlyName: "ID", type: "string"},
                    {name: "Name", friendlyName: "Name", type: "string"}
                ]} filterCB={f => setFilters(f)} /> :
                <>
                    <CustomInput autocomplete="off" ref={searchRef} onInput={searchHandler} theme="dark" accent="#3F7CEA" placeholder="Search..." style={{width: "100%"}} />
                </>}
            </div>

            <div className="route__creativeList__wrap__data">
                {data ? <>
                    {data.status === "ok" ? <>
                        {data.data.length === 0 ? <p style={{gridColumn: "1 / span all"}}>No data to show</p> : <>
                            {data.data.map(item => {
                                return <div className="route__creativeList__wrap__data__item" onClick={e => {
                                    animateNavigate(`/main/creative-list/${item.ID}`);
                                }}>
                                    <div className="route__creativeList__wrap__data__item__img">
                                        <img src="/images/folderFullIcon.svg" />
                                    </div>

                                    <p>{item.Name}</p>

                                    <div className="route__creativeList__wrap__data__item__btns">
                                        {(userSelector?.Flags?.isAdmin || userSelector?.ID === item.UserID) && <Button theme="dark" accent="rgb(63, 124, 234)" value="Rename" onClick={e => {
                                            e.stopPropagation();
                                            animateBox(e, <AddCreative data={item} onChange={getData} />);
                                        }} />}
                                        {(userSelector?.Flags?.isAdmin || userSelector?.ID === item.UserID) && <Button theme="dark" accent="rgb(234, 63, 63)" value="Remove" onClick={e => {
                                            e.stopPropagation();
                                            animateBox(e, <RemoveCreative data={item} onChange={getData} />);
                                        }} />}
                                    </div>
                                </div>
                            })}
                        </>}
                    </> : <p style={{gridColumn: "1 / span all"}}>There was an error while fetching creatives!</p>}
                </> : <Spinner color="#3F7CEA" align="center" style={{gridColumn: "1 / span all"}} />}
            </div>
        </div>
    </div>
};

const AddCreative = props => {
    const [spinner, setSpinner] = React.useState(false);
    const [infoP, setInfoP] = React.useState("");

    const nameRef = React.useRef();

    const addCreative = () => {
        setInfoP("");

        let data = {
            Name: nameRef.current.value
        };
        if (props.data) data["ID"] = props.data.ID;

        if (!data.Name) return setInfoP("Name can't be empty");

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/creatives/list/${props.data ? "renameCreative" : "addCreative"}`,
            data,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                props.onClose();
                props.onChange();
            } else {
                setInfoP("Group name already exists!");
            };
        }).catch(() => {
            setInfoP("Server timed out!");
        });
    };

    return <div className="route__creativeList__addCreative">
        <div className="route__creativeList__addCreative__wrap">
            <div className="route__creativeList__addCreative__wrap__spinner" style={{
                opacity: spinner ? 1 : 0,
                pointerEvents: spinner ? "all" : "none"
            }} onClick={e => spinner && e.stopPropagation()}>
                <Spinner color="#fff" />
            </div>

            <h3 style={{marginBottom: "20px"}}>{props.data ? "Rename" : "Add"} creative group</h3>

            <CustomInput autocomplete="off" defaultValue={props.data ? props.data.Name : ""} ref={nameRef} accent="white" theme="dark" placeholder="Group name" style={{width: "100%"}} />            

            <div className="route__creativeList__addCreative__wrap__btns">
                <p onClick={addCreative}>
                    <img style={{ marginRight: "15px" }} src="/images/saveBtn.png" />
                    <span>Save</span>
                </p>
                <p onClick={props.onClose}>
                    <span>Close</span>
                    <img style={{ marginLeft: "15px" }} src="/images/closeBtn.png" />
                </p>
            </div>

            {infoP && <p className="route__creativeList__addCreative__wrap__infoP">{infoP}</p>}
        </div>
    </div>
};

const RemoveCreative = props => {
    const [spinner, setSpinner] = React.useState(false);
    const [infoP, setInfoP] = React.useState("");

    const removeCreative = () => {
        setInfoP("");

        let data = {
            ID: props.data.ID
        };

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/creatives/list/removeCreative`,
            data,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                props.onClose();
                props.onChange();
            } else {
                setInfoP("Error while removing creative group!");
            };
        }).catch(() => {
            setInfoP("Server timed out!");
        });
    };

    return <div className="route__creativeList__addCreative">
        <div className="route__creativeList__addCreative__wrap">
            <div className="route__creativeList__addCreative__wrap__spinner" style={{
                opacity: spinner ? 1 : 0,
                pointerEvents: spinner ? "all" : "none"
            }} onClick={e => spinner && e.stopPropagation()}>
                <Spinner color="#fff" />
            </div>

            <h3 style={{marginBottom: "20px"}}>Remove creative group</h3>
            <p>Are you sure?</p>
            <p>Removal of <span style={{color: "#3F7CEA"}}>{props.data.Name}</span> is irreversible!</p>
            <p style={{color: "rgb(234, 63, 63)"}}>This will also remove all creatives in this group!</p>
            <p>Proceed with caution</p>

            <div className="route__creativeList__addCreative__wrap__btns">
                <p onClick={removeCreative}>
                    <img style={{ marginRight: "15px" }} src="/images/saveBtn.png" />
                    <span>Remove</span>
                </p>
                <p onClick={props.onClose}>
                    <span>Close</span>
                    <img style={{ marginLeft: "15px" }} src="/images/closeBtn.png" />
                </p>
            </div>

            {infoP && <p className="route__creativeList__addCreative__wrap__infoP">{infoP}</p>}
        </div>
    </div>
};

export default CreativesList;
export { AddCreative }