import gjs from "grapesjs";

function script() {
    eval(`(()=>{
        if (!this) return;
        if (this.getAttribute("data-gjs-type")) return;
    
        const btn = this.querySelector("button");
        const curLanguage = this.getAttribute("language");
        const dataToken = this.getAttribute("data-token");
        const dataThankYou = this.getAttribute("data-thankyou");
        const dataArticle = this.getAttribute("data-article");
        const dataLanguage = this.getAttribute("language");

        const dataCustomButtonText = this.getAttribute("data-custombuttontext");
        if (dataCustomButtonText) btn.innerText = dataCustomButtonText;
    
        let FirstLastName = this.querySelector("input[name=FirstLastName]");
        let PhoneNumber = this.querySelector("input[name=PhoneNumber]");
        let errP = this.querySelector(".scalecrm-form-err");

        let leadSubtype = this.getAttribute("data-subtype");
        let leadOfferid = this.getAttribute("data-offerid");
        let leadAdvertiserid = this.getAttribute("data-advertiserid");
        let leadPublisherid = this.getAttribute("data-publisherid");
    
        btn.onclick = () => {
            errP.innerHTML = "";
            if (!FirstLastName.value || !PhoneNumber.value) {
                switch (curLanguage) {
                    case "pl":
                        errP.innerHTML = "Nazwa lub numer nie mogą być puste";
                        return;
                    case "si":
                        errP.innerHTML = "Polja z imenom ali številko ne smejo biti prazna";
                        return;
                    default:
                        errP.innerHTML = "Ime ili broj ne mogu biti prazni";
                        return;
                };
            };
            let pn = PhoneNumber.value.replace(/ /g, "").replace(/\\+/g, "").replace(/-/g, "").replace(/\\//g, "");
            if (isNaN(Number(pn)) || pn.length < 8 || pn.length > 14) {
                switch (curLanguage) {
                    case "pl":
                        errP.innerHTML = "Numer telefonu jest nieprawidłowy!";
                        return;
                    case "si":
                        errP.innerHTML = "Telefonska številka ni veljavna!";
                        return;
                    default:
                        errP.innerHTML = "Broj telefona nije validan!";
                        return;
                };
            };

            let ad = {};
            if (leadOfferid) ad["OfferID"] = leadOfferid;
            if (leadAdvertiserid) ad["AdvertiserID"] = leadAdvertiserid;
            if (leadPublisherid) ad["PublisherID"] = leadPublisherid;
            if (window.sctrk) {
                if (window.sctrk.getTrackingID) {
                    let tmp_id = window.sctrk.getTrackingID();
                    if (tmp_id) ad["ClickID"] = tmp_id;
                };
            };
    
            btn.style.display = "none";
            fetch(\`https://backend.scale-crm.com:5000/api/leads/addLead\`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    token: dataToken,
                    Name: dataArticle,
                    FirstLastName: FirstLastName,
                    PhoneNumber: pn,
                    Country: dataLanguage.toUpperCase(),
                    SubType: leadSubtype,
                    AdditionalData: ad
                })
            }).then((res) => res.json())
                .then(async (res) => {
                    try {
                        await window.sctrk.convert({ integration_scalecrm: res.data });
                    } catch { }
                    let params = window.location.toString().split("?");
                    if (params.length > 1) params = params[1]; else params = "";
                    params += \`\${params ? "&" : ""}user_name=\${FirstLastName.value}&user_phone=\${pn}\`;
                    window.location.href = \`\${dataThankYou}\${params ? \`?\${params}\` : ""}\`;
                }).catch(() => {
                    btn.style.display = null;
                    switch (curLanguage) {
                        case "pl":
                            errP.innerHTML = "Proszę wprowadzić swoje dane";
                            return;
                        case "si":
                            errP.innerHTML = "Prosimo vnesite svoje podatke";
                            return;
                        default:
                            errP.innerHTML = "Molimo unesite Vaše podatke";
                            return;
                    };
                });
        };
    })()`)
};

/**
 * @param {gjs.Editor} editor
 */
export default (editor) => {
    editor.Components.addType('scalecrm-form_cmp', {
        isComponent: e => e?.classList?.contains("scalecrm-form"),
        model: {
            init() {
                this.on("change", e => {
                    this.addAttributes({ token: e?.attributes?.attributes?.token })
                    let language = e?.attributes?.attributes?.language;

                    this.get("components").each(child => {
                        if (child?.attributes?.attributes?.for === "FirstLastName") {
                            switch (language) {
                                case "pl":
                                    child.components("Imię i nazwisko");
                                    break;
                                case "si":
                                    child.components("Ime in priimek");
                                    break;
                                case "ba":
                                case "rs":
                                case "hr":
                                    child.components("Ime i prezime");
                                    break;
                                default:
                                    break;
                            };
                        } else if (child?.attributes?.attributes?.for === "PhoneNumber") {
                            switch (language) {
                                case "pl":
                                    child.components("Numer telefonu");
                                    break;
                                case "si":
                                    child.components("Telefonska številka");
                                    break;
                                case "ba":
                                case "rs":
                                case "hr":
                                    child.components("Broj telefona");
                                    break;
                                default:
                                    break;
                            };
                        } else if (child?.attributes?.attributes?.for === "orderBtn") {
                            switch (language) {
                                case "pl":
                                    child.components("Zamówienie");
                                    break;
                                case "si":
                                    child.components("Oddajte naročilo");
                                    break;
                                case "ba":
                                case "rs":
                                case "hr":
                                    child.components("Naruči");
                                    break;
                                default:
                                    break;
                            };
                        } else if (["FirstLastName", "PhoneNumber"].includes(child?.attributes?.attributes?.name)) {
                            let curChildName = child?.attributes?.attributes?.name;
                            switch (language) {
                                case "pl":
                                    if (curChildName === "FirstLastName") {
                                        child.addAttributes({ placeholder: "Imię i nazwisko *" });
                                    } else if (curChildName === "PhoneNumber") {
                                        child.addAttributes({ placeholder: "Numer telefonu *" });
                                    };
                                    break;
                                case "si":
                                    if (curChildName === "FirstLastName") {
                                        child.addAttributes({ placeholder: "Ime in priimek *" });
                                    } else if (curChildName === "PhoneNumber") {
                                        child.addAttributes({ placeholder: "Telefonska številka *" });
                                    };
                                    break;
                                case "ba":
                                case "rs":
                                case "hr":
                                    if (curChildName === "FirstLastName") {
                                        child.addAttributes({ placeholder: "Ime i prezime *" });
                                    } else if (curChildName === "PhoneNumber") {
                                        child.addAttributes({ placeholder: "Broj telefona *" });
                                    };
                                default:
                                    break;
                            };
                        };
                    });
                });
            },
            defaults: {
                type: "default",
                traits: [
                    { name: "data-token", label: "ScaleCRM Add Lead Token", placeholder: "Paste your token here...", value: "" },
                    { name: "data-article", label: "Article name (that will show up in ScaleCRM)", placeholder: "Natura active BA....", value: "" },
                    { name: "data-thankyou", label: "Thank you page", placeholder: "./thankyou...", value: "/thankyou" },
                    { name: "data-subtype", label: "Lead SubType (optional) (promo, free...)", placeholder: "(empty), promo, free...", value: "" },
                    { name: "data-offerid", label: "Lead OfferID", placeholder: "(empty), *****-*****-*****", value: "" },
                    { name: "data-advertiserid", label: "Lead AdvertiserID", placeholder: "(empty), *****-*****-*****", value: "" },
                    { name: "data-publisherid", label: "Lead PublisherID", placeholder: "(empty), *****-*****-*****", value: "" },
                    {
                        name: "language", type: "select", options: [
                            { id: "custom", name: "Custom" },
                            { id: "ba", name: "Bosanski", selected: true },
                            { id: "rs", name: "Srpski", selected: true },
                            { id: "hr", name: "Hrvatski", selected: true },
                            { id: "pl", name: "Poljski" },
                            { id: "si", name: "Slovenski" }
                        ], value: "ba"
                    }, 
                    { name: "data-custombuttontext", label: "Custom button text", placeholder: "Buy now, Order now...", value: "" },
                ],
                attributes: {
                    class: "scalecrm-form"
                },
                script,
                styles: `
                    .scalecrm-form {
                        display: flex;
                        flex-direction: column;
                        max-width: 320px;
                        margin: 0 auto;
                        padding: 20px;
                    }
                    .scalecrm-form > label {
                        margin-bottom: 10px;
                    }
                    .scalecrm-form > input {
                        margin-bottom: 20px;
                    }
                `,
                components: [
                    {
                        type: "label",
                        attributes: { for: "FirstLastName" },
                        components: "Ime i prezime",
                        removable: false
                    },
                    {
                        type: "input",
                        attributes: { type: "text", name: "FirstLastName", placeholder: "Ime i prezime" },
                        removable: false
                    },

                    {
                        type: "label",
                        attributes: { for: "PhoneNumber" },
                        components: "Broj telefona",
                        removable: false
                    },
                    {
                        type: "input",
                        attributes: { type: "text", name: "PhoneNumber", placeholder: "Broj telefona" },
                        removable: false
                    },
                    {
                        type: "button",
                        attributes: { for: "orderBtn" },
                        components: "Naruči",
                        removable: false,
                        traits: []
                    },
                    {
                        type: "text",
                        attributes: { class: "scalecrm-form-err" },
                        components: ""
                    }
                ]
            }
        }
    });


    editor.BlockManager.add('scalecrm-form_blk', {
        label: 'ScaleCRM form',
        content: {
            type: "scalecrm-form_cmp",
        },
        category: {
            label: 'Interactions'
        },
        media: "<img src='/images/component_form.png' style='width: 100%; height: auto; filter: invert(1) hue-rotate(180deg);' />",
        attributes: {
            title: 'Insert a ScaleCRM form block',
        },
        select: true
    });
};