import React from "react";
import { createRoot } from "react-dom/client";
import "./index.scss";

import CustomInput from "../../../components/customComponents/CustomInput";

let root = null;
let curTimeout = null;

const BlockManager = props => {
    const [blocks, setBlocks] = React.useState({});
    const [search, setSearch] = React.useState("");

    const [openBlocks, setOpenBlocks] = React.useState([]);

    const searchRef = React.useRef()

    let searchHandler = () => {
        let searchVal = searchRef?.current?.value;
        clearTimeout(curTimeout);
        curTimeout = setTimeout(() => {
            setSearch(searchVal);
        }, 500);
    };

    const sortBlocks = (blks, search) => {
        let out = {};
        if (search) search = search.toLowerCase();

        for (let item of blks) {
            if (search) {
                if (!String(item?.attributes?.label).toLowerCase().includes(search)) continue;
            };

            let category = item?.attributes?.category;
            if (!category) category = "(None)";
            if (typeof(category) === "object") {
                category = category?.label;
                if (!category) category = "(None)";
            };

            if (!out[category]) out[category] = [];
            out[category].push(item);
        };

        return out;
    };

    const handleDragStart = blk => {
        props.dragStart(blk);
    };
    const handleDragEnd = () => {
        props.dragStop();
    };

    const handleDropdown = elem => {
        let currEl = openBlocks.find(el => el === elem);
        if(currEl){
            setOpenBlocks(openBlocks.filter(el => el !== elem));
        }else{
            setOpenBlocks([...openBlocks, elem]);
        }
    }

    React.useEffect(() => {
        setBlocks(sortBlocks(props.blocks, search));
    }, [search]);

    React.useEffect(() => {
        if(!search) return;
        let elems = [];
        Object.keys(blocks).forEach(el => {
            elems.push(el)
        })
        setOpenBlocks(elems);
    }, [blocks, search]);

    return <div className="route__createWebsite__blockManager">
        <CustomInput ref={searchRef} theme={'dark'} accent={'white'} placeholder={'Search components..'} onInput={searchHandler} />
        <div className="route__createWebsite__blockManager__container">
            {Object.keys(blocks).map((block) => {
                return <div className={`route__createWebsite__blockManager__block ${openBlocks.includes(block) ? 'block-open':''}`} onClick={() => handleDropdown(block)}>
                    <p>{openBlocks.includes(block) ? <i class="gjs-caret-icon fa fa-caret-down"></i>:<i class="gjs-caret-icon fa fa-caret-right"></i>} {block}</p>
                    <div className="route__createWebsite__blockManager__block__elements">
                        {blocks[block].map(element => {
                            return <div className="route__createWebsite__blockManager__block__elements__element" draggable={true} onDragStart={() => handleDragStart(element)} onDragEnd={handleDragEnd}>
                                <div className="route__createWebsite__blockManager__block__elements__element__image" dangerouslySetInnerHTML={{__html: element?.attributes?.media ?? ""}}></div>
                                <div className="route__createWebsite__blockManager__block__elements__element__text">{element?.attributes?.label}</div>
                            </div>
                        })}
                    </div>
                </div>
            })}
        </div>
    </div>
};

export default props => {
    if (root) {
        root.render(<BlockManager key="grapesjs-customBlockManager" {...props}></BlockManager>)
    } else {
        root = createRoot(props.container);
        root.render(<BlockManager key="grapesjs-customBlockManager" {...props}></BlockManager>);
    };
};