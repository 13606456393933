import React from "react";
import "./index.scss";

import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import axios from "axios";
import * as backendModule from "../../../modules/backendModule";
import animateModule from "../../../modules/animateModule";
import { animateBox } from "../../../modules/componentAnimation";

import CustomInput from "../../../components/customComponents/CustomInput";
import FilterPanel from "../../../components/customComponents/FilterPanel";
import Spinner from "../../../components/customComponents/Spinner";
import Button from "../../../components/customComponents/Button";

let curTimeout = null;
const CreativeItems = () => {
    const [data, setData] = React.useState();
    const [filters, setFilters] = React.useState([]);
    const [canPaginate, setCanPaginate] = React.useState(false);
    const [secondarySpinner, setSecondarySpinner] = React.useState(false);

    const paginationOffset = React.useRef();
    const curPaginationTimestamp = React.useRef();
    const searchRef = React.useRef();

    const userTimestampSelector = useSelector(state => state?.timestamp ?? null);
    const siteSettingsSelector = useSelector(state => state?.siteSettings ?? {});
    const userSelector = useSelector(state => state?.userData?.userData?.UserInfo ?? {});

    const curNavigate = useNavigate();
    const curParams = useParams();

    const animateNavigate = to => animateModule(curNavigate, to, document.querySelector(".component__mainContent"));

    let searchHandler = () => {
        let searchVal = searchRef?.current?.value;
        clearTimeout(curTimeout);
        curTimeout = setTimeout(() => {
            setFilters([{
                or: [
                    { name: "Name", op: "like", value: searchVal }
                ]
            }]);
        }, 500);
    };

    const getData = () => {
        paginationOffset.current = 0;
        curPaginationTimestamp.current = Date.now();

        setCanPaginate(false);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/creatives/item/getAllCreatives`,
            data: {
                offset: paginationOffset.current,
                filters: [
                    ...filters,
                    {name: "GroupID", op: "eq", value: curParams.id}
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (res.data.data.length === 20) {
                    paginationOffset.current += 20;
                    setTimeout(() => setCanPaginate(true), 500);
                } else {
                    setCanPaginate(false);
                    paginationOffset.current = -1;
                };

                return setData({ ...res.data, timestamp: Date.now() });
            }
            return setData({ status: "error", data: "SERVER_ERROR", timestamp: Date.now() });
        }).catch(() => {
            return setData({ status: "error", data: "SERVER_ERROR", timestamp: Date.now() });
        });
    };

    const continueData = (timestamp) => {
        if (paginationOffset.current === -1) {
            if (timestamp !== curPaginationTimestamp.current) return;
            if (canPaginate) setCanPaginate(false);
            return;
        };

        setSecondarySpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/creatives/item/getAllCreatives`,
            data: {
                offset: data?.data?.length ?? 0,
                filters: [
                    ...filters,
                    {name: "GroupID", op: "eq", value: curParams.id}
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestamp !== curPaginationTimestamp.current) return;
            if (res.data.status === "ok") {
                if (res.data.data.length === 20) {
                    paginationOffset.current += 20;
                    setTimeout(() => setCanPaginate(true));
                } else {
                    setCanPaginate(false);
                    paginationOffset.current = -1;
                };
                return setData(old => {
                    return {
                        ...old,
                        data: [
                            ...old.data,
                            ...res.data.data
                        ], timestamp: Date.now()
                    };
                });
            }
            return setData({ status: "error", data: "SERVER_ERROR", timestamp: Date.now() });
        }).catch(() => {
            return setData({ status: "error", data: "SERVER_ERROR", timestamp: Date.now() });
        }).finally(() => {
            if (timestamp !== curPaginationTimestamp.current) return;
            setSecondarySpinner(false);
        });
    };

    const PaginationData = () => {
        let tmpRef = React.useRef();
        React.useEffect(() => {
            if (!tmpRef?.current) return;
            let observer = null;
            try {
                let observer = new IntersectionObserver((entries) => {
                    entries.forEach(entry => {
                        if (entry.intersectionRatio > 0) {
                            try { observer.unobserve(tmpRef.current); } catch { };
                            if (canPaginate) {
                                continueData(curPaginationTimestamp.current);
                            };
                        };
                    });
                }, { threshold: [1] });
                observer.observe(tmpRef.current);
            } catch { };

            return () => {
                if (tmpRef?.current) {
                    try { observer.unobserve(tmpRef.current); } catch { };
                };
            };
        }, [tmpRef]);

        return <div ref={tmpRef}></div>;
    };

    React.useEffect(() => {
        if (filters.length > 0) setFilters([]);
    }, [siteSettingsSelector?.advancedSearch]);

    React.useEffect(() => {
        getData();
    }, [userTimestampSelector, filters]);

    return <div className="route__creativeItems">
        <div className="route__creativeItems__filter">
            {siteSettingsSelector?.advancedSearch ?
                <FilterPanel accent="rgb(63, 124, 234)" theme="dark" filters={[
                    { name: "ID", friendlyName: "ID", type: "string" },
                    { name: "Name", friendlyName: "Name", type: "string" }
                ]} filterCB={f => setFilters(f)} /> :
                <>
                    <CustomInput autocomplete="off" ref={searchRef} onInput={searchHandler} theme="dark" accent="#3F7CEA" placeholder="Search..." style={{ width: "100%" }} />
                </>}
        </div>

        <div className="route__creativeItems__wrap">
            <div className="route__creativeItems__wrap__data">
                {data ? <>
                    {data.status === "ok" ? <>
                        {data.data.length === 0 ? <p style={{ gridColumn: "1 / span all" }}>No data to show</p> : <>
                            {data.data.map(item => {
                                return <div className="route__creativeItems__wrap__data__item" onClick={e => {
                                    animateNavigate(`${item.ID}`);
                                }}>
                                    <div className="route__creativeItems__wrap__data__item__img">
                                        <img src={item.Image} />
                                    </div>

                                    <p>{item.Name}</p>

                                    <div className="route__creativeItems__wrap__data__item__btns">
                                        {(userSelector?.Flags?.isAdmin || userSelector?.ID === item.UserID) && <Button theme="dark" accent="rgb(63, 124, 234)" value="Rename" onClick={e => {
                                            e.stopPropagation();
                                            animateBox(e, <RenameCreative data={item} onChange={getData} />);
                                        }} />}
                                        {(userSelector?.Flags?.isAdmin || userSelector?.ID === item.UserID) && <Button theme="dark" accent="rgb(234, 63, 63)" value="Remove" onClick={e => {
                                            e.stopPropagation();
                                            animateBox(e, <RemoveCreative data={item} onChange={getData} />);
                                        }} />}
                                    </div>
                                </div>
                            })}
                            {secondarySpinner && <Spinner color="#3F7CEA" />}
                            {canPaginate && <PaginationData />}
                        </>}
                    </> : <p style={{ gridColumn: "1 / span all" }}>There was an error while fetching creatives!</p>}
                </> : <Spinner color="#3F7CEA" align="center" style={{ gridColumn: "1 / span all" }} />}
            </div>
        </div>
    </div>
};

const AddCreative = (props) => {
    const [spinner, setSpinner] = React.useState(false);
    const [infoP, setInfoP] = React.useState("");
    const [image, setImage] = React.useState();

    const nameRef = React.useRef();

    const curParams = useParams();

    const groupID = props.groupID ? props.groupID : curParams.id;

    const uploadImage = (id) => {
        return new Promise((resolve, reject) => {
            let imgSplit = image.name.split(".");
            let fd = new FormData();
            fd.append("tag", `creative-${groupID}-${id}`);
            fd.append("ImageName", `creative-${groupID}-${id}-${Date.now()}.${imgSplit.pop()}`);
            fd.append("image", image);
    
            axios({
                method: "POST",
                url: `${backendModule.backendURL}/images/uploadImage`,
                data: fd,
                ...backendModule.axiosConfig,
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            }).then(res => {
                if (res.data.status === "ok") return resolve(res.data.data);
                reject("ERR");
            }).catch(() => {
                reject("ERR");
            });
        });
    };

    const addCreative = () => {
        setInfoP("");

        const data = {
            GroupID: groupID,
            Name: nameRef.current.value,
        };

        if (!image) return setInfoP("Please select an image!");
        if (!data.GroupID) return setInfoP("Group ID was not found, refresh the page!");
        if (!data.Name) return setInfoP("Name can't be empty!");

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/creatives/item/addCreative`,
            data,
            ...backendModule.axiosConfig,
        }).then(async res => {
            if (res.data.status === "ok") {
                let id = res.data.data;
                let imgUrl = "";
                try {
                    imgUrl = await uploadImage(id);
                } catch {
                    setInfoP("Error while uploading the image");
                    axios({
                        method: "POST",
                        url: `${backendModule.backendURL}/creatives/item/removeCreative`,
                        data: {
                            ID: id
                        },
                        ...backendModule.axiosConfig
                    }).then(() => null).catch(() => null);
                };

                data["ID"] = id;
                data["Image"] = imgUrl;
                console.warn(data);

                axios({
                    method: "POST",
                    url: `${backendModule.backendURL}/creatives/item/editCreative`,
                    data,
                    ...backendModule.axiosConfig
                }).then(final => {
                    if (final.data.status === "ok") {
                        props.onClose();
                        props.onChange();
                    } else {
                        setInfoP("Error while adding the image!");
                        axios({
                            method: "POST",
                            url: `${backendModule.backendURL}/creatives/item/removeCreative`,
                            data: {
                                ID: id
                            },
                            ...backendModule.axiosConfig
                        }).then(() => null).catch(() => null);
                    };
                }).catch(() => {
                    axios({
                        method: "POST",
                        url: `${backendModule.backendURL}/creatives/item/removeCreative`,
                        data: {
                            ID: id
                        },
                        ...backendModule.axiosConfig
                    }).then(() => null).catch(() => null);
                    setInfoP("Server timed out!");
                }).finally(() => {
                    setSpinner(false);
                });
            } else {
                setInfoP("Name already exists in this group!");
                setSpinner(false);
            };
        }).catch(() => {
            setInfoP("Server timed out!")
            setSpinner(false);
        });
    };

    return <div className="route__creativeItems__addCreative">
        <div className="route__creativeItems__addCreative__wrap">
            <div className="route__creativeList__addCreative__wrap__spinner" style={{
                opacity: spinner ? 1 : 0,
                pointerEvents: spinner ? "all" : "none"
            }} onClick={e => spinner && e.stopPropagation()}>
                <Spinner color="#fff" />
            </div>

            <h3 style={{ marginBottom: "20px" }}>{props.data ? "Edit" : "Add"} creative</h3>

            <CustomInput autocomplete="off" ref={nameRef} theme="dark" accent="#fff" placeholder="Creative name" defaultValue={props.data ? props.data.Name : ""} style={{ width: "100%" }} />

            <div className="route__creativeItems__addCreative__wrap__selectImage">
                <div className="route__creativeItems__addCreative__wrap__selectImage__wrap" onClick={e => {
                    e.currentTarget.parentNode.querySelector("input[type=file]").click();
                }}>
                    <span>Click to select an image</span>
                    <input type="file" accept="image/*" style={{ display: "none" }} onChange={e => {
                        setImage(e.currentTarget.files[0]);
                    }} />
                </div>
                {image && <img src={URL.createObjectURL(image)} />}
            </div>

            <div className="route__creativeList__addCreative__wrap__btns">
                <p onClick={addCreative}>
                    <img style={{ marginRight: "15px" }} src="/images/saveBtn.png" />
                    <span>Save</span>
                </p>
                <p onClick={props.onClose}>
                    <span>Close</span>
                    <img style={{ marginLeft: "15px" }} src="/images/closeBtn.png" />
                </p>
            </div>

            {infoP && <p className="route__creativeList__addCreative__wrap__infoP">{infoP}</p>}
        </div>
    </div>
};

const RenameCreative = (props) => {
    const [spinner, setSpinner] = React.useState(false);
    const [infoP, setInfoP] = React.useState("");

    const nameRef = React.useRef();

    const renameCreative = () => {
        setInfoP("");

        let data = {
            ...props.data,
            Name: nameRef.current.value
        };

        if (!data.Name) return setInfoP("Name can't be empty")

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/creatives/item/editCreative`,
            data,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                props.onClose();
                props.onChange();
            } else {
                return setInfoP("Creative with that name already exists!");
            };
        }).catch(() => {
            setInfoP("Server timed out!");
        }).finally(() => {
            setSpinner(false);
        });
    };
    
    return <div className="route__creativeItems__addCreative">
        <div className="route__creativeItems__addCreative__wrap">
            <div className="route__creativeList__addCreative__wrap__spinner" style={{
                opacity: spinner ? 1 : 0,
                pointerEvents: spinner ? "all" : "none"
            }} onClick={e => spinner && e.stopPropagation()}>
                <Spinner color="#fff" />
            </div>

            <h3 style={{ marginBottom: "20px" }}>Rename creative</h3>
            <CustomInput autocomplete="off" ref={nameRef} accent="#fff" theme="dark" placeholder="Creative name" style={{width: "100%"}} defaultValue={props.data?.Name} />

            <div className="route__creativeList__addCreative__wrap__btns">
                <p onClick={renameCreative}>
                    <img style={{ marginRight: "15px" }} src="/images/saveBtn.png" />
                    <span>Save</span>
                </p>
                <p onClick={props.onClose}>
                    <span>Close</span>
                    <img style={{ marginLeft: "15px" }} src="/images/closeBtn.png" />
                </p>
            </div>

            {infoP && <p className="route__creativeList__addCreative__wrap__infoP">{infoP}</p>}
        </div>
    </div>
};

const RemoveCreative = (props) => {
    const [spinner, setSpinner] = React.useState(false);
    const [infoP, setInfoP] = React.useState("");


    const removeCreative = () => {
        setInfoP("");

        let data = {
            ID: props.data.ID
        };

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/creatives/item/removeCreative`,
            data,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                props.onClose();
                props.onChange();
            } else {
                setInfoP("Error while removing the creative!");
            };
        }).catch(() => {
            setInfoP("Server timed out!");
        }).finally(() => {
            setSpinner(false);
        });
    };
    
    return <div className="route__creativeItems__addCreative">
        <div className="route__creativeItems__addCreative__wrap">
            <div className="route__creativeList__addCreative__wrap__spinner" style={{
                opacity: spinner ? 1 : 0,
                pointerEvents: spinner ? "all" : "none"
            }} onClick={e => spinner && e.stopPropagation()}>
                <Spinner color="#fff" />
            </div>

            <h3 style={{ marginBottom: "20px" }}>Remove creative</h3>
            <p>Are you sure?</p>
            <p>Removal of <span style={{color: "#3F7CEA"}}>{props.data.Name}</span> is irreversible!</p>

            <div className="route__creativeList__addCreative__wrap__btns">
                <p onClick={removeCreative}>
                    <img style={{ marginRight: "15px" }} src="/images/saveBtn.png" />
                    <span>Save</span>
                </p>
                <p onClick={props.onClose}>
                    <span>Close</span>
                    <img style={{ marginLeft: "15px" }} src="/images/closeBtn.png" />
                </p>
            </div>

            {infoP && <p className="route__creativeList__addCreative__wrap__infoP">{infoP}</p>}
        </div>
    </div>
};

export default CreativeItems;
export { AddCreative, RemoveCreative as RemoveCreativeItem, RenameCreative as RenameCreativeItem }