let fontAvailable = new Set();
const fontCheck = new Set([
    // Windows 10
  'Arial', 'Arial Black', 'Bahnschrift', 'Calibri', 'Cambria', 'Cambria Math', 'Candara', 'Comic Sans MS', 'Consolas', 'Constantia', 'Corbel', 'Courier New', 'Ebrima', 'Franklin Gothic Medium', 'Gabriola', 'Gadugi', 'Georgia', 'HoloLens MDL2 Assets', 'Impact', 'Ink Free', 'Javanese Text', 'Leelawadee UI', 'Lucida Console', 'Lucida Sans Unicode', 'Malgun Gothic', 'Marlett', 'Microsoft Himalaya', 'Microsoft JhengHei', 'Microsoft New Tai Lue', 'Microsoft PhagsPa', 'Microsoft Sans Serif', 'Microsoft Tai Le', 'Microsoft YaHei', 'Microsoft Yi Baiti', 'MingLiU-ExtB', 'Mongolian Baiti', 'MS Gothic', 'MV Boli', 'Myanmar Text', 'Nirmala UI', 'Palatino Linotype', 'Segoe MDL2 Assets', 'Segoe Print', 'Segoe Script', 'Segoe UI', 'Segoe UI Historic', 'Segoe UI Emoji', 'Segoe UI Symbol', 'SimSun', 'Sitka', 'Sylfaen', 'Symbol', 'Tahoma', 'Times New Roman', 'Trebuchet MS', 'Verdana', 'Webdings', 'Wingdings', 'Yu Gothic',
    // macOS
    'American Typewriter', 'Andale Mono', 'Arial', 'Arial Black', 'Arial Narrow', 'Arial Rounded MT Bold', 'Arial Unicode MS', 'Avenir', 'Avenir Next', 'Avenir Next Condensed', 'Baskerville', 'Big Caslon', 'Bodoni 72', 'Bodoni 72 Oldstyle', 'Bodoni 72 Smallcaps', 'Bradley Hand', 'Brush Script MT', 'Chalkboard', 'Chalkboard SE', 'Chalkduster', 'Charter', 'Cochin', 'Comic Sans MS', 'Copperplate', 'Courier', 'Courier New', 'Didot', 'DIN Alternate', 'DIN Condensed', 'Futura', 'Geneva', 'Georgia', 'Gill Sans', 'Helvetica', 'Helvetica Neue', 'Herculanum', 'Hoefler Text', 'Impact', 'Lucida Grande', 'Luminari', 'Marker Felt', 'Menlo', 'Microsoft Sans Serif', 'Monaco', 'Noteworthy', 'Optima', 'Palatino', 'Papyrus', 'Phosphate', 'Rockwell', 'Savoye LET', 'SignPainter', 'Skia', 'Snell Roundhand', 'Tahoma', 'Times', 'Times New Roman', 'Trattatello', 'Trebuchet MS', 'Verdana', 'Zapfino',
  ].sort());
  
  (async() => {
    await document.fonts.ready;
  
  
    for (const font of fontCheck.values()) {
      if (document.fonts.check(`12px "${font}"`)) {
        fontAvailable.add(font);
      }
    }
    fontAvailable = [...fontAvailable];
  })();

export default editor => {
    /** @type {RichTextEditor} */
    const rte = editor.RichTextEditor;

    rte.add('fontSize', {
        icon: `<select placeholder="Size" style="color:inherit; width: auto;" class="gjs-field gjs-field-select">
            <option value="" disabled selected>Font size</option>
            <option value="1">xx-small</option>
            <option value="2">x-small</option>
            <option value="3">small</option>
            <option value="4">medium</option>
            <option value="5">large</option>
            <option value="6">x-large</option>
            <option value="7">xx-large</option>
        </select>`,
        // Bind the 'result' on 'change' listener
        event: 'change',
        attributes: {
            style: "padding: 0 4px 2px;",
            title: 'Font Size'
        },
        result: (rte, action) => rte.exec('fontSize', action.btn.firstChild.value),
        // Callback on any input change (mousedown, keydown, etc..)
        update: (rte, action) => {
            const value = rte.doc.queryCommandValue(action.name);
            if (value != 'false') { // value is a string
                action.btn.firstChild.value = value;
            }
        }
    });

    const allFontOptions = fontAvailable.map(item => {
        return `<option value="${item}">${item}</option>`
    }).join("");
    rte.add('fontSize', {
        icon: `<select placeholder="Size" style="color:inherit; width: auto;" class="gjs-field gjs-field-select">
            <option value="" disabled selected>Font family</option>
            ${allFontOptions}
        </select>`,
        // Bind the 'result' on 'change' listener
        event: 'change',
        attributes: {
            style: "padding: 0 4px 2px;",
            title: 'Font Family'
        },
        result: (rte, action) => rte.exec('fontName', action.btn.firstChild.value),
        // Callback on any input change (mousedown, keydown, etc..)
        update: (rte, action) => {
            const value = rte.doc.queryCommandValue("fontName");
            if (value) { // value is a string
                if (!fontAvailable.find(t => t.includes(value))) return action.btn.firstChild.value = "";
                action.btn.firstChild.value = value;
            }
        }
    });

    rte.add('converttoanchor', {
        icon: '&#128279;',
        attributes: {
            title: 'Convert to anchor'
        },
        result: rte => {
            rte.exec("createLink", "#");
        }
    });
    rte.add('heading1', {
        icon: '<div>H1</div>',
        attributes: {
            title: 'Heading 1'
        },
        result: rte => rte.exec("formatBlock", '<h1>')
    });
    rte.add('heading2', {
        icon: '<div>H2</div>',
        attributes: {
            title: 'Heading 2'
        },
        result: rte => rte.exec("formatBlock", '<h2>')
    });
    rte.add('heading3', {
        icon: '<div>H3</div>',
        attributes: {
            title: 'Heading 3'
        },
        result: rte => rte.exec("formatBlock", '<h3>')
    });
    rte.add('heading4', {
        icon: '<div>H4</div>',
        attributes: {
            title: 'Heading 4'
        },
        result: rte => rte.exec("formatBlock", '<h4>')
    });
    rte.add('heading5', {
        icon: '<div>H5</div>',
        attributes: {
            title: 'Heading 5'
        },
        result: rte => rte.exec("formatBlock", '<h5>')
    });
    rte.add('heading6', {
        icon: '<div>H6</div>',
        attributes: {
            title: 'Heading 6'
        },
        result: rte => rte.exec("formatBlock", '<h6>')
    });
    rte.add('paragraph', {
        icon: '&#182;',
        attributes: {
            title: 'Paragraph'
        },
        result: rte => rte.exec("formatBlock", '<p>')
    });

    rte.add('clearFormatting', {
        icon: '<i class="fa fa-eraser"></i>',
        attributes: {
            title: 'Clear Formatting'
        },
        result: rte => rte.exec('removeFormat')
    });

    rte.add('olist', {
        icon: '<i class="fa fa-list-ol"></i>',
        attributes: {
            title: 'Ordered List'
        },
        result: rte => rte.exec('insertOrderedList')
    });
    rte.add('ulist', {
        icon: '<i class="fa fa-list-ul"></i>',
        attributes: {
            title: 'Unordered List'
        },
        result: rte => rte.exec('insertUnorderedList')
    });

    rte.add('code', {
        icon: '<i class="fa fa-code"></i>',
        attributes: {
            title: 'Code'
        },
        result: rte => rte.exec("formatBlock", '<pre>')
    });

    rte.add('android', {
        icon: '<i class="fab fa-android"></i>',
        attributes: {
            title: 'Android'
        },
        result: rte => rte.exec("formatBlock", '<pre>')
    });

    rte.add('justifyLeft', {
        icon: '<i class="fa fa-align-left"></i>',
        attributes: {
            title: 'Align Left'
        },
        result: rte => rte.exec('justifyLeft')
    });
    rte.add('justifyCenter', {
        icon: '<i class="fa fa-align-center"></i>',
        attributes: {
            title: 'Align Center'
        },
        result: rte => rte.exec('justifyCenter')
    });
    rte.add('justifyFull', {
        icon: '<i class="fa fa-align-justify"></i>',
        attributes: {
            title: 'Align Justify'
        },
        result: rte => rte.exec('justifyFull')
    });
    rte.add('justifyRight', {
        icon: '<i class="fa fa-align-right"></i>',
        attributes: {
            title: 'Align Right'
        },
        result: rte => rte.exec('justifyRight')
    });
};