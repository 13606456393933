import React from "react";
import "./index.scss";


const DocCreateComments = (props) => {

  let keyword = '{{{KEYWORD}}}'

  return <div className="route__documentation">
    <div className="route__documentation__headline">
      <p>Create Comments</p>
    </div>

    <div className="route__documentation__desc">
      <p>Creating Comments through our CMS and binding them to an offer</p>
    </div>


    <div className="route__documentation__desc">
      <p>Custom Assets is a section of different components and add ons for the web building,</p>
      <p>We will go through them and what do they do.</p>
    </div>
    <div className="route__documentation__introBlock">
      <div className="route__documentation__introBlock__left">
        <img src="/images/tutorials/createComments/createComment1.png" />
      </div>
      <div className="route__documentation__introBlock__right">
        <p>In the sidebar section, we should open the Comments tab</p>
        <p>Then we will see all our offers by ScaleTrack, and at this point we're supposed to choose one offer, as for out example we will go with apollo kosa</p>
      </div>
    </div>

    <div className="route__documentation__desc">
      <p>Creating a Comment</p>
    </div>

    <div className="route__documentation__introBlock">
      <div className="route__documentation__introBlock__left">
        <img src="/images/tutorials/createComments/createComment2.png" />
      </div>
      <div className="route__documentation__introBlock__right">
        <p>To create a comment, at the header of our tab, we should see an ' Add a comment ' button, so we click there</p>
        <p>We should get a modal with different parameters for out comment that we're creating</p>
      </div>
    </div>
    <div className="route__documentation__introBlock">
      <div className="route__documentation__introBlock__left">
        <img style={{minWidth: '250px'}} src="/images/tutorials/createComments/createComment3.png" />
      </div>
      <div className="route__documentation__introBlock__right">
        <p>Select Offer - all offers fetched from ScaleTrack, choose an offer to whos comment is binded</p>
        <p>Select Gomment Gender - choose gender of the person thats leaving the comment male/female</p>
        <p>Select Comment Language - select the language in which the comment is written</p>
        <p>Comment Name - stands for the first and last name of the person that is leaving the comment</p>
        <p>Comment Text - is the content of the comment, text of the comments, to have a link in the comment that will lead user to the form <span style={{color: '#FF3333'}}>IT IS IMPORTANT TO PUT THE {keyword} </span> - replace KEYWORD with your word for the link</p>
        <p>Regenerate Button will regenerate comment for that offer</p>
        <p>Generate Generic Button will generate generic comment that can fit any offer</p>
        {/* <p>Select Translations - select translations for the comment</p> */}
        <p>Select User Image - This is the image of the user that is leaving the comment, basically users profile picture</p>
        <p>Select Text Image - This is the image that user posted in his/her comment, usually it's product image</p>
        <p>Likes/Dislikes Amount - represents the amount of likes and dislikes that current comment has</p>
      </div>
    </div>

    <div className="route__documentation__desc">
      <p>Templates</p>
    </div>

    <div className="route__documentation__introBlock flex-column">
      <div className="route__documentation__introBlock__left">
        <img src="/images/tutorials/createComments/createComment4.png" />
      </div>
      <div className="route__documentation__introBlock__right">
        <p>After creating all the desired and needed comments, we move onto templates, </p>
        <p>In here we'll get all templates (comment sections) binded to an offer, hit the Create Template button to create a new one</p>
      </div>
      <div className="route__documentation__introBlock__left">
        <img src="/images/tutorials/createComments/createComment5.png" />
      </div>
      <div className="route__documentation__introBlock__right">
        <p>Select the name of the Template, Comment languange thats this template binded to, and select Comments taht are in this template/section</p>
      </div>
      <div className="route__documentation__introBlock__left">
        <img src="/images/tutorials/createComments/createComment6.png" />
      </div>
      <div className="route__documentation__introBlock__right">
        <p>After we've created the template, we should have it in our list</p>
        <p>And if we go back to the Comments section, select the OFFERS TAB select the TEMPLATES TAB, we should have all our comments that are binded to THAT OFFER and THAT TEMPLATE</p>
      </div>
      <div className="route__documentation__introBlock__left">
        <img src="/images/tutorials/createComments/createComment7.png" />
      </div>
      <div className="route__documentation__introBlock__right">
        <p>To add a reply Comment that is binded to this comment, we just click the Add Reply button and create a comment</p>
      </div>
    </div>

  </div>
};

export default DocCreateComments;
export { DocCreateComments };