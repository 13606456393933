import React from 'react';
import { createRoot } from "react-dom/client";
import { HashRouter as Router } from 'react-router-dom';
import './index.css';
import App from './App';

import { legacy_createStore } from 'redux';
import { Provider } from 'react-redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import allReducers from "./reducers";

import { setAxiosDataHandler, setStore } from "./modules/backendModule";

setAxiosDataHandler();

let store = legacy_createStore(allReducers, composeWithDevTools());
setStore(store);

const rootElem = createRoot(document.querySelector("#root"));
rootElem.render(<Provider store={store}>
    <Router>
        <App />
    </Router>
</Provider>);