import gjs from "grapesjs";


/**
 * @param {gjs.Editor} editor
 */
export default editor => {
    editor.Components.addType('scalecms-threeColumns', {
        isComponent: e => e?.classList?.contains("scalecms-threeColumns"),
        model: {
            init(){
                this.on('change', e => {
                    let responsive = e?.attributes?.attributes?.responsive;
                    this.get("components").each(child => {
                        if(child?.attributes?.tagName === 'style'){
                            child.components( responsive === true ? 
                            `
                                @media screen and (max-width: 600px){
                                    .scalecms-threeColumns{
                                        grid-template-columns: 1fr !important;
                                    }
                                    .scalecms-threeColumns-right{
                                        width: 100% !important;
                                    }
                                    .scalecms-threeColumns-center{
                                        width: 100% !important;
                                    }
                                    .scalecms-threeColumns-left{
                                        width: 100% !important;
                                    }
                                }
                            `
                            : '');
                        }
                    })
                });
            },
            defaults: {
                droppable: false,
                type: "default",
                traits: [
                    { name: "responsive", type:"checkbox"},
                ],
                attributes: {
                    class: "scalecms-threeColumns"
                },
                styles: `
                    .scalecms-threeColumns {
                        width: 100%;
                        height: auto;
                        min-height: 40px;
                        display: grid;
                        grid-template-rows: max-content;
                        grid-template-columns: repeat(3, 1fr);
                    }
                    .scalecms-threeColumns-left, .scalecms-threeColumns-center, .scalecms-threeColumns-right {
                        display: block;
                        width: 100%;
                        height: auto;
                        min-height: 40px;
                    }

                    @media screen and (max-width: 1000px) {
                        .scalecms-threeColumns {
                            grid-template-columns: max-content;
                            grid-template-rows: repeat(3, 1fr);
                        }
                    }
                `,
                components: [
                    {
                        tagName: "div",
                        attributes: {class: "scalecms-threeColumns-left"}
                    },
                    {
                        tagName: "div",
                        attributes: {class: "scalecms-threeColumns-center"}
                    },
                    {
                        tagName: "div",
                        attributes: {class: "scalecms-threeColumns-right"}
                    },
                    {
                        tagName: "style",
                        attributes: {class: "scalecms-threeColumns-style"}
                    }
                ]
            }
        }
    });


    editor.BlockManager.add('scalecms-threeColumns-blk', {
        label: '3 columns',
        content: {
            type: "scalecms-threeColumns",
        },
        category: {
            label: 'Layout'
        },
        media: `<svg viewBox="0 0 23 24"><path fill="currentColor" d="M2 20h4V4H2v16Zm-1 0V4a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1ZM17 20h4V4h-4v16Zm-1 0V4a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1ZM9.5 20h4V4h-4v16Zm-1 0V4a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1Z"></path></svg>`,
        attributes: {
            title: 'Basic 3 columns block',
        },
        select: true
    });
};