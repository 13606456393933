import gjs from "grapesjs";

function script() {
    if (!this) return;
}

/**
 * @param {gjs.Editor} editor
 */
export default editor => {
    editor.Components.addType('scalecms-grid', {
        isComponent: e => e?.classList?.contains("scalecms-grid"),
        model: {
            defaults: {
                type: "default",
                traits: [

                ],
                attributes: {
                    class: "scalecms-grid"
                },
                script,
                styles: `
                    .scalecms-grid {
                        width: 100%;
                        height: 100px;
                    }
                    .scalecms-grid-box{
                        display: grid;
                        grid-template-columns: 1fr 1fr 1fr;
                        grid-template-rows: 1fr 1fr 1fr;
                        width: 100%;
                        height: 100%;
                    }
                    .scalecms-grid-box-block{
                        width: 100%;
                        height: 100%;
                    }
                `,
                components: [
                    {
                        tagName: "div",
                        attributes: { class: "scalecms-grid-box" },
                        components: [
                            {
                                tagName: "div",
                                attributes: { class: "scalecms-grid-box-block"}
                            },
                            {
                                tagName: "div",
                                attributes: { class: "scalecms-grid-box-block"}
                            },
                            {
                                tagName: "div",
                                attributes: { class: "scalecms-grid-box-block"}
                            },
                            {
                                tagName: "div",
                                attributes: { class: "scalecms-grid-box-block"}
                            },
                            {
                                tagName: "div",
                                attributes: { class: "scalecms-grid-box-block"}
                            },
                            {
                                tagName: "div",
                                attributes: { class: "scalecms-grid-box-block"}
                            },
                            {
                                tagName: "div",
                                attributes: { class: "scalecms-grid-box-block"}
                            },
                            {
                                tagName: "div",
                                attributes: { class: "scalecms-grid-box-block"}
                            },
                            {
                                tagName: "div",
                                attributes: { class: "scalecms-grid-box-block"}
                            },
                        ]
                    },
                ]
            }
        }
    });


    editor.BlockManager.add('scalecms-grid-blk', {
        label: 'Grid 3x3',
        content: {
            type: "scalecms-grid",
        },
        category: {
            label: 'Layout'
        },
        media: `
        <svg version="1.1" id="Icons" x="0px" y="0px" viewBox="0 0 32 32" style="enable-background:new 0 0 32 32;" xml:space="preserve">
        <style type="text/css">
            .st0{fill:none;stroke:#000000;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
        </style>
        <g>
        <rect x="12" y="2" width="8" height="8" id="id_101" style="fill: rgb(255, 255, 255);"></rect>
        <rect x="12" y="12" width="8" height="8" id="id_102" style="fill: rgb(255, 255, 255);"></rect>
        <rect x="2" y="12" width="8" height="8" id="id_103" style="fill: rgb(255, 255, 255);"></rect>
        <rect x="12" y="22" width="8" height="8" id="id_104" style="fill: rgb(255, 255, 255);"></rect>
        <rect x="22" y="12" width="8" height="8" id="id_105" style="fill: rgb(255, 255, 255);"></rect>
        <path d="M22,22v8h7c0.6,0,1-0.4,1-1v-7H22z" id="id_106" style="fill: rgb(255, 255, 255);"></path>
        <path d="M10,22H2v7c0,0.6,0.4,1,1,1h7V22z" id="id_107" style="fill: rgb(255, 255, 255);"></path>
        <path d="M10,10V2H3C2.4,2,2,2.4,2,3v7H10z" id="id_108" style="fill: rgb(255, 255, 255);"></path>
        <path d="M22,10h8V3c0-0.6-0.4-1-1-1h-7V10z" id="id_109" style="fill: rgb(255, 255, 255);"></path>
        </g>
        </svg>
        `,
        attributes: {
            title: 'Basic grid 3x3',
        },
        select: true
    });
};