import gjs from "grapesjs";


/**
 * @param {gjs.Editor} editor
 */
export default editor => {
    editor.Components.addType('scalecms-oneColumn', {
        isComponent: e => e?.classList?.contains("scalecms-oneColumn"),
        model: {
            defaults: {
                type: "default",
                traits: [

                ],
                attributes: {
                    class: "scalecms-oneColumn"
                },
                styles: `
                    .scalecms-oneColumn {
                        width: 100%;
                        height: auto;
                        min-height: 40px;
                    }
                `,
                components: ``
            }
        }
    });


    editor.BlockManager.add('scalecms-oneColumn-blk', {
        label: '1 column',
        content: {
            type: "scalecms-oneColumn",
        },
        category: {
            label: 'Layout'
        },
        media: `<svg viewBox="0 0 24 24"><path fill="currentColor" d="M2 20h20V4H2v16Zm-1 0V4a1 1 0 0 1 1-1h20a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1Z"></path></svg>`,
        attributes: {
            title: 'Basic 1 column block',
        },
        select: true
    });
};