import gjs from "grapesjs";
import axios from "axios";
import * as backendModule from "../../../../modules/backendModule";

function script() {
  let headlineText = this.querySelector(".bottom-bar-cta-left");
  let scrollToBtn = this.querySelector(".bottom-bar-cta-right");
  let scrollTarget = document.querySelector(".scroll-target");
  if(!scrollTarget) scrollTarget = document.querySelector(".scalelead-form");


  scrollToBtn.addEventListener('click', () => {
    scrollTarget.scrollIntoView({ behavior: 'smooth' });
  });

  let mainText = this.getAttribute('data-main-text');
  let btnText = this.getAttribute('data-button-text');
  let btnBackground = this.getAttribute('data-button-background');

  if(mainText) headlineText.textContent = `${mainText}`;
  if(btnText) scrollToBtn.textContent = `${btnText}`
  if(btnBackground) scrollToBtn.style.backgroundColor = `${btnBackground}`

  if (!this) return;
  if (this.getAttribute("data-gjs-type")) return;
};

/**
 * @param {gjs.Editor} editor
 */

export default (editor, offers) => {


  editor.on('load', async function () {
    try {
      setTimeout(async () => {
        let myComponent = findComponentByType(editor.DomComponents.getComponents(), 'bottom-bar-cta_cmp');

        if (!myComponent) {
          return;
        }
      }, 500);

    } catch {}
  });

  const findComponentByType = (components, type) => {
    for (let component of components) {
      if (component.get('type') === type) {
        return component;
      }
      if (component.models && component.models.length > 0) {
        let nestedComponent = findComponentByType(component.components, type);
        if (nestedComponent) {
          return nestedComponent;
        }
      }
    }
    return null;
  }


  editor.Components.addType('bottom-bar-cta_cmp', {
    isComponent: e => e?.classList?.contains("bottom-bar-cta"),
    model: {
      init() {
        this.on("change", e => {
          this.get("components").each(child => {

            let mainText = e?.attributes?.attributes?.["data-main-text"];
            let BtnText = e?.attributes?.attributes?.["data-button-text"];
            let BtnBackground = e?.attributes?.attributes?.["data-button-background"];

            let el = child.getEl();
            if(el.className === 'bottom-bar-cta-left'){
              el.textContent = mainText;
            }else if(el.className === 'bottom-bar-cta-right'){
              el.textContent = BtnText;
              el.style.backgroundColor = BtnBackground;
            }
            let elMainText = el.querySelector(".bottom-bar-cta-right-head");
            let elBtn = el.querySelector(".bottom-bar-cta-right-text");

            if (elMainText) elMainText.textContent = mainText;
            if (elBtn) elBtn.textContent = BtnText;
            if (elBtn) elBtn.style.backgroundColor = BtnBackground;
          });

        });
      },
      defaults: {
        type: "default",
        traits: [
          { name: "data-main-text", label: "Main text", placeholder: "Glavni tekst", default: "Glavni tekst" },
          { name: "data-button-text", label: "Button text", placeholder: "Btn text", default: "Button Text" },
          { name: "data-button-background", label: "Button background", placeholder: "Btn Background", default: "#FF5c00" },
        ],
        attributes: {
          class: "bottom-bar-cta"
        },
        script,
        styles: `
          .bottom-bar-cta{
            display: flex;
            justify-content: center;
            align-items: center;
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;
            height: auto;
            background-color: red;
            gap: 20px;
            cursor: pointer;
            z-index: 999;
            padding: 4px;
          }
          .bottom-bar-cta-left{
            width: 100%;
            height: auto;
            width: auto;
            font-family: nunito,roboto,proxima-nova,"proxima nova",sans-serif;
            font-size: 16px;
            font-weight: 800;
            line-height: 16px;
            color: white;
          }
          .bottom-bar-cta-right{
            font-family: nunito,roboto,proxima-nova,"proxima nova",sans-serif;
            font-size: 16px;
            font-weight: 800;
            line-height: 16px;
            min-height: 40px;
            padding: 10px 30px;
            background-color: green;
            color: white;
            border-radius: 5px;
          }

          @media screen and (max-width: 550px) {
            .bottom-bar-cta{
              padding: unset;
            }
            .bottom-bar-cta-left {
              display: none;
            }
            .bottom-bar-cta-right{
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              height: 100%;
              border-radius: unset;
            }
          }

        `,
        components: [
          {
            type: 'text',
            attributes: { class: 'bottom-bar-cta-left'},
            components: 'tekst ide ovdje'
          },
          {
            type: 'text',
            attributes: { class: 'bottom-bar-cta-right'},
            components: 'Naruči'
          },
        ]
      }
    }
  });


  editor.BlockManager.add('bottom-bar-cta_blk', {
    label: 'Bottom Bar CTA',
    content: {
      type: "bottom-bar-cta_cmp",
    },
    category: {
      label: 'Custom assets'
    },
    media: "<img src='/images/component_form.png' style='width: 100%; height: auto; filter: invert(1) hue-rotate(180deg);' />",
    attributes: {
      title: 'Insert an Bottom Bar CTA',
    },
    select: true
  });
};