import gjs from "grapesjs";

function script() {
    if (!this) return;
    if (this.getAttribute("data-gjs-type")) return;

}

/**
 * @param {gjs.Editor} editor
 */
export default editor => {
    editor.Components.addType('scalecms-contactForm', {
        isComponent: e => e?.classList?.contains("scalecms-contactForm"),
        model: {
            defaults: {
                script,
                type: "default",
                attributes: {
                    class: "scalecms-contactForm"
                },
                styles: `
                    .scalecms-contactForm{
                        width: 500px;
                        height: fit-content;
                        padding: 20px;
                        box-shadow: 0 0 4px gray;
                        display: flex;
                        flex-direction: column;
                        justify-content: baseline;
                        align-items: baseline;
                        font-family: 'Arial';
                        border-radius: 5px;
                        margin-left: auto;
                        margin-right: auto;
                        margin-top: 20px;
                    }
                    .scalecms-contactForm-heading{
                        display: block;
                        margin-left: auto;
                        margin-right: auto;
                        margin-bottom: 10px;
                        font-weight: 700;
                        font-size: 22px;
                    }
                    .scalecms-contactForm-button{
                        margin-top: 10px;
                        cursor: pointer;
                        display: block;
                        margin-left: auto;
                        margin-right: auto;
                    }
                    .scalecms-contactForm-form{
                        display: flex;
                        flex-direction: column;
                        justify-content: baseline;
                        align-items: baseline;
                        width: 100%;
                    }
                    input{
                        width: 100%;
                        height: 2.5rem;
                        border-radius: 5px;
                        border: 1px solid gray;
                        margin-bottom: 10px;
                        margin-top: 5px;
                    }
                    textarea{
                        width: 100%;;
                        border-radius: 5px;
                        border: 1px solid gray;
                        margin-top: 5px;
                        margin-bottom: 10px;
                    }
                    input[type='submit']{
                        cursor: pointer;
                        width: 100px;
                        display: block;
                        margin-left: auto;
                        margin-right: auto;
                    }
                    @media screen and (max-width: 600px){
                        .scalecms-contactForm{
                            width: 95%;
                        }
                    }
                `,
                components: [
                    {
                        type: 'text',
                        attributes: {class: 'scalecms-contactForm-heading'},
                        components: 'KONTAKTIRAJ NAS'
                    },
                    {
                        type: 'form',
                        attributes: {class: 'scalecms-contactForm-form'},
                        components: [
                            {
                                type: 'text',
                                components: 'Ime i prezime',
                            },
                            {
                                type: 'input',
                            },
                            {
                                type: 'text',
                                components: 'Email',
                            },
                            {
                                type: 'input',
                            },
                            {
                                type: 'text',
                                components: 'Poruka',
                            },
                            {
                                type: 'textarea',
                            },
                            {
                                tagName: 'input',
                                attributes: {type: 'submit', value: 'SUBMIT'},
                            }
                        ]
                    }
                ]
            }
        }
    });


    editor.BlockManager.add('scalecms-contactForm-blk', {
        label: 'Contact form',
        content: {
            type: "scalecms-contactForm",
        },
        category: {
            label: 'Custom assets'
        },
        media: `
        <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
        <g id="Layer_1_1_">
        <path d="M49,7H1v36h48V7z M47,41H3V9h44V41z" id="id_101" style="fill: rgb(255, 255, 255);"></path>
        <path d="M23,27c0-2.449-1.773-4.483-4.101-4.909C19.577,21.237,20,20.172,20,19c0-2.757-2.243-5-5-5s-5,2.243-5,5
                c0,1.172,0.422,2.237,1.101,3.091C8.773,22.517,7,24.551,7,27v7h16V27z M15,16c1.654,0,3,1.346,3,3s-1.346,3-3,3s-3-1.346-3-3
                S13.346,16,15,16z M21,32H9v-5c0-1.654,1.346-3,3-3h6c1.654,0,3,1.346,3,3V32z" id="id_102" style="fill: rgb(255, 255, 255);"></path>
        <rect x="26" y="17" width="7" height="2" id="id_103" style="fill: rgb(255, 255, 255);"></rect>
        <rect x="36" y="17" width="7" height="2" id="id_104" style="fill: rgb(255, 255, 255);"></rect>
        <rect x="26" y="22" width="3" height="2" id="id_105" style="fill: rgb(255, 255, 255);"></rect>
        <rect x="32" y="22" width="11" height="2" id="id_106" style="fill: rgb(255, 255, 255);"></rect>
        <rect x="26" y="27" width="8" height="2" id="id_107" style="fill: rgb(255, 255, 255);"></rect>
        <rect x="39" y="27" width="4" height="2" id="id_108" style="fill: rgb(255, 255, 255);"></rect>
        <rect x="26" y="32" width="2" height="2" id="id_109" style="fill: rgb(255, 255, 255);"></rect>
        <rect x="30" y="32" width="2" height="2" id="id_110" style="fill: rgb(255, 255, 255);"></rect>
        <rect x="34" y="32" width="2" height="2" id="id_111" style="fill: rgb(255, 255, 255);"></rect>
        <rect x="41" y="32" width="2" height="2" id="id_112" style="fill: rgb(255, 255, 255);"></rect>
        </g>
        </svg>
        `,
        attributes: {
            title: 'Contact form',
        },
        select: true
    });
};