import React from "react";
import "./index.scss";

import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { animateBox } from "../../modules/componentAnimation";

import animateModule from "../../modules/animateModule";
import { updateTimestamp } from "../../actions/timestampActions";
import { toggleAdvancedSearch } from "../../actions/siteSettingsActions";
import { AddUser } from "../../routes/UsersList";
import { AddApiToken } from "../../routes/ApiTokens";
import { AddRoute } from "../../routes/RouteList";
import { AddDomain } from "../../routes/DomainsList";
import { CreateComment } from "../../routes/CommentsList";
import { AddCampaign } from "../../routes/CampaignsList";
import { AddImage } from "../../routes/ImagesList";
import { AddCreative } from "../../routes/Creatives/CreativesList";
import { AddLanderRequest } from "../../routes/LanderRequests";
import { AddCreative as AddCreativeItem } from "../../routes/Creatives/CreativeItems";
import { openSaveDialog, openTemplateDialog, openRecoverDialog } from "../../routes/CreateWebsite";
import { CreateRecension } from "../../routes/RecensionsList";

const Header = () => {
    const curLocation = useLocation();
    const curDispatch = useDispatch();
    const curNavigate = useNavigate();

    const siteSettingsSelector = useSelector(state => state.siteSettings ?? {});
    const siteSavedSelector = useSelector(state => state.siteSaved ?? {});
    const siteCreatedCampaignSelector = useSelector(state => state.siteCreatedCampaign ?? {});
    const userDataSelector = useSelector(state => state?.userData?.userData ?? {});

    const [initialSiteStatus, setInitialSiteStatus] = React.useState(String(String(window.location.href).split("?")[1]).split("=")[1] ?? 0)

    const animateNavigate = to => animateModule(curNavigate, to, document.querySelector(".component__contentWrapper"));

    return <div className="component__header">
        <div className="component__header__left">
            <h3 onClick={() => animateNavigate("/main/")}><img src="/images/logo.png" /></h3>
        </div>
        <div className="component__header__right">
            {curLocation.pathname.startsWith("/main/") && <p className="component__header__right__btn" onClick={(e) => {
               curDispatch(updateTimestamp()); 
            }}>
                <img src="/images/refreshBtn.png" />
                <span>Refresh</span>
            </p>}
            {curLocation.pathname.startsWith("/main/route-list") && <p className="component__header__right__btn" onClick={(e) => {
               animateBox(e, <AddRoute onChange={() => curDispatch(updateTimestamp())} />); 
            }}>
                <img src="/images/addBtn.png" />
                <span>Add route</span>
            </p>}
            {curLocation.pathname.startsWith("/main/campaign-list") && <p className="component__header__right__btn" onClick={(e) => {
               animateBox(e, <AddCampaign onChange={() => curDispatch(updateTimestamp())} />); 
            }}>
                <img src="/images/addBtn.png" />
                <span>Add campaign</span>
            </p>}
            {(curLocation.pathname.startsWith("/main/domain-list") && userDataSelector?.UserInfo?.Flags?.isAdmin) && <p className="component__header__right__btn" onClick={(e) => {
                animateBox(e, <AddDomain onChange={() => curDispatch(updateTimestamp())} />)
            }}>
                <img src="/images/addBtn.png" />
                <span>Add domain</span>
            </p>}
            {(curLocation.pathname.startsWith("/main/comment-list")) && <p className="component__header__right__btn" onClick={(e) => {
                animateBox(e, <CreateComment createdBy={userDataSelector?.UserInfo?.Username} onChange={() => curDispatch(updateTimestamp())} />)
            }}>
                <img src="/images/addBtn.png" />
                <span>Add comment</span>
            </p>}
            {(curLocation.pathname.startsWith("/main/recension-list")) && <p className="component__header__right__btn" onClick={(e) => {
                animateBox(e, <CreateRecension createdBy={userDataSelector?.UserInfo?.Username} onChange={() => curDispatch(updateTimestamp())} />)
            }}>
                <img src="/images/addBtn.png" />
                <span>Add recension</span>
            </p>}
            {curLocation.pathname.startsWith("/main/asset-list") && <p className="component__header__right__btn" onClick={(e) => {
                animateBox(e, <AddImage onChange={() => curDispatch(updateTimestamp())} />)
            }}>
                <img src="/images/addBtn.png" />
                <span>Add image</span>
            </p>}
            {curLocation.pathname.match(/\/main\/creative-list\/?$/g) && <p className="component__header__right__btn" onClick={(e) => {
                animateBox(e, <AddCreative onChange={() => curDispatch(updateTimestamp())} />)
            }}>
                <img src="/images/addBtn.png" />
                <span>Add creative group</span>
            </p>}
            {curLocation.pathname.match(/\/main\/creative-list\/[\S]{1}/g) && <p className="component__header__right__btn" onClick={(e) => {
                animateBox(e, <AddCreativeItem groupID={curLocation.pathname.split("/").pop()} onChange={() => curDispatch(updateTimestamp())} />)
            }}>
                <img src="/images/addBtn.png" />
                <span>Add creative item</span>
            </p>}
            {curLocation.pathname.startsWith("/main/landing-list") && <p className="component__header__right__btn" onClick={() => {
                let wnd = window.open(`${window.location.origin}/#/create-website/landing`, "_blank");
                wnd.addEventListener("beforeunload", () => curDispatch(updateTimestamp()));
            }}>
                <img src="/images/addBtn.png" />
                <span>Add landing (offer) page</span>
            </p>}
            {curLocation.pathname.startsWith("/main/prelanding-list") && <p className="component__header__right__btn" onClick={() => {
                let wnd = window.open(`${window.location.origin}/#/create-website/prelanding`, "_blank");
                wnd.addEventListener("beforeunload", () => curDispatch(updateTimestamp()));
            }}>
                <img src="/images/addBtn.png" />
                <span>Add pre-landing page</span>
            </p>}
            {curLocation.pathname.startsWith("/main/thankyou-list") && <p className="component__header__right__btn" onClick={() => {
                let wnd = window.open(`${window.location.origin}/#/create-website/thankyou`, "_blank");
                wnd.addEventListener("beforeunload", () => curDispatch(updateTimestamp()));
            }}>
                <img src="/images/addBtn.png" />
                <span>Add thankyou page</span>
            </p>}
            {curLocation.pathname.startsWith("/main/static-list") && <p className="component__header__right__btn" onClick={() => {
                let wnd = window.open(`${window.location.origin}/#/create-website/static`, "_blank");
                wnd.addEventListener("beforeunload", () => curDispatch(updateTimestamp()));
            }}>
                <img src="/images/addBtn.png" />
                <span>Add static page</span>
            </p>}
            {curLocation.pathname.startsWith("/main/templates") && <p className="component__header__right__btn" onClick={() => {
                let wnd = window.open(`${window.location.origin}/#/create-website/template`, "_blank");
                wnd.addEventListener("beforeunload", () => curDispatch(updateTimestamp()));
            }}>
                <img src="/images/addBtn.png" />
                <span>Add template</span>
            </p>}
            {(curLocation.pathname.startsWith("/main/users-list") && userDataSelector?.UserInfo?.Flags?.isAdmin) && <p className="component__header__right__btn" onClick={(e) => {
                animateBox(e, <AddUser />);
            }}>
                <img src="/images/addBtn.png" />
                <span>Add User</span>
            </p>}
            {(curLocation.pathname.startsWith("/main/api-tokens-list") && userDataSelector?.UserInfo?.Flags?.isAdmin) && <p className="component__header__right__btn" onClick={(e) => {
                animateBox(e, <AddApiToken />);
            }}>
                <img src="/images/addBtn.png" />
                <span>Add API token</span>
            </p>}
            {(curLocation.pathname.startsWith("/main/lander-requests") && userDataSelector?.UserInfo?.Flags?.canRequestLander) && <p className="component__header__right__btn" onClick={(e) => {
                animateBox(e, <AddLanderRequest />);
            }}>
                <img src="/images/addBtn.png" />
                <span>Add request</span>
            </p>}

            
            {curLocation.pathname.startsWith("/main") && <p className="component__header__right__btn component__header__right__btn--orange" onClick={() => {
                curDispatch(toggleAdvancedSearch());
            }}>
                <img style={{width: "16px", height: "16px"}} src="/images/filterImg.png" />
                <span>{siteSettingsSelector.advancedSearch ? "Simple" : "Advanced"} filters</span>
            </p>}


            
            {(window.indexedDB && curLocation.pathname.startsWith("/create-website")) && <p className="component__header__right__btn" onClick={(e) => {
                openRecoverDialog(e);
            }}>
                <img src="/images/refreshBtn.png" />
                <span>Recover</span>
            </p>}
            {/* {curLocation.pathname.startsWith("/create-website") && <p className={`component__header__right__btn ${!siteSavedSelector?.siteSaved && 'component__header__right__btn--closed' }`} onClick={(e) => {
                if(!siteSavedSelector?.siteSaved) return;
                animateBox(e, <AddCampaign curLocation={curLocation} onChange={() => curDispatch(updateTimestamp())} />); 
            }}>
                <img src="/images/addBtn.png" />
                <span>Create Campaign</span>
            </p>} */}
            {(Number(initialSiteStatus) === 100 || Number(initialSiteStatus) === 110) && <p className={`component__header__right__btn`} onClick={(e) => {
                animateBox(e, <AddCampaign curLocation={curLocation} onChange={() => curDispatch(updateTimestamp())} />); 
            }}>
                <img src="/images/addBtn.png" />
                <span>Create Campaign</span>
            </p>}
            {curLocation.pathname.startsWith("/create-website") && <p className="component__header__right__btn" onClick={(e) => {
                openTemplateDialog(e);
            }}>
                <img src="/images/selectTemplateBtn.png" />
                <span>Load template</span>
            </p>}
            {curLocation.pathname.startsWith("/create-website") && <p className="component__header__right__btn component__header__right__btn--red" onClick={(e) => {
                animateBox(e, <CloseWindowPopup />);
            }}>
                <img src="/images/closeBtn.png" />
                <span>Close window</span>
            </p>}
            
            {curLocation.pathname.startsWith("/create-website") && <p className={`component__header__right__btn ${siteCreatedCampaignSelector.siteCreatedCampaign && 'component__header__right__btn--closed'}`} onClick={(e) => {
                if(siteCreatedCampaignSelector.siteCreatedCampaign) return;
                if((Number(initialSiteStatus) === 100 || Number(initialSiteStatus) === 110)){
                    setInitialSiteStatus(110);
                }else{
                    setInitialSiteStatus(0);
                }
                openSaveDialog(e);
            }}>
                <img src="/images/saveBtn.png" />
                <span>Save website</span>
            </p>}
        </div>
    </div>
};

const CloseWindowPopup = (props2) => {
    return <div className="route__landingList__delete">
        <div className="route__landingList__delete__wrap">
            <h3>Are you sure?</h3>
            <p>You will loose all your changes!</p>

            <div className="route__landingList__delete__wrap__btns">
                <p onClick={() => window.close()}>
                    <img style={{ marginRight: "15px" }} src="/images/saveBtn.png" />
                    <span>Close</span>
                </p>
                <p onClick={props2.onClose}>
                    <span>Cancel</span>
                    <img style={{ marginLeft: "15px" }} src="/images/closeBtn.png" />
                </p>
            </div>
        </div>
    </div>
};

export default Header;