import React from "react";
import { createRoot } from "react-dom/client";
import "./index.scss";
import axios from "axios";

import * as backendModule from "../../../modules/backendModule";
import CustomInput from "../../../components/customComponents/CustomInput";
import Button from "../../../components/customComponents/Button";
import Dropdown from "../../../components/customComponents/Dropdown";

let root = null;

const AssetManager = props => {
  const [selectedAsset, setSelectedAsset] = React.useState();
  const [allAssets, setAllAssets] = React.useState(props.assets ?? []);

  const [baseOffers, setBaseOffers] = React.useState([]);
  const [initialImages, setInitialImages] = React.useState([])
  const [images, setImages] = React.useState([]);
  const [additionalFilters, setAdditionalFilters] = React.useState([])

  const mainRef = React.useRef();
  const uploadFileRef = React.useRef();
  const searchRef = React.useRef();

  const getAllOffersForComments = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/comments/getAllOffersForComments`,
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        let out = res.data.data;
        setBaseOffers(res.data.data)
      } else {
      };
    }).catch(() => {
    }).finally(() => {
    });
  }

  const getAssetManagerImages = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/images/getAllImages`,
      data: {
        // filters: [{ name: "Tag", op: "eq", value: "grapejs" }],
        filters: additionalFilters ? additionalFilters : [],
        limit: 1000
      },
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        setInitialImages(res.data.data)
        setImages(res.data.data)
      };
    }).catch(() => null);
  };

  const performUpload = (e) => {
    if (e.target.files.length === 0) return;
    props.uploadFile(e, additionalFilters);
    setTimeout(() => {
      getAssetManagerImages();
    }, 1000);
  };

  const performSearch = () => {
    if (!searchRef.current.value) {
      setAllAssets(props.assets ?? []);
      setImages(initialImages ?? []);
      return;
    }
  
    if (!props.assets && !initialImages) {
      setAllAssets([]);
      setImages([]);
      return;
    }
  
    let searchString = searchRef.current.value.toLowerCase();
  
    let outAssets = [];
    let outImages = [];
  
    for (let item of props.assets || []) {
      if (item?.attributes?.name.toLowerCase().includes(searchString)) {
        outAssets.push(item);
      }
    }
  
    for (let image of initialImages || []) {
      if (image?.ImageName.toLowerCase().includes(searchString)) {
        outImages.push(image);
      }
    }
  
    setAllAssets(outAssets);
    setImages(outImages);
  };

  React.useEffect(() => {
    if (!props.isOpen) {
      setSelectedAsset();
      if (searchRef.current) searchRef.current.value = "";
    } else {
      if (mainRef.current) {
        let tmp = mainRef.current.querySelector(".route__createWebsite__assetManager__left__list");
        if (tmp) {
          tmp.scrollTo(0, 0);
        };
      };
    };
  }, [props.isOpen]);

  React.useEffect(() => {
    getAllOffersForComments();
    getAssetManagerImages();
  }, []);

  React.useEffect(() => {
    if (!additionalFilters) return
    getAssetManagerImages();
  }, [additionalFilters]);


  if (!props.assets) return null;
  return <div className="route__createWebsite__assetManager" ref={mainRef}>
    <div className="route__createWebsite__assetManager__left">
      <div className="route__createWebsite__assetManager__left__search">
        <CustomInput autocomplete="off" theme="dark" accent="#3F7CEA" placeholder="Search..." style={{ width: "250px" }} ref={searchRef} onChange={performSearch} />
        <Button theme="dark" accent="#3F7CEA" value="Upload" style={{ marginLeft: "20px" }} onClick={() => uploadFileRef.current?.click()} />
        <input type="file" accept="image/*" style={{ display: "none" }} ref={uploadFileRef} onChange={performUpload} multiple={true} />
        {baseOffers.length && <Dropdown
          theme="dark"
          accent="rgb(63, 124, 234)"
          style={{ marginLeft: "20px" }}
          inlinePlaceholder="Select Offer"
          data={[
            { name: 'Any', value: null },
            { name: 'All Products', value: 'allProducts' },
            ...baseOffers.map(u => {
              return { name: u.OfferName, value: u.OfferName };
            })
          ]}
          selected={(() => {
            // if (users.status !== "ok") return null;
            if (!additionalFilters) return null;

            return baseOffers.indexOf(baseOffers.find(u => u.OfferName === additionalFilters));
          })()}
          onChange={e => setAdditionalFilters([{ name: "Tag", op: "eq", value: `offername-${e?.value}` }])}
        />}
      </div>
      <div className="route__createWebsite__assetManager__left__list">
        {(images.length > 0) ? images.map(asset => {
          return <div className={`route__createWebsite__assetManager__left__list__img ${asset === selectedAsset ? "route__createWebsite__assetManager__left__list__img--active" : ""}`} onClick={() => {
            if (selectedAsset !== asset) setSelectedAsset(asset);
          }}>
            <img loading="lazy" src={asset?.URL} />
          </div>
        }) : <div className="route__createWebsite__assetManager__left__list__noData">
          <p>No assets to show</p>
        </div>}
      </div>
    </div>
    <div className={`route__createWebsite__assetManager__right ${selectedAsset ? "route__createWebsite__assetManager__right--active" : ""}`}>
      <img src={selectedAsset?.URL} />
      <p><span>ID:</span> <span>{selectedAsset?.ID}</span></p>
      <p><span>Name:</span> <span>{selectedAsset?.ImageName}</span></p>
      <Button value="Select asset" className="route__createWebsite__assetManager__right__assetSelect" accent="rgb(63, 124, 234)" onClick={() => {
        props.select(selectedAsset?.URL);
        props.close();
      }} />
    </div>
  </div>
};


export default props => {
  const finalProps = {
    isOpen: props.open,
    assets: props.assets,
    select: props.select,
    close: props.close,
    uploadFile: props?.am.config?.uploadFile,
    additionalFilters: props?.additionalFilters,
  };
  if (root) {
    root.render(<AssetManager key="grapesjs-customAssetsManager" {...finalProps}></AssetManager>)
  } else {
    root = createRoot(props.container);
    root.render(<AssetManager key="grapesjs-customAssetsManager" {...finalProps}></AssetManager>);
  };
};