import gjs from "grapesjs";

function script() {
    if(!this) return;

    const hamburger = this.querySelector('.scalecms-headerFour-bottomHeader-hambruger');
    const navbar = this.querySelector('.scalecms-headerFour-bottomHeader-navbar');
    if (!hamburger || !navbar) return;

    hamburger.addEventListener('click', () => {
        if(!navbar.classList.contains('active')){
            navbar.style.display = 'flex';
            navbar.classList.add('active');
        }
        else {
            navbar.style.display = 'none';
            navbar.classList.remove('active');
        }
    });
}

/**
 * @param {gjs.Editor} editor
 */
export default editor => {
    editor.Components.addType('scalecms-headerFour', {
        isComponent: e => e?.classList?.contains("scalecms-headerFour"),
        model: {
            defaults: {
                type: "default",
                traits: [

                ],
                attributes: {
                    class: "scalecms-headerFour"
                },
                script,
                styles: `
                    .scalecms-headerFour {
                        font-family: 'Arial';
                        width: 100%;
                    }
                    .scalecms-headerFour-topHeader{
                        height: 82px;
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        background: #162332;
                        padding-top: 5px;
                        padding-bottom: 5px;
                    }
                    .scalecms-headerFour-topHeader-banner{
                        width: 541px;
                        height: 72px;
                        margin-left: 30px;
                        margin-right: 30px;
                    }
                    .scalecms-headerFour-topHeader-socials{
                        display: grid;
                        grid-template-columns: 1fr 1fr 1fr;
                        gap: 10px;
                    }
                    .scalecms-headerFour-topHeader-socials-item{
                        width: 26px;
                        height: 26px;
                        cursor: pointer;
                        background: #677b91;
                        padding: 3px;
                        border-radius: 50%;
                    }
                    .scalecms-headerFour-topHeader-siteName{
                        font-size: 30px;
                        color: rgba(63, 103, 197, 1);
                        font-weight: 700;
                    }
                    .scalecms-headerFour-bottomHeader{
                        width: 100%;
                        background: linear-gradient(to bottom, rgba(49, 85, 171, 1) 0%, rgba(63, 103, 197, 1) 100%);
                        height: 52px;
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                    }
                    .scalecms-headerFour-bottomHeader-navbar{
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                    }
                    .scalecms-headerFour-bottomHeader-navbar-item{
                        margin-right: 20px;
                        font-weight: 700;
                        color: white;
                        cursor: pointer;
                    }
                    .scalecms-headerFour-bottomHeader-highlites{
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                    }
                    .scalecms-headerFour-bottomHeader-highlites-item{
                        display: flex;
                        flex-direction: row;
                        margin-left: 20px;
                        background: white;
                        padding: 5px;
                        cursor: pointer;
                        border-radius: 5px;
                    }
                    .scalecms-headerFour-bottomHeader-highlites-item-image{
                        width: 20px;
                        height: 20px;
                    }
                    .scalecms-headerFour-bottomHeader-highlites-item-text{
                        margin-left: 5px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        font-size: 13px;
                    }
                    .scalecms-headerFour-bottomHeader-weather{
                        margin-left: 20px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        color: white;
                        font-weight: 700;
                        background: #162332;
                        padding: 3px;
                    }
                    .scalecms-headerFour-bottomHeader-weather-box{
                        display: flex;
                        flex-direction: row;
                        justify-content: space-evenly;
                    }
                    .scalecms-headerFour-bottomHeader-weather-box-image{
                        width: 24px;
                        height: 24px;
                    }
                    .scalecms-headerFour-bottomHeader-hambruger{
                        display: none;
                    }

                    @media screen and (max-width: 1400px){
                        @keyframes animateNav{
                            0%{
                                margin-right: -1000px;
                            }
                            100%{
                                margin-right: 0px;
                            }
                        }
                        .scalecms-headerFour-bottomHeader-navbar{
                            display: none;
                            flex-direction: column;
                            position: absolute;
                            top: 0;
                            right: 0;
                            background: linear-gradient(to bottom, rgba(49, 85, 171, 1) 0%, rgba(63, 103, 197, 1) 100%);
                            width: 30%;
                            height: 100%;
                            justify-content: flex-start;
                            align-items: baseline;
                            padding: 20px;
                            animation: animateNav 1s linear; 
                        }
                        .scalecms-headerFour-bottomHeader-navbar-item{
                            margin-bottom: 10px;
                            background: white;
                            width: 100%;
                            color: rgba(63, 103, 197, 1);
                            padding: 5px;
                        }
                        .scalecms-headerFour-topHeader{
                            flex-direction: column;
                            height: fit-content;
                        }
                        .scalecms-headerFour-topHeader-banner{
                            margin-top: 10px;
                            margin-bottom: 10px;
                        }
                        .scalecms-headerFour-bottomHeader-hambruger{
                            display: block;
                        }
                        .scalecms-headerFour-bottomHeader{
                            justify-content: space-between;
                            padding-left: 10px;
                            padding-right: 10px;
                        }
                    }

                    @media screen and (max-width: 600px){
                        .scalecms-headerFour-topHeader-banner{
                            width: 95%;
                            height: 72px;
                        }
                        .scalecms-headerFour-bottomHeader-navbar{
                            width: 50%;
                        }
                        .scalecms-headerFour-bottomHeader-highlites-item{
                            display: none;
                        }
                    }
                `,
                components: [
                    {
                        tagName: 'div',
                        attributes: {class: 'scalecms-headerFour-topHeader'},
                        components: [
                            {
                                type: 'text',
                                attributes: {class: 'scalecms-headerFour-topHeader-siteName'},
                                components: 'NazivSajta'
                            },
                            {
                                type: 'image',
                                attributes: {class: 'scalecms-headerFour-topHeader-banner'}
                            },
                            {
                                tagName: 'div',
                                attributes: {class: 'scalecms-headerFour-topHeader-socials'},
                                components: [
                                    {
                                        tagName: 'img',
                                        attributes: {class: 'scalecms-headerFour-topHeader-socials-item', src: 'https://iconarchive.com/download/i80477/uiconstock/socialmedia/Pinterest.ico'}
                                    },
                                    {
                                        tagName: 'img',
                                        attributes: {class: 'scalecms-headerFour-topHeader-socials-item', src: 'https://i.imgur.com/yh45vCH.png'}
                                    },
                                    {
                                        tagName: 'img',
                                        attributes: {class: 'scalecms-headerFour-topHeader-socials-item', src: 'https://i.imgur.com/OWdUupI.png'}
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        tagName: 'div',
                        attributes: {class: 'scalecms-headerFour-bottomHeader'},
                        components: [
                            {
                                tagName: 'img',
                                attributes: {class: 'scalecms-headerFour-bottomHeader-hambruger', src: 'https://i.imgur.com/boqY7Lz.png'}
                            },
                            {
                                tagName: 'div',
                                attributes: {class: 'scalecms-headerFour-bottomHeader-navbar'},
                                components: [
                                    {
                                        type: 'text',
                                        attributes: {class: 'scalecms-headerFour-bottomHeader-navbar-item'},
                                        components: 'nav-item'
                                    },
                                    {
                                        type: 'text',
                                        attributes: {class: 'scalecms-headerFour-bottomHeader-navbar-item'},
                                        components: 'nav-item'
                                    },
                                    {
                                        type: 'text',
                                        attributes: {class: 'scalecms-headerFour-bottomHeader-navbar-item'},
                                        components: 'nav-item'
                                    },
                                    {
                                        type: 'text',
                                        attributes: {class: 'scalecms-headerFour-bottomHeader-navbar-item'},
                                        components: 'nav-item'
                                    },
                                    {
                                        type: 'text',
                                        attributes: {class: 'scalecms-headerFour-bottomHeader-navbar-item'},
                                        components: 'nav-item'
                                    },
                                ]
                            },
                            {
                                tagName: 'div',
                                attributes: {class: 'scalecms-headerFour-bottomHeader-highlites'},
                                components: [
                                    {
                                        type: 'div',
                                        attributes: {class: 'scalecms-headerFour-bottomHeader-highlites-item'},
                                        components: [
                                            {
                                                tagName: 'img',
                                                attributes: {class: 'scalecms-headerFour-bottomHeader-highlites-item-image', src: 'https://cdn-icons-png.flaticon.com/512/493/493805.png'}
                                            },
                                            {
                                                type: 'text',
                                                components: 'Konsultacije',
                                                attributes: {class: 'scalecms-headerFour-bottomHeader-highlites-item-text'},
                                            }
                                        ]
                                    },
                                    {
                                        type: 'text',
                                        attributes: {class: 'scalecms-headerFour-bottomHeader-highlites-item'},
                                        components: [
                                            {
                                                tagName: 'img',
                                                attributes: {class: 'scalecms-headerFour-bottomHeader-highlites-item-image', src: 'https://cdn-icons-png.flaticon.com/512/3209/3209915.png'}
                                            },
                                            {
                                                type: 'text',
                                                components: 'Najnovije',
                                                attributes: {class: 'scalecms-headerFour-bottomHeader-highlites-item-text'},
                                            }
                                        ]
                                    },
                                    {
                                        type: 'text',
                                        attributes: {class: 'scalecms-headerFour-bottomHeader-highlites-item'},
                                        components: [
                                            {
                                                tagName: 'img',
                                                attributes: {class: 'scalecms-headerFour-bottomHeader-highlites-item-image', src: 'https://cdn-icons-png.flaticon.com/512/55/55281.png'}
                                            },
                                            {
                                                type: 'text',
                                                components: 'Zakazivanje',
                                                attributes: {class: 'scalecms-headerFour-bottomHeader-highlites-item-text'},
                                            }
                                        ]
                                    },
                                ]
                            },
                            {
                                tagName: 'div',
                                attributes: {class: 'scalecms-headerFour-bottomHeader-weather'},
                                components: [
                                    {
                                        type: 'text',
                                        components: 'VRIJEME',
                                    },
                                    {
                                        tagName: 'div',
                                        attributes: {class: 'scalecms-headerFour-bottomHeader-weather-box'},
                                        components: [
                                            {
                                                tagName: 'img',
                                                attributes: {src: 'https://cdn-icons-png.flaticon.com/512/831/831682.png', class: 'scalecms-headerFour-bottomHeader-weather-box-image'}
                                            },
                                            {
                                                type: 'text',
                                                components: '+7*'
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        }
    });


    editor.BlockManager.add('scalecms-headerFour-blk', {
        label: 'Header 4',
        content: {
            type: "scalecms-headerFour",
        },
        category: {
            label: 'Headers'
        },
        media: `<svg viewBox="0 0 24 24"><g data-name="layout 34"><path d="M19,2H5A3,3,0,0,0,2,5V19a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V5A3,3,0,0,0,19,2Zm1,17a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V5A1,1,0,0,1,5,4H19a1,1,0,0,1,1,1Z"/><path d="M17,5H7A2,2,0,0,0,5,7V8a2,2,0,0,0,2,2H17a2,2,0,0,0,2-2V7A2,2,0,0,0,17,5Zm0,3H7V7H17Z"/></g></svg>`,
        attributes: {
            title: 'Basic header 4',
        },
        select: true
    });
};