import gjs from "grapesjs";

import swiper from "swiper/bundle";

/**
 * @param {gjs.Editor} editor
 */
export default editor => {
    editor.Components.addType('scalecms-slider', {
        isComponent: e => e?.classList?.contains("scalecms-slider"),
        model: {
            init() {
                let curSwiper = null;
                this.on("component:update", () => {
                    if (curSwiper) return;

                    setTimeout(() => {
                        let slider = this.find(".swiper");
                        let pagination = this.find(".swiper-pagination");
                        let prevBtn = this.find(".swiper-button-prev");
                        let nextBtn = this.find(".swiper-button-next");

                        if (
                            slider.length !== 1 ||
                            pagination.length !== 1 ||
                            prevBtn.length !== 1 ||
                            nextBtn.length !== 1
                        ) return;
                        slider = slider[0].getEl();
                        pagination = pagination[0].getEl();
                        prevBtn = prevBtn[0].getEl();
                        nextBtn = nextBtn[0].getEl();
                        
                        curSwiper = new swiper(slider, {
                            direction: 'horizontal',
                            loop: false,
                            pagination: {
                              el: pagination,
                            },
                            navigation: {
                              nextEl: nextBtn,
                              prevEl: prevBtn,
                            },
                          });
                    }, 0);
                });
            },
            defaults: {
                type: "default",
                traits: [
                    {name: "data-effect", label: "Effect", type: "select", options: [
                        {id: "fade", name: "Fade in-out", selected: true},
                        {id: "cube", name: "Cube 3D"},
                        {id: "coverflow", name: "Book covers"},
                        {id: "flip", name: "Flip"},
                        {id: "cards", name: "Deck of cards"},
                        {id: "creative", name: "Fly in-out"}
                    ], value: "fade"},
                    {name: "data-loop", label: "Loop slides", type: "select", options: [
                        {id: "1", name: "Yes", selected: true},
                        {id: "0", name: "No"},
                    ], value: "true"},
                ],
                attributes: {
                    class: "scalecms-slider"
                },
                styles: `
                    .scalecms-slider {
                        width: 100%;
                        height: auto;
                        min-height: 40px;
                    }
                    .scalecms-slider .swiper {
                        width: 600px;
                        height: 300px;
                    }                      
                `,
                activeOnRender: 1
            },
        },
        view: {
            onActive() {
                let cols = null;
                const cb = (c) => {
                    cols = c;
                };
                let modal = editor.Modal
                    .setTitle("Insert slider")
                    .onceClose(() => {
                        if (!cols) {
                            return editor.getSelected().remove();
                        };
                        this.model.components(`
                            <div class="swiper">
                                <div class="swiper-wrapper">

                                ${new Array(cols).fill(0).map((_,idx) => {
                                    return `<div class="swiper-slide">
                                        <p>Slide ${idx+1}</p>
                                    </div>`
                                }).join("")}

                                </div>

                                <div class="swiper-pagination"></div>
                            
                                <div class="swiper-button-prev"></div>
                                <div class="swiper-button-next"></div>
                            </div>
                        `);
                    })
                    .open();
                let content = modalContent(modal, cb);
                modal.setContent(content.wrap);
            }
        }
    });

    editor.BlockManager.add('scalecms-slider-blk', {
        label: 'Slider',
        content: {
            type: "scalecms-slider",
        },
        category: {
            label: 'Extra'
        },
        media: `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 32 32" style="filter:invert(1); enable-background:new 0 0 32 32;" xml:space="preserve"><style type="text/css">.st0{fill:none;stroke:#000000;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}</style><polyline class="st0" points="25,11 27,13 25,15 "/><polyline class="st0" points="7,11 5,13 7,15 "/><path class="st0" d="M29,23H3c-1.1,0-2-0.9-2-2V5c0-1.1,0.9-2,2-2h26c1.1,0,2,0.9,2,2v16C31,22.1,30.1,23,29,23z"/><circle class="st0" cx="16" cy="28" r="1"/><circle class="st0" cx="10" cy="28" r="1"/><circle class="st0" cx="22" cy="28" r="1"/></svg>`,
        attributes: {
            title: 'Creates a slider',
        },
        select: true
    });
};

/**
 * 
 * @param {gjs.Modal} modal 
 * @param {(rows: number, cols: number) => void} cb
 * @returns {{wrap: HTMLDivElement, getData: () => {rows: number, cols: number}}}
 */
const modalContent = (modal, cb) => {
    let wrap = document.createElement("div");
    wrap.classList.add("custom_modalSwiper");

    let colsWrap = document.createElement("div");
    colsWrap.innerHTML = "<span>No. of columns:</span>";
    let colsInput = document.createElement("input");
    colsInput.value = 2;
    colsInput.type = "number";
    colsWrap.appendChild(colsInput);

    let saveBtn = document.createElement("button");
    saveBtn.textContent = "Save";
    saveBtn.style.marginTop = "20px";
    saveBtn.classList.add("gjs-btn-prim");

    const style = document.createElement("style");
    style.innerHTML = `
        .custom_modalSwiper input {
            background: transparent;
            border: 1px solid gray;
            margin-left: 10px;
            padding: 0px 5px;
            outline: none;
            color: white;
            width: 80px;
        }
        .custom_modalSwiper > div {
            margin-bottom: 5px;
        }
    `;

    saveBtn.addEventListener("click", () => {
        let cols = Number(colsInput.value);
        if (isNaN(cols)) {
            cb(0);
            return setTimeout(() => modal.close(), 0);
        };
        cb(cols);
        return setTimeout(() => modal.close(), 0);
    });

    wrap.appendChild(colsWrap);
    wrap.appendChild(saveBtn);
    wrap.appendChild(style);

    return {wrap};
};