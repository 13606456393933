import React from "react";
import "./index.scss";


const DocCustomAssets = (props) => {

  return <div className="route__documentation">
    <div className="route__documentation__headline">
      <p>Custom Assets</p>
    </div>
    <div className="route__documentation__desc">
      <p>Custom Assets is a section of different components and add ons for the web building,</p>
      <p>We will go through them and what do they do.</p>
    </div>
    <div className="route__documentation__introBlock">
      <div className="route__documentation__introBlock__left">
        <img src="/images/tutorials/customAssets/DocCustomAssets1.png" />
      </div>
      <div className="route__documentation__introBlock__right">
        <p>Firstly we have to click on the '+' icon to open the side menu dropdown</p>
        <p>Then we have to select the 'Custom assets' tab in the menu</p>
      </div>
    </div>

    <div className="route__documentation__section-headline">
      <p>Producnt Bught PopUp</p>
    </div>
    <div className="route__documentation__introBlock">
      <div className="route__documentation__introBlock__left">
        <img src="/images/tutorials/customAssets/DocCustomAssets5.png" />
      </div>
      <div className="route__documentation__introBlock__right">
        <p>This is a component for pop up on the site</p>
      </div>
    </div>
    <div className="route__documentation__introBlock">
      <div className="route__documentation__introBlock__left">
        <img src="/images/tutorials/customAssets/DocCustomAssets6.png" />
      </div>
      <div className="route__documentation__introBlock__right">
        <p>When we insert the component, these are it's settings</p>
        <p>Basically we are choosing how much seconds will it take for component to pop out</p>
      </div>
    </div>
    <div className="route__documentation__introBlock flex-column">
    <div className="route__documentation__introBlock__right">
        <p>This is how our component looks like</p>
        <p>Keep in mind that everything can be customizable</p>
      </div>
      <div className="route__documentation__introBlock__left">
        <img src="/images/tutorials/customAssets/DocCustomAssets7.png" />
      </div>
    </div>


  </div>
};

export default DocCustomAssets;
export { DocCustomAssets };